import styled from 'styled-components';
import {mediaQuery} from '../../utils';

export const MainContainer = styled.div`
  width: 1054px;
  height: 352px;
  color: rgba(0,0,0,0.87);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  :hover {
    box-shadow: 0 0 13px 2px rgb(14 26 62 / 16%);
    border-radius: 8px;
  }
  button {
    font-size: 16px;
  }
  ${mediaQuery} {
    height: fit-content;
    width: 300px;
    flex-direction: column-reverse;
    button {
      font-size: 14px;
      height: 36px;
      line-height: 19px;
      padding: 8px 21px !important;
    }
  }
`;

export const LeftContainer = styled.div`
width: 100%;
display: flex; 
color: ${props => props.textColor};
flex-direction: column;
padding: 1% 1.46% 0.8% 3%;
background-color: ${props => props.color};
flex-basis:33.33%; 
border-radius: 8px 0 0 8px;
${mediaQuery} {
  padding-left: 16px;
  height: 174px;
  flex-basis: auto;
  border-radius: 0 0 8px 8px;
}
`;

export const RightContainer = styled.div`
flex-basis:66.66%; 
width: 100%;
& img {
    border-radius: 0 8px 8px 0;
    width: 100%;
    height: 100%;
  }
  ${mediaQuery} {
    width: 100%;
    height: 151px;
    flex-basis: auto;

    & img 
    {
      border-radius: 8px 8px 0px 0;
    }
  }
`;

export const Title = styled.div`
  height: 70px;
  margin-bottom: 45px;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 40px;
  width: 50%;
  font-family: Rubik;
  width: fit-content;
  ${mediaQuery} {
    min-height: 40px;
    height: fit-content;
    margin-bottom: 20px;
    font-size: 20px;
    letter-spacing: 0.15px;
    line-height: 26px;
  }
`;

export const Place = styled.div`
  font-family: Rubik;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 28px;
  margin-top: 25%;
  margin-bottom: 16px;
  ${mediaQuery} {
    margin-top: 16px;
    margin-bottom: 8px;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 22px;
}
`;




