import styled from 'styled-components';
import {mediaQuery1320} from '../../utils';

export const MainContainer = styled.div`
  width: 100%;
  padding: 0px 80px;
  display: flex;
  justify-content: center;
  
  #description > p {
    margin-bottom: 8px;
  } 

  ${mediaQuery1320} {
    padding: 0 0 24px;
    overflow-x: hidden;
  }
`;

export const Container = styled.div`
  ${mediaQuery1320} {
    width: 100%;
  }
  
  .map-container {
    height: 320px; 
    width: 840px;
    margin-bottom: 44px;

    ${mediaQuery1320} {
      width: 100%;
      height: 35vh;
      padding: 0 16px;
      margin-bottom: 28px;
    }
  }
  
  .title-event {
    ${mediaQuery1320} {
      display: none;
    }
  }
  
  .iframe-video {
    width: 840px;
    height: 440px;
    border: none;
    border-radius: 8px;
    margin-bottom: 24px;
    
    ${mediaQuery1320} {
      width: 100%;
      padding: 0 16px;
      height: 172px;
      margin-bottom: 40px;
    }
  }
`;

export const LocalizationContainer = styled.div`
  display: flex;
  color: rgba(0,0,0,0.87);
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 24px;
  margin-bottom: 64px;

  ${mediaQuery1320} {
    margin-bottom: 40px;;
    display: inline;
  }
`;

export const DirectionContainer = styled.div`
  width: 410px;
  padding-right: 20px;

  ${mediaQuery1320} {
    width: 90%;
    margin: 0 16px 42px 16px;
  }
`;

export const TelephoneContainer = styled.div`
  ${mediaQuery1320} {
    width: 90%;
    margin: 0 16px 80px 16px;
  }
`;

export const CarrouselContainer = styled.div`
  max-width:100%;
  ${mediaQuery1320} {
    width: 97%;
    margin-bottom: 82px;
  }
`;

export const Title = styled.div`
  width: 840px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.18px;
  line-height: 32px;
  margin: 0 0 32px 0;
  padding-top: 40px;

  ${mediaQuery1320} {
    margin: 0 16px 24px 16px;
  }
`;

export const Subtitle = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.15px;
  line-height: 24px;
  margin-bottom: 16px;
`;

export const Description = styled.div`
  width: 840px;
  color: #7E7E7E;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 24px;
  margin-bottom: 16px;
  overflow: hidden;
  -webkit-line-clamp: ${props => props.readMore ? 'inherit' : '3'};
  -webkit-box-orient: vertical;
  display: -webkit-box;

  ${mediaQuery1320}{
    width: 100%;
    padding: 0 16px;
    -webkit-line-clamp: ${props => props.readMore ? 'inherit' : '5'};
  }
`;

export const ButtonRead = styled.div`
  color: #7B00B2;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.15px;
  line-height: 24px;
  cursor: pointer;

  ${mediaQuery1320}{
    padding: 0 16px;
    margin-bottom: 0px;
  }
`;




