import React, {memo} from 'react';
import GlobalStyles from '../../GlobalStyles';
//import BannerApp from '../../components/BannerApp';
import {
    EventHomeImage,
    AnunciateConNosotros,
    AnunciateConNosotrosComplete,
    EventHomeImageResponsive
} from '../../Assets';
import DiscoverPlan from '../../components/DiscoverPlan';
import AdvantagesGroup from '../../components/AdvantagesGroup';
import HowtoBuyList from '../../components/HowToBuyContainer';
import DoubtsContainer from '../../components/DoubtsContainer';
import {advantages_data, 
    instructions_data, social_media_data, doubts_topics_data, doubts_questions_data, our_clients_data
       }from './data';
import ContactUs from '../../components/ContactUs';
import UsBannerSeparate from '../../components/UsBannerSeparate';
import OurClients from '../../components/OurClients';

function EventsSection() {
    return (
        <div style={{overflowX: 'hidden'}}>
            
            <GlobalStyles/>
            <>
            <DiscoverPlan
                title="Armenos un plan juntos."
                buttonText = "Empieza aquí"
                image = {EventHomeImage}
                imageResponsive = {EventHomeImageResponsive}
                type= "image"
                urlToGo='https://form.typeform.com/to/aFOy7WHS '
            />
            <HowtoBuyList 
                title="Tu evento empieza aquí"
                data={instructions_data}
                style="secondary"
                video="//www.youtube.com/embed/QMx6KB2y1L0"
                />
            <AdvantagesGroup 
                titleMobile="Beneficios de publicar tu evento"
                titleDesktop="Beneficios de publicar tu evento"
                data={advantages_data}
                style="secondary"
                />
            <OurClients 
                title="Nuestros clientes"
                data={our_clients_data}
            />
            <DoubtsContainer 
                title="¿Tienes dudas?"
                topics={doubts_topics_data}
                questions={doubts_questions_data}
                style="secondary"
                />
            <UsBannerSeparate
                 title="¿Buscas anunciarte con nosotros?"
                 image={AnunciateConNosotrosComplete}
                 imageResponsive={AnunciateConNosotros}
                 url="/anunciate"
            />
            <ContactUs
                title="Escríbenos o llámanos, estamos para ayudarte"
                description="Nuestro equipo esta listo para ayudarte, comunícate con nosotros."
                subtitle="Síguenos en nuestras redes sociales"
                data={social_media_data}
            />
                {/* {!localStorage.getItem('pwa') &&
                <BannerApp
                    image={BannerAppImage}
                    imageResponsive={BannerAppImageResponsive}
                    title="¡Llévanos contigo! "
                    subtitle="Descarga la app"
                    urlAppStore=""
                    urlGooglePlay=""
                />}  */}

            </>
        </div>
    );
}

export default memo(EventsSection);
