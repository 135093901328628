import styled from 'styled-components';
import {mediaQuery1320} from '../../utils';

export const MainContainer = styled.div`
  position: fixed;
  width: 288px;
  margin-top: 79px;
  height: calc(100vh - 80px);
  min-width: 288px;
  border: 1px solid #E5E5E5;
  background: #fff;
  padding-top: 56px;

  ${mediaQuery1320} {
    display: none;
  }
`;

export const MenuOption = styled.div`
  display: flex;
  width: fit-content;
  color: ${props => props.isActive ? '#B361CF' : '#666666'};
  font-size: 16px;
  font-weight: ${props => props.isActive ? '600' : '400'};
  letter-spacing: 0.15px;
  font-family: Rubik;
  line-height: 24px;
  margin-bottom: 28px;
  cursor: ${props => props.isActive ? 'inherit' : 'pointer'};

  & .icon {
    width: 24px;
    height: 24px;
    margin: 0 16px 0 ${props => props.isActive ? '21px' : '24px'};
  }
`;

export const MenuOptionMarker = styled.div`
  border-right: ${props => props.isActive ? '3px solid #B361CF' : 'none'};
  height: 32px;
  width: 3px;
  background-color: ${props => props.isActive ? '#B361CF' : 'white'};
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  margin-left: -2px;
  margin-top: -4px;
`;

export const BottomContainer = styled.div`
  position: fixed;
  width: 287px;
  bottom: 0;
  height: 88px;
  border-top: 1px solid #E5E5E5;
  padding-top: 32px;
`;

