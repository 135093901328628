import styled from 'styled-components';
import {mediaQuery} from '../../utils';

export const MainContainer = styled.div`
  position: relative;

  & img {
    height: ${props => props.bigCard ? '448px' : '352px'};
    width: ${props => props.bigCard ? '300px' : '236px'};
    border-radius: 8px;
    
    ${mediaQuery} {
      width: 144px;
      height: 216px;
    }
  }
`;

export const TitleContainer = styled.div`
  color: rgba(255, 255, 255);
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 36px;
  position: absolute;
  bottom: 24px;
  margin: 0px 24px;
  
  ${mediaQuery} {
    font-size: 18px;
    letter-spacing: 0.14px;
    line-height: 20px;
    margin: 0px 16px;
  }
`;


