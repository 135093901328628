import React, {memo} from 'react';
import {MainContainer, Title, DescriptionDesktop, DescriptionResponsive} from './styles';
import {AsombradoPhone} from '../../Assets';
import Button from "../../components/Button";
import ModalFullscreen from "../../components/ModalFullscreen";
import {useHistory, useLocation} from "react-router-dom";
import axios from "axios";
import {message} from "antd";
import { Helmet } from "react-helmet";

const RemainderActiveAccount = () => {
    let {search} = useLocation();
    const query = new URLSearchParams(search);
    const history = useHistory();

    const closeAndRedirectIcon = () => {
        history.push("/login")
    }

    const email = query.get('email');
    const reSendEmail = () => {
        const data = {
            email: email,
            type_email: "web"
        }
        axios.post(process.env.REACT_APP_API_ENDPOINT_URL + `/api/v1/auth/resend-account-validate`, data)
            .then(res => {
                message.success({ content: 'El correo de activación ha sido reenviado.', duration: 3 });
                history.push("/login");
            }).catch(error => {
            console.log("error", error);
        });
    }

    return (
        <ModalFullscreen openModal onCloseModal={closeAndRedirectIcon}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Activa tu Cuenta</title>
            </Helmet>
            <MainContainer>
                <img src={AsombradoPhone} alt="" className="phone-icon"/>
                <Title>Por favor activa tu cuenta</Title>
                <DescriptionDesktop>Para iniciar sesión confirma tu correo electrónico, si han <br/> pasado más de 7 días desde que
                    creaste cuenta, selecciona <br/> el botón para reenviar tu correo de activación</DescriptionDesktop>
                <DescriptionResponsive>Para iniciar sesión confirma tu correo electrónico, si han pasado más de 7 días desde que
                    creaste cuenta, selecciona el botón para reenviar tu correo de activación</DescriptionResponsive>
                <Button
                    text="Reenviar correo"
                    className="button-back"
                    onClick={reSendEmail}
                />
            </MainContainer>
        </ModalFullscreen>
    )
};

/*
RemainderActiveAccount.propTypes = {
  openModal: PropTypes.bool,
  onCloseModal: PropTypes.func,
};

RemainderActiveAccount.defaultProps = {
  openModal: false,
  onCloseModal: () => {},
};*/

export default memo(RemainderActiveAccount);