import styled from 'styled-components';
import {mediaQuery1320} from '../../utils';

export const MainContainer = styled.div`
  display: flex;
  ${mediaQuery1320} {
    flex-direction: column;
  }
`;

export const RightContainer = styled.div`
  width: 100%;
  margin-left: ${props => props.isEmpty && '288px'};
  height: calc(100vh - 80px);
  background-color: #FAFAFA;
  margin-top: 80px;
  padding: 56px 80px;
  
  ${mediaQuery1320} {
    margin-top: 203px;
    height: calc(100vh - 203px);
    padding: 22px 8px;
  }
`;

export const Container = styled.div`
  width: 100%;
  text-align: center;
  padding: 40px 0 40px 96px;
  overflow-x: hidden;

  ${mediaQuery1320} {
    width: 100%;
    height: 100%;
    padding: 0;
  }
`;

export const TicketsContainer = styled.div`
  width: 102%;
  display: flex;
  overflow-x: scroll;
  margin-bottom: 53px;
  padding-bottom: 24px;

  ${mediaQuery1320} {
    padding: 0px 0px 24px 16px;
  }
`;

export const TicketBody = styled.div`
  margin-right: 48px;

  ${mediaQuery1320} {
    margin-right: 16px;
  }
`;

export const Title = styled.div`
  color: #191919;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 40px;
  margin-bottom: 69px;
  text-align: left;

  ${mediaQuery1320} {
    font-size: 20px;
    letter-spacing: 0.15px;
    line-height: 28px;
    padding: 18px 16px;
    width: 100%;
    border-bottom: 1px solid #D9D9D9;
    background-color: white;
    margin-bottom: 16px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;

  & .button-download {
    height: 48px;
    width: 210px;
    border-radius: 8px;
    background-color: #E9E1EE;
    color: #7B00B2;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.23px;
    line-height: 24px;
    margin-right: 24px;
  }

  & .button-calendar {
    height: 49px;
    width: 219px;
    border: 1px solid #E9E1EE;
    border-radius: 7px;
    color: #7B00B2;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.23px;
    line-height: 24px;
    display: flex;
    padding: 13px 16px 11px 16px;
  }

  & .button-calendar img {
    height: 22px;
    width: 24px;
    margin-right: 12px;
  }
`;

export const EmptyMainContainer = styled.div`
  width: 100%;
  text-align: center;

  & .phone-icon {
    margin-top: 150px;
    margin-bottom: 40px;
    height: 120px;
  }

  & .button-back {
    margin-top: 48px;
    font-weight: 600;
  }
`;

export const EmptyTitle = styled.div`
  color: #333333;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.18px;
  line-height: 32px;
  margin-bottom: 32px;
`;

export const EmptyDescription = styled.div`
  color: #666666;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 26px;
  text-align: center;
`;

export const ViewMore = styled.a`
  float: right;
  height: 24px;
  width: 63px;
  color: #7B00B2;
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.15px;
  line-height: 24px;
`



