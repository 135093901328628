import React, { memo, useState, useEffect } from 'react';
import GlobalStyles from '../../GlobalStyles';
import {
  getDiscounts,
  getWeekend,
  getFree,
  getHighlighted,
  getOnline,
  getBanners,
  getRecommendations,
  getCategories,
} from '../../services/events';

import ExploreEvents from '../../components/ExploreEvents';
import CategoryCardList from '../../components/CategoryCardList';
import ExploreEventsCarrousel from '../../components/ExploreEventsCarrousel';
import RecommendationsCarrousel from '../../components/RecommendationsCarrousel';
import Carrousel from '../../components/Carrousel';
import CardOrganizeEvent from '../../components/CardOrganizeEvent';
import BannerApp from '../../components/BannerApp';
import {
  VasAOrganizar,
  VasAOrganizarResponsive,
  BuscasAnunciarte,
  BuscasAnunciarteResponsive,
  BannerAppImage,
  BannerAppImageResponsive,
} from '../../Assets';
import { get } from 'lodash';
import DialogFirstLogin from '../../components/DialogFirstLogin';


function Dashboard() {
  const user =  JSON.parse(localStorage.getItem('d'));
  const [groupData, setGroupData] = useState({});
  const [responseBanners, setBanners] = useState([]);
  const [recommendations, setRecommendations] = useState([]);
  const [categories, setCategories] = useState([]);
  const [freeCards, setFreeCards] = useState([]);
  const [highlightsCards, setHighlightsCards] = useState([]);
  const [offersGroupCards, setOffersGroupCards] = useState([]);
  const [onlineCards, setOnlineCards] = useState([]);
  const [weekendCards, setWeekendCards] = useState([]);
  const [isFirstLogin, setIsFirstLogin] = useState(
    user && user.is_first_login,
  );
  
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const handleCloseDialog = () => {
    user.is_first_login = false

    localStorage.setItem('d', JSON.stringify(user));
    setIsFirstLogin(false);
    setOpenDialog(false);
  };

  useEffect(() => {
    fetchBanners();
    fetchRecommendations();
    fetchCategories();
    fetchHome();
  }, []);

  useEffect(() => {
    setGroupData({
      RecommendationsGroup: {
        title: 'Nuestras recomendaciones',
        cardsInfo: recommendations,
      },
      CategoriesInfo: categories,
    });
    if (isFirstLogin === 'true') {
      setOpenDialog(true);
    }
  }, [recommendations, categories]);

  const fetchHome = async () => {
    setLoading(true);
    let [
      freeData,
      weekendData,
      discountData,
      highlightsData,
      onlineData,
    ] = await Promise.all([
      getFree(),
      getWeekend(),
      getDiscounts(),
      getHighlighted(),
      getOnline(),
    ]);

    if (onlineData.data) {
      let tempOnlineCards = [];
      onlineData.data.cardInfo.forEach((category) => {
        tempOnlineCards.push({
          ...category,
          url: `/detail/${category.id}`,
        });
      });
      setOnlineCards(tempOnlineCards);
    }

    if (freeData.data) {
      let tempFreeCards = [];
      freeData.data.cardInfo.forEach((category) => {
        tempFreeCards.push({
          ...category,
          url: `/detail/${category.id}`,
        });
      });
      setFreeCards(tempFreeCards);
    }

    if (highlightsData.data) {
      let tempHighlightsCards = [];
      highlightsData.data.cardInfo.forEach((category) => {
        tempHighlightsCards.push({
          ...category,
          url: `/detail/${category.id}`,
        });
      });
      setHighlightsCards(tempHighlightsCards);
    }

    if (weekendData.data) {
      let tempWeekendCards = [];
      weekendData.data.cardInfo.forEach((category) => {
        tempWeekendCards.push({
          ...category,
          url: `/detail/${category.id}`,
        });
      });
      setWeekendCards(tempWeekendCards);
    }

    if (discountData.data) {
      let tempOffersGroupCards = [];
      discountData.data.cardInfo.forEach((category) => {
        tempOffersGroupCards.push({
          ...category,
          url: `/detail/${category.id}`,
        });
      });
      setOffersGroupCards(tempOffersGroupCards);
    }

    setLoading(false);
  };

  const fetchBanners = async () => {
    try {
      const data = await getBanners();
      if (data) {
        setBanners(get(data, 'data', []));
      }
    } catch (err) {
      // ERR
    }
  };

  const fetchRecommendations = async () => {
    try {
      const data = await getRecommendations();
      if (data) {
        let tempRecomendations = [];
        const recommendationsResponse = get(data, 'data.data', []);
        recommendationsResponse.map((item) => {
          tempRecomendations.push({
            title: item.description,
            image: item.icon_url,
            url: `/recomendaciones?recommendationId=${item.id}`,
          });
        });
        setRecommendations(tempRecomendations);
      }
    } catch (err) {
      // ERR
    }
  };

  const fetchCategories = async () => {
    try {
      const data = await getCategories();
      if (data) {
        let tempCategories = [];
        data.data.forEach((category) => {
          tempCategories.push({
            title: category.title,
            image: category.icon,
            color: category.hex_color,
            background: category.image_url,
            id: category.id,
          });
        });
        setCategories(tempCategories);
      }
    } catch (err) {
      // ERR
    }
  };

  
  return (
    <div style={{ overflowX: 'hidden' }}>
      
      <DialogFirstLogin open={openDialog} onClose={handleCloseDialog} />
      
      <GlobalStyles />
      <>
        <ExploreEvents />
        <div className="body-dashboard">
          {responseBanners.length !== 0 && (
            <ExploreEventsCarrousel data={responseBanners} />
          )}
          {categories.length !== 0 && <CategoryCardList data={categories} />}
          {highlightsCards.length !== 0 && (
            <Carrousel
              data={highlightsCards}
              title="Destacados"
              path="/eventos-destacados"
            />
          )}
          {get(groupData, 'RecommendationsGroup.cardsInfo', []).length !==
            0 && (
            <RecommendationsCarrousel
              recommendationsGroup={groupData.RecommendationsGroup}
            />
          )}
          {offersGroupCards.length !== 0 && (
            <Carrousel
              data={offersGroupCards}
              title="Ofertas"
              path="/ofertas"
            />
          )}
          <CardOrganizeEvent
            image={VasAOrganizar}
            imageResponsive={VasAOrganizarResponsive}
            title="¿Vas a organizar un "
            lastWord="evento?"
            color="#FFFFFF"
            url="https://form.typeform.com/to/aFOy7WHS"
          />
          {weekendCards.length !== 0 && (
            <Carrousel
              data={weekendCards}
              title="En este fin de semana"
              path="/esta-semana"
            />
          )}
          {freeCards.length !== 0 && (
            <Carrousel
              data={freeCards}
              title="Entrada libre"
              path="/entrada-libre"
            />
          )}
          {onlineCards.length !== 0 && (
            <Carrousel data={onlineCards} title="En línea" path="/en-linea" />
          )}
          <CardOrganizeEvent
            image={BuscasAnunciarte}
            imageResponsive={BuscasAnunciarteResponsive}
            title="¿Buscas "
            lastWord="anunciarte?"
            url="https://form.typeform.com/to/jDHUs9Vo"
          />
          
        </div>
        {/*
                {!localStorage.getItem('pwa') &&
                <BannerApp
                    image={BannerAppImage}
                    imageResponsive={BannerAppImageResponsive}
                    title="¡Llévanos contigo! "
                    subtitle="Descarga la app"
                    urlAppStore=""
                    urlGooglePlay=""
                />}*/}
      </>
    </div>
  );
}

export default memo(Dashboard);
