import styled from "styled-components";

export const Container = styled.div`
  max-width: 580px;

  h2 {
    margin-bottom: 48px;
    font-size: 32px;
    font-weight: 600;
  }

  .select-card-label {
    color: #191919;
    margin-bottom: 24px;
    font-size: 16px;
  }
`;

export const MethodItem = styled.div`
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  padding: 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.25s;
  margin-bottom: ${(props) => props.lastItem ? '24px' : '16px'};

  img {
    width: 40px;
    object-fit: contain;
    margin-right: 16px;
  }

  .method-name {
    padding-right: 16px;
    flex-grow: 1;
    font-size: 18px;
    color: #333333;
  }

  &:hover {
    opacity: 0.7;
  }
`;

export const Check = styled.div`
  width: 20px;
  height: 20px;
  border: 2px solid ${props => props.active ? '#B361CF' : '#CCCCCC'};
  padding: 2px;
  border-radius: 50%;
  transition: all 0.25s;

  div {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: ${props => props.active ? '#B361CF' : '#ffffff'};
    transition: all 0.25s;
  }
`;

export const CardItem = styled.div`
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  margin-bottom: 16px;
  transition: all 0.25s;
  font-size: 16px;

  .header {
    display: flex;
    align-items: center;
    color: #666666;
    padding: 16px 24px 16px;
    transition: all 0.25s;
    cursor: pointer;
  }

  .header .card-image {
    width: 36px;
    object-fit: contain;
    margin-right: 24px;
  }

  .header .card-details {
    flex-grow: 1;
    padding-right: 16px;
  }

  .header .arrow-icon {
    ${props => props.open ? 'transform: rotate(180deg);' : ''}
    transition: all 0.25s;
  }

  .content {
    padding: 8px 16px 16px 76px;
    display: flex;
    align-items: center;
    color: #666666;
  }

  .new-card {
    padding-left: 24px;
    padding-right: 24px;
  }

  .content .input {
    height: 40px;
    border-radius: 8px;
    margin-right: 16px;
    width: 136px;
  }

  .header:hover {
    opacity: 0.7;
  }

  &:last-child {
    /* margin-bottom: 24px; */
  }

  .medium {
    font-weight: 600;
  }
`;

export const ErrorMessage = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 16px;
  margin-bottom: 16px;

  img {
    margin-right: 16px;
    margin-top: 4px;
  }

  div {
    font-size: 16px;
    color: #666666;
    max-width: 546px;
  }
`;

export const NewPaymentContainer = styled.div`

  .payment-title {
    color: #191919;
    margin-bottom: 24px;
  }

  .instructions {
    color: #666666;
    margin-bottom: 24px;
  }

  .input-name {
    height: 52px;
    border-radius: 8px;
    margin-bottom: 16px;
    width: 100%;
  }
`;
