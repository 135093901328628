import React from 'react';
import PropTypes from 'prop-types';
import {compose} from 'redux';
import {Route, withRouter} from 'react-router-dom';
import Header from '../../../components/Header';

const Index = (props) => {
    const {
        component: Component,
        token,
        layout: Layout,
        history,
        layoutProps,
        fixedHeader,
        headerBorder,
        ...rest
    } = props;

    return (
        <Route
            {...rest}
            render={props => (
                <Layout
                    {...layoutProps}
                    pathname={props.location.pathname}
                    history={history}
                >
                    {!localStorage.getItem('pwa') && <Header User fixedHeader headerBorder={headerBorder}/>}
                    <Component {...props} />
                </Layout>
            )}
        />
    );
};

Index.propTypes = {
    component: PropTypes.any,
    location: PropTypes.any,
    token: PropTypes.object,
    layout: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    layoutProps: PropTypes.object,
    fixedHeader: PropTypes.string,
    headerBorder: PropTypes.bool,
};

Index.defaultProps = {
    layoutProps: {},
    headerBorder: false,
};

export default compose(withRouter)(Index);
