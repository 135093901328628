import styled from 'styled-components';
import {mediaQuery} from '../../utils';

export const MainContainer = styled.div`
  // width: calc(100% + 82px);
  width: 100%;
  overflow: hidden;
  ${mediaQuery} {
    overflow-x: scroll;
    width: calc(100% + 16px);
  }
`;

export const HeaderContainer = styled.div`
  height: 382px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${mediaQuery} {
    height: 100%;
  }
`;


export const PaginateControls = styled.div`
  display:flex;
  width: calc(100%);
  position: absolute;


  .left-arrow, .right-arrow {
    z-index:9999;
    background: #fff;
    display: flex;
    justify-content: center;
    justify-items: center;
  }
  
  .right-arrow {
    position: absolute;
    margin-right:48px;
    right: 0;
  }

  .left-arrow {
    position: absolute;
    margin-left: 48px;
    left: 0;
  }

  .left-arrow img, .right-arrow img {
    width: 24px;
  }

  ${mediaQuery} {
    display:none;
  }
`;


export const ChangePage = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  cursor: pointer;
`;

export const ExplorerContainer = styled.div`
  display: flex;
`;