import styled from 'styled-components';
import {mediaQuery, mediaQuery1320, mediaQuery930} from '../../utils';

export const MainContainer = styled.div`
  width: 100%;
  height: 800px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0, 0, 84, 0.1);
  padding: 5% 25% 80px 25%;

  .background-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 800px;
    z-index: -1;
  }
  .background-video {
    position: absolute; 
    right: 0; 
    bottom: 0;
    min-width: 100%; 
    min-height: 100%;
    width: auto; 
    height: auto; 
    z-index: -100;
    background-size: cover;
    overflow: hidden;
  }
  ${mediaQuery1320}{
    padding: 0% 25% 80px 25%; 
  }
  ${mediaQuery930}{
    padding: 0% 20% 80px 20%; 
  }
  ${mediaQuery} {
    height: ${props => props.type == "image" ? "576px" : "800px"};
    justify-content: flex-start;
    padding: 0% 100px 48px 100px;
    .background-img{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 578px;
      z-index: -1;
    }
    .background-video {
      object-fit: cover;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
    }
    @media (max-width: 578px) {
    padding-left: 24px;
    padding-bottom: 48px;
    align-items: flex-start;
    }
  }
`;

export const Title = styled.div`
  color: #FFFFFF;
  font-family: Gotham;
  font-size: 80px;
  font-weight: bold;
  letter-spacing: 0.4px;
  line-height: 112px;
  text-align: center;
  margin-top: 280px;
  margin-bottom: 48px;
  ${mediaQuery1320}{
    font-size:7rem;
  }
  ${mediaQuery}{
    
    font-size: 48px;
    line-height: 56px;
  }
  @media (max-width: 578px) {
    margin-top: 264px;
    text-align: left;
    letter-spacing: 0.4px;
    line-height: 56px;
    padding: 30px 60px 0 0px;
    min-width: 360px;
    margin-bottom: 30px;
  }
  @media (max-width: 300px) {
    width: 250px; 
    font-size: 39px;
    letter-spacing: 0.4px;
    line-height: 56px;
    padding: 30px 20px 0 6px;
    min-width: 250px;
    margin-bottom: 30px;
  }
`;

export const Button = styled.a`
  min-width: fit-content;
  height: 56px;
  width: 191px;
  padding:  16px 25px;
  border-radius: 8px;
  font-family: Rubik;
  background-color: #7B00B2;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.8px;
  line-height: 22px;
  text-align: center;
  cursor: pointer;
  user-select: none;
  border: 0;
  
  :hover {
    opacity: .9;
  }

  :focus {
    outline:0;
  }
  
  & img {
    width: 24px;
    height: 24px;
  }

  ${mediaQuery}{
    height: 48px;
    width: 201px;
    font-size: 16px;
    padding: 12px 16px;
    font-weight: 500;
    letter-spacing: 0.8px;
    line-height: 22px;
    text-align: center;
  }

  @media (max-width: 578px) {
    height: 48px;
    width: 201px;
    font-size: 16px;
    padding: 12px 16px;
    font-weight: 500;
    letter-spacing: 0.8px;
    line-height: 22px;
    text-align: center;
  }
`;



