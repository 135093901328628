import React, {memo} from 'react';
import {GuiaDeHoy} from '../../Assets';
import {useHistory} from "react-router-dom";
import {
    Facebook,
    Tiktok,
    LinkedIn,
    Instagram,
    Youtube,
    Twitter,
    Spotify,
    Telephone,
    Mail
} from '../../Assets';
import {
    MainContainer,
    BottomContainer,
    TopContainer,
    LeftContainer,
    RightContainer,
    MiddleContainer,
    LineSeparator,
    LinkConditions,
    Title,
    LabelLink,
    LabelRedirect,
    ContactUs,
} from './styles';
import { userStoraged } from '../../utils';


function Footer() {
    const user = JSON.parse(localStorage.getItem('d'));
    const history = useHistory();

    const goToLogin = () => {
        localStorage.clear();
        history.push('/login');
    }

    return (
        <MainContainer>
            <TopContainer>
                <MiddleContainer>
                    <img src={GuiaDeHoy} alt="" style={{width: 27, height: 40}}/>
                    <Title>Encuentra</Title>
                    <Title>tu mejor plan</Title>
                </MiddleContainer>
                <MiddleContainer>
                    <div className="first-link">
                        <LabelLink onClick={() => history.push('/')}>Inicio</LabelLink>
                    </div>
                    <div className="link-container">
                        <LabelLink onClick={() => history.push('/food')}>Comida</LabelLink>
                    </div>
          {/*          <div className="link-container">
                        <LabelLink onClick={() => window.open('https://guiadehoy.com#about', '_blank')}>Nosotros</LabelLink>
                    </div>*/}
                    {!user && (
                        <div className="link-container" onClick={goToLogin}>
                            <LabelLink>Inicia sesión</LabelLink>
                        </div>
                    )}
                </MiddleContainer>
                <MiddleContainer>
                    <ContactUs>Contáctanos</ContactUs>
                    <div className="contact-us">
                        <img alt="" src={Telephone}/>
                        <LabelRedirect href="tel:6674718532">667 471 8532</LabelRedirect>
                    </div>
                    <div className="contact-us">
                        <img alt="" src={Mail}/>
                        <LabelRedirect href="mailto:hola@guiadehoy.com">hola@guiadehoy.com</LabelRedirect>
                    </div>
                </MiddleContainer>
            </TopContainer>
            <BottomContainer>
                <LeftContainer>
                    <span className='hide-on-phone'>Copyright © {`${new Date().getFullYear()}`} Guía de Hoy S.A. De C.V.</span>
                    <span className='hide-on-phone separator'><LineSeparator>|</LineSeparator></span>
                    <span className='hide-on-phone'><LinkConditions href={process.env.REACT_APP_PRIVACY_POLICY}>Condiciones de uso Avisos fiscales y de
                        privacidad</LinkConditions></span>
                </LeftContainer>
                <LeftContainer>
                    <p className='hide-on-desktop'>Copyright © {(new Date().getFullYear())} Guía de Hoy S.A. De C.V.</p>
                    <p className='hide-on-desktop'><LinkConditions href={process.env.REACT_APP_PRIVACY_POLICY}>Condiciones
                        de uso Avisos fiscales y de
                        privacidad</LinkConditions></p>
                </LeftContainer>
                <RightContainer>
                    <a href="https://www.facebook.com/guiadehoymx/" target="_blank" rel="noopener noreferrer">
                        <img src={Facebook} alt="" className="icon-social"/>
                    </a>
                    <a href="https://twitter.com/guiadehoycln" target="_blank" rel="noopener noreferrer" >
                        <img src={Twitter} alt="" className="icon-social"/>
                    </a>
                    <a href="https://www.instagram.com/guiadehoycln/" target="_blank" rel="noopener noreferrer" >
                        <img src={Instagram} alt="" className="icon-social"/>
                    </a>
                    <a href="https://www.tiktok.com/@guiadehoy" target="_blank" rel="noopener noreferrer" >
                        <img src={Tiktok} alt="" className="icon-social"/>
                    </a>
                    <a href="https://open.spotify.com/user/gotgwg9ue4zwdlecv15ivl3bv?si=yKDItPaGSVWVp-uRHp8KxQ"
                       target="_blank" rel="noopener noreferrer" >
                        <img src={Spotify} alt="" className="icon-social"/>
                    </a>
                    <a href="https://www.youtube.com/channel/UCVDtf1lfz6Qnc6THN_DLaaQ" target="_blank" rel="noopener noreferrer" >
                        <img src={Youtube} alt="" className="icon-social"/>
                    </a>
                    <a href="https://www.linkedin.com/company/guia-de-hoy/" target="_blank" rel="noopener noreferrer" >
                        <img src={LinkedIn} alt="" className="icon-social"/>
                    </a>
                </RightContainer>
            </BottomContainer>
        </MainContainer>
    );
}

export default memo(Footer);
