import React, { Component } from 'react';
// Track de info for react js
import ReactGA from 'react-ga';
import PropTypes from "prop-types";

ReactGA.initialize('G-EHR16JR5Q4');
ReactGA.pageview(window.location.pathname + window.location.search);

const withTracker = (WrappedComponent, options = {}) => {
  const trackPage = (page) => {
    ReactGA.set({
      page,
      ...options
    });
    ReactGA.pageview(page);
  };

  const HOC = class extends Component {
    componentDidMount() {
      const page = this.props.location.pathname;
      trackPage(page);
    }

    componentWillReceiveProps(nextProps) {
      const currentPage = this.props.location.pathname;
      const nextPage = nextProps.location.pathname;

      if (currentPage !== nextPage) {
        trackPage(nextPage);
      }
    }

    render() {
      return (
        <>
          <WrappedComponent {...this.props} />
        </>
      );
      ;
    }
  };


  HOC.propTypes = {
    location: PropTypes.any
  };

  return HOC;

}

export default withTracker;
