/**
 *
 * Loader
 *
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Content } from './styles';
import LoaderAnimation from "../Lottie/Loader";
function Loader({ mini, position, color, opacity, left, id }) {
  return (
    <Content
      id={id}
      left={left}
      position={position}
      mini={mini}
      color={color}
      opacity={opacity}
    >
      <div className="sk-three-bounce">
        <LoaderAnimation/>
      </div>
    </Content>
  );
}
Loader.propTypes = {
  mini: PropTypes.bool,
  position: PropTypes.string,
  color: PropTypes.string,
  opacity: PropTypes.string,
  left: PropTypes.any,
  id: PropTypes.string
};
Loader.defaultProps = {
  mini: false,
  position: 'fixed',
  color: '#774595',
  opacity: '1',
  left: 0,
  id: ''
};
export default memo(Loader);
