import styled from 'styled-components';
import {mediaQuery} from '../../utils';

export const MainContainer = styled.div`
  width: 100%;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
  font-size: 24px;
  letter-spacing: 0.18px;
  line-height: 32px;
  text-align: center;
  padding: 111px 0 200px 0;
  
  ${mediaQuery} {
    padding: 48px 16px;
`;

export const SearchTextContainer = styled.div`
  margin-top: 48px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const Search = styled.div`
  font-weight: bold;
  margin: 0 6px;
`;

export const InputButtonContainer = styled.div`
  margin-top: 48px;
  display: flex;
  justify-content: center;

  & .search-input {
    margin-right: 32px;
  }

  ${mediaQuery} {
    display: inherit;
    
    & .search-button {
      width: 100%;
      margin-top: 24px;
    }

    & .search-input {
      width: 100%;
      margin: 0;
    }
  }
`;




