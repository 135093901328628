import styled from 'styled-components';
import {mediaQuery1320} from '../../utils';

export const MainContainer = styled.div`
  width: 100%;
  display: flex;

  ${mediaQuery1320} {
    display: inline;
  }
`;

export const LeftContainer = styled.div`
  width: 840px;
  margin-right: 24px;
  margin-top: 32px;

  ${mediaQuery1320} {
    width: 100%;
    margin-bottom: 28px;
    margin-top: 0;
  }

  .eventImage {
    width: 840px;
    height: 440px;
    border-radius: 8px;
    margin-bottom: 24px;

    ${mediaQuery1320} {
      height: 216px;
      border-radius: 0;
      margin-bottom: 16px;
    }
  }

  .carousel {
    border-radius: 6px;

    ${mediaQuery1320} {
      border-radius: 0;
    }
  }

  .carousel-image {
    object-fit: inherit;
    height: 420px;
    min-height: 420px;
    max-height: 420px;
    
    ${mediaQuery1320} {
      height: 216px;
      min-height: 216px;
      max-height: 216px;
    }
  }

  .carousel-eventImage {
    margin-bottom: 32px;

    ${mediaQuery1320} {
      margin-bottom: 16px;
    }
  }

  .carousel img {
    width: auto;
    max-width: 100%;
  }
`;

export const RightContainerSticky = styled.div`
  width: 416px !important;
  border-radius: 8px;
  background-color: #F4F4F4;
  color: rgba(0, 0, 0, 0.87);
  padding: 32px;
  margin-top: 32px;

  .schedule {
    margin-bottom: 80px;
  }

  .button-entradas {
    width: 352px;
    height: 56px;
  }

  ${mediaQuery1320} {
    display: none;
  }
`;

export const RightContainerResponsive = styled.div`
  display: none;

  ${mediaQuery1320} {
    display: inline;
    width: 100%;
    border-radius: 0;
    background-color: #ffffff;
    color: rgba(0, 0, 0, 0.87);

    .button-entradas {
      width: 352px;
      height: 56px;

      ${mediaQuery1320} {
        display: none;
      }
    }

    .button-entradas-responsive {
      ${mediaQuery1320} {
        height: 40px;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.8px;
        line-height: 22px;
        padding: 10px 16px;
      }
    }
  }
`;

export const FixedBottomContainerResponsive = styled.div`
  display: none;

  ${mediaQuery1320} {
    display: inherit;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 64px;
    background-color: #F4F4F4;
    box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.1);
    z-index: 999;
    display: flex;
    justify-content: space-between;
    padding: 12px 16px;
    margin-left: -16px;
  }
`;

export const CategoriesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const CategoriesAndShareContainer = styled.div`
  display: flex;
  justify-content: space-between;

  ${mediaQuery1320} {
    padding: 0 16px;
  }
`;

export const RightContainerPaddingFix = styled.div`
  ${mediaQuery1320} {
    padding: 0 16px;
  }
`;

export const EventName = styled.div`
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 40px;
  margin-bottom: 40px;

  ${mediaQuery1320} {
    font-size: 24px;
    letter-spacing: 0.18px;
    line-height: 32px;
  }
`;

export const Text = styled.div`
  font-size: 16px;
  letter-spacing: 0.15px;

  ${mediaQuery1320} {
    font-size: 14px;
    letter-spacing: 0.13px;
    line-height: 22px;
  }
`;

export const Price = styled.div`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.18px;
  line-height: 32px;
  text-align: center;
  margin-bottom: 48px;

  ${mediaQuery1320} {
    display: none;
  }
`;

export const PriceResponsive = styled.div`
  ${mediaQuery1320} {
    font-size: ${props => props.longPrice ? '14px' : '20px'};
    font-weight: 600;
    letter-spacing: 0.15px;
    line-height: 28px;
    margin-top: -6px;
    display: flex;
    align-items: center;
  }
`;

export const IconTextContainer = styled.div`
  display: flex;
  margin-bottom: 24px;

  & img {
    margin-right: 16px;
    width: 24px;
    height: 24px;
  }
`;

export const ScheduleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  ${mediaQuery1320} {
    width: 50%;
  }
`;

export const ScheduleItem = styled.div`
  width: 50%;
  font-size: 16px;
  letter-spacing: 0.15px;
`;

export const Category = styled.div`
  border: 1px solid #D3C3DD;
  border-radius: 20px;
  padding: 8px 16px;
  display: flex;
  color: #7E7E7E;
  font-size: 14px;
  letter-spacing: -0.2px;
  line-height: 22px;
  height: 40px;

  ${mediaQuery1320} {
    padding: 6px 12px;
    font-size: 12px;
    letter-spacing: -0.17px;
    line-height: 20px;
    height: 32px;
  }

  & img {
    margin-right: 8px;
    width: 24px;
    height: 24px;
    object-fit: fill;

    ${mediaQuery1320} {
      width: 16px;
      height: 16px;
    }
  }
`;

export const CategoryItem = styled.div`
  margin-right: 8px;
  margin-bottom: 8px;
  cursor: pointer;
`;



