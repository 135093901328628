export const mediaQuery = '@media only screen and (max-width: 810px)';
export const mediaQuery290 = '@media only screen and (max-width: 290px)';

export const mediaQuery930 = '@media only screen and (max-width: 930px)';

export const mediaQuery1050 = '@media only screen and (max-width: 1050px)';

export const mediaQuery1150 = '@media only screen and (max-width: 1150px)';

export const mediaQuery1320 = '@media only screen and (max-width: 1320px)';

export const mediaQuery1350 = '@media only screen and (max-width: 1350px)';

export const EMAIL_REGEX = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;

export const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,99}$/;

export const MEDIA_QUERY_MAX_PIXELS = 1320;

export const formatHourText = text => {
  let newText = text;
  if (newText.includes('am')) {
    newText = newText.replace('am', 'a. m.')
  } else {
    newText = newText.replace('pm', 'p. m.')
  }
  return newText;
}

export var userStoraged = JSON.parse(localStorage.getItem('d'));

export function getUserStoraged() {
  try {
    return JSON.parse(localStorage.getItem('d'));
  } catch (e) {
    return null;
  }
}