import styled from 'styled-components';
import {mediaQuery} from '../../utils';

export const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 40px 0 48px 96px;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  background-color: white;

  .close-icon {
    right: 64px;
    top: 64px;
    width: 24px;
    height: 24px;
    position: absolute;
    z-index: 999999;
    cursor: pointer;
  }
  
  ${mediaQuery} {
    width: 100%;
    height: fit-content;
    padding: 0;

    .close-icon {
      right: 20px;
      top: 20px;
    }
  }

`;

export const Container = styled.div`
  height: 100%;
  padding-bottom: 80px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const TicketsContainer = styled.div`
  width: 102%;
  display: flex;
  overflow-x: auto;
  margin-bottom: 30px;
  padding-bottom: 24px;

  ${mediaQuery} {
    padding: 0px 0px 24px 16px;
  }
`;

export const PDFTicketsContainer = styled.div`
  position: absolute;
  z-index: -1 !important;
  width: 585px;
  min-width: 585px;
  max-width: 585px;
  height: fit-content;
  padding: 0 40px;
  overflow-x: hidden;
  overflow-y: hidden;
`;

export const PDFTicketBody = styled.div`
  margin-bottom: ${props => props.isLast ? '0' : '254px'};
`;


export const TicketBody = styled.div`
  margin-right: 48px;

  ${mediaQuery} {
    margin-right: 16px;
  }
`;

export const Title = styled.div`
  color: #191919;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 40px;
  margin-bottom: 69px;
  text-align: left;

  ${mediaQuery} {
    font-size: 20px;
    letter-spacing: 0.15px;
    line-height: 28px;
    padding: 18px 16px;
    width: 100%;
    border-bottom: 1px solid #D9D9D9;
    background-color: white;
    margin-bottom: 16px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;

  & .button-download {
    height: 48px;
    width: 210px;
    border-radius: 8px;
    background-color: #E9E1EE;
    color: #7B00B2;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.23px;
    line-height: 24px;
    margin-right: 24px;
  }

  & .button-calendar {
    height: 49px;
    width: 219px;
    border: 1px solid #E9E1EE;
    border-radius: 7px;
    color: #7B00B2;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.23px;
    line-height: 24px;
    display: flex;
    padding: 13px 16px 11px 16px;
  }

  & .button-calendar img {
    height: 22px;
    width: 24px;
    margin-right: 12px;
  }
`;






