import React, {memo, useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {
    MainContainer,
    LeftContainer,
    RightContainer,
    Title,
    Text,
    TextCustom,
    CheckItemsContainer,
    CheckItem,
    AdmissionItem,
    AdmissionText,
    DateText,
    AdmissionFewPlaces,
    PriceQuantityContainer,
    AdmissionPrice,
    Quantity,
    RightContainerCard,
    EventInfoContainer,
    EventName,
    EventInfoText,
    BottomContainer,
    BottomLeftContainer,
    BottomRightContainer,
    TotalBottom,
    TotalBottomText,
    TitleResponsive,
    PlaceResponsive,
    ControlsContainer,
    LeftControl,
    RightControl,
    ChangePage,
} from './styles';
import {get} from 'lodash';
import {
    MoreEnabled,
    LessDisabled,
    UbicationIcon,
    LessEnabled,
    MoreDisabled,
    LeftArrow,
    RightArrow,
} from '../../Assets';
import Button from '../Button';
import https from '../../config/https';
import {formatHourText, getUserStoraged} from '../../utils';
import PriceWithDiscount from '../PriceWithDiscount';
import axios from 'axios';


const PaymentModalStep1 = (props) => {

    const user = getUserStoraged();
    const {onClickButton, admission, eventDays, id, buyDetails, detailImage, openNoTicketsModal, previousData} = props;
    let isMultipleType = true;
    if (previousData) {
        isMultipleType = previousData.isMultipleTicketTypes !== false;
    }
    const [admissionTypes, setAdmissionCount] = useState([]);
    const [total, setTotal] = useState('$0.00');
    const [currentEventDays, setCurrentEventDays] = useState(eventDays);
    const [hours, setHours] = useState([]);
    const [selectedData, setSelectedData] = useState({eventId: id});
    const [scrollLeft, setScrollLeft] = useState(0);
    const [maxRightScrolled, setMaxRightScrolled] = useState(false);
    const [hourScrollLeft, setHourScrollLeft] = useState(0);
    const [maxHourRightScrolled, setMaxHourRightScrolled] = useState(false);
    const [isMultipleTicketTypes, setMultipleTicketsTypes] = useState(isMultipleType);

    const previousArrow = useRef(null);
    const nextArrow = useRef(null);
    const previousImage = useRef(null);
    const nextImage = useRef(null);
    const containerRef = useRef(null);

    const previousHourArrow = useRef(null);
    const nextHourArrow = useRef(null);
    const previousHourImage = useRef(null);
    const nextHourImage = useRef(null);
    const containerHourRef = useRef(null);

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    useEffect(() => {
        if (eventDays) {
            setSelectedDay(0);
        }

        if (previousData) {
            setSelectedDay(previousData.dayIndex);
        }

        function nextSection() {
            let scrollAmount = 0;
            const slideTimer = setInterval(function () {
                containerRef.current.scrollLeft += 10;
                scrollAmount += 10;
                if (scrollAmount > 100) {
                    window.clearInterval(slideTimer);
                    setScrollLeft(containerRef.current.scrollLeft);
                    setMaxRightScrolled(!!!(Math.floor(containerRef.current.scrollWidth - containerRef.current.offsetWidth - containerRef.current.scrollLeft)));
                }
            }, 20);
        };

        function previousSection() {
            let scrollAmount = 0;
            const slideTimer = setInterval(function () {
                containerRef.current.scrollLeft -= 10;
                scrollAmount += 10;
                if (scrollAmount > 100) {
                    window.clearInterval(slideTimer);
                    setScrollLeft(containerRef.current.scrollLeft);
                    setMaxRightScrolled(!!!(Math.floor(containerRef.current.scrollWidth - containerRef.current.offsetWidth - containerRef.current.scrollLeft)));
                }
            }, 20);
        };


        function nextHourSection() {
            let scrollAmount = 0;
            const slideTimer = setInterval(function () {
                containerHourRef.current.scrollLeft += 10;
                scrollAmount += 10;
                if (scrollAmount > 100) {
                    window.clearInterval(slideTimer);
                    setHourScrollLeft(containerHourRef.current.scrollLeft);
                    setMaxHourRightScrolled(!!!(Math.floor(containerHourRef.current.scrollWidth - containerHourRef.current.offsetWidth - containerHourRef.current.scrollLeft)));
                }
            }, 20);
        };

        function previousHourSection() {
            let scrollAmount = 0;
            const slideTimer = setInterval(function () {
                containerHourRef.current.scrollLeft -= 10;
                scrollAmount += 10;
                if (scrollAmount > 100) {
                    window.clearInterval(slideTimer);
                    setHourScrollLeft(containerHourRef.current.scrollLeft);
                    setMaxHourRightScrolled(!!!(Math.floor(containerHourRef.current.scrollWidth - containerHourRef.current.offsetWidth - containerHourRef.current.scrollLeft)));
                }
            }, 20);
        }

        const windowResize = () => {
            setMaxRightScrolled(!!!(Math.floor(containerRef.current.scrollWidth - containerRef.current.offsetWidth - containerRef.current.scrollLeft)));
            setMaxHourRightScrolled(!!!(Math.floor(containerHourRef.current.scrollWidth - containerHourRef.current.offsetWidth - containerHourRef.current.scrollLeft)));
        }

        getBuyDetails();
        setAdmissionCount(differentTickets);
        if (nextArrow && nextArrow.current) {
            nextArrow.current.addEventListener('click', nextSection);
            setMaxRightScrolled(!!!(Math.floor(containerRef.current.scrollWidth - containerRef.current.offsetWidth - containerRef.current.scrollLeft)));
        }
        if (previousArrow && previousArrow.current) {
            previousArrow.current.addEventListener('click', previousSection);
        }


        if (nextHourArrow && nextHourArrow.current) {
            nextHourArrow.current.addEventListener('click', nextHourSection);
            setMaxHourRightScrolled(!!!(Math.floor(containerHourRef.current.scrollWidth - containerHourRef.current.offsetWidth - containerHourRef.current.scrollLeft)));
        }
        if (previousHourArrow && previousHourArrow.current) {
            previousHourArrow.current.addEventListener('click', previousHourSection);
        }


        window.addEventListener('resize', windowResize);

        sessionStorage.removeItem('validPayment');
        sessionStorage.removeItem('preventTimeout');

        return () => {
            nextArrow.current && nextArrow.current.removeEventListener('click', nextSection);
            previousArrow.current && previousArrow.current.removeEventListener('click', previousSection);
            nextHourArrow.current && nextHourArrow.current.removeEventListener('click', nextHourSection);
            previousHourArrow.current && previousHourArrow.current.removeEventListener('click', previousHourSection);
            window.removeEventListener('resize', windowResize);
        };
    }, []);

    let differentTickets = [];

    const getBuyDetails = () => {
        if (buyDetails) {
            for (let i = 0; i < buyDetails.admissionCount.length; i++) {
                differentTickets.push(buyDetails.admissionCount[i]);
            }
            setSelectedData({...buyDetails, eventId: id});
            https.get(`/api/v1/obtener-evento/${id}/horas-funcion/${buyDetails.day}`)
                .then(res => {
                    let hoursTemp = res.map(hour => {
                        return {
                            text: hour.hora,
                            id: hour.id,
                            selected: hour.id === buyDetails.hour
                        }
                    })
                    setHours(hoursTemp);
                    setTotal(`${buyDetails.total}`);
                })
        } else {
            for (let i = 0; i < admission.length; i++) {
                differentTickets.push({
                    type: admission[i].text,
                    id: admission[i].typeId,
                    count: 0,
                    price: 0,
                    total: 0
                });
            }
        }
    }

    const addAdmissionTicket = (index, admissionTypesParam, price) => {
        const admissionTypesValue = admissionTypesParam && admissionTypesParam.length && admissionTypesParam || admissionTypes.length && admissionTypes;
        if (admissionTypesValue[index].count >= admissionTypesValue[index].limit) {
            return;
        }
        let admissionTypesHelper = [...admissionTypesValue]
        admissionTypesHelper[index].count++;
        let clients = [];
        for (let i = 0; i < admissionTypesHelper[index].count; i++) {
            clients.push({
                name: '',
                email: '',
                lastName: '',
                accountOwner: false
            })
        }
        admissionTypesHelper[index].clients = clients;
        let totalHelper = 0;
        admissionTypesHelper.forEach((item, admissionIndex) => {
            if (item.discounted_price) {
                const admissionTypesValueTotal = replaceAll(admissionTypesValue[admissionIndex].discounted_price, ",", "" );
                totalHelper += item.count * admissionTypesValueTotal;
                item.total = item.count * admissionTypesValueTotal;
            } else {
                const admissionTypesValueTotal = replaceAll(admissionTypesValue[admissionIndex].price, ",", "" );
                totalHelper += item.count * admissionTypesValueTotal;
                item.total = item.count * admissionTypesValueTotal;
            }

        })
        let formattedAmmount = formatter.format(totalHelper)
        setTotal(formattedAmmount);
        let selectedDataTemp = selectedData;
        selectedDataTemp.admissionCount = admissionTypesHelper;
        selectedDataTemp.total = formattedAmmount;
        setSelectedData(selectedDataTemp);
        setAdmissionCount(admissionTypesHelper);
    };

    function replaceAll( text, busca, reemplaza ){
        while (text.toString().indexOf(busca) != -1)
            text = text.toString().replace(busca,reemplaza);
        return text;
    }

    const subAdmissionTicket = (index) => {
        let admissionTypesHelper = [...admissionTypes]
        if (admissionTypesHelper[index].count <= 0 || (!isMultipleTicketTypes && admissionTypesHelper[index].count <= 1)) {
            return;
        }
        admissionTypesHelper[index].count--;
        let clients = [];
        for (let i = 0; i < admissionTypesHelper[index].count; i++) {
            clients.push({
                name: '',
                email: '',
                lastName: '',
                accountOwner: false
            })
        }
        admissionTypesHelper[index].clients = clients;
        let totalHelper = 0;
        admissionTypesHelper.forEach((item, admissionIndex) => {
            if (item.discounted_price) {
                totalHelper += item.count * (admission[admissionIndex].discounted_price ? admission[admissionIndex].discounted_price : admission[admissionIndex].price);
                item.total = item.count * (admission[admissionIndex].discounted_price ? admission[admissionIndex].discounted_price : admission[admissionIndex].price);
            } else {
                totalHelper += item.count * admission[admissionIndex].price;
                item.total = item.count * admission[admissionIndex].price;
            }
        })
        let formattedAmmount = formatter.format(totalHelper)
        setTotal(formattedAmmount);
        setSelectedData({...selectedData, admissionCount: admissionTypesHelper, total: formattedAmmount});
        setAdmissionCount(admissionTypesHelper);
    };

    const setSelectedDay = (index) => {
        let temporalEventDays = [...currentEventDays];
        if (temporalEventDays[index].selected) {
            temporalEventDays[index].selected = false;
            setMaxHourRightScrolled(true);
            setSelectedHour(-1);
            setHours([]);
            setAdmissionCount([]);
            return;
        }
        temporalEventDays.forEach(day => {
            day.selected = false;
        });
        temporalEventDays[index].selected = true;
        const selectedDataTemp = selectedData;
        selectedDataTemp.day = temporalEventDays[index].id;
        selectedDataTemp.dayName = temporalEventDays[index].text;
        selectedDataTemp.originalDate = temporalEventDays[index].originalDate;
        selectedDataTemp.dayIndex = index;
        setSelectedData(selectedDataTemp);
        setCurrentEventDays(temporalEventDays);
        https.get(`/api/v1/obtener-evento/${id}/horas-funcion/${temporalEventDays[index].id}`)
            .then(res => {
                let hoursTemp = res.map(hour => {
                    return {
                        text: hour.hora,
                        id: hour.id,
                        selected: false
                    }
                })
                setHours(hoursTemp);
                if (!buyDetails) {
                    setSelectedHour(0, hoursTemp);
                }
                if (hours.length === 1) {
                    setSelectedHour(0, hoursTemp);
                }
                else if (get(previousData, 'hourIndex', null) && hours.length !== 0) {
                    setSelectedHour(previousData.hourIndex);
                }
            })
    };

    const setSelectedHour = (index, hoursTemp, firstRun) => {
        const hoursToWorkWith = hours.length && hours || hoursTemp || [];
        let temporalHours = [...hoursToWorkWith];
        if (index >= 0 && temporalHours[index].selected) {
            temporalHours[index].selected = false;
            setTotal('$0.00');
            setAdmissionCount([]);
            return;
        }

        temporalHours.forEach(hour => {
            hour.selected = false;
        });
        if (index === -1) {
            setTotal('$0.00');
            setHours(temporalHours);
            return;
        }
        temporalHours[index].selected = true;
        let selectedDataTemp = selectedData;
        selectedDataTemp.hour = temporalHours[index].id;
        selectedDataTemp.hourName = temporalHours[index].text;
        selectedDataTemp.hourIndex = index;
        setTotal('$0.00');
        setSelectedData(selectedDataTemp);
        setHours(temporalHours);
        handleFetchAvailableTickets(temporalHours[index].id);
    };

    const handleFetchAvailableTickets = async hourId => {
        axios.get(`${process.env.REACT_APP_API_ENDPOINT_URL}/api/v1/sale/available-tickets?id_hour=${hourId}&id_day=${get(selectedData, 'day', '')}&id_event=${get(selectedData, 'eventId', '')}`, {})
            .then(result => {
                const res = get(result, 'data', []);
                // TODO Boletos agotados
                if (!res || res.length === 0) {
                    return openNoTicketsModal(get(detailImage, 'name', ''));
                }
                const isMultipleTicketTypes = res.length > 1;
                setMultipleTicketsTypes(isMultipleTicketTypes);
                let singleTicketPrice = 0;
                if (!isMultipleTicketTypes) {
                    if (get(res, '[0].discounted_price', null)) {
                        singleTicketPrice = res[0].discountPrice
                    } else {
                        singleTicketPrice = res[0].price
                    }
                }
                let admissionTemp = res.map(data => {
                    return {
                        text: data.name,
                        fewPlaces: data.capacity < 50,
                        price: data.price === "" ? data.discounted_price : data.price,
                        capacity: data.capacity,
                        id: data.id_ticket,
                        limit: data.limit,
                        count: 0,
                        available_text: data.available_text,
                        discounted_price: data.price === "" ? data.price : data.discounted_price,
                    }
                })
                if (!isMultipleTicketTypes) {
                    addAdmissionTicket(0, admissionTemp, singleTicketPrice);
                } else {
                    setAdmissionCount(admissionTemp);
                }
                setMaxHourRightScrolled(!!!(Math.floor(containerHourRef.current.scrollWidth - containerHourRef.current.offsetWidth - containerHourRef.current.scrollLeft)));

            })
    };

    const disabled = (!get(detailImage, 'isFree', false) && parseInt(total.substr(1)) === 0.00) || !get(selectedData, 'hour') || !selectedData.day

    const formatDateText = text => {
        const firstLetter = text.charAt(0).toUpperCase();
        const lowercaseText = text.toLowerCase().replace(',', '.');
        return firstLetter + lowercaseText.substr(1, text.length + 1);
    }

    return (
        <MainContainer>
            <div className="container">
                <LeftContainer>
                    <Title>Selecciona tus entradas</Title>
                    <TitleResponsive>{get(detailImage, 'name', '')}</TitleResponsive>
                    <PlaceResponsive>
                        <img src={UbicationIcon} alt="" className="place-icon"/>
                        {get(detailImage, 'place', '')}
                    </PlaceResponsive>
                    {currentEventDays.length === 1 && hours.length === 1 ? <TextCustom>Día y hora del
                        evento</TextCustom> : (currentEventDays.length ? (currentEventDays.length === 1 ?
                        <TextCustom>Día del evento</TextCustom> :
                        <TextCustom>Selecciona día</TextCustom>) : React.null)}
                        {currentEventDays.length === 1 ? React.null : 
                    <ControlsContainer>
                        <LeftControl style={scrollLeft ? {display: 'inherit'} : {display: 'none'}}>
                            <ChangePage ref={previousArrow}> <img src={LeftArrow} alt="" ref={previousImage}/>
                            </ChangePage>
                        </LeftControl>
                        <RightControl style={maxRightScrolled ? {display: 'none'} : {display: 'inherit'}}>
                            <ChangePage ref={nextArrow}> <img src={RightArrow} alt="" ref={nextImage}/> </ChangePage>
                        </RightControl>
                    </ControlsContainer>
                    }               
                    <CheckItemsContainer ref={containerRef}>
                        {currentEventDays.length > 1 ?
                            currentEventDays.map((day, index) => (
                                <CheckItem key={day.text} selected={day.selected} onClick={() => setSelectedDay(index)}>
                                    {day.textFull}
                                </CheckItem>
                            ))
                            :
                            <DateText>
                                {currentEventDays[0].textFull.replaceAll('.','')} {hours.length === 1 ? `a las ${formatHourText(hours[0].text)}` : React.null}
                            </DateText>
                        }
                    </CheckItemsContainer>
                    {currentEventDays.length === 1 && hours.length === 1 ? React.null : (hours.length ? (hours.length === 1 ?
                        <Text>Hora del evento</Text> : <Text>Selecciona hora</Text>) : React.null)}
                       {hours.length === 1 ? React.null :
                    <ControlsContainer>
                        <LeftControl style={hourScrollLeft ? {display: 'inherit'} : {display: 'none'}}>
                            <ChangePage ref={previousHourArrow}> <img src={LeftArrow} alt="" ref={previousHourImage}/>
                            </ChangePage>
                        </LeftControl>
                        <RightControl style={maxHourRightScrolled ? {display: 'none'} : {display: 'inherit'}}>
                            <ChangePage ref={nextHourArrow}> <img src={RightArrow} alt="" ref={nextHourImage}/>
                            </ChangePage>
                        </RightControl>
                    </ControlsContainer>
                        }
                    <CheckItemsContainer ref={containerHourRef}>
                        {currentEventDays.length == 1 && hours.length == 1 ? React.null : (hours.length ? (hours.length > 1 ? hours.map((hour, index) => (
                            <CheckItem key={hour.text} selected={hour.selected} onClick={() => setSelectedHour(index)}>
                                {formatHourText(hour.text)}
                            </CheckItem>
                        )) : <DateText>{formatHourText(hours[0].text)}</DateText>) : React.null)}

                    </CheckItemsContainer>
                    {admissionTypes && admissionTypes.length > 0 ?
                        <Text>Selecciona número de entradas</Text> : React.null}
                    {get(admissionTypes[0], 'text', '') !== '' && admissionTypes.map((item, index) => (
                        <AdmissionItem key={item.text}>
                            <div className="admission-text">
                                <AdmissionText>{item.text}</AdmissionText>
                                <AdmissionFewPlaces>{item.available_text}</AdmissionFewPlaces>
                            </div>
                            <PriceQuantityContainer>
                                {item.discounted_price ? (
                                    <AdmissionPrice>
                                        <div className="fix-discount"><PriceWithDiscount
                                            discountPrice={item.discounted_price} normalPrice={item.price}/></div>
                                    </AdmissionPrice>
                                ) : (
                                    <AdmissionPrice>${item.price}</AdmissionPrice>
                                )}
                                <img
                                    src={get(admissionTypes[index], 'count', 0) === 0 || (!isMultipleTicketTypes && get(admissionTypes[index], 'count', 1) === 1) ? LessDisabled : LessEnabled}
                                    alt=""
                                    className="icon-less"
                                    onClick={() => subAdmissionTicket(index)}/>
                                <Quantity>{get(admissionTypes[index], 'count', 0)}</Quantity>
                                <img
                                    src={get(admissionTypes[index], 'count', 0) === get(admissionTypes[index], 'limit', 0) ? MoreDisabled : MoreEnabled}
                                    alt=""
                                    className="icon-more"
                                    onClick={() => addAdmissionTicket(index)}/>
                            </PriceQuantityContainer>
                        </AdmissionItem>
                    ))}
                </LeftContainer>
                <RightContainer>
                    <RightContainerCard>
                        <img src={get(detailImage, 'images[0]', '')} alt="" className="card-image"/>
                        <EventName>{get(detailImage, 'name', '')}</EventName>
                        <EventInfoContainer>
                            <img src={UbicationIcon} alt="" className="card-icon"/>
                            <EventInfoText>{get(detailImage, 'place', 'En Linea')}</EventInfoText>
                        </EventInfoContainer>
                    </RightContainerCard>
                </RightContainer>
            </div>
            <BottomContainer>
                <BottomLeftContainer>
                    {get(detailImage, 'isFree', false) ?
                        <TotalBottom>Gratis</TotalBottom> :
                        <>
                            <TotalBottom>{total}</TotalBottom>
                            <TotalBottomText>Total</TotalBottomText>
                        </>
                    }
                </BottomLeftContainer>
                <BottomRightContainer>
                    <Button
                        text={previousData ? "Actualizar entradas" : "Comprar entradas"}
                        className="button-entradas"
                        onClick={() => onClickButton(selectedData, isMultipleTicketTypes)}
                        disabled={disabled}
                    />
                </BottomRightContainer>
            </BottomContainer>
        </MainContainer>
    )
};


PaymentModalStep1.propTypes = {
    onClickButton: PropTypes.func,
    openNoTicketsModal: PropTypes.func,
    previousData: PropTypes.object,
};

PaymentModalStep1.defaultProps = {
    onClickButton: () => {
    },
    openNoTicketsModal: () => {
    },
    previousData: {},
};

export default memo(PaymentModalStep1);