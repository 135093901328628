import styled from 'styled-components';
import {mediaQuery} from '../../utils';

export const MainContainer = styled.div`
  width: 100%;
`;

export const HeaderContainer = styled.div`
  height: 48px;
  margin: ${props => props.customMargin ? props.customMargin : '0 48px'};
  width: ${props => props.customMargin ? '100%' : 'calc(100% - 96px)'};
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  ${mediaQuery} {
    width: 100%;
    margin:inherit;
  }
  
  .ver-todo {
    display: none;
    ${mediaQuery} {
      display: inherit;
    }
  }
`;

export const Title = styled.div`
  font-family: Gotham;
  height: 32px;
  color: rgba(0,0,0,0.87);
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0.18px;
  line-height: 32px;
  ${mediaQuery} {
    margin-bottom: ${props => props.isRecommendations && '16px'};
    margin-left: ${props => props.children === 'También podría gustarte' ? '16px' : '10px'}
  }
`;


export const PaginateControls = styled.div`
  display:flex;
  align-items: center;

  ${mediaQuery} {
    display: none;
  }
`;

export const FullList = styled.div`
  height: 24px;
  width: 75px;
  color: #7B00B2;
  // font-family: "Helvetica Neue";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.15px;
  line-height: 24px;
  text-align: right;
  margin-right:32px;
  cursor: pointer;
  min-width: 75px;
  
  a {
    text-decoration: none;
    color: #7B00B2;
  }
  
  ${mediaQuery} {
    margin-right: 12px;
  }
`;

export const ChangePage = styled.div`
  box-sizing: border-box;
  height: 40px;
  width: 40px;
  border: 1px solid #E9E1EE;
  border-radius: 50%;
  overflow: hidden;
  margin-left:16px;

  img {
    width: 100%;
    cursor: pointer;
  }
  
  ${mediaQuery} {
    overflow-x: scroll;
  }
`;
