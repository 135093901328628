import styled from 'styled-components';

export const MainContainer = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & .button-back {
    margin-top: 48px;
    font-weight: 600;
  }

  @media screen and (min-width: 1000px){
    & .img4042 {display: none;}
  }

  @media screen and (max-width: 340px){
    & .img4042 {width: 250px;}
  }

  @media screen and (max-width: 1000px){
    & .img404 {display: none;}
  }

`;

export const Title = styled.div`
  color: #333333;m,k
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.18px;
  line-height: 32px;
  margin-top: 25px;
  margin-bottom: 32px;
`;

export const Description = styled.div`
  color: #666666;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 26px;
  text-align: center;
`;

