import styled from 'styled-components';
import { mediaQuery } from '../../utils';

export const MainContainer = styled.div`
  width: 300px;
  color: rgba(0,0,0,0.87);
  position: relative;
  margin: ${props => props.isSearchResponse ? '24px 16px 0 0' : 'initial'};
  ${mediaQuery} {
      width: ${props => props.isRecomendation ? `156px;`: `300px;`};
      margin: ${props => props.isSearchResponse ? '16px 0 0 0' : props.isRecomendation ? '16px':'initial'};
  }
`;

export const ImageContainer = styled.div`

  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  background-color: #000000;
  border-radius: 8px;
  
  & .image {
    height: 150px;
    border-radius: 8px;
    width: auto;
    object-fit: inherit;
    max-width: 100%;
  }

  ${mediaQuery} {
    ${props => props.isRecomendation ? `
    width: 156px !important;
    height:234px !important;
    `:``}
    &  .recommendation-size {
      width: 156px !important;
      height:234px !important;
    }
  }
`;

export const DateText = styled.div`
  font-family: Rubik;
  color: #1263B8;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 22px;
  margin-bottom: 8px; 
`;

export const EventName = styled.div`
  color: rgba(0,0,0,0.87);
  font-family: Rubik;
  font-weight: 600;
  font-size: 24px;
  font-weight: 800;
  letter-spacing: 0.18px;
  line-height: 28px;
  margin-bottom: 8px;
`;

export const EventPlace = styled.div`
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.15px;
  line-height: 24px;
  margin-bottom: 8px;
  color: #666666;
`;

export const EventPrice = styled.div`
  font-size: 20px;
  letter-spacing: 0.16px;
  line-height: 28px;
  margin-bottom: 16px;
  font-weight: 800;
`;

export const EventPriceDiscounted = styled.div`
  height: 30px;
  align-content: center;
  display: flex;
  flex-direction: row;
  font-family: Rubik;
  line-height: 28px;
  margin-bottom: 16px;
`;


export const OldPrice = styled.div`
  text-decoration:line-through;
  color: #666666;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.13px;
  line-height: 28px;
  margin-right: 8px;
`;


export const NewPrice = styled.div`
  color: #D10008;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.16px;
  line-height: 28px;

`;

export const Discount = styled.div`
  font-family: Rubik;
  position: absolute;
  top: 16px;
  left: 0px;
  padding: 3px 8px;
  color: #FFFFFF;
  background-color: #D10008;
  font-weight: bold;
  letter-spacing: 0px;
  line-height: 18px;
  font-size: 12px;
`;

export const TitleContainer = styled.div`
  color: rgba(255, 255, 255, 0.87);
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 36px;
  position: absolute;
  bottom: 20px;
  margin: 0px 24px;
  width: calc(100% - 24px);
  
  ${mediaQuery} {
    font-size: 18px;
    letter-spacing: 0.14px;
    line-height: 20px;
    margin: 0px 16px;
  }
`;


