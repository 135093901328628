import React, { memo,  } from 'react';

import 'moment/locale/es-mx';
import {
    MainContainer,
    LeftContainer,
    RightContainer,
    Title,
    Image,
} from './styles';
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';

function UsBanner(props) {
    const {titleOrganize, imageOrganize, urlOrganize, titleSearch, imageSearch, urlSearch} = props;
    let history = useHistory();
    const goToPage = (url) => {
        history.push(url)
    };
    return (
        <>
        <MainContainer>
            <LeftContainer onClick={() => goToPage(urlOrganize)}>
                <Image src={imageOrganize}/>
                <Title>{titleOrganize}</Title>
            </LeftContainer>
            <RightContainer onClick={() => goToPage(urlSearch)}>
                <Image src={imageSearch}/>
                <Title>{titleSearch}</Title>
            </RightContainer>
        </MainContainer>
        
        </>
    );
}
UsBanner.propTypes = {
    titleSearch: PropTypes.string,
    imageSearch: PropTypes.string,
    urlSearch: PropTypes.string,
    titleOrganize: PropTypes.string,
    imageOrganize: PropTypes.string,
    urlOrganize: PropTypes.string,
};

UsBanner.defaultProps = {
    titleSearch: '',
    imageSearch: '',
    urlSearch: '',
    titleOrganize: '',
    imageOrganize: '',
    urlOrganize: '',
};
export default memo(UsBanner);
