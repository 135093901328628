import React, { useState} from 'react';
import ReactDOM from 'react-dom';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

import './index.css';
import Routes from './routers';
import {Provider} from 'react-redux'
import configureStore from './store';
import {ToastProvider} from 'react-toast-notifications';
import {ResponsiveContext} from './contexts/responsive-context';
import ErrorBoundary from './components/Helpers/ErrorBoundary';
import 'antd/dist/antd.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Loader from './components/Loader';
import moment from 'moment';
import {ConfigProvider} from 'antd';
import esES from 'antd/lib/locale/es_ES';
import GlobalContext from './contexts/global-context';
import FirebaseAuthContext from './contexts/firebase-auth-context';
import AxiosWithContext from './config/AxiosWithContext';
import OfflineModal from './components/OfflineModal';
import {MatomoProvider, createInstance, useMatomo} from '@datapunt/matomo-tracker-react';
import CacheBuster from 'react-cache-buster';
import {version} from '../package.json';
import firebaseConfig from './config/firebaseConfig';

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
auth.languageCode = 'es';

const instance = createInstance({
    urlBase: 'https://analytics.prod.guiadehoy.com/',
    siteId: 2,
    trackerUrl: 'https://analytics.prod.guiadehoy.com/matomo.php', // optional, default value: `${urlBase}matomo.php`
    srcUrl: 'https://analytics.prod.guiadehoy.com/matomo.js', // optional, default value: `${urlBase}matomo.js`
    disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
    heartBeat: { // optional, enabled by default
        active: true, // optional, default value: true
        seconds: 10 // optional, default value: `15
    },
    linkTracking: false, // optional, default value: true
    configurations: { // optional, default value: {}
        // any valid matomo configuration, all below are optional
        disableCookies: true,
        setSecureCookie: true,
        setRequestMethod: 'POST'
    }
})

const App = () => {
  const {enableLinkTracking} = useMatomo();
  const [offline, setOffline] = useState(false);
  const value = {offline, setOffline};
  moment.locale('es-mx');

  enableLinkTracking();

  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={true}
      isVerboseMode={false}
    >
      <FirebaseAuthContext.Provider value={auth}>
        <GlobalContext.Provider value={value}>
          <AxiosWithContext>
            <ConfigProvider locale={esES}>
              <Loader id="loader" style={{zIndex: 9999999, display: 'none'}}/>
              <ErrorBoundary>
                <ToastProvider>
                  <Provider store={configureStore()}>
                    <React.StrictMode>
                      <ResponsiveContext.Provider value={true}>
                        {offline ? <OfflineModal/> : <Routes token="demo"/>}
                      </ResponsiveContext.Provider>
                    </React.StrictMode>
                  </Provider>
                </ToastProvider>
              </ErrorBoundary>
            </ConfigProvider>
          </AxiosWithContext>
        </GlobalContext.Provider>
      </FirebaseAuthContext.Provider>
    </CacheBuster>
  );
}

ReactDOM.render(
  <MatomoProvider value={instance}>
    <App/>
  </MatomoProvider>,
  document.getElementById('root')
);

//serviceWorker.register();

navigator && navigator.serviceWorker && navigator.serviceWorker.getRegistrations().then( function(registrations) {
  for(let registration of registrations) {
    registration.unregister();
  }
});
