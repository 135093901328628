import React, { Component } from 'react';
import Lottie from 'react-lottie';
import animationData from './data.json';

class SuccessIcon extends Component {
    render() {
        const defaultOptions = {
            loop: false,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
            },
        };

        return (
            <div>
                <Lottie
                    options={defaultOptions}
                    height={80}
                    width={80}
                />
            </div>
        );
    }
}

export default SuccessIcon;