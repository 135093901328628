import styled from 'styled-components';
import { mediaQuery1320, mediaQuery } from '../../../utils';

export const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const CloseModal = styled.img`
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  max-width: fit-content;
`;

export const Header = styled.div`
  height: 32px;
  color: #666666;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.15px;
  line-height: 32px;
`;

export const PaymentInfoHeader = styled.div`
  height: 40px;
  width: 416px;
  color: #191919;
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 40px;
`;

export const ModalMainContainer = styled.div`
  width: 100%;
  height: 100%;
  color: rgba(0, 0, 0, 0.87);
  padding: 50px 0 40px 0;
  text-align: center;
  position: relative;

  & img {
    margin-bottom: 54px;

    ${mediaQuery} {
      margin-bottom: 40px;
      width: 126px;
    }
  }

  .close-icon {
    width: 20px;
    height: 20px;
    position: absolute;
    z-index: 999999;
    top: 0;
    right: 0;
    cursor: pointer;

    ${mediaQuery1320} {
      margin-bottom: 40px;
      width: 126px;
      top: 15px;
    right: 15px;
    }
  }
`;
