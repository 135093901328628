import {
    HANDLE_DRAWER
} from './actionTypes';

const initialState = {
    openDrawer: false,
};

export default function uiReducer(state = initialState, action) {
    if (action.type === HANDLE_DRAWER) {
        return {
            ...state,
          openDrawer: action.value,
        }
    }
    else {
        return state;
    }
}
