import React, { memo, useState } from 'react';
import Button from '../Button';
import InputText from '../Input';
import { DatePicker, Space, ConfigProvider } from 'antd';
import { SearchIcon, CalendarIcon, ExploreEventFlowersBackground, Arrow } from '../../Assets';
import { useHistory } from "react-router-dom";
import 'moment/locale/es-mx';

import {
    MainContainer,
    SearchContainer,
    BottomContainer,
    Title,
} from './styles';

function ExploreEvents() {

    const [eventName, setEventName] = useState('');
    const [initialDate, setInitialDate] = useState('');
    const [finalDate, setFinalDate] = useState('');

    const { RangePicker } = DatePicker;

    let history = useHistory();

    const onChangeSearchName = value => {
        setEventName(value);
    };

    const onKeyPressCard = e => {
        if (e.key === 'Enter' && eventName !== '') {
            e.preventDefault();
            searchScreen();
        }
    };

    const onChange = (_, dateString) => {
        setInitialDate(dateString[0]);
        setFinalDate(dateString[1]);
    };

    const searchScreen = () => {
        if (initialDate && finalDate && eventName) {
            history.push({
                pathname: '/search',
                search: `?initialDate=${initialDate}&finalDate=${finalDate}&eventName=${eventName}`
            })

            window.location.reload();
        } else if (eventName) {
            history.push({
                pathname: '/search',
                search: `?eventName=${eventName}`
            });
            window.location.reload();
        }
    }
    return (
        <MainContainer onKeyDown={e => onKeyPressCard(e)}>
            <img className="background-img" src={ExploreEventFlowersBackground} alt="" />
            <Title>Explora eventos en Culiacán</Title>
            <SearchContainer>
                <InputText
                    placeholder="¿Qué quieres hacer?"
                    onChange={event => onChangeSearchName(event.target.value)}
                    className='exploreInput'
                    value={eventName}
                    icon={SearchIcon}
                    width="300px"
                    id='search-event'
                />
                <BottomContainer>
                    <ConfigProvider>
                        <Space direction="vertical" size={12}>
                            <RangePicker onChange={onChange}
                                suffixIcon={<img src={CalendarIcon} alt="" className="icon-rangepicker" />}
                                placeholder={['¿Cuándo?', '']}
                                onFocus={(ev) => {
                                    if (ev.target.placeholder === '¿Cuándo?') {
                                        ev.target.placeholder = ' '
                                    }
                                }
                                }
                                onBlur={(ev) => {
                                    if (ev.target.placeholder === ' ') {
                                        ev.target.placeholder = ["¿Cuándo?"]
                                    }
                                }
                                }
                            />
                        </Space>
                        <Button
                            text="Buscar plan"
                            className="button hide-on-phone"
                            onClick={searchScreen}
                            icon={null}
                        />
                        <Button
                            icon={Arrow}
                            text=""
                            className="button hide-on-desktop"
                            onClick={searchScreen}
                        />
                    </ConfigProvider>
                </BottomContainer>
            </SearchContainer>
        </MainContainer>
    );
}

export default memo(ExploreEvents);
