import styled from 'styled-components';
import {mediaQuery} from '../../utils';

export const Image = styled.img`
  width: 60%;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  z-index: -1;
  
  ${mediaQuery} {
    display: none;
  }
`;

export const RegisterForm = styled.div`
  width: 40%;
  padding-top: ${props => props.removePadding ? "0": "80px"};
  
  ${mediaQuery} {
    width: 100%;
  }
`;

export const Form = styled.div`
  margin: 0 10%;
  height: max-content;

  ${mediaQuery} {
    margin: 0 16px;
  }
  
  & #advanced_search_birthday {
    margin: 12px 0 4px 0;
  }
  
  & .ant-form-item-explain, .ant-form-item-extra {
    max-width: 392px;
    
    ${mediaQuery} {
      max-width: 100%;
    }
  }

  .input {
    height: 52px;
    width: 100%;
    max-width: 392px;
    border: 1px solid #EDEDF3;
    border-radius: 8px;

    ${mediaQuery} {
      max-width: 100%;
    }
  }
`;

export const RegisterContainer = styled.div`
  display: flex;
  overflow-x: hidden;
`;

export const Title = styled.div`
  height: 40px;
  color: rgba(0,0,0,0.87);
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 40px;
  margin: 88px 10% 48px 10%;

  ${mediaQuery} {
    font-size: 28px;
    letter-spacing: 0;
    line-height: 36px;
    margin: 32px 16px 40px 16px;
  }
`;

export const DateInput = styled.input`
  height: 52px;
  margin: 20px 0;
  border-radius: 5px;
  border: 1.3px solid #dfdfea;
  padding: 0 10px;
  width: ${props => props.width ? props.width : '200px'};
  max-width: 118px;

  ::placeholder {
    color: #84839c;
    line-height: 52px;
    font-size: 14px;
  }

  :focus {
    border: 1.3px solid #dfdfea;
    outline: none !important;
  }

  ${mediaQuery} {
    width: 33%;
    max-width: 33%;
    min-width: 80px;
    margin-right: 8px;
  }
`;

export const ButtonContainer = styled.div`
  margin: 20px 0 32px 0;
  width: 100%;
  padding: 0 10%;

  ${mediaQuery} {
    width: 100%;
    margin: 44px 0 36px 0;
    padding: 0 16px;
  }

  .button-register {
    background-color: #7B00B2;
    border: none;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.8px;
    line-height: 22px;
    text-align: center;
    width: 100%;
    max-width: 392px;
    height: 48px;
    border-radius: 8px;

    ${mediaQuery} {
      max-width: 100%;
    }
  }
`;

export const DateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 392px;
  
  & .ant-row {
    max-width: 100%;
    min-width: 100%;
  }
  
  ${mediaQuery} {
    max-width: 100%;
  }
`;

export const Label = styled.label`
  height: 22px;
  color: #84839C;
  font-size: 14px;
  letter-spacing: -0.2px;
  line-height: 22px;
`;

export const CheckGrouper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  max-width: 392px;
  
  ${mediaQuery} {
    max-width: 100%;
  }
`;

export const GenderCheck = styled.div`
  height: 49px;
  width: 48%;
  line-height: 49px;
  border: 1px solid ${props => props.selected ? '#7B00B2' : '#D7D7E5'};
  border-radius: 8px;
  text-align: center;
  background-color: ${props => props.selected ? '#7B00B2' : '#ffffff'};
  color: ${props => props.selected ? '#ffffff' : '#84839C'};
  margin-bottom: 16px;
  cursor: pointer;
  
  ${mediaQuery} {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.2px;
  }
`;

export const DateLabelContainer = styled.div`
  margin-top: 8px;
`;

export const CustomDateInput = styled.input`
  height: 52px;
  margin: 20px 0;
  border-radius: 5px;
  border: 1.3px solid #dfdfea;
  padding: 0 10px;
  width: 100%;
  max-width: 392px;

  ${mediaQuery} {
    max-width: 100%;
  }
`;

export const GenderLabelContainer = styled.div`
  margin-top: 12px;
  margin-bottom: 12px;
`;

export const NewAccountText = styled.div`
  height: 20px;
  color: #636363;
  font-size: 14px;
  letter-spacing: -0.2px;
  line-height: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 32px;
  max-width: 392px;

  ${mediaQuery} {
    max-width: 100%;
  }
`;

export const LogInText = styled.div`
  height: 20px;
  color: #7B00B2;
  font-size: 14px;
  letter-spacing: -0.2px;
  line-height: 20px;
  font-weight: 600;
  cursor: pointer;
  margin-left: 5px;
`;

export const PasswordInput = styled.div`
  position: relative;
  max-width: 392px;

  & img {
    position: absolute;
    top: 26px;
    right: 12px;
    width: 24px;
    height: 24px;
  }

  ${mediaQuery} {
    max-width: 100%;
  }
`;