import styled from 'styled-components';
import {mediaQuery, mediaQuery1150, mediaQuery1320, mediaQuery930} from '../../utils';

export const MainContainer = styled.div`
  width: 100%;
  height: fit-content;
  background-color: ${props => props.color == "secondary" ? "#F4F4F4" : "#FFFFFF"};
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 96px 0px 0px 0px;
  
  ${mediaQuery1320}{
    height: fit-content;
    
  }
  ${mediaQuery930}{
    height: fit-content;
  }
  ${mediaQuery} {
    height: fit-content;
    padding: 40px 24px 35px 24px;
  }
`;

export const TopContainer = styled.div`
 display: flex;
 height: 1/5;
 width: 100%;
 padding-left: 5.56%;
 padding-right: 50.72%;
 ${mediaQuery1150}{
   padding-right:30% ;
 }
 ${mediaQuery}{
   padding-right: 20%;
 }
`;
export const BottomContainer = styled.div`

 padding: 72px 13.04% 0px 13.04%;
 height: fit-content;
 display: flex;
 flex-flow: wrap;
 width: 100%;
 justify-content: space-between;
 ${mediaQuery}{
   padding: 48px 0px 0px 0px;
 }
`;

export const AdvantageContainer = styled.div`
display: flex;
flex-direction: column;
width: 295px;
height: 360px;
align-items: center;
flex: 0 0 calc(33% - 2em);
${mediaQuery930}{
  height: fit-content;
  margin-bottom: 64px;
}
${mediaQuery}{
  height: 245px;
  width: 150px;
  margin-bottom: 80px;
  flex: 0 1 calc(50% - .5em); 
}

@media (max-width: 500px) {
  margin-bottom: 12px;
}
`
export const Title = styled.div`
  color: rgba(64,0,110,0.87);
  font-family: Gotham;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 48px;
  
  @media (max-width: 500px) {
    font-size: 24px;
    letter-spacing: 0.18px;
    line-height: 32px;
  }
`;

export const Subtitle = styled.div`
    margin-bottom: 24px;
    font-family: Rubik;
    color: #191919;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 0.26px;
    line-height: 36px;
    text-align: center;
    @media (max-width: 500px) {
      font-size: 20px;
      letter-spacing: 0.19px;
      margin-bottom: 8px;
    }
`;

export const Description = styled.div`
  max-width: 350px;
  color: #7E7E7E;
  font-family: Rubik;
  font-size: 24px;
  letter-spacing: 0.23px;
  line-height: 32px;
  text-align: center;
  padding: 0px 1.2rem 0px 1.2rem;
  @media (max-width: 500px) {
    max-width: 148px;
    font-size: 16px;
    letter-spacing: 0.15px;
    line-height: 24px;
    padding: 0px;
  }
`
export const Icon = styled.img`
  height: 104px;
  width: 104px;
  margin-bottom: 32px;
  @media (max-width: 500px) {
    height: 80px;
    width: 80px;
    margin-bottom: 12px;
  }

`

// export const CircleExample = styled.div`

//   justify-content: center;
//   align-items: center;
//   overflow: hidden;
//   height: 112px;
//   width: 112px;
//   background: #D8D8D8;
//   border-radius: 50%;
//   margin-bottom: 40px;
  
//   /* & .icon {
//     width: 48px;
//     height: 48px;
//     object-fit: contain;
//   } */

//   ${mediaQuery1150}{
//     height: 80px;
//     width: 80px;
//   }

//   ${mediaQuery} {
//     width: 48px;
//     height: 48px;
  
//     & .icon {
//       width: 24px;
//       height: 24px;
//     }
//   }
// `;






