import React, { memo, useState, useEffect } from 'react';
import { Collapse, Radio } from 'antd';
import 'moment/locale/es-mx';
import {
    MainContainer,
    DesktopContainer,
    MobileContainer,
    LeftContainer,
    RightContainer,
    Title,
    TitleCollapse,
    Description,
    Instruction,
    SectionsContainer,
    TitleTopic,
    TopicsContainer
} from './styles';
import StylesTabs from './stylesTabs'; 
import PropTypes from 'prop-types';
import {ArrowDownIcon, PlusIcon, MinusIcon } from '../../Assets';

const { Panel } = Collapse;

function DoubtsContainer(props) {
    const {title, topics, questions, style} = props;
    const [topicSelected, setTopicSelected] = useState(1);
    const changeQuestions = (id) => {
        setTopicSelected(id);
    }

    useEffect(() => {
        setTopicSelected('1');
    }, []);

    return (
        <>
        <StylesTabs/>
        <MainContainer color={style}>
            <DesktopContainer>
                <LeftContainer>
                    <Title>{title}</Title>
                    <SectionsContainer>
                        <Radio.Group value={topicSelected} className='radioGroup'>
                            {topics.map((topic => 
                                <Radio.Button value={topic.id} key={topic.id} onChange={() => changeQuestions(topic.id)} >{topic.title}</Radio.Button>
                            ))}
                        </Radio.Group>
                    </SectionsContainer>
                </LeftContainer>
                <RightContainer>
                <Collapse
                    bordered={false}
                    defaultActiveKey={['1']}
                    expandIcon={({ isActive }) => isActive ? <img src={ArrowDownIcon} style={{transform : "rotate(180deg)"}} className="arrow"/> : <img src={ArrowDownIcon}/>}
                    className="site-collapse-custom-doubtscontainer"
                    expandIconPosition="right"
                    accordion
                    >
                    {questions.map((question => 
                        topicSelected == question.foreignId && 
                        <Panel header={<Instruction> <TitleCollapse>{question.title}</TitleCollapse> </Instruction>} key={question.id} className="site-collapse-custom-panel">
                            <Description><div dangerouslySetInnerHTML={{__html: question.text}} /></Description>
                        </Panel>
                    ))}
                </Collapse>
                </RightContainer>
            </DesktopContainer>
            <MobileContainer color={style}>
                <Title>{title}</Title>
                <Collapse
                    bordered={false}
                    defaultActiveKey={['1']}
                    expandIcon={({ isActive }) => isActive ? <img src={MinusIcon} className="arrow"/> : <img src={PlusIcon}/>}
                    className={style}
                    expandIconPosition="right"
                    accordion
                    >
                    {topics.map((topic => 
                        <Panel header={<Instruction> <TitleTopic>{topic.title}</TitleTopic> </Instruction>} key={topic.id} className="site-collapse-custom-panel">
                            <TopicsContainer>
                            <Collapse
                                bordered={false}
                                defaultActiveKey={['1']}
                                expandIcon={({ isActive }) => isActive ? <img src={ArrowDownIcon} style={{transform : "rotate(180deg)"}} className="arrow"/> : <img src={ArrowDownIcon}/>}
                                className="site-collapse-custom-doubtscontainer customDoubts"
                                expandIconPosition="right"
                                accordion
                                >
                                {questions.map((question => 
                                    topic.id == question.foreignId && 
                                    <Panel header={<Instruction> <TitleCollapse className='collapse-custom-title-responsive'>{question.title}</TitleCollapse> </Instruction>} key={question.id} className="site-collapse-custom-panel">
                                        <Description><div dangerouslySetInnerHTML={{__html: question.text}} /></Description>
                                    </Panel>
                                ))}
                            </Collapse>
                            </TopicsContainer>
                        </Panel>
                    ))}
                </Collapse>
            </MobileContainer>
        </MainContainer>
        
        </>
    );
}
DoubtsContainer.propTypes = {
    title: PropTypes.string,
    topics: PropTypes.array,
    questions: PropTypes.array,
    style: PropTypes.string
  };
  
  DoubtsContainer.defaultProps = {
    title: '',
    topics: {},
    questions: {},
    style: ""
  };
export default memo(DoubtsContainer);
