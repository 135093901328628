import styled from 'styled-components';
import {mediaQuery} from '../../utils';

export const MainContainer = styled.div``;

export const ShareButton = styled.div`
  border: 1.5px solid #D3C3DD;
  border-radius:  ${props => props.onlyIcon ? '20px' : '8px'};
  background-color:  ${props => props.onlyIcon ? 'rgba(119,69,149,0.08)' : '#ffffff'};
  padding: ${props => props.onlyIcon ? '8px' : '8px 12px'};
  border: ${props => props.onlyIcon && 'none'};
  color: ;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.4px;
  line-height: 22px;
  display: flex;
  cursor: pointer;
  width:  ${props => props.onlyIcon && '41px'};
  height:  ${props => props.onlyIcon && '41px'};
  
  & div {
    display: ${props => props.onlyIcon ? 'none' : 'inherit'};
  }
  
  ${mediaQuery} {
    width: 40px;
    height: 40px;
    padding: 10px;
    border-radius: 20px;
    & div {
      display: none;
    }
  }
  
  & img {
    object-fit: inherit;
    margin: ${props => props.onlyIcon ? '0' : '0 8px 0 0'};
    transform: ${props => props.onlyIcon && 'rotate(180deg)'};
    
    ${mediaQuery} {
      margin: 0;
    }
  }
`;

export const ShareContentContainer = styled.div`
  border-radius: 14px;
`;

export const Divider = styled.div`
  width: 150%;
  max-width: 207px;
  border-bottom: 1px solid #D9D9D9;
  margin-left: -30px;
  margin-bottom: 10px;
`;

export const SocialMediaItems = styled.div`
  padding: 10px 14px 0 14px;
  
  .socialMedia {
    display: flex;
    margin-bottom: 20px;
  }
  
  .socialMediaName {
    color: rgba(0,0,0,0.87);
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.15px;
    line-height: 24px;
  }
  
  & img {
    margin-right: 16px;
    height: 24px;
    width: 24px;
  }
`;
