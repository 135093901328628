import React, {memo, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Drawer, Modal} from 'antd';
import PaymentModalStep1 from '../../../components/PaymentModalStep1';
import PaymentModalStep2 from '../../../components/PaymentModalStep2';
import PaymentModalStep3 from '../../../components/PaymentModalStep3';
import PaymentModalStep4 from '../../../components/PaymentModalStep4';
import {CloseIconGray} from '../../../Assets';
import {
    MainContainer,
    ModalMainContainer,
    CloseModal,
} from './PaymentConfirmStyles';
import https, {getToken} from '../../../config/https';
import {get} from 'lodash';
import NoTicketsModal from '../../../components/NoTicketsModal';
import ExpiredTimeModal from '../../../components/ExpiredTimeModal';
import CloseTicketSelection from '../../../components/CloseTicketSelection';
import {useMatomo} from '@datapunt/matomo-tracker-react';
import {getUserStoraged} from '../../../utils';
import BecomeMemberModal from '../../../components/BecomeMemberModal';
import RegisterSocialMediaModal from '../../../components/RegisterSocialMediaModal';
import axios from 'axios';

const PaymentConfirm = (props) => {
    const user = getUserStoraged();
    const {toggleDialog, openDialog, id, detailImage, step} = props;

    const [currentStep, setCurrentStep] = useState(1);
    const [, forceUpdate] = useState({});
    const [admission, setAdmission] = useState(null);
    const [eventDays, setEventDays] = useState(null);
    const [buyDetails, setBuyDetails] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [openNoTicketsModal, setOpenNoTicketsModal] = useState(false);
    const [eventName, setEventName] = useState('');
    const [previousSelectedData, setPreviousSelectedData] = useState(null);
    const [idBasket, setIdBasket] = useState('');
    const [confirmCloseModalOpen, setConfirmCloseModalOpen] = useState(false);
    const [showGuestModal, setShowGuestModal] = useState(false);
    const [showRegisterModal, setShowRegisterModal] = useState(false);

    const {trackEvent} = useMatomo();

    const closeRegisterModal = () => {
        setShowRegisterModal(false);
    };

    // To disable submit button at the beginning.
    useEffect(() => {
        forceUpdate({});
        https.get(`/api/v1/obtener-evento/${id}/tipo-entradas`).then((res) => {
            https
                .get(`/api/v1/event/${id}`)
                .then((dayEvents) => {
                    let dayEventsResponse = get(dayEvents, 'days_date', []);
                    let eventDaysTemp = dayEventsResponse.map((day) => {
                        day.day = day.day.replace('00:00:00', '12:00:00');
                        return {
                            text: day.day_spanish,
                            textFull: day.day_spanish_full,
                            originalDate: day.day,
                            selected: false,
                            id: day.id,
                        };
                    });
                    setEventDays(eventDaysTemp);
                })
                .catch((err) => {
                    setEventDays([]);
                });
            let tempAdmissionResponse = res || [];
            let admissionTemp = tempAdmissionResponse.map((data) => {
                return {
                    text: data.type,
                    fewPlaces: data.capacity < 50,
                    price: data.price,
                    capacity: data.capacity,
                    typeId: data.id,
                    discounted_price: data.discounted_price,
                };
            });
            setAdmission(admissionTemp);
        });
    }, [openDialog]);

    const setDataForStepThree = (elements, isMultipleTicketTypes = false) => {
        setPreviousSelectedData({
            dayIndex: get(elements, 'dayIndex', ''),
            hourIndex: get(elements, 'hourIndex', ''),
            isMultipleTicketTypes,
        });
        let payload = {
            id_event: elements.eventId,
            tickets: [],
        };
        let admissionCount = get(elements, 'admissionCount', []);
        admissionCount.forEach((admission) => {
            payload.tickets.push({
                id_hour: elements.hour,
                id_day: elements.day,
                id_ticket: admission.id,
                amount: admission.count,
            });
        });

        trackEvent({
            category: 'Pago',
            action: 'Comprar',
            name: `EventoId ${elements.eventId}`,
        });

        https.post('/api/v1/sale/reserve-tickets', payload).then((res) => {
            console.log('reserve-tickets', res);
            let basket = get(res, 'basket', '');
            setBuyDetails({...elements, basket});
            if (!user) {
                setShowGuestModal(true);
            } else {
                setCurrentStep(2);
            }
        });
    };

    const onEdit = () => {
        setCurrentStep(1);
    };

    const closeDrawer = (ev, closeConfirmation) => {
        if (!closeConfirmation && currentStep === 2) {
            setConfirmCloseModalOpen(true);
        } else {
            if (confirmCloseModalOpen) {
                setConfirmCloseModalOpen(false);
                setPreviousSelectedData(null);
                setBuyDetails(null);
            }
            toggleDialog();
            setCurrentStep(1);
        }
    };

    const processPayment = async (buyDetails, isFree, guestEmail) => {

        trackEvent({
            category: 'Pago',
            action: 'Confirmar',
            name: `EventoId ${buyDetails.eventId}`,
        });

        if (isFree) {
            let currentUser = getUserStoraged();
            const email = currentUser && currentUser.email || guestEmail;
            let postPayload = {
                tickets: [],
            };

            buyDetails.admissionCount.forEach((ticket) => {
                let tempClients = get(ticket, 'clients', []);
                tempClients.forEach((client, index) => {
                    postPayload.tickets.push({
                        id_hour: buyDetails.hour,
                        id_day: buyDetails.day,
                        id_ticket: ticket.id,
                        email: index === 0 && guestEmail ? guestEmail : client.email,
                        first_name: client.name,
                        last_name: client.lastName,
                    });
                });
            });

            const reservedTickeds = await https.post(
                '/api/v1/sale/paid-reserved-tickets',
                {
                    ...postPayload,
                    basket: buyDetails.basket,
                },
            );

            let payload = {basket: buyDetails.basket}

            const user = getUserStoraged();

            let headers = {
                'Authorization': `Bearer ${getToken()}`,
            }

            if (!user) {
                payload.send_sale_info_to_email = guestEmail;
                headers = undefined;
            }

            await axios.post(process.env.REACT_APP_API_ENDPOINT_URL + '/api/v1/sale/pay', payload, {
                headers: headers
            })

            sessionStorage.removeItem('paymentDetails');
            sessionStorage.setItem(
                'paymentDetails',
                JSON.stringify({
                    ...reservedTickeds,
                    email: guestEmail ? guestEmail : email,
                    basket: buyDetails.basket,
                }),
            );
            sessionStorage.setItem('validPayment', true);
        } else {
            document.getElementById('stripe-payment').click();
        }
    };

    const handleOpenNoTimeModal = () => {
        setOpenModal(true);
    };

    const handleOpenNoTicketsModal = (name) => {
        setEventName(name);
        setOpenNoTicketsModal(true);
    };

    const goToTickets = (item) => {
        setIdBasket(item);
        setCurrentStep(4);
        setShowRegisterModal(!!!user);
    };

    const handleCloseNoTicketsModal = () => {
        setOpenNoTicketsModal(false);
        setEventName('');
        closeDrawer();
    };

    const handleCloseNoTimesModal = () => {
        setOpenModal(false);
        setCurrentStep(1);
    };

    const closeLoginModal = () => {
        setShowGuestModal(false);
        setCurrentStep(2);
    };

    const handlePaidModal = () => {
        setCurrentStep(3);
    };

    return (
        <div>
            <CloseTicketSelection
                openModal={confirmCloseModalOpen}
                onAccept={() => setConfirmCloseModalOpen(false)}
                onCloseModal={() => setConfirmCloseModalOpen(false)}
                onCancel={(ev) => closeDrawer(ev, true)}
            />
            <Drawer
                placement={'bottom'}
                closable={false}
                onClose={closeDrawer}
                visible={openDialog}
                key={'bottom'}
                width={'100%'}
                height={'100%'}
                style={{zIndex: 999}}
            >
                <MainContainer>
                    <img
                        src={CloseIconGray}
                        alt=""
                        className="close-icon"
                        onClick={closeDrawer}
                    />
                    {currentStep === 1 && openDialog && (
                        <PaymentModalStep1
                            onClickButton={setDataForStepThree}
                            admission={admission}
                            eventDays={eventDays}
                            id={id}
                            buyDetails={buyDetails}
                            detailImage={detailImage}
                            openNoTicketsModal={(name) => handleOpenNoTicketsModal(name)}
                            previousData={previousSelectedData}
                        />
                    )}
                    {currentStep === 2 && openDialog && (
                        <PaymentModalStep2
                            onClickEdit={onEdit}
                            onClickButton={(isFree, guestEmail) =>
                                processPayment(buyDetails, isFree, guestEmail)
                            }
                            nextStep={handlePaidModal}
                            buyDetails={buyDetails}
                            detailImage={detailImage}
                            openNoTimeModal={() => handleOpenNoTimeModal()}
                        />
                    )}
                    {currentStep === 3 && openDialog && (
                        <PaymentModalStep3 onClickButton={goToTickets}/>
                    )}
                    {currentStep === 4 && openDialog && (
                        <PaymentModalStep4 idBasket={idBasket}/>
                    )}
                    <NoTicketsModal
                        openModal={openNoTicketsModal}
                        eventName={eventName}
                        onCloseModal={() => handleCloseNoTicketsModal()}
                        onClickButton={() => handleCloseNoTicketsModal()}
                    />
                    <ExpiredTimeModal
                        openModal={openModal}
                        onCloseModal={() => handleCloseNoTimesModal()}
                        onClickButton={() => handleCloseNoTimesModal()}
                    />
                </MainContainer>
            </Drawer>
            <Drawer
                placement={'bottom'}
                closable={true}
                onClose={() => {
                    setShowGuestModal(false);
                }}
                visible={showGuestModal}
                key={'bottom'}
                width={'100%'}
                height={'100%'}
                style={{zIndex: 9999}}
            >
                <BecomeMemberModal closeLoginModal={closeLoginModal}/>
            </Drawer>
            <Modal
                visible={showRegisterModal}
                closable={true}
                onCancel={closeRegisterModal}
                footer={null}
            >
                <ModalMainContainer>
                    <CloseModal
                        src={CloseIconGray}
                        alt=""
                        className="modal-close-icon"
                        onClick={closeRegisterModal}
                    />
                    <RegisterSocialMediaModal closeRegisterModal={closeRegisterModal}/>
                </ModalMainContainer>
            </Modal>
        </div>
    );
};

PaymentConfirm.propTypes = {
    toggleDialog: PropTypes.func,
    openDialog: PropTypes.bool,
    step: PropTypes.number,
    id: PropTypes.string,
};

PaymentConfirm.defaultProps = {
    toggleDialog: () => {
    },
    openDialog: false,
    step: 1,
    id: 1,
};

export default memo(PaymentConfirm);
