import React, {memo, useLayoutEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {LeftArrow, RightArrow, LeftArrowDisabled, RightArrowDisabled} from '../../Assets'
import {
    MainContainer,
    HeaderContainer,
    Title,
    PaginateControls,
    FullList,
    ChangePage,
} from './styles';
import {get} from 'lodash';

function EventGrouper(props) {
    const {events, title, path, customMargin, isRecommendations } = props;
    const [scrollWidth, setScrollWidth] = useState();
    useLayoutEffect(() => {
        const container = document.getElementById(`${title}`);
        setScrollWidth(container.scrollWidth);
        
        const next = document.getElementById(`${title}-next`);
        const previous = document.getElementById(`${title}-previous`);
        const nextImage = document.getElementById(`${title}-next-image`);
        const previousImage = document.getElementById(`${title}-previous-image`);

        const initialMaxScrollLength = container.scrollWidth - container.clientWidth;
        if (container.scrollLeft >= initialMaxScrollLength) {
            nextImage.src = RightArrowDisabled;
            nextImage.style.cursor = 'auto';
        }
        previousImage.style.cursor = 'auto';

        next.onclick = function () {
            setScrollWidth(container.scrollWidth);
            const maxScrollLeft = container.scrollWidth - container.clientWidth;
            let scrollAmount = 0;
            const slideTimer = setInterval(function(){
                container.scrollLeft += 30;
                if (container.scrollLeft > 0) {
                    previousImage.src = LeftArrow;
                    previousImage.style.cursor = 'pointer';
                } 
                if (container.scrollLeft >= maxScrollLeft) {
                    nextImage.src = RightArrowDisabled;
                    nextImage.style.cursor = 'auto';
                }
                scrollAmount += 30;
                if(scrollAmount > 300){
                    window.clearInterval(slideTimer);
                }
            }, 20);
            
        };
        previous.onclick = function () {
            const maxScrollLeft = container.scrollWidth - container.clientWidth;
            let scrollAmount = 0;
            const slideTimer = setInterval(function(){
                container.scrollLeft -= 30;
                if (container.scrollLeft < maxScrollLeft) {
                    nextImage.src = RightArrow;
                    nextImage.style.cursor = 'pointer';
                } 
                if (container.scrollLeft === 0) {
                    previousImage.src = LeftArrowDisabled;
                    previousImage.style.cursor = 'auto';
                }
                scrollAmount += 30;
                if(scrollAmount > 300){
                    window.clearInterval(slideTimer);
                }
            }, 20);
        }
    },[]);

    return (
        <MainContainer> 
            <HeaderContainer customMargin={customMargin}>
                <Title isRecommendations={isRecommendations}>{title}</Title>
                {get(events, 'props.children', []).length >= 7 && <FullList className="ver-todo"><a href={path}>Ver todos</a></FullList>}
                <PaginateControls>
                    {get(events, 'props.children', []).length >= 7 && <FullList><a href={path}>Ver todos</a></FullList>}
                    <ChangePage id={`${title}-previous`} > <img src={LeftArrowDisabled}  alt="" id={`${title}-previous-image`} /> </ChangePage>
                    <ChangePage id={`${title}-next`}> <img src={RightArrow} alt="" id={`${title}-next-image`}/> </ChangePage>
                </PaginateControls>
            </HeaderContainer>
            {events}
        </MainContainer>
    );
}

EventGrouper.propTypes = {
    events: PropTypes.node,
    title: PropTypes.string,
    path: PropTypes.string,
    customMargin: PropTypes.string,
    isRecommendations: PropTypes,
};

EventGrouper.defaultProps = {
    events: <></>,
    title: '',
    customMargin: '',
    isRecommendations: false,
};

export default memo(EventGrouper);
