import styled from 'styled-components';
import { mediaQuery1320 } from '../../utils';

export const MainContainer = styled.div`
  margin-left: 288px;
  & .ant-table-tbody {
    background-color: #FAFAFA;
  }
  
  & .event-image {
    height: 40px;
    width: 40px;
    border-radius: 4px;
  }
  ${mediaQuery1320} {
    margin-left: auto;
  }
`;