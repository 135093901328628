import { useContext, useMemo } from 'react'
import GlobalContext from '../contexts/global-context';
import axios from './https';

const AxiosWithContext = ({ children }) => {
    const { setOffline } = useContext(GlobalContext);

    useMemo(() => {
        axios.interceptors.response.use(response => response, async (error) => {
          if (!error.response || !error.response.status) {
            setOffline(true)
          }
        })
    }, [setOffline])

    return children
}

export default AxiosWithContext