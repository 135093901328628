import styled from 'styled-components';
import { mediaQuery } from '../../utils';

export const ItemContainer = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;
  margin-bottom: 26px;
  @media only screen and (max-device-width: 480px) {
    overflow-x: scroll;
  }
`;


export const ExploreGrouperContainer = styled.div`
  margin-bottom: 64px;
  ${mediaQuery} {
    margin-bottom: 32px;
  }
`;
