import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {MainContainer, Title, DescriptionDesktop, DescriptionResponsive} from './styles';
import ModalFullscreen from '../ModalFullscreen';
import {SadPhone} from '../../Assets';
import Button from '../Button';

const NoTicketsModal = (props) => {
    const {openModal, onCloseModal, onClickButton, eventName} = props;

    return (
        <ModalFullscreen openModal={openModal} onCloseModal={onCloseModal}>
            <MainContainer>
                <img src={SadPhone} alt="" className="phone-icon"/>
                <Title>Ups, se han agotado las entradas</Title>
                <DescriptionDesktop>Lo sentimos, las entradas para {eventName} se han <br/> agotado, puedes buscar de
                    nuevo dandole click al botón</DescriptionDesktop>
                <DescriptionResponsive>Lo sentimos, las entradas para {eventName} se han agotado, puedes buscar de
                    nuevo dandole click al botón</DescriptionResponsive>
                <Button
                    text="Buscar de nuevo"
                    className="button-back"
                    onClick={onClickButton}
                />
            </MainContainer>
        </ModalFullscreen>
    )
};


NoTicketsModal.propTypes = {
    openModal: PropTypes.bool,
    onCloseModal: PropTypes.func,
    onClickButton: PropTypes.func,
    eventName: PropTypes.string,
};

NoTicketsModal.defaultProps = {
    openModal: false,
    onCloseModal: () => {
    },
    onClickButton: () => {
    },
    eventName: '',
};

export default memo(NoTicketsModal);