import styled from 'styled-components';
import {mediaQuery, mediaQuery1320, mediaQuery1150} from '../../utils';

export const MainContainer = styled.div`
  width: 100%;
  height: 962px;
  background-color: #FFFFFF;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 120px 80px;
  ${mediaQuery1320}{
    padding-left: 30px;
    padding-right: 30px;
  }
  ${mediaQuery}{
    height: 880px;
    justify-content: end;
    flex-direction: column;
    padding: 0px;
    margin-bottom: 64px;
    
  }
`;



export const LeftContainer = styled.a`
    position: relative;
    display: inline-block;
    text-align: center;
    height: 722px;
    width: 620px;
    flex: 0 1 calc(47%);
    ${mediaQuery}{
      margin-bottom: 4px;
      width: 100%;
      height: 420px;
      flex: 1;
    }
    :hover {
    opacity: 0.9;
  }
    `;

export const RightContainer = styled.a`
    position: relative;
    display: inline-block;
    text-align: center;
    height: 722px;
    width: 620px;
    flex: 0 1 calc(47%);
    ${mediaQuery}{
      width: 100%;
      height: 420px;
      flex: 1;
    }
    :hover {
    opacity: 0.9;
  }
`;

export const Title = styled.div`
  text-transform: uppercase;
  position: absolute;
  bottom: 6%;
  left: 40%;
  transform: translate(-50%, 0%);
  text-align: center;
  color: #FFFFFF;
  font-family: Gotham;
  font-weight: ultra!important;
  font-size: 48px;
  letter-spacing: 3px;
  line-height: 56px;
  text-align: left;
  ${mediaQuery1150}{
    left: 52%;
    font-size: 34px;
  }
  ${mediaQuery}{
    bottom: 20%;
    left: 40%;
    font-size: 32px;
    letter-spacing: 2px;
    line-height: 40px;
  }
`;

export const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
    ${mediaQuery}{
      border-radius: 0px;
    }
`
