import React, {memo, useState, useEffect} from 'react';
import GlobalStyles from '../../GlobalStyles';
import EmptySearch from '../../components/EmptySearch';
import SearchText from '../../components/SearchText';
import https from '../../config/https';
import moment from 'moment';
import { Helmet } from 'react-helmet';

function Search() {
    const [queryParams, setQueryParams] = useState({});
    const [resultCards, setResultCards] = useState([]);
    const [stringDate, setStringDate] = useState('');
    const [showEmpty, setShowEmpty] = useState(false);

    useEffect(() => {
        let query = window.location.search;
        query = query.substr(1, query.length);
        const params = query.split('&');
        if (params.length > 4) {
            //TODO: Lógica para evitar inyección de parametros
        } else {
            const paramsObject = {};
            params.forEach(param => {
                const objectArray = param.split('=');
                paramsObject[objectArray[0]] = objectArray[1]
            });
            setQueryParams(paramsObject);
            let queryParam = paramsObject.initialDate ? `?start_date=${paramsObject.initialDate}&end_date=${paramsObject.finalDate}` : '';
            
            https.get(`/api/v1/event/home/search/${paramsObject.eventName}${queryParam}`)
                .then(res => {
                    const initialDate = moment(paramsObject.initialDate).format('DD MMMM');
                    const dateArray = initialDate.split(' ');
                    setStringDate(`${dateArray[0]} de ${dateArray[1]}`);
                    const results = res && res.cardInfo || [];
                    if (results.length === 0) setShowEmpty(true);
                    setResultCards(results);
                })
                .catch(err => {
                    //TODO: Agregar logica para cuando truenen los servicios.
                })
        }
    }, []);


    return (
        <div>
            <GlobalStyles/>
            <div className="containerSearch">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Buscar</title>
                </Helmet>
                {resultCards.length || !showEmpty ? (
                    <div className="searchText">
                        <SearchText
                            item={queryParams.eventName}
                            firstDate={queryParams.initialDate}
                            secondDate={queryParams.finalDate}
                            results={resultCards}
                        />
                    </div>
                ) : (
                    <EmptySearch
                        search={queryParams.eventName}
                        initialDate={queryParams.initialDate}
                        finalDate={queryParams.finalDate}/>
                )
                }
            </div>
        </div>
    );
}

export default memo(Search);
