import React, { memo} from 'react';
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
import 'moment/locale/es-mx';

import {
    MainContainer,
    Title,
    Button
} from './styles';

function DiscoverPlan(props) {
    const {title, buttonText, image, imageResponsive, type, urlToGo} = props;
    
    let history = useHistory();
    
    return (
        <MainContainer type={type}>
            {
                type == "image" 
                ?
                    <>
                        <img className="background-img hide-on-phone" src={image} alt="" />
                        <img className="background-img hide-on-desktop" src={imageResponsive} alt="" />
                    </>
                :
                    <>
                        <video autoPlay="autoplay" loop="loop" muted className="background-video hide-on-phone" >
                            <source src={image} type="video/mp4" />
                        </video>
                        <video autoPlay="autoplay" loop="loop" muted className="background-video hide-on-desktop">
                            <source src={imageResponsive} type="video/mp4" />
                        </video>
                    </>
            }
             <Title>{title}</Title>
             <Button href={urlToGo}>{buttonText}</Button>
        </MainContainer>
    );
}

DiscoverPlan.propTypes = {
    title: PropTypes.string,
    buttonText: PropTypes.string,
    image: PropTypes.string,
    imageResponsive: PropTypes.string,
    type: PropTypes.string,
    urlToGo: PropTypes.string,
  };
  
  DiscoverPlan.defaultProps = {
    title: '',
    buttonText: '',
    image: '',
    imageResponsive: '',
    type: "",
    urlToGo: "",
  };

export default memo(DiscoverPlan);
