import React, {memo, useEffect} from 'react';
import {MainContainer, Title, Description} from './styles';
import {HappyPhone} from '../../Assets';
import Button from "../../components/Button";
import ModalFullscreen from "../../components/ModalFullscreen";
import {useHistory, useLocation} from "react-router-dom";
import https from "../../config/https";
import { Helmet } from "react-helmet";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const ConfirmAccount = () => {

    const history = useHistory();
    const closeAndRedirectIcon = () => {
        history.push("/login")
    }

    let query = useQuery();
    useEffect(() => {
        const token = query.get("token");
        https.get(`/api/v1/auth/user/validate?token=${token}`)
            .then(res => {
                console.log(res);
            })

    }, []);


    return (
        <ModalFullscreen openModal onCloseModal={closeAndRedirectIcon}>
            <MainContainer>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Confirmar Cuenta</title>
            </Helmet>
                <img src={HappyPhone} alt="" className="phone-icon"/>
                <Title>Gracias por confirmar tu cuenta</Title>
                <Description>Inicia sesión y comienza a disfrutar de las mejores </Description>
                <Description>experiencias en tu ciudad.</Description>
                <Button
                    text="Iniciar sesión"
                    className="button-back"
                    onClick={closeAndRedirectIcon}
                />
            </MainContainer>
        </ModalFullscreen>
    )
};

export default memo(ConfirmAccount);