import React, {memo, useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {MainContainer, DiscountText, NormalText, NoDiscountPrice} from './styles';

function PriceWithDiscount(props) {
    const {normalPrice, discountPrice, bigText, smallText, showPriceSign} = props;

    const [fontSizeNormal, setFontSizeNormal] = useState('20px');
    const [fontSizeDiscount, setFontSizeDiscount] = useState('20px');

    useEffect(() => {
        handleChangeFontSize();
    }, []);

    const handleChangeFontSize = () => {
      if (bigText) {
          setFontSizeNormal('18px');
          setFontSizeDiscount('24px');
      }
      if (smallText) {
          setFontSizeDiscount('16px');
          setFontSizeNormal('16px');
      }
    };

    return (
        <MainContainer>
            {discountPrice ? 
                <>
                    <NormalText fontSize={fontSizeNormal}>{showPriceSign ? `$${normalPrice}` : `${normalPrice}`} </NormalText>
                    <DiscountText fontSize={fontSizeDiscount}>{showPriceSign ? `$${discountPrice}` : `${discountPrice}`}</DiscountText>
                </> 
                :
                <NoDiscountPrice fontSize={fontSizeNormal}>{showPriceSign ? `$${normalPrice}` : `${normalPrice}`}</NoDiscountPrice>
            }
        </MainContainer>
    );
}

PriceWithDiscount.propTypes = {
    normalPrice: PropTypes.string,
    discountPrice: PropTypes.string,
    bigText: PropTypes.bool,
    smallText: PropTypes.bool,
    showPriceSign: PropTypes.bool
};

PriceWithDiscount.defaultProps = {
    normalPrice: '',
    discountPrice: '',
    bigText: false,
    smallText: false,
    showPriceSign: true
};

export default memo(PriceWithDiscount);
