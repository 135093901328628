import React, {memo} from 'react';
import {MainContainer, Title, Description} from './styles';
import {AsombradoPhone} from '../../Assets';
import Button from "../../components/Button";
import ModalFullscreen from "../../components/ModalFullscreen";
import {useHistory} from "react-router-dom";
import {HOME_REDIRECT} from "../../constants";
import { Helmet } from "react-helmet";
const NotFoundEvent = () => {

    const history = useHistory();
    const closeAndRedirectIcon = () => {
        history.push(HOME_REDIRECT)
    }

    return (
        <ModalFullscreen openModal onCloseModal={closeAndRedirectIcon}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Evento No Encontrado</title>
            </Helmet>
            <MainContainer>
                <img src={AsombradoPhone} alt="" className="phone-icon"/>
                <Title>Upps evento no encontrado</Title>
                <Description>No se encontro el evento que estas buscando,</Description>
                <Description>pero que crees tenemos muchos eventos que pueden gustarte</Description>
                <Button
                    text="Seguir explorando"
                    className="button-back"
                    onClick={closeAndRedirectIcon}
                />
            </MainContainer>
        </ModalFullscreen>
    )
};


export default memo(NotFoundEvent);