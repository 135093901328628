import React, { useState } from "react";
import PropTypes from 'prop-types';
import "./index.css";

const FloatLabel = props => {
  const [focus, setFocus] = useState(false);
  const { children, label, value } = props;

  const labelClass =
    focus || (value && value.length !== 0) ? "label label-float" : "label";
  return (
    <>
        <div
        className="float-label"
        onBlur={() => setFocus(false)}
        onFocus={() => setFocus(true)}
        >
        {children}
        <label className={labelClass}>{label}</label>
        </div>
    </>
  );
};

FloatLabel.propTypes = {
    children: PropTypes.node,
    label: PropTypes.string,
    value: PropTypes.string
};
  
FloatLabel.defaultProps = {
    children: '',
    label: '',
    value: '',
};

export default FloatLabel;