import React, {memo, useState, useEffect} from 'react';
import {
    FormContainer,
    Form as Design,
    ButtonContainer,
    DateContainer,
} from './styles';
import https from '../../config/https';
import {Form, message, Input, Button as ButtonAnt} from 'antd';
import moment from 'moment';
import FloatLabel from '../../components/FloatLabel';
import DateInput from '../DateInput';
import FloatLabelDate from '../FloatLabelDate';

const PersonalInformation = () => {
    const user = JSON.parse(localStorage.getItem('d'));
    const [form] = Form.useForm();
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [birthday, setBirthday] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const [userId, setId] = useState('');
    const currentDay = moment();
    currentDay.subtract(15, 'y');
    currentDay.format('DD-MM-YYYY');

    useEffect(() => {
        if (user) {
            getUserInfo();
        }
    }, []);

    const getUserInfo = () => {
        https.get(`/api/v1/user-profile`, {headers: {Authorization: `Bearer ${user.access_token}`}})
            .then((res) => {
                setId(res.id)
                setName(res.first_name)
                form.setFieldsValue({
                    first_name: res.first_name
                })
                setLastName(res.last_name)
                form.setFieldsValue({
                    last_name: res.last_name
                })
                setEmail(res.email)
                form.setFieldsValue({
                    email: res.email
                })
                setNumber(res.phone)
                form.setFieldsValue({
                    number: res.phone
                })


                if (res.birthday !== '') {
                    const formatBirthday = moment.utc(res.birthday).format('DD/MM/YYYY');
                    setBirthday(formatBirthday)
                    form.setFieldsValue({
                        birthday: formatBirthday
                    })
                    
                }

            })
            .catch((err => {
                message.error('Ha ocurrido un error inesperado.');
            }))
    };

    async function update(body) {
        try {
            const response = await https.patch("/api/v1/auth/user?noloader=true&noloader=true", body, {headers: {Authorization: `Bearer ${user.access_token}`}},);
            response['access_token'] = user.access_token;
            localStorage.setItem('d', JSON.stringify(response));
            
            await message.success({content: 'Información actualizada con éxito', duration: 1});
            window.location.reload()
        } catch (exception) {
            message.error('Ha ocurrido un error inesperado.');
        }
    }

    const onClickRegister = () => {
        form.validateFields().then(values => {
            const birthday = moment(values.birthday, "DD/MM/YYYY");
            const body = {
                id: userId,
                first_name: values.first_name.trim(),
                last_name: values.last_name.trim(),
                email: values.email.trim(),
                birthday: birthday.format('YYYY-MM-DD'),
                gender: 'MALE',
            }
            if (values.number) {
                body['phone'] = values.number.trim()
            }
            update(body)
        }).catch((err) => {
            message.error('Hubo un error inesperado');
        });
    }

    const validateEmail = (rule, value, callback) => {
        var emailValidation = /^(([^<>()\[\]\\.,;:\s@”]+(\.[^<>()\[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/
        if (value && user.email !== value && (emailValidation.test(value))) {
            https.get(`api/v1/auth/user/exist?email=${value}&noloader=true`)
                .then(res => {
                    if (res.exist) {
                        callback("Error!");
                    } else {
                        callback();
                    }
                })
        } else {
            callback();
        }
    };

    const onChangeFirstName = (e) => {
        form.setFieldsValue({
            first_name: e.target.value
        })
        setName(e.target.value)
    }

    const onChangeLastName = (e) => {
        form.setFieldsValue({
            last_name: e.target.value
        })
        setLastName(e.target.value)
    }
    const onChangeNumber = (e) => {
        form.setFieldsValue({
            number: e.target.value
        })
        setNumber(e.target.value)
    }

    const checkDate = (rule, value, callback) => {
       
        let result = moment(value, 'DD/MM/YYYY', true).isValid();
        
        if(result == false && value != ''){
            callback("Error!")
        }
        else{
            const validDay = moment();
            validDay.subtract(15, 'y');
            validDay.format('DD-MM-YYYY');
            let date1 =  moment(value, 'DD-MM-YYYY').valueOf();
            let date2 = validDay.valueOf();
            if (date2 < date1) {
                callback("Error!") 
            }
            else {
                callback();
            }
        }

    }

    const onChangeDate= (e) => {
        if (e != null) {
            setBirthday(e.target.value)
            form.setFieldsValue({
                birthday: e.target.value
            })
        } 
        else {
            setBirthday(null)
            form.setFieldsValue({
                birthday: null
            })
        }
    }

    const onChangeEmail = (e) => {
        const values = form.getFieldsValue();
        form.email = form.setFieldsValue({
            email: values.email.trim()
        });
        form.email = form.setFieldsValue({
            email: e.target.value
        })
        setEmail(e.target.value)
    }

    return (
        <FormContainer>
            <Form
                layout="vertical"
                form={form}
                onFinish={onClickRegister}
                initialValues={{
                    first_name: "",
                    last_name: "",
                    birthday: "",
                    email: "",
                    number: ""
                }}
                scrollToFirstError
                name="advanced_search"
                className="ant-advanced-search-form"
            >
                <Design>
                    <Form.Item
                        name="first_name"
                        className="input-container"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor ingresa el nombre',
                            },
                            {
                                pattern: new RegExp(/^[a-zA-Z @~`áéíóúüñÑ!@#$%^&*()_=+\\\\';:\"\\/?>.<,-]+$/i),
                                message: "Solo se aceptan letras y caracteres especiales"
                            },
                            {
                                min: 3,
                                message: 'El nombre debe contener al menos 3 caracteres.'
                            },
                        ]}
                    >
                        <FloatLabel label="Nombre*" name="firstname" value={name} >
                            <Input
                                autoComplete="new-password"
                                className="input"
                                maxLength={50}
                                value={name}
                                onChange={e => onChangeFirstName(e)}
                            />
                        </FloatLabel>
                    </Form.Item>
                    <Form.Item
                        name="last_name"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor ingresa el apellido',
                            },
                            {
                                pattern: new RegExp(/^[a-zA-Z @~`áéíóúüñÑ!@#$%^&*()_=+\\\\';:\"\\/?>.<,-]+$/i),
                                message: "Solo se aceptan letras y caracteres especiales"
                            },
                            {
                                min: 3,
                                message: 'El apellido debe contener al menos 3 caracteres.'
                            },
                        ]}
                    >
                        <FloatLabel label="Apellido*" name="lastName" value={lastName}>
                            <Input
                                onChange={e => onChangeLastName(e)}
                                className="input"
                                maxLength={50}
                                value={lastName}
                            />
                        </FloatLabel>
                    </Form.Item>
                    <DateContainer>
                        <Form.Item
                            name="birthday"
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor ingresa tu fecha de nacimiento',
                                    
                                },
                                {
                                    validator: checkDate,
                                    message: 'Por favor ingresa una fecha válida',
                                }
                            ]}
                        >
                           
                            <FloatLabelDate label="DD/MM/AAAA*" name="birthday" value={birthday && birthday.toString()} onChange={onChangeDate} />
                            
                        </Form.Item>
                    </DateContainer>
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor ingresa tu correo electrónico',
                            },
                            {
                                type: 'email',
                                message: 'Por favor ingresa un correo válido'
                            },
                            {
                                validator: validateEmail,
                                message: "Este correo ya esta en uso elige otro"
                            }
                        ]}
                    >
                        <FloatLabel label="Correo electrónico*" name="email" value={email}>
                            <Input
                                disabled={user && user.email !== ''}
                                className="input"
                                onChange={(e) => onChangeEmail(e)}
                                value={email}
                                onBlur={(e) => onChangeEmail(e)}
                                maxLength={254}
                            />
                        </FloatLabel>
                    </Form.Item>
                    <Form.Item
                        name="number"
                        rules={[
                            {
                                required: false,
                            },
                            {
                                min: 10,
                                message: 'Introduce un número telefónico válido'
                            },
                            {
                                max: 10,
                                message: 'Introduce un número telefónico válido'
                            },
                        ]}
                    >
                        <FloatLabel label="Celular (opcional para recibir promociones)" name="number" value={number}>
                            <Input
                                onChange={e => onChangeNumber(e)}
                                type="number"
                                className="input"
                                maxLength={254}
                                value={number}
                            />
                        </FloatLabel>
                    </Form.Item>
                </Design>
                <ButtonContainer>
                    <ButtonAnt
                        type="primary"
                        htmlType="submit"
                        block
                        className="button-register"
                    >
                        Actualizar
                    </ButtonAnt>
                </ButtonContainer>
            </Form>
        </FormContainer>
    )
};


PersonalInformation.propTypes = {};

PersonalInformation.defaultProps = {};

export default memo(PersonalInformation);