import React, {memo, useEffect} from 'react';
import PropTypes from 'prop-types';
import {LeftArrow, RightArrow} from '../../Assets'
import {
    MainContainer,
    HeaderContainer,
    PaginateControls,
    ChangePage,
    ExplorerContainer,
} from './styles';

function ExploreGrouper(props) {
    const {events} = props;


    useEffect(() => {
        const container = document.getElementById(`explore-grouper`);
        const next = document.getElementById(`explore-grouper-explore-next`);
        const previous = document.getElementById(`explore-grouper-explore-previous`);
        previous.style.display = 'none';

        const initialMaxScrollLength = container.scrollWidth - container.clientWidth;
        if (container.scrollLeft >= initialMaxScrollLength) {
            next.style.display = 'none';
        }
        
        next.onclick = function () {
            const maxScrollLeft = container.scrollWidth - container.clientWidth;
            let scrollAmount = 0;
            const slideTimer = setInterval(function () {
                previous.style.display = 'inherit';
            if (container.scrollLeft > 0) previous.style.display = 'inherit'; 
                container.scrollLeft += 77;
                scrollAmount += 77;
                if (scrollAmount > 1054) {
                    window.clearInterval(slideTimer);
                }
                if (Math.ceil(container.scrollLeft) >= maxScrollLeft) {
                    next.style.display = 'none';
                }
                if (container.scrollLeft === 0) previous.style.display = 'none';
            }, 20);
            
        };
        previous.onclick = function () {
            let scrollAmount = 0;
            const maxScrollLeft = container.scrollWidth - container.clientWidth;
            next.style.display = 'inherit';
            
            const slideTimer = setInterval(function () {
                container.scrollLeft -= 77;
                scrollAmount += 77;
                if (scrollAmount > 1054) {
                    window.clearInterval(slideTimer);
                }
                if (Math.ceil(container.scrollLeft) >= maxScrollLeft) {
                    next.style.display = 'none';
                }
                if (container.scrollLeft === 0) previous.style.display = 'none';
            }, 20);

        };
        
    }, []);


    return (
        <MainContainer id='explore-grouper'>
            <HeaderContainer>
                <PaginateControls>
                    <ChangePage id={`explore-grouper-explore-previous`} className="left-arrow"> <img src={LeftArrow}
                                                                                                     alt=""
                                                                                                     className='arrow-color'/>
                    </ChangePage>
                    <ChangePage id={`explore-grouper-explore-next`} className="right-arrow"> <img src={RightArrow}
                                                                                                  alt=""
                                                                                                  className='arrow-color'/>
                    </ChangePage>
                </PaginateControls>
                <ExplorerContainer>{events}</ExplorerContainer>
            </HeaderContainer>
        </MainContainer>
    );
}

ExploreGrouper.propTypes = {
    events: PropTypes.node,
    title: PropTypes.string,
};

ExploreGrouper.defaultProps = {
    events: <></>,
    title: '',
};

export default memo(ExploreGrouper);
