
export const data = {
  "items": [{
    "id": 1,
    "description": "La tradicional ala de pollo elaborada se compone de dos ingredientes: una salsa picante (a base de vinagre y cayena) y margarina o mantequilla.",
    "name": "Mandados el blady",
    "type": "Mandados",
    "image": "https://scontent.fmzt1-1.fna.fbcdn.net/v/t1.0-9/s960x960/120194562_1050604988745443_7012524459457269815_o.jpg?_nc_cat=106&ccb=2&_nc_sid=825194&_nc_ohc=X_Zg8QWbRMEAX_dm6kB&_nc_ht=scontent.fmzt1-1.fna&tp=7&oh=a068846e705e170340356019ff7a25c5&oe=5FCDC372",
    "categoryId": 1,
    "price": 95.0,
    "status": 1,
    "created_at": "2020-08-16T13:53:24.000+0000",
    "updated_at": "2020-08-16T13:53:24.000+0000"
  },
    {
      "id": 2,
      "description": "La definición de deshuesada en el diccionario es que se han extraído los huesos o que no contienen huesos.",
      "name": "Papas Lucas",
      "type": "Comida",
      "image": "https://scontent.fmzt1-1.fna.fbcdn.net/v/t1.0-9/18447588_1357692580987833_8874520699052046307_n.jpg?_nc_cat=101&ccb=2&_nc_sid=e3f864&_nc_ohc=XmokgWitJI8AX-sdN3S&_nc_ht=scontent.fmzt1-1.fna&oh=7c6d7d0cdc94890410f630bfc5a95cb6&oe=5FCB4B13",
      "categoryId": 1,
      "price": 90.0,
      "offer": 40.0,
      "status": 1,
      "created_at": "2020-08-16T13:53:24.000+0000",
      "updated_at": "2020-08-16T13:53:24.000+0000"
    },
    {
      "id": 3,
      "description": "La tradicional ala de pollo elaborada se compone de dos ingredientes: una salsa picante (a base de vinagre y cayena) y margarina o mantequilla.",
      "name": "Saine Rose",
      "type": "Belleza",
      "image": "https://scontent.fmzt1-1.fna.fbcdn.net/v/t1.0-9/115851839_167487528154780_1841161200475818550_o.jpg?_nc_cat=106&ccb=2&_nc_sid=e3f864&_nc_ohc=r1EYEzoFtvoAX-NE0W_&_nc_ht=scontent.fmzt1-1.fna&oh=4532071a9e55db811c6373025e825d6d&oe=5FCD5761",
      "categoryId": 1,
      "price": 95.0,
      "status": 1,
      "created_at": "2020-08-16T13:53:24.000+0000",
      "updated_at": "2020-08-16T13:53:24.000+0000"
    },
    {
      "id": 4,
      "description": "La definición de deshuesada en el diccionario es que se han extraído los huesos o que no contienen huesos.",
      "name": "Kame Sushi",
      "type": "Comida",
      "image": "https://scontent.fmzt1-1.fna.fbcdn.net/v/t1.0-9/118131468_584632095560837_1685189733997953040_o.jpg?_nc_cat=102&ccb=2&_nc_sid=e3f864&_nc_ohc=VNUunCp8WIMAX9zeSey&_nc_ht=scontent.fmzt1-1.fna&oh=93103c6867a97459b8087813fe820a3f&oe=5FCCA2E3",
      "categoryId": 1,
      "price": 90.0,
      "status": 1,
      "created_at": "2020-08-16T13:53:24.000+0000",
      "updated_at": "2020-08-16T13:53:24.000+0000"
    }
  ]
};
