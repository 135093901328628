import styled from 'styled-components';
import { mediaQuery1320, mediaQuery } from '../../utils';

export const FormContainer = styled.div`
   display: flex;
   position: relative;
   flex-direction: column;
   margin-top: 21px;
   width: 100%;
   ${mediaQuery} {
      margin-top: 14px;
    }
`

export const Form = styled.div`
  margin: 0 10% 0 0;
  height: max-content;

  ${mediaQuery} {
    margin: 0 0px;
  }
  
  & #advanced_search_birthday {
    margin: 12px 0 4px 0!important;
  }
  
  & .ant-form-item-explain .ant-form-item-explain-error:not(:first-child) {
  display: none;
  }


  & .ant-form-item-explain, .ant-form-item-extra {
    max-width: 392px;
    margin-bottom: 10px;
    ${mediaQuery} {
      max-width: 100%;
    }
  }
  
  .input {
    padding-top: 15px!important;
    height: 52px!important;
    width: 100%;
    max-width: 432px;
    border: 1px solid #EDEDF3;
    border-radius: 8px;
    box-shadow: none;
    ${mediaQuery} {
      padding-top: 20px!important;
      padding-bottom: 10px;
      max-width: 100%;
    }
  }

  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
  }

  .paddingLeft {
    padding-left: 10px;
    box-shadow: none;
  }

  .date-register{
    padding: 10px;
  }
  
`;

export const ButtonContainer = styled.div`
  margin: 9px 0 32px 0;
  width: 100%;
  padding: 0 10% 0 0;

  ${mediaQuery} {
    width: 100%;
    margin: 8px 0 36px 0;
    padding: 0 0px;
  }

  .button-register {
    background-color: #7B00B2;
    border: none;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.8px;
    line-height: 22px;
    text-align: center;
    width: 100%;
    max-width: 432px;
    height: 48px;
    border-radius: 8px;

    ${mediaQuery} {
      max-width: 100%;
    }
  }
`;

export const DateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  
  & .ant-row {
    max-width: 100%;
    min-width: 100%;
  }
  
  ${mediaQuery} {
    max-width: 100%;
  }
`;
