import React, {memo, useContext} from 'react';
import {MainContainer, Title, DescriptionDesktop, DescriptionResponsive, ChildrenContainer} from './styles';
import {Drawer, message} from 'antd';
import ModalFullscreen from '../ModalFullscreen';
import {CloseIconGray, OfflineIcon} from '../../Assets';
import Button from '../Button';

const  ExpiredTimeModal= () => {
    
    const checkConnection = () => {
        if (window.navigator.onLine) {
            window.location.reload();
        } else {
            message.info('Favor de intentar de nuevo.');
        }
    }

    return (
        <Drawer
            placement={"bottom"}
            closable={false}
            visible={true}
            key={"bottom"}
            width={"100%"}
            height={"100%"}
        >
            <ChildrenContainer>
                <img src={CloseIconGray} alt="" className="close-icon" onClick={checkConnection}/>
                <MainContainer>
                    <img src={OfflineIcon} alt="" className="phone-icon"/>
                    <Title>Sin acceso a internet</Title>
                    <DescriptionDesktop>Revisa tu conexión y vuelve a intentarlo.</DescriptionDesktop>
                    <DescriptionResponsive>Revisa tu conexión y vuelve a intentarlo.</DescriptionResponsive>
                    <Button
                        text="Reintentar"
                        className="button-back"
                        onClick={checkConnection}
                    />
                </MainContainer>
            </ChildrenContainer>
            </Drawer>
    )
};


ExpiredTimeModal.propTypes = {
};

ExpiredTimeModal.defaultProps = {
};

export default ExpiredTimeModal;