import React, {memo, useContext} from 'react';
import {compose} from 'redux';
import {ResponsiveContext} from "../../contexts/responsive-context";
import { useHistory } from "react-router-dom";
import NotFoundImage from './404logo.svg';
import NotFoundImage2 from './404logo2.svg';
import Button from '../../components/Button';
import {MainContainer, Title, Description} from './styles';

export function NotFound() {

  // TODO 2020-11-07 add how var color empty state
  const isResponsive = useContext(ResponsiveContext);

  let history = useHistory();

  const redirectToHome = () => {
    history.push("/");
  }

  

return (
      <MainContainer>
          <img className="img404" src={NotFoundImage} />
          <img className="img4042" src={NotFoundImage2} />
          <Title>No hay nada por aquí</Title>
            <Description>Lo sentimos, algo salió mal.</Description>

            <Button
                    text="Ir a inicio"
                    className="button-back"
                    onClick={redirectToHome}
                />
      </MainContainer>
)
}
NotFound.propTypes = {
};

export default compose(memo)(NotFound);
