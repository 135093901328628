import React from 'react';
import axios from 'axios';
import {get} from 'lodash';
import {GlobalContext} from '../contexts/global-context';
import {message} from 'antd';
import history from '../utils/history';

// const AUTH_TOKEN = 'auth_token';

const service = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT_URL,
    timeout: 60000
})

// Config
const ENTRY_ROUTE = '/login';
const TOKEN_PAYLOAD_KEY = 'Authorization';
const PUBLIC_REQUEST_KEY = 'public-request';
// API Request interceptor
const user = JSON.parse(localStorage.getItem('d'));

service.interceptors.request.use(config => {
    const user = JSON.parse(localStorage.getItem('d'));
    const loader = document.getElementById('loader');
    const urlParams = new URLSearchParams(config.url);
    var noloader = urlParams.get('noloader');
    if (loader && !localStorage.getItem('pwa') && !noloader) {
        loader.style.display = 'flex';
        loader.style.opacity = "0.8";
    }
    const jwtToken = user && user.access_token

    let timeZone = String(Intl.DateTimeFormat().resolvedOptions().timeZone);
    timeZone = timeZone.replace('%2F', '/');

    if (jwtToken && jwtToken !=='') {
        config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${jwtToken}`
    }

    config.params = {...config.params, timezone: timeZone};

    return config
}, error => {
    console.log("Error en el interceptor request")
    return Promise.reject(error)
})

// API respone interceptor
service.interceptors.response.use((response) => {

    const loader = document.getElementById('loader');
    if (loader && !localStorage.getItem('pwa')) {
        loader.style.display = 'none';
    }

    return response.data
}, (error) => {
    console.log("Error en el interceptor response")
    console.log(error.config);
    console.log(error);

    const originalRequest = error.config;
    // const REFRESH_TOKEN = localStorage.getItem('refresh_token');
    const loader = document.getElementById('loader');
    if (loader && !localStorage.getItem('pwa')) {
        loader.style.display = 'none';
    }
    console.log('Estatus code', error.response);
    let errorStatus = get(error, 'response.status', 500);
    // Remove token and redirect
    if (errorStatus === 403) {
        user.access_token = null

        localStorage.setItem('d', JSON.stringify(user));
        //window.location.reload();
    }

    if (errorStatus === 404) {
        console.log('Not Found');
    }

    if (errorStatus === 500) {
        console.log('Internal Server Error');
    }

    if (errorStatus === 508) {
        console.log('Time Out');
    }

    /* if (errorStatus === 401 && originalRequest.url != "/api/v1/auth/login") {
         axios.post(`${API_BASE_URL}/api/v1/auth/refresh`,{},{
             headers: {
                 'Authorization': `Bearer ${REFRESH_TOKEN}`,
             }
         })
         .then( res => {
             localStorage.setItem(AUTH_TOKEN, res.data.access_token);
             service(originalRequest);
         })
     }*/

    if (errorStatus === 401) {
        localStorage.removeItem('d');

        if (originalRequest.url !== "/api/v1/auth/login") {
            message.error({
                content: 'La sesión caduco entre de nuevo a la plataforma para seguir con la compra',
                duration: 6
            });
            history.push(ENTRY_ROUTE);
            window.location.reload();
        }
        return;
    }

    // if (!error.response || !error.response.status || !window.navigator.onLine) {
    //if (!window.navigator.onLine) {
    //    error = {offline: true};
    //    console.log('Network Error');
    // }
    console.log("errrro", error)

    return Promise.reject(error);
});

export function getToken() {
    const user = JSON.parse(localStorage.getItem('d'));

    const token = user && user.access_token;

    if (token) {
        return token;
    }
    return '';
}

export function getGeneric(data) {
    return new Promise((resolve, reject) => {
        service({
            method: 'get',
            url: data.url,
            headers: data.headers,
        })
            .then(response => {
                resolve({data: response})
            })
            .catch(err => {
                reject(err);
            });
    });
}

export function postGeneric(data) {
    return new Promise((resolve, reject) => {
        service({
            method: 'post',
            url: data.url,
            headers: data.headers,
            data: data.data
        })
            .then(response => resolve({data: response}))
            .catch(err => {
                reject(err);
            });
    });
}

export default service
