import React, {memo, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {
    MainContainer,
} from './styles';
import {useHistory} from 'react-router-dom';
import https from '../../config/https';
import {get} from 'lodash';
import {CloseIconGray, GoogleCalendarIcon} from '../../Assets';
import {
    ButtonsContainer,
    PDFTicketBody,
    PDFTicketsContainer,
    TicketBody,
    TicketsContainer,
    Title
} from './styles';
import Ticket from '../Ticket';
import {Button, message} from 'antd';
import Axios from 'axios';

const PaymentModalStep4 = (props) => {
    const {idBasket} = props;
    const [showButtons] = useState(false);
    const [tickets, setTickets] = useState([]);
    const [disableDownload, setDisableDownload] = useState(false);

    const pdfTickets = useRef();
    const history = useHistory();

    useEffect(() => {
        fetchTickets();
    }, []);

    const fetchTickets = async () => {
        https.get(`/api/v1/sale/${idBasket}`)
            .then(res => {
                let tempTickets = [];
                get(res, 'data', []).map(ticket => {
                    tempTickets.push({
                        img: get(ticket, 'cover_image', ''),
                        name: `${get(ticket, 'first_name', '')} ${get(ticket, 'last_name', '')}`,
                        event_name: get(ticket, 'title', ''),
                        date: get(ticket, 'date', ''),
                        hour: get(ticket, 'time', '0:00'),
                        place: get(ticket, 'location', ''),
                        type: get(ticket, 'type_ticket', ''),
                        qr: get(ticket, 'qr', ''),
                        cover_image_base64: get(ticket, 'cover_image_base64', ''),
                    })
                })
                setTickets(tempTickets);
            })
            .catch(
                //ERR
            )
            .finally(() => {
                sessionStorage.removeItem('paymentDetails');
            })
    }

    const onClose = () => {
        history.push('/');
    }

   /* const printPDF = () => {
        setDisableDownload(true);
        const domElement = pdfTickets.current;
        html2canvas(domElement, {
            scrollY: 0,
            scrollX: 0,
            margin: 0,
            allowTaint: true,
            removeContainer: true,
            backgroundColor: null,
            scale: 2,
            useCORS: true,
        }).then(canvas => {
            window.scrollTo(0, 0);
            const contentDataURL = canvas.toDataURL("image/png");

            let position = 5;
            let imgWidth = 585;
            let pageHeight = 800;
            const doc = new jsPDF('p', 'mm', [imgWidth, pageHeight]);
            let imgHeight = (canvas.height * imgWidth) / canvas.width;
            const imgProps = doc.getImageProperties(contentDataURL);
            const pdfWidth = doc.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            let heightLeft = imgHeight;

            doc.addImage(contentDataURL, "PNG", 0, position, pdfWidth, pdfHeight, 'page', 'SLOW');
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                doc.addPage();
                doc.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight, 'page', 'SLOW');
                heightLeft -= pageHeight;
            }

            const pageCount = doc.internal.getNumberOfPages();
            doc.deletePage(pageCount);

            // window.open(doc.output("bloburl"), "_blank");
            doc.save(`${get(tickets[0], 'event_name', '')} tickets.pdf`);
        })
            .finally(() => {
                setDisableDownload(false);
                }
            );
    }; */

    const getTicketsPdf = async () => {
        Axios.get(`${process.env.REACT_APP_API_ENDPOINT_URL}/api/v1/sale/${idBasket}/pdf`)
            .then(res => {
                if (res) {
                    window.open(`${process.env.REACT_APP_API_ENDPOINT_URL}/api/v1/sale/${idBasket}/pdf`);
                } else {
                    throw Error();
                }
            })
            .catch( () => {
                message.error({
                    content: 'Ha ocurrido un error al obtener los tickets, favor de reportarlo con el administrador.',
                    duration: 10
                });
            }
        )
    }

    return (
        <MainContainer>
            <PDFTicketsContainer ref={pdfTickets}>
                {tickets.map((ticket, index) => (
                    <PDFTicketBody isLast={index === tickets.length}>
                        <Ticket data={ticket} textInsideImage={`Entrada ${index + 1} de ${tickets.length}`} insidePdf/>
                    </PDFTicketBody>
                ))}
            </PDFTicketsContainer>
            <Title>Mis entradas</Title>
            <TicketsContainer>
                {tickets.map((ticket, index) => (
                    <TicketBody>
                        <Ticket data={ticket} textInsideImage={`Entrada ${index + 1} de ${tickets.length}`}/>
                    </TicketBody>
                ))}
            </TicketsContainer>
            <ButtonsContainer>
                <Button className="button-download" onClick={() => getTicketsPdf()} disabled={disableDownload}>
                    Descargar entradas
                </Button>
                {showButtons && (
                    <Button className="button-calendar">
                        <img src={GoogleCalendarIcon} alt=""/>
                        Añadir a Calendario
                    </Button>
                )}
            </ButtonsContainer>
        </MainContainer>
    )
};


PaymentModalStep4.propTypes = {
    idBasket: PropTypes.string,
};

PaymentModalStep4.defaultProps = {
    idBasket: '',
};

export default memo(PaymentModalStep4);