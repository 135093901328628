import React, {memo, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {get} from 'lodash';
import {Sticky} from 'react-sticky';
import {useMatomo} from '@datapunt/matomo-tracker-react';

import {
    UbicationIcon,
    ClockIcon,
    CalendarIcon,
} from '../../Assets';
import Button from '../Button';

import {
    MainContainer,
    LeftContainer,
    RightContainerSticky,
    RightContainerResponsive,
    IconTextContainer,
    ScheduleContainer,
    CategoriesContainer,
    CategoriesAndShareContainer,
    FixedBottomContainerResponsive,
    RightContainerPaddingFix,
    EventName,
    Text,
    Price,
    PriceResponsive,
    Category,
    ScheduleItem,
    CategoryItem,
} from './styles';
import Share from '../Share';
import {useHistory} from 'react-router-dom';
import {Carousel as PhotoCarousel} from 'react-responsive-carousel';
import PriceWithDiscount from "../PriceWithDiscount";

function EventDetail(props) {
    const {data, onClickButton, url, openModalOnLoad} = props;

    const [openSharePopup, setOpenSharePopup] = useState(false);

    const {images, isFree} = data;
    const history = useHistory();
    const {trackEvent} = useMatomo();
    useEffect(() => {
        if (openModalOnLoad) {
            // setTimeout(()=>{onClickButton()}, 2000); TODO:FUNCIONA PERO NO ES LO CORRECTO.
        }
    }, [openModalOnLoad]);

    const goToEventByCategory = id => {
        trackEvent({
            category: 'Categoria',
            action: 'click',
            name: `Id ${id}`,
            customDimensions: [
                {
                    id: "1",
                    value: "test",
                },
            ], // optional
        })
        history.push({
            pathname: '/categoria',
            search: `?id=${id}`
        });
    }

    const longPrice = get(data, 'price', '').length > 10;
    const showSellButton = get(data, 'sell_tickets', true);
    return (
        <MainContainer onClick={() => setOpenSharePopup(false)}>
            <LeftContainer>
                <div className='carousel-eventImage'>
                    <PhotoCarousel showArrows={true} showStatus={false}>
                        {images && images.map((item, index) => <img src={item} key={index} alt="Imagen de Evento"
                                                                    className="carousel-image"/>)}
                    </PhotoCarousel>
                </div>
                <CategoriesAndShareContainer>
                    <CategoriesContainer>
                        {get(data, 'categories', []).map((category, index) => {
                                return (
                                    <CategoryItem key={index} onClick={() => goToEventByCategory(category.id)}>
                                        <Category>
                                            <img src={category.icon} alt=""/>
                                            <div>{category.title}</div>
                                        </Category>
                                    </CategoryItem>
                                )
                            }
                        )}
                    </CategoriesContainer>
                    <div>
                        <Share
                            url={url}
                            onClickButton={() => setOpenSharePopup(!openSharePopup)}
                            isOpen={openSharePopup}
                            onlyWhatsapp
                        />
                    </div>
                </CategoriesAndShareContainer>
            </LeftContainer>
            <RightContainerResponsive>
                <RightContainerPaddingFix>
                    <EventName>{get(data, 'name', '')}</EventName>
                    <IconTextContainer>
                        <img src={UbicationIcon} alt=""/>
                        <Text>{get(data, 'place', '')}</Text>
                    </IconTextContainer>
                    {get(data, 'periodicity', '') !== '' && (
                        <IconTextContainer>
                            <img src={CalendarIcon} alt=""/>
                            <Text>{get(data, 'periodicity', '')}</Text>
                        </IconTextContainer>
                    )}
                    <IconTextContainer className="schedule">
                        <div>
                            <img src={ClockIcon} alt=""/>
                        </div>
                        <ScheduleContainer>
                            {get(data, 'schedule', []).map(hour => (
                                <ScheduleItem key={hour}>{hour}</ScheduleItem>
                            ))}
                        </ScheduleContainer>
                    </IconTextContainer>
                    <EventName>{get(data, 'subtitle', '')}</EventName>
                    <Price>{isFree ? 'Gratis' : get(data, 'price', '')}</Price>
                    {
                        showSellButton &&
                        <>
                            <Button
                                text="Adquiere entradas"
                                className="button-entradas"
                                onClick={onClickButton}
                            />   </>
                    }
                    <FixedBottomContainerResponsive>
                        <PriceResponsive
                            longPrice={longPrice}>
                            {get(data, 'price_discounted', '') !== '' ? (
                                <PriceWithDiscount discountPrice={data.price_discounted} normalPrice={data.price}
                                                   showPriceSign={false}/>
                            ) : (
                                <div>
                                    {isFree ? 'Gratis' : get(data, 'price', '')}
                                </div>
                            )}
                        </PriceResponsive>
                        {
                            showSellButton &&
                            <>   <Button
                                text="Adquiere entradas"
                                className="button-entradas-responsive"
                                onClick={onClickButton}
                            />
                            </>
                        }
                    </FixedBottomContainerResponsive>
                </RightContainerPaddingFix>
            </RightContainerResponsive>
            <Sticky>{({style}) =>
                <RightContainerSticky style={style}>
                    <EventName>{get(data, 'name', '')}</EventName>
                    <IconTextContainer>
                        <img src={UbicationIcon} alt=""/>
                        <Text>{get(data, 'place', '')}</Text>
                    </IconTextContainer>
                    {get(data, 'periodicity', '') !== '' && (
                        <IconTextContainer>
                            <img src={CalendarIcon} alt=""/>
                            <Text>{get(data, 'periodicity', '')}</Text>
                        </IconTextContainer>
                    )}
                    <IconTextContainer className="schedule">
                        <div>
                            <img src={ClockIcon} alt=""/>
                        </div>
                        <ScheduleContainer>
                            {get(data, 'schedule', []).map(hour => (
                                <ScheduleItem key={hour}>{hour}</ScheduleItem>
                            ))}
                        </ScheduleContainer>
                    </IconTextContainer>
                    <Price>
                        {get(data, 'price_discounted', '') !== '' ? (
                            <PriceWithDiscount discountPrice={data.price_discounted} normalPrice={data.price}
                                               showPriceSign={false} bigText />
                        ) : (
                            <div>
                                {isFree ? 'Gratis' : get(data, 'price', '')}
                            </div>
                        )}
                    </Price>
                    {
                        showSellButton &&
                        <Button
                            text="Adquiere entradas"
                            className="button-entradas"
                            onClick={onClickButton}
                        />

                    }
                </RightContainerSticky>
            }</Sticky>

        </MainContainer>
    );
}

EventDetail.propTypes =
    {
        data: PropTypes.object,
        onClickButton: PropTypes.func,
        url: PropTypes.string,
    }
;

EventDetail.defaultProps =
    {
        data: {},
        onClickButton: () => {
        },
        url: ''
    }
;

export default memo(EventDetail);
