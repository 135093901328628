import styled from 'styled-components';
import { mediaQuery } from '../../utils';

export const BodyAboutResponsive = styled.div`
  display: none;
   ${mediaQuery}{
      display: block;
      max-width: 100%;
      padding: 0px 11px 32px 0;
   }
`;
