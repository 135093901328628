import {getToken, getGeneric} from '../config/https';

export async function getHome() {
    const token = getToken();
    return getGeneric({
        url: `${process.env.REACT_APP_API_ENDPOINT_URL}/api/v1/event/home`,
        headers: {Authorization: `Bearer ${token}`},
    });
}

export async function getDiscounts() {
    const token = getToken();
    return getGeneric({
        url: `${process.env.REACT_APP_API_ENDPOINT_URL}/api/v1/event/home/discounts`,
        headers: {Authorization: `Bearer ${token}`},
    });
}

export async function getWeekend() {
    const token = getToken();
    return getGeneric({
        url: `${process.env.REACT_APP_API_ENDPOINT_URL}/api/v1/event/home/weekend`,
        headers: {Authorization: `Bearer ${token}`},
    });
}

export async function getFree() {
    const token = getToken();
    return getGeneric({
        url: `${process.env.REACT_APP_API_ENDPOINT_URL}/api/v1/event/home/free`,
        headers: {Authorization: `Bearer ${token}`},
    });
}

export async function getHighlighted() {
    const token = getToken();
    return getGeneric({
        url: `${process.env.REACT_APP_API_ENDPOINT_URL}/api/v1/event/home/highlighted`,
        headers: {Authorization: `Bearer ${token}`},
    });
}

export async function getOnline() {
    const token = getToken();
    return getGeneric({
        url: `${process.env.REACT_APP_API_ENDPOINT_URL}/api/v1/event/home/online`,
        headers: {Authorization: `Bearer ${token}`},
    });
}


export async function getBanners() {
    const token = getToken();
    return getGeneric({
        url: `${process.env.REACT_APP_API_ENDPOINT_URL}/api/v1/banner`,
        headers: {Authorization: `Bearer ${token}`},
    });
}

export async function getRecommendations() {
    const token = getToken();
    return getGeneric({
        url: `${process.env.REACT_APP_API_ENDPOINT_URL}/api/v1/recommendation`,
        headers: {Authorization: `Bearer ${token}`},
    });
}

export async function getCategories() {
    const token = getToken();
    return getGeneric({
        url: `${process.env.REACT_APP_API_ENDPOINT_URL}/api/v1/category`,
        headers: {Authorization: `Bearer ${token}`},
    });
}

export async function getRelated(id) {
    const token = getToken();
    return getGeneric({
        url: `${process.env.REACT_APP_API_ENDPOINT_URL}/api/v1/event/home/related/${id}`,
        headers: {Authorization: `Bearer ${token}`},
    });
}

export async function getRecommendationsById(id, page, itemsPerPage) {
    const token = getToken();
    return getGeneric({
        url: `${process.env.REACT_APP_API_ENDPOINT_URL}/api/v1/event/home/recommendation/${id}?page=${page}&per_page=${itemsPerPage}`,
        headers: {Authorization: `Bearer ${token}`},
    });
}

export async function getPaginatedHighlightsEvents(page, itemsPerPage) {
    const token = getToken();
    return await getGeneric({
        url: `${process.env.REACT_APP_API_ENDPOINT_URL}/api/v1/event/home/highlighted?page=${page}&per_page=${itemsPerPage}`,
        headers: {Authorization: `Bearer ${token}`},
    });
}

export async function getPaginatedDiscountsEvents(page, itemsPerPage) {
    const token = getToken();
    return await getGeneric({
        url: `${process.env.REACT_APP_API_ENDPOINT_URL}/api/v1/event/home/discounts?page=${page}&per_page=${itemsPerPage}`,
        headers: {Authorization: `Bearer ${token}`},
    });
}

export async function getPaginatedWeekendEvents(page, itemsPerPage) {
    const token = getToken();
    return await getGeneric({
        url: `${process.env.REACT_APP_API_ENDPOINT_URL}/api/v1/event/home/weekend?page=${page}&per_page=${itemsPerPage}`,
        headers: {Authorization: `Bearer ${token}`},
    });
}

export async function getPaginatedFreeEvents(page, itemsPerPage) {
    const token = getToken();
    return await getGeneric({
        url: `${process.env.REACT_APP_API_ENDPOINT_URL}/api/v1/event/home/free?page=${page}&per_page=${itemsPerPage}`,
        headers: {Authorization: `Bearer ${token}`},
    });
}

export async function getPaginatedOnlineEvents(page, itemsPerPage) {
    const token = getToken();
    return await getGeneric({
        url: `${process.env.REACT_APP_API_ENDPOINT_URL}/api/v1/event/home/online?page=${page}&per_page=${itemsPerPage}`,
        headers: {Authorization: `Bearer ${token}`},
    });
}
export async function getPaginatedRecommendations(page, itemsPerPage) {
    const token = getToken();
    return await getGeneric({
        url: `${process.env.REACT_APP_API_ENDPOINT_URL}/api/v1/recommendation?page=${page}&per_page=${itemsPerPage}`,
        headers: {Authorization: `Bearer ${token}`},
    });
}


export async function getCategoryProperties(id) {
    const token = getToken();
    return await getGeneric({
        url: `${process.env.REACT_APP_API_ENDPOINT_URL}/api/v1/category/${id}`,
        headers: {Authorization: `Bearer ${token}`},
    });
}

export async function getEventsByCategoryId(id, page, itemsPerPage) {
    const token = getToken();
    return await getGeneric({
        url: `${process.env.REACT_APP_API_ENDPOINT_URL}/api/v1/event/home/category/${id}?page=${page}&per_page=${itemsPerPage}`,
        headers: {Authorization: `Bearer ${token}`},
    });
}

export async function getRecommendationPropertiesById(id) {
    const token = getToken();
    return await getGeneric({
        url: `${process.env.REACT_APP_API_ENDPOINT_URL}/api/v1/recommendation/${id}`,
        headers: {Authorization: `Bearer ${token}`},
    });
}

