import styled from 'styled-components';
import {mediaQuery} from '../../utils';

export const MainContainer = styled.div`
    display: flex;
    justify-content: center;

  ${mediaQuery} {
    display: inline;
  }
`;

export const DiscountText = styled.div`
  font-size: ${props => props.fontSize};
  line-height: 32px;
  color: #1F1F1F;
  letter-spacing: 0.18px;
  margin-left: 8px;
  
  ${mediaQuery} {
    font-size: 20px;
    line-height: 28px;
    margin-left: 0;
  }
`;

export const NoDiscountPrice = styled.div`
  color: #999;
  font-size: ${props => props.fontSize};
  letter-spacing: 0.14px;
  line-height: 32px;
`;

export const NormalText = styled.div`
  font-size: ${props => props.fontSize};
  font-weight: 100;
  text-decoration-line: line-through;
  color: #666666;
  line-height: 32px;

  ${mediaQuery} {
    font-size: 14px;
    line-height: 22px;
  }
`;




