import React, {memo} from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import { useHistory } from "react-router-dom";

import {
    MainContainer,
    UnderlineWord,
    Title,
} from './styles';

function EmptyFood(props) {
    const {lastWord, title, image} = props;

    const history = useHistory();

    return (
        <MainContainer>
            <img src={image} alt=""/>
            <Title>{title}<UnderlineWord>{lastWord}</UnderlineWord></Title>
            <Button
                text="Explorar eventos"
                style={{ padding: '15px 36px 19px 36px'}}
                onClick={() => history.push('/')}
            />
        </MainContainer>
    );
}

EmptyFood.propTypes = {
    lastWord: PropTypes.string,
    title: PropTypes.string,
    image: PropTypes.object,
};

EmptyFood.defaultProps = {
    lastWord: '',
    title: '',
    image: {},
};

export default memo(EmptyFood);
