import styled from 'styled-components';
import {mediaQuery, mediaQuery1150} from '../../utils';

export const MainContainer = styled.div`

  width: 100%;
  height: 800px;
  position: relative;
  display: flex;
  justify-content: flex-center;
  align-items: center;
  
  padding: 1.77% 23.26% 2.77% 3.56%;
  background-color: #40006E;

  ${mediaQuery}{
    height: 576px;
    padding: 1.27% 8.46% 1.27% 8.56%;
  }
 
`;

export const Title = styled.div`
  color: #D66CFF;
  font-family: Gotham;
  font-size: 80px;
  font-weight: bold;
  letter-spacing: 0.6px;
  line-height: 112px;
  text-align: left;
  ${mediaQuery1150}{
    font-size: 6rem;
  }
  ${mediaQuery} {
    font-size: 48px;
    font-weight: bold;
    letter-spacing: 0.36px;
    line-height: 58px;
    margin-bottom: 30px;
  }
`;



