import React, { memo, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import CategoryTitle from '../../components/CategoryTitle';
import EventCard from '../../components/EventCard';
import Button from '../../components/Button';
import {
    getPaginatedHighlightsEvents,
    getCategoryProperties,
    getPaginatedDiscountsEvents,
    getPaginatedWeekendEvents,
    getPaginatedFreeEvents,
    getPaginatedOnlineEvents,
    getRecommendationsById,
    getEventsByCategoryId,
    getPaginatedRecommendations,
    getRecommendationPropertiesById
} from '../../services/events';
import { get } from 'lodash';
import {MainContainer, Container, LoadMoreContainer} from './styles'
import { useHistory } from "react-router-dom";
import { HOME_REDIRECT } from "../../constants";
import GlobalContext from '../../contexts/global-context';
import { Helmet } from "react-helmet";

function OutstandingEvents(props) {
    const { title } = props;
    const history = useHistory();
    const [currentPage, setCurrentPage] = useState(1);
    const [categoryCurrentTitle, setCategryCurrentTitle] = useState('');
    const [customTitle, setCustomTitle] = useState('');
    const [isRecommendation, setIsRecomendation] = useState(false);

    const [remainingItems, setRemainingItems] = useState(0);
    const [events, setEvents] = useState([]);

    const ITEMS_PER_PAGE = 12;

    useEffect(() => {
        let query = window.location.search;
        query = query.substr(1, query.length);
        const params = query.split('&');

        if (params.length > 0) {
            const paramsObject = {};
            params.forEach(param => {
                const objectArray = param.split('=');
                paramsObject[objectArray[0]] = objectArray[1]
            });
            if (paramsObject.id) {
                fetchEvents(get(paramsObject, 'id', ''))
            } else {
                fetchEvents('', 1);
            }
        } else {
            fetchEvents('', 1);
        }
    }, []);


    const setPageResponseData = (serviceResponse, isRecommendationParam) => {
        const remaining = get(serviceResponse, 'data.remaining', [])
        const newEvents = isRecommendation || isRecommendationParam ? get(serviceResponse, 'data.data', []) : get(serviceResponse, 'data.cardInfo', [])
        const nextPage = get(serviceResponse, 'data.next_page', [])
        const allEvents = events.concat(newEvents);
        setCurrentPage(nextPage);
        setRemainingItems(remaining);
        allEvents.forEach(event => {
            event.url = isRecommendation || isRecommendationParam ? `/recomendaciones?recommendationId=${event.id}` : `/detail/${event.id}`;
            if (isRecommendation || isRecommendationParam) {
                event.image = event.icon_url;
                event.customCardStyle = { height: 448, width: 300 };
                event.recommendationTitle = event.description

            }
        })
        setEvents(allEvents);
    }

    const fetchEvents = async (id) => {
        let query = window.location.search;
        query = query.substr(1, query.length);
        const params = query.split('&');
        const paramsObject = {};
        params.forEach(param => {
            const objectArray = param.split('=');
            paramsObject[objectArray[0]] = objectArray[1]
        });
        if (!id && !paramsObject.id) {
            switch (title) {
                case 'Eventos destacados':
                    getPaginatedHighlightsEvents(currentPage, ITEMS_PER_PAGE)
                        .then(res => {
                            setPageResponseData(res);
                        })
                        .catch(err => {
                            console.log(err);
                        })
                    break;
                case 'Esta semana':
                    getPaginatedWeekendEvents(currentPage, ITEMS_PER_PAGE)
                        .then(res => {
                            setPageResponseData(res);
                        })
                        .catch(err => {
                            console.log(err);
                        })
                    break;
                case 'En linea':
                    getPaginatedOnlineEvents(currentPage, ITEMS_PER_PAGE)
                        .then(res => {
                            setPageResponseData(res);
                        })
                        .catch(err => {
                            console.log(err);
                        })
                    break;
                case 'Entrada libre / Gratis':
                    getPaginatedFreeEvents(currentPage, ITEMS_PER_PAGE)
                        .then(res => {
                            setPageResponseData(res);
                        })
                        .catch(err => {
                            console.log(err);
                        })
                    break;

                case 'Ofertas':
                    getPaginatedDiscountsEvents(currentPage, ITEMS_PER_PAGE)
                        .then(res => {
                            setPageResponseData(res);
                        })
                        .catch(err => {
                            console.log(err);
                        })
                    break;
                default:
                    let query = window.location.search;
                    query = query.substr(1, query.length);
                    const params = query.split('&');
                    const paramsObject = {};
                    params.forEach(param => {
                        const objectArray = param.split('=');
                        paramsObject[objectArray[0]] = objectArray[1]
                    });

                    const currentRecommendationId = get(paramsObject, 'recommendationId', '');
                    if (currentRecommendationId) {
                        let recommendationDetails = await getRecommendationPropertiesById(currentRecommendationId)
                        setCustomTitle(get(recommendationDetails, 'data.description', ''));
                        getRecommendationsById(currentRecommendationId, currentPage, ITEMS_PER_PAGE)
                            .then(res => {
                                setPageResponseData(res);
                            })
                            .catch(err => {
                                console.log(err);
                            })
                    } else {
                        setIsRecomendation(true);
                        getPaginatedRecommendations(currentPage, ITEMS_PER_PAGE)
                            .then(res => {
                                setPageResponseData(res, true);
                            })
                            .catch(err => {
                                console.log(err);
                            })
                    }
                    break;
            }
        } else {
            const categoryId = id || paramsObject.id || 0;
            getCategoryProperties(categoryId)
                .then(res => {
                    setCategryCurrentTitle(res.data.title);
                })
                .catch(err => {
                    console.log(err);
                })
            getEventsByCategoryId(categoryId, currentPage, ITEMS_PER_PAGE)
                .then(res => {
                    setPageResponseData(res);
                })
                .catch(err => {
                    console.log(err);
                })
        }
    }

    const changeRoute = (item) => {
        history.push(get(item, 'url', HOME_REDIRECT));
        window.location.reload();
    }

    return (
        <>
            <MainContainer>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Eventos</title>
            </Helmet>
                <CategoryTitle
                    title={customTitle || categoryCurrentTitle || title}
                    // image={customBackgroundImage || backgroundImage || image || ExploreEventBackground}
                />
                <Container id={title} isRecommendation={title.includes('Nuestras recomendaciones')}>
                    {events.length !== 0 && events.map((item, index) => (
                        <div
                            key={index}
                            className="card"
                            onClick={() => changeRoute(item)}
                        >
                            <EventCard {...item} viewAll={true} />
                        </div>
                    ))}
                </Container>
                {remainingItems > 0 ? <LoadMoreContainer onClick={() => fetchEvents()}>
                    <Button
                        text="Ver más"
                        style={{ padding: '15px 38px 15px 38px' }}
                    />
                </LoadMoreContainer> : React.null}
            </MainContainer>
        </>
    );
}

OutstandingEvents.propTypes = {
    title: PropTypes.string,
    image: PropTypes.any,
};

OutstandingEvents.defaultProps = {
    title: '',
};

export default memo(OutstandingEvents);
