import React, {memo, useState, useEffect} from 'react';
import Menu from '../../components/Menu';
import {
    MainContainer,
    RightContainer,
    EmptyMainContainer,
    EmptyDescription,
    ViewMore,
} from './styles';
import EventDetailTable from '../../components/EventDetailTable';
import {CheckingPhone} from '../../Assets';
import Button from '../../components/Button';
import {useHistory} from 'react-router-dom';
import https from '../../config/https';
import {get} from 'lodash';
import {Helmet} from "react-helmet";

const UpcomingEvents = () => {
    const user = JSON.parse(localStorage.getItem('d'));
    const token = user && user.access_token;
    const [tableData, setTableData] = useState([]);
    const [page, setPage] = useState(1);
    const [remaining, setRemaining] = useState(0);
    const [showInfo, setShowInfo] = useState(false);

    const history = useHistory();

    useEffect(() => {
        fetchTableitems(page);
    }, []);

    const fetchTableitems = async (page) => {
        const params = {
            page,
            per_page: 10
        };
        https.get('/api/v1/sale/upcoming-events', params, {headers: {Authorization: `Bearer ${user.access_token}`}},)
            .then(res => {
                const elementsHelper = [...tableData];
                const cardInfo = get(res, 'cardInfo', []);
                setPage(get(res, 'next_page', 1));
                setRemaining(get(res, 'remaining', 0));
                setTableData(elementsHelper.concat(cardInfo));
            })
            .finally(() => {
                setShowInfo(true);
            })
    };

    const onClickButton = () => {
        history.push('/');
    };

    return (
        <MainContainer>
            <Helmet>
                <html lang="en" />
                <title>Siguientes Eventos</title>
                <meta name="theme-color" content="#E6E6FA" />
            </Helmet>
            <Menu className="menu" title='Mis entradas'/>
            <RightContainer>
                {tableData.length ? <EventDetailTable data={tableData} buttonText={'Ver entradas'}/> :
                    <EmptyMainContainer>
                        {showInfo &&
                            <div>
                                <img src={CheckingPhone} alt="" className="phone-icon"/>
                                <EmptyDescription>Aquí podrás ver las entradas de tu próxima</EmptyDescription>
                                <EmptyDescription> salida, ¡crea tu plan ahora!</EmptyDescription>
                                <Button
                                    text="Explorar"
                                    className="button-back"
                                    onClick={onClickButton}
                                />
                            </div>
                        }
                    </EmptyMainContainer>
                }
                { remaining > 0 ? <ViewMore onClick={()=> fetchTableitems(page)}>Ver más</ViewMore> : React.null }
            </RightContainer>
        </MainContainer>
    )
};


UpcomingEvents.propTypes = {};

UpcomingEvents.defaultProps = {};

export default memo(UpcomingEvents);