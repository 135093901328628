import React, {memo, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Popover, message} from 'antd';

import {
    ShareButton,
    SocialMediaItems,
    ShareContentContainer,
    MainContainer,
    Divider,
} from './styles';

import {ShareIcon, WhatsappIcon, FacebookIcon, TwitterIcon, LinkIcon} from '../../Assets';

function Share(props) {
    const {onlyIcon, urlText, url, isOpen, onClickButton, onlyWhatsapp} = props;

    const [shareObject, setShareObject] = useState({});
    const [visible, setVisible] = useState(isOpen);

    useEffect(() => {
        setVisible(isOpen);
    }, [isOpen]);

    useEffect(() => {
        //TODO: Agregar endpoint u otra forma de obtener a donde compartir.
        let socialMediaObject = {};
        if (onlyWhatsapp) {
            socialMediaObject = {
                toShareLinks: [{
                    socialMediaName: 'WhatsApp',
                    socialMediaIcon: <img src={WhatsappIcon} alt=""/>, //TODO: Cambiar a iconos.
                    socialMediaShareUrl: `https://api.whatsapp.com/send?text=${urlText}${url}`//TODO: Agregar texto deseado antes de %20.
                }]
            };
        } else {
            socialMediaObject = {
                toShareLinks: [{
                    socialMediaName: 'WhatsApp',
                    socialMediaIcon: <img src={WhatsappIcon} alt=""/>, //TODO: Cambiar a iconos.
                    socialMediaShareUrl: `https://api.whatsapp.com/send?text=${urlText}${url}`//TODO: Agregar texto deseado antes de %20.
                }, {
                    socialMediaName: 'Facebook',
                    socialMediaIcon: <img src={FacebookIcon} alt=""/>,
                    socialMediaShareUrl: `https://www.facebook.com/sharer/sharer.php?${urlText}${url}`
                }, {
                    socialMediaName: 'Twitter',
                    socialMediaIcon: <img src={TwitterIcon} alt=""/>,
                    socialMediaShareUrl: `https://twitter.com/intent/tweet?text=&url=${urlText}${url}` //TODO: Agregar texto deseado.
                }]
            };
        }
        setShareObject(() => socialMediaObject)
    }, []);

    const content = (
        <ShareContentContainer>
            {shareObject.toShareLinks && shareObject.toShareLinks.map((object, index) => (
                <SocialMediaItems key={index}>
                    <a href={object.socialMediaShareUrl} target="_blank" className="socialMedia">
                        <div>{object.socialMediaIcon}</div>
                        <div className="socialMediaName">{object.socialMediaName}</div>
                    </a>
                    <Divider/>
                </SocialMediaItems>
            ))}
            <SocialMediaItems>
                <a onClick={() => {
                    navigator.clipboard.writeText(url);
                    message.success({ content: 'El link se copió en el portapapeles', duration: 3 })
                }} className="socialMedia">
                    <div><img src={LinkIcon} style={{
                        minWidth: 32,
                        minHeight: 32,
                        marginRight: '12px',
                        marginTop: '-8px',
                        marginBottom: '-8px',
                        marginLeft: '-4px'
                    }} alt=""/></div>
                    <div className="socialMediaName">Copiar enlace</div>
                </a>
            </SocialMediaItems>
        </ShareContentContainer>
    );

    const handleClickButton = event => {
        event.stopPropagation();
        onClickButton();
    }

    return (
        <MainContainer onClick={event => handleClickButton(event)}>
            <Popover placement="bottomRight" content={
                <div>
                    {content}
                </div>
            } trigger="click"
                     visible={visible}>
                <ShareButton onlyIcon={onlyIcon}>
                    <img src={ShareIcon}/>
                    <div>Compartir</div>
                </ShareButton>
            </Popover>
        </MainContainer>
    );
}

Share.propTypes = {
    urlText: PropTypes.string,
    url: PropTypes.string,
    onlyIcon: PropTypes.bool,
    isOpen: PropTypes.bool,
    onClickButton: PropTypes.func,
    onlyWhatsapp: PropTypes.bool,
};

Share.defaultProps = {
    urlText: '',
    url: '',
    onlyIcon: false,
    isOpen: false,
    onClickButton: () => {},
    onlyWhatsapp: false
};

export default memo(Share);
