import styled from 'styled-components';
import {mediaQuery} from '../../utils';

export const MainContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 80px 0 116px 0;
  
  ${mediaQuery} {
    padding: 30px 16px;
  }
`;

export const Container = styled.div`
  width: 552px;

  ${mediaQuery} {
    width: 100%;
  }
`;

export const TopContainer = styled.div`
  display: flex;
  margin-bottom: 56px;
  text-align: left;
  
  ${mediaQuery} {
    display: inherit;
    margin-bottom: 32px;
  }
  
  & .success-icon {
    ${mediaQuery} {
      margin-bottom: 32px;
    }
  }
  
  & .title {
    color: #191919;
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 40px;
    margin-bottom: 16px;
    margin-left: 39px;

    ${mediaQuery} {
      margin-bottom: 16px;
      margin-left: 0;
      text-align: center;
      font-size: 24px;
      letter-spacing: 0.18px;
      line-height: 32px;
    }
  }
  
  & .subtitle {
    color: #666666;
    font-size: 20px;
    letter-spacing: 0.15px;
    line-height: 32px;
    font-weight: 500;
    margin-left: 39px;
    
    ${mediaQuery} {
      margin-left: 0;
      text-align: center;
      font-size: 20px;
      letter-spacing: 0.15px;
      line-height: 32px;
    }
  }
`;

export const EventInfoContainer = styled.div`
  margin-bottom: 64px;
  text-align: left;
  
  & img {
    width: 100%;
    height: 294px;
    border-radius: 8px;
    margin-bottom: 40px;
  }
  
  & .name {
    color: #333333;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 32px;
    margin-bottom: 24px;
  }
  
  & .confirm-text {
    color: #666666;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.15px;
    line-height: 32px;
    margin-bottom: 48px;
  }
  
  & .confirm-text a {
    text-decoration: none;
    cursor: inherit;
    color: #666666;
    margin-left: 6px;
    font-weight: 600;
  }

  & .confirm-text a:hover {
    color: #666666 !important;
  }
`;

export const EventDetailsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 48px;
  
  ${mediaQuery} {
    display: inherit;
  }
`;

export const EventDetail = styled.div`
  display: flex;
  color: #666666;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 24px;
  width: ${props => props.width};
  margin-bottom: 16px;
  
  & img {
    height: 24px;
    width: 24px;
    margin-right: 16px;
  }
  
  ${mediaQuery} {
    width: 100%;
  }
`;

export const PurchaseSummaryContainer = styled.div`
  & .text {
    color: #191919;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.12px;
    line-height: 24px;
    text-align: left;
    margin-bottom: 24px;
  }
`;

export const TicketsPriceInfo = styled.div`
  color: #666666;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 32px;
  border-bottom: 1px solid #E5E5E5;
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  
  & .price {
    color: #333333;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.15px;
    line-height: 24px;
    text-align: right;
  }
`;

export const TotalInfo = styled.div`
  color: rgba(0,0,0,0.87);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.15px;
  line-height: 24px;
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  
  & .price {
    color: rgba(0,0,0,0.87);
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.19px;
    line-height: 28px;
  }
`;

export const AdditionalInfo = styled.div`
  color: #191919;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.12px;
  line-height: 24px;
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  justify-content: space-between;
  text-align: left;
  padding-right: 50px;
  & .information {
    margin-top: 16px;
    font-weight: 300;
    color: #666666;
    font-size: 14px;
    letter-spacing: 0.13px;
    line-height: 24px;
  }
`;

export const Termstext = styled.div`
    justify-content: space-between;
    text-align: justify;
    color: #6B6F7B;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 48px;
    
    & a {
      color: #6B6F7B;
      text-decoration: none;
      text-decoration: underline;
    }
`;

export const ButtonContainer = styled.div`
  text-align: right;

  & .button-show-tickets {
    height: 56px;
    width: 181px;
    
    ${mediaQuery} {
      width: 100%;
    }
  }

  ${mediaQuery} {
    padding: 12px 16px 24px 16px;
    margin: 0 -16px;
    width: calc(100% + 32px);
    border-top: 1px solid rgba(0,0,0,0.1);
  }
`;








