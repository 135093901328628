import React, {memo} from 'react';
import PropTypes from 'prop-types';
import CategoryCard from '../CategoryCard';
import {get} from 'lodash';

import {
    Container,
    CardContainer
} from './styles';

function CategoryCardList(props) {
    const {data} = props;

    return (
        <Container>
            {data.map((card, index) => (
                <CardContainer key={index}>
                    <CategoryCard
                        id={get(card, 'id', '')}
                        title={get(card, 'title', '')}
                        image={get(card, 'image', {})}
                        color={get(card, 'color', {})}
                        background={get(card, 'background', {})}
                    />
                </CardContainer>
            ))}
        </Container>
    );
}

CategoryCardList.propTypes = {
    data: PropTypes.array
};

CategoryCardList.defaultProps = {
    data: []
};

export default memo(CategoryCardList);
