import styled from 'styled-components';
import {mediaQuery} from '../../utils';

export const Container = styled.button`
  width: fit-content;
  min-width: fit-content;
  height: ${props => props.size === 'large' ? '56' : '40'}px;
  padding:  ${props => props.size === 'large' ? '16px 25px' : '8px 32px'};
  border-radius: 8px;
  background-color: ${props => props.color};
  color: ${props => props.textColor};
  font-size: ${props => props.size === 'large' ? '16' : '14'}px;
  font-weight: 600;
  letter-spacing: 0.8px;
  line-height: 22px;
  text-align: center;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  user-select: none;
  border: 0;
  
  :hover {
    opacity: ${props => props.disabled ? 1 : 0.9};
  }

  :focus {
    outline:0;
  }
  
  & img {
    width: 24px;
    height: 24px;
  }

  ${mediaQuery} {
    font-size: 16px;
    padding: 12px 16px;
    font-weight: 600;
    letter-spacing: 0.6px;
    line-height: 22px;
    text-align: center;
  }
`;




