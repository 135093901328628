import styled from 'styled-components';
import {mediaQuery} from "../../utils";

export const MainContainer = styled.div`
  width: 100%;
  text-align: center;
  
  & .phone-icon {
    margin-top: 184px;
    margin-bottom: 40px;
    width: 98px;
    height: 120px;
  }
  
  & .button-back {
    margin-top: 48px;
    font-weight: 600;
  }

  ${mediaQuery} {
    padding: 16px;
  }
`;

export const Title = styled.div`
  color: #333333;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.18px;
  line-height: 32px;
  margin-bottom: 32px;
`;

export const DescriptionDesktop = styled.div`
  color: #666666;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 26px;
  text-align: center;

  ${mediaQuery} {
    display: none;
  }
`;

export const DescriptionResponsive = styled.div`
  display: none;
  
  ${mediaQuery} {
    display: inherit;
    color: #666666;
    font-size: 16px;
    letter-spacing: 0.15px;
    line-height: 26px;
    text-align: center;
  }
`;