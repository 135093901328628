import React, {memo, useState, useEffect} from 'react'
import {useHistory} from 'react-router-dom';
import {RegisterImage} from '../../Assets'
import https from '../../config/https';
import {Form, message, Input, Checkbox, Button as ButtonAnt, DatePicker} from 'antd';
import {PASSWORD_REGEX} from '../../utils';
import moment from 'moment';
import {
    RegisterContainer,
    Image,
    RegisterForm,
    Form as Design,
    Title,
    Label,
    ButtonContainer,
    CheckGrouper,
    // GenderCheck,
    NewAccountText,
    LogInText,
    DateLabelContainer,
    DateContainer,
    CustomDateInput,
    //  GenderLabelContainer,
} from './styles';
import {CheckBoxContainer} from "../../components/PaymentModalStep2/styles";
import {Helmet} from "react-helmet";
import {useMatomo} from '@datapunt/matomo-tracker-react';

function Register({removePadding}) {
    const [form] = Form.useForm();
    const [gender, setGender] = useState('MALE');
    const [tosChecked, setTosChecked] = useState(false);
    const {trackPageView, trackEvent} = useMatomo();


    const currentDay = moment().subtract(15, 'years').format('YYYY-MM-DD');

    const history = useHistory();

    useEffect(() => {
        trackPageView();
        const preventValue = (ev) => {
            ev.preventDefault();
        }
        const advancedSearchBirthday = document.getElementById('advanced_search_birthday');

        advancedSearchBirthday && advancedSearchBirthday.addEventListener('keypress', ev => preventValue(ev))
        return () => {
            advancedSearchBirthday.removeEventListener('keypress', ev => preventValue(ev))
        }
    }, [])

    const goToLogin = () => {
        let path = `/login`;
        history.push(path);
    }


    const onClickRegister = () => {
        if (!tosChecked) {
            message.error("Es necesario aceptar las políticas de privacidad")
            return;
        }
        form.validateFields().then(values => {
            delete values["confirm-password"];
            const birthday = moment(values.birthday).format('YYYY-MM-DD');
            const body = {
                first_name: values.first_name.toLowerCase().trim(),
                last_name: values.last_name.toLowerCase().trim(),
                password: values.password.trim(),
                email: values.email.toLowerCase().trim(),
                gender,
                birthday
            }

            https.post('/api/v1/auth/user', body)
                .then(() => {
                    history.push('/message-register');
                    trackEvent({category: 'Register', action: 'register-succesful', name: "Register"});
                })
                .catch((err => {
                    message.error('Ya existe una cuenta con ese correo.');
                }))
        }).catch(() => {
            message.error('Por favor ingresa todos los campos requeridos.');
        });
    }

    const validatePassword = (rule, value, callback) => {
        if (value && !PASSWORD_REGEX.test(value)) {
            callback("Error!");
        } else {
            callback();
        }
    };

    const validateEmail = (rule, value, callback) => {
        var emailValidation = /^(([^<>()\[\]\\.,;:\s@”]+(\.[^<>()\[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/
        if (value && (emailValidation.test(value))) {
            https.get(`api/v1/auth/user/exist?email=${value}&noloader=true`)
                .then(res => {
                    if (res.exist) {
                        callback("Error!");
                    } else {
                        callback();
                    }
                })
        } else {
            callback();
        }
    };

    const onChangeEmail = () => {
        const values = form.getFieldsValue();
        form.setFieldsValue({
            email: values.email.trim()
        });
    }


    const disabledDate = (current) => {
        return current && current > moment(currentDay);
    }

    return (
        <RegisterContainer>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Registrate</title>
            </Helmet>
            <RegisterForm removePadding={removePadding}>
                <Title>Bienvenido a bordo</Title>
                <Form
                    layout="vertical"
                    form={form}
                    initialValues={{
                        first_name: "",
                        last_name: "",
                        password: "",
                        email: "",
                    }}
                    onFinish={onClickRegister}
                    scrollToFirstError
                    name="advanced_search"
                    className="ant-advanced-search-form"
                >
                    <Design>
                        <Form.Item
                            name="first_name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor ingresa tu nombre',
                                },
                                {
                                    pattern: new RegExp(/^[a-zA-Z @~`áéíóúüñÑ!@#$%^&*()_=+\\\\';:\"\\/?>.<,-]+$/i),
                                    message: "Solo se aceptan letras y caracteres especiales"
                                },
                                {
                                    min: 3,
                                    message: 'El nombre debe contener al menos 3 caracteres.'
                                },
                            ]}
                        >
                            <Input
                                placeholder="Nombre"
                                className="input"
                                autoComplete="new-password"
                                maxLength={50}
                            />
                        </Form.Item>
                        <Form.Item
                            name="last_name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor ingresa tu apellido',
                                },
                                {
                                    pattern: new RegExp(/^[a-zA-Z @~`áéíóúüñÑ!@#$%^&*()_=+\\\\';:\"\\/?>.<,-]+$/i),
                                    message: "Solo se aceptan letras y caracteres especiales"
                                },
                                {
                                    min: 3,
                                    message: 'El apellido debe contener al menos 3 caracteres.'
                                },
                            ]}
                        >
                            <Input
                                placeholder="Apellido"
                                className="input"
                                autoComplete="new-password"
                                maxLength={50}
                            />
                        </Form.Item>
                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor ingresa tu correo',
                                },
                                {
                                    type: 'email',
                                    message: 'Por favor ingresa un correo válido'
                                },
                                {
                                    validator: validateEmail,
                                    message: "Este correo ya esta en uso elige otro"
                                }
                            ]}
                        >
                            <Input
                                placeholder="Correo electrónico"
                                className="input"
                                onChange={onChangeEmail}
                                onBlur={onChangeEmail}
                                autoComplete="new-password"
                                maxLength={254}
                            />

                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor ingresa tu contraseña',
                                },
                                {
                                    validator: validatePassword,
                                    message: "La contraseña debe contener 8 o más caracteres, letras mayúsculas, minúsculas y al menos un número"
                                }
                            ]}
                        >
                            <Input.Password
                                placeholder="Contraseña"
                                className="input"
                                autoComplete="new-password"
                            />
                        </Form.Item>
                        <Form.Item
                            name="confirm-password"
                            dependencies={['password']}
                            rules={[
                                {
                                    required: true,
                                    message: 'Las contraseñas no coinciden',
                                },
                                ({getFieldValue}) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Las dos contraseñas que ingresas no son iguales'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password
                                placeholder="Confirmar contraseña"
                                className="input"
                                autoComplete="new-password"
                            />
                        </Form.Item>
                        {/* <GenderLabelContainer>
                            <Label>¿Cuál es tu género?</Label>
                        </GenderLabelContainer>
                        <CheckGrouper>
                            <GenderCheck
                                onClick={() => setGender('FEMALE')}
                                selected={gender === 'FEMALE'}
                            >
                                Femenino
                            </GenderCheck>
                            <GenderCheck
                                onClick={() => setGender('MALE')}
                                selected={gender === 'MALE'}
                            >
                                Masculino
                            </GenderCheck>
                        </CheckGrouper> */}
                        <DateLabelContainer>
                            <Label>¿Cuál es tu fecha de nacimiento?</Label>
                        </DateLabelContainer>
                        <DateContainer>
                            <Form.Item
                                name="birthday"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor ingresa tu fecha de nacimiento',
                                    }
                                ]}
                            >
                                <DatePicker className='register-date-picker'
                                            placeholder='Fecha de nacimiento'
                                            name='birthday'
                                            disabledDate={disabledDate}
                                            defaultPickerValue={moment(currentDay)}
                                />
                            </Form.Item>
                        </DateContainer>
                        <CheckBoxContainer marginBottom="48px">
                            <Checkbox className="check-box marginRight"
                                      onClick={(ev) => setTosChecked(ev.target.checked)}/>
                            <div>
                                Acepto la <a href={process.env.REACT_APP_PRIVACY_POLICY} target="_blank">Política
                                de
                                Privacidad</a> de Guía De Hoy.
                            </div>
                        </CheckBoxContainer>
                    </Design>
                    <ButtonContainer>
                        <ButtonAnt
                            type="primary"
                            htmlType="submit"
                            block
                            className="button-register"
                        >
                            Crear cuenta
                        </ButtonAnt>
                    </ButtonContainer>
                    <Image src={RegisterImage} alt=""/>
                </Form>
                <ButtonContainer>
                    <NewAccountText>
                        ¿Ya tienes cuenta?
                        <LogInText onClick={goToLogin}>Inicia sesión</LogInText>
                    </NewAccountText>
                </ButtonContainer>
            </RegisterForm>
            <Image src={RegisterImage} alt=""/>
        </ RegisterContainer>
    );
}

export default memo(Register);
