import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {
    EventContainer,
    DetailsContainer,
    InnerDetailsContainer,
    EventImage,
    Title,
    Detail,
    Icon,
    DetailText,
} from './styles';
import {
    UbicationIcon,
    ClockIcon,
    CalendarIcon,
    TicketIcon
} from '../../Assets';
import Button from '../Button';
import {get} from 'lodash';
import {useHistory} from 'react-router-dom';
import {formatHourText, MEDIA_QUERY_MAX_PIXELS} from '../../utils';

function EventDetailTable(props) {
    const {data, buttonText} = props;

    const history = useHistory();

    const onClickSeeTickets = idBasket => {
        history.push(`/ticket/${idBasket}`);
    };

    const seeTicketsOnSmallScreen = (idBasket) => {
        if (window.window.innerWidth <= MEDIA_QUERY_MAX_PIXELS) {
            history.push(`/ticket/${idBasket}`);
        }
    }

    return data.map((item, key) => (
        <EventContainer key={key} isResponsive={window.window.innerWidth <= MEDIA_QUERY_MAX_PIXELS} onClick={() => seeTicketsOnSmallScreen(get(item, 'basket', ''))}>
            <EventImage src={item.cover_image}/>
            <DetailsContainer>
                <Title>{item.event}</Title>
                <InnerDetailsContainer>
                    <Detail flex='50%'>
                        <Icon src={UbicationIcon} alt=""/>
                        <DetailText>{item.location ? item.location : 'En Linea'}</DetailText>
                    </Detail>
                    <Detail flex='100%' className="hide-on-desktop-1320">
                        <Icon src={CalendarIcon} alt=""/>
                        <DetailText>{item.date}</DetailText>
                    </Detail>
                    <Detail>
                        <Icon src={ClockIcon} alt=""/>
                        <DetailText>{formatHourText(item.time)}</DetailText>
                    </Detail>
                    <Detail className="hide-on-phone1320">
                        <Icon src={CalendarIcon} alt=""/>
                        <DetailText>{item.date}</DetailText>
                    </Detail>
                    <Detail>
                        <Icon src={TicketIcon} alt=""/>
                        <DetailText>{item.ticket_count} {item.ticket_count > 1 ? 'tickets' : 'ticket' }</DetailText>
                    </Detail>
                </InnerDetailsContainer>
            </DetailsContainer>
            <Button
                text={buttonText}
                className="button-show-tickets hide-on-phone1320"
                onClick={() => onClickSeeTickets(get(item, 'basket', ''))}
            />
        </EventContainer>
    ))
}


EventDetailTable.propTypes = {
    data: PropTypes.array,
    buttonText: PropTypes.string,
};

EventDetailTable.defaultProps = {
    data: [],
    buttonText: 'Ver entradas'
};

export default memo(EventDetailTable);