import React, {memo} from 'react';
import PropTypes from 'prop-types';

import {
    Container,
} from './styles';

function Button(props) {
    const { onClick, text, style, color, size, className, disabled, icon, textColor } = props;

    return (
        <Container
            onClick={!disabled && onClick}
            style={style}
            color={disabled ? '#A8A7BD' : color}
            size={size}
            className={className}
            disabled={disabled}
            textColor={textColor}
        >
            {icon && <img src={icon} alt=""/>}
            {text}
        </Container>
    );
}

Button.propTypes = {
    onClick: PropTypes.func,
    text: PropTypes.string,
    style: PropTypes.object,
    color: PropTypes.string,
    size: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    icon: PropTypes.any,
    textColor: PropTypes.string,
};

Button.defaultProps = {
    onClick: () => {},
    text: 'button',
    style: {},
    color: '#7B00B2',
    size: 'large',
    className: '',
    disabled: false,
    icon: null,
    textColor : 'white',
};

export default memo(Button);
