import styled from 'styled-components';
import {mediaQuery} from '../../utils';

export const MainContainer = styled.div`
  width: 100%;
  height: 720px;
  position: relative;
  padding: 240px 80px;
  color: rgba(0, 0, 0, 0.87);
  
  ${mediaQuery} {
    padding: 324px 116px 56px 16px;
    height: 576px;
  }

  & img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
`;

export const Title = styled.div`
  width: 448px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 48px;
  letter-spacing: 0;
  line-height: 60px;
  margin-bottom: 50px;
  position: relative;
  
  ${mediaQuery}{
    width: 228px;
    min-width: 228px;
    max-width: 228px;
    font-size: 28px;
    letter-spacing: 0;
    line-height: 36px;
    margin-bottom: 32px;
    width: 60%;
  }
`;

export const UnderlineWord = styled.p`
  position: absolute;
  top: 50%;
  right: 28px;
  border-bottom: 6px solid #B361CF;

  ${mediaQuery}{
    top: 67%;
    right: 12px;
    border-bottom: 4px solid #B361CF;
  }
`;



