import styled from 'styled-components';
import {mediaQuery} from '../../utils';

export const Container = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${mediaQuery} {
    img {
      height:21px
    }
  }
  `;

export const Title = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.17px;
  line-height: 26px;
  text-align: center;


  ${mediaQuery} {
    height: 26px;
    width: 80px;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
  }
`;

export const BackgroundColorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 128px;
  height: 128px;
  background: ${props => props.color ? props.color : '#F1F8EB'};
  border-radius: 50%;
  margin-bottom: 24px;
  cursor: pointer;
  
  & .icon {
    width: 48px;
    height: 48px;
    object-fit: contain;
  }

  ${mediaQuery} {
    width: 56px;
    height: 56px;
    margin-bottom: 10px;

    & .icon {
      width: 24px;
      height: 24px;
    }
  }
`;

