import React, { memo, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

import Register from '../../views/Register';
import { MainContainer, Title, SubTitle, Container, Button } from './styles';

import { message, Drawer } from 'antd';

import {
  GoogleAuthProvider,
  signInWithPopup,
  FacebookAuthProvider,
  OAuthProvider,
} from 'firebase/auth';
import FirebaseAuthContext from '../../contexts/firebase-auth-context';
import { Mail } from '../../Assets';
import Login from '../../views/Login';

function RegisterSocialMediaModal({ closeRegisterModal }) {
  const [showRegisterPage, setShowRegisterPage] = useState(false);
  const [showLoginPage, setShowLoginPage] = useState(false);

  const FACEBOOK = 'FACEBOOK';
  const GOOGLE = 'GOOGLE';
  const APPLE = 'APPLE';
  const MAIL = 'MAIL';

  const history = useHistory();

  const firebaseAuth = useContext(FirebaseAuthContext);

  const handleSuccessSocialLogin = async (firebasePayload) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_ENDPOINT_URL + '/api/v1/login/hook ',
        firebasePayload,
      );

      localStorage.setItem('auth_token', response.data.access_token);
      localStorage.setItem('first_name', response.data.first_name);
      localStorage.setItem('profile', response.data.profile);
      localStorage.setItem('email', response.data.email);
      localStorage.setItem('name', response.data.name);
      localStorage.setItem('last_name', response.data.last_name);
      localStorage.setItem('gender', response.data.gender);
      localStorage.setItem('refresh_token', response.data.refresh_token);
      localStorage.setItem('is_first_login', response.data.is_first_login);
      localStorage.setItem('d', JSON.stringify(response.data));
      closeRegisterModal();
    } catch (e) {
      message.error('Intente de nuevo por favor');
    }
  };

  const handleErrorHandler = (e, loginType) => {
    try {
      const errorCode = e.code;
      if (loginType === FACEBOOK) {
        FacebookAuthProvider.credentialFromError(e);
      } else if (loginType === GOOGLE) {
        GoogleAuthProvider.credentialFromError(e);
      } else if (loginType === APPLE) {
        OAuthProvider.credentialFromError(e);
      }

      if (
        errorCode === 'auth/popup-closed-by-user' || // Usuario cierra el popup
        errorCode === 'auth/cancelled-popup-request' || // Usuario abre más de un popup
        errorCode === 'auth/user-cancelled' // Usuario cancela desde el login social correspondiente
      ) {
        return;
      } else if (errorCode === 'auth/popup-blocked') {
        message.error(
          'Revisa que la configuración de tu explorador te permita abrir ventanas emergentes',
        );
      } else if (
        errorCode === 'auth/account-exists-with-different-credential' // Mismo correo en distintos proveedores
      ) {
        message.error('Intenta de nuevo utilizando otro proveedor');
      } else {
        message.error('Intente de nuevo por favor');
      }
    } catch (e) {
      message.error('Intente de nuevo por favor');
    }
  };

  const handleLogin = async (loginType) => {
    if (loginType === FACEBOOK) {
      handleLoginFacebook();
    } else if (loginType === GOOGLE) {
      handleLoginGoogle();
    } else if (loginType === APPLE) {
      handleLoginApple();
    } else if (loginType === MAIL) {
      setShowLoginPage(true);
    }
  };

  const handleRegister = () => {
    setShowRegisterPage(true);
  };

  const handleGuestPurchase = () => {
    closeRegisterModal();
  };

  const handleLoginFacebook = async () => {
    try {
      const provider = new FacebookAuthProvider();
      const response = await signInWithPopup(firebaseAuth, provider);

      handleSuccessSocialLogin(response);
    } catch (e) {
      handleErrorHandler(e, FACEBOOK);
    }
  };

  const handleLoginGoogle = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const response = await signInWithPopup(firebaseAuth, provider);

      handleSuccessSocialLogin(response);
    } catch (e) {
      handleErrorHandler(e, GOOGLE);
    }
  };

  const handleLoginApple = async () => {
    try {
      const provider = new OAuthProvider('apple.com');
      provider.addScope('email');
      provider.addScope('name');
      const response = await signInWithPopup(firebaseAuth, provider);

      handleSuccessSocialLogin(response);
    } catch (e) {
      handleErrorHandler(e, APPLE);
    }
  };

  const handleLoginModal = () => {
    setShowLoginPage(false);
    closeRegisterModal();
  };
  return (
    <MainContainer>
      <Container>
        <Title>¡Compra rápido la próxima vez!</Title>
        <SubTitle>
          Conviértete en miembro y obtén todos los beneficios que tenemos para
          tí.
        </SubTitle>
        <div className="social-options">
          <Button
            shape="circle"
            className="social-button"
            onClick={() => handleLogin(GOOGLE)}
          >
            <img src="/images/social/google.svg" alt="google" />
            <span>Crear cuenta con Google</span>
          </Button>
          <Button
            shape="circle"
            className="social-button"
            onClick={() => handleLogin(FACEBOOK)}
          >
            <img src="/images/social/facebook.svg" alt="facebook" />
            <span>Crear cuenta con Facebook</span>
          </Button>
          <Button
            shape="circle"
            className="social-button"
            onClick={() => handleLogin(APPLE)}
          >
            <img src="/images/social/apple.svg" alt="apple" />
            <span>Crear cuenta con Apple</span>
          </Button>
          <Button
            shape="circle"
            className="social-button"
            onClick={() => handleLogin(MAIL)}
          >
            <img src={Mail} alt="mail" />
            <span>Continuar con correo</span>
          </Button>
        </div>
      </Container>
      <Drawer
        placement={'center'}
        closable={true}
        onClose={() => {
          setShowRegisterPage(false);
        }}
        visible={showRegisterPage}
        key={'center'}
        width={'100%'}
        height={'100%'}
        style={{ zIndex: 99999 }}
      >
        <Register removePadding />
      </Drawer>
      <Drawer
        placement={'bottom'}
        closable={true}
        onClose={() => {
          setShowLoginPage(false);
        }}
        visible={showLoginPage}
        key={'bottom'}
        width={'100%'}
        height={'100%'}
        style={{ zIndex: 99999 }}
      >
        <Login preventRedirect={true} onSuccess={handleLoginModal} />
      </Drawer>
    </MainContainer>
  );
}

export default memo(RegisterSocialMediaModal);
