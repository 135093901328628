import styled from 'styled-components';
import {mediaQuery, mediaQuery1320} from '../../utils';

export const MainContainer = styled.div`
  width: fit-content;
  color: rgba(0, 0, 0, 0.87);
  padding: 50px 0 40px 0;
  text-align: center;
  position: relative;

  & img {
    margin-bottom: 54px;

    ${mediaQuery} {
      margin-bottom: 40px;
      width: 126px;
    }
  }
  
  .close-icon {
    width: 20px;
    height: 20px;
    position: absolute;
    z-index: 999999;
    top: 0;
    right: 0;
    cursor: pointer;
    ${mediaQuery} {
      top: 15px;
    right: 15px;
    }
  }
`;

export const Title = styled.div`
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 40px;
  text-align: center;
  margin-bottom: 24px;

  ${mediaQuery} {
    font-size: 24px;
    letter-spacing: 0.18px;
    line-height: 32px;
  }
`;

export const Text = styled.div`
  padding: 0 12px;
  font-size: 20px;
  letter-spacing: 0.15px;
  line-height: 32px;
  
  ${mediaQuery} {
    font-size: 16px;
    letter-spacing: 0.12px;
    line-height: 24px;
    padding: 0;
  }
`;

