import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import EventGrouper from '../EventGrouper';
import { ItemContainer } from './styles';
import EventCard from '../EventCard';
import { useHistory } from "react-router-dom";
import { useMatomo } from '@datapunt/matomo-tracker-react';

function Carrousel(props) {
    const { data, path, title, customMargin, customEventMargin } = props;
    const { trackEvent } = useMatomo();
    const history = useHistory();

    const goToEvent = (item) => {
        trackEvent({
            category: title,
            action: 'click',
            name: `EventoId ${item.id}`,
            customDimensions: [
                {
                    id: "1",
                    value: "test",
                },
            ], // optional
        })
        trackEvent({category: "Eventos", action: 'Ir a pantalla de evento', name:"Evento"});
        const url = get(item, 'url', '/');
        if (url.search('detail/')) {
            history.push(get(item, 'url', '/'));
        } else {
            history.push(get(item, 'url', '/').replace('detail/', ''));
        }
        return window.location.reload();
    }

    return (
        <EventGrouper
            customMargin={customMargin}
            title={title}
            path={path}
            events={
                <ItemContainer id={title}>
                    {get(data, 'cardInfo', data).map((item, index, array) => {
                        return index < 10 ?
                            <div key={index}>
                                <div
                                    className="hide-on-phone"
                                    style={{
                                        margin: customEventMargin ? customEventMargin : '24px 0 0 48px',
                                        marginRight: array.length - 1 === index ? '48px' : 'inherit',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => goToEvent(item)}
                                >
                                    <EventCard {...item} />
                                </div>
                                <div
                                    className="hide-on-desktop"
                                    style={{
                                        margin: customEventMargin ? customEventMargin : '24px 4px 0 12px',
                                        marginRight: array.length - 1 === index ? '12px' : 'inherit',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => goToEvent(item)}
                                >
                                    <EventCard {...item} />
                                </div>
                            </div> : null
                    })}
                </ItemContainer>
            }>
        </EventGrouper>
    );
}

Carrousel.propTypes = {
    data: PropTypes.array,
    path: PropTypes.string,
    title: PropTypes.string,
    customMargin: PropTypes.string,
    customEventMargin: PropTypes.string
};

Carrousel.defaultProps = {
    data: [],
    path: '/',
    title: '',
    customMargin: '',
    customEventMargin: ''
};

export default memo(Carrousel);
