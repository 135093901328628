import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {Modal, Button as ButtonAnt} from 'antd';
import {SwingWoman, CloseIconGray} from '../../Assets';
import {
    MainContainer,
    Title,
    Text,
    ButtonContainer
} from './styles';

function CompleteProfile(props) {
    const {open, onClose} = props;
    
    return (
        <Modal visible={open} onCancel={onClose} footer={null} width={417}>
            <MainContainer>
                <img src={CloseIconGray} alt="" className="close-icon" onClick={onClose}/>
                <img src={SwingWoman} alt=""/>
                <Title>¡Completa tu perfil!</Title>
                <Text>Por favor completa tu perfil para recibir todos los beneficios que tenemos para ti.</Text>
                <ButtonContainer>
                    <ButtonAnt
                        onClick={() => onClose()}
                        type="primary"
                        className="button-continue"
                    >
                        Continuar
                    </ButtonAnt>
                </ButtonContainer>
            </MainContainer>
        </Modal>
    );
}

CompleteProfile.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
};

CompleteProfile.defaultProps = {
    open: false,
    onClose: () => {},
};

export default memo(CompleteProfile);
