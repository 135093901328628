import React, {memo, useCallback, useEffect, useState} from 'react';
import EventDetail from '../../components/EventDetail';
import {
    ButtonRead,
    CarrouselContainer,
    Container,
    Description,
    DirectionContainer,
    LocalizationContainer,
    MainContainer,
    Subtitle,
    TelephoneContainer,
    Title,
} from './styles';
import {getRelated} from '../../services/events';
import {get} from 'lodash';
import GoogleMapReact from 'google-map-react';
import {StickyContainer} from 'react-sticky';
import https from '../../config/https';
import {GoogleMarker} from '../../Assets';
import PaymentConfirmModal from './Modals/PaymentConfirm';
import {useHistory} from "react-router-dom";
import {LabelRedirect} from "../../components/Footer/styles";
import Carrousel from "../../components/Carrousel";
import {useMatomo} from '@datapunt/matomo-tracker-react';
import SEO from "../../components/Seo";
import { getUserStoraged } from '../../utils';

function EventDetailContainer(props) {
    const user = getUserStoraged();
    const {trackEvent, pushInstruction} = useMatomo();
    const {match} = props;

    const history = useHistory();
    const [readMore, setReadMore] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [event, setEvent] = useState();
    const [eventDetails, setEventDetails] = useState();
    const [images, setImages] = useState([]);
    const [canonicalImage, setCanonicalImage] = useState('');
    const [cardInfo, setCardInfo] = useState([]);
    const [categories, setCategories] = useState([]);
    const [embedUrl, setEmbedUrl] = useState('');
    const [descriptionLength, setDescriptionLength] = useState(0);
    const [currentStep, setCurrentStep] = useState(1);
    const [openModalOnLoad, setOpenModalOnLoad] = useState(false);

    const ubicationUrl = `https://maps.google.com/maps?q=${Number(get(event, 'location.latitude', 0))},${Number(get(event, 'location.longitude', 0))}`;

    useEffect(() => {
        window.scrollTo(0, 0);
        const id = get(match, 'params.id', '');
        const getData = async () => {
            https.get(`/api/v1/event/${id}`)
                .then(res => {
                    res.formatted_performances = res.performances_time.map((performance) => performance.time_formatted);
                    setEvent(res);
                    document.getElementById('description').innerHTML = get(res, 'description', '');
                    setDescriptionLength(get(res, 'description', '').length);
                    // Set Images event
                    const imageObjects = get(res, 'images', []);
                    const embedVideoUrl = get(res, 'embed_promo_video', '')
                    setEmbedUrl(embedVideoUrl ? embedVideoUrl.replace('&t=96s', '') : '');
                    let tempImages = [];
                    imageObjects.forEach(image => {
                        tempImages.push(get(image, 'image_url', ''));
                    })
                    setImages(tempImages);
                    setCanonicalImage(tempImages[0])

                    setCategories(get(res, 'categories', []));
                    if (!res.id_location) {
                        setEventDetails({title: 'En Linea'});
                    } else {
                        setEventDetails(res.location);
                    }
                    if (window.location.search && window.location.search.substr(1) === 'compra') {
                        setOpenModalOnLoad(true);
                    }
                })
                .catch(err => {
                })
            // Get related events
            let newEvents = await getRelated(id);
            let eventsTemp = get(newEvents, 'data.cardInfo', []);
            eventsTemp.forEach(selectedEvent => {
                selectedEvent.url = `detail/${selectedEvent.id}`
            })
            setCardInfo(eventsTemp);
        }
        getData();

    }, [])

    useEffect(() => {
        if (event) {
            window.scrollTo(0, 0);
            const status = get(event, 'status', undefined);
            const title = get(event, 'title', undefined);
            if (!title || status !== "ACTIVE") {
                history.push("/not-found-event")
            }
        }
    }, [event])

    const jsonResponse = {
        name: get(event, 'title', ''),
        subtitle: get(event, 'subtitle', ''),
        place: get(eventDetails, 'title', ''),
        periodicity: get(event, 'date_spanish_with_range', ''),
        event_type: get(event, 'event_type', ''),
        schedule: get(event, 'formatted_performances', []),
        price: get(event, 'price_range', ''),
        price_discounted: get(event, 'price_range_discounted', ''),
        image: "https://encounterculiacan.com/ed4f5af95aa2723b4fd56d989cf5ab61.jpg",
        images: images,
        categories,
        title: get(eventDetails, 'title', ''),
        description: get(eventDetails, 'description', ''),
        telephone: '667 715 0036',
        isFree: get(event, 'is_free', false),
        cardGroup: {
            title: 'También podría gustarte',
            cardInfo
        },
        sell_tickets: get(event, 'sell_tickets', ''),
    };

    const toggleDialog = useCallback((eventId) => {

        // const token = user && user.access_token
        // if (!token) {
        //     let paramId = get(match, 'params.id', '');
        //     history.push(`/no-login/${paramId}`)
        //     return;
        // }

        setOpenDialog(!openDialog);

        if (!openDialog) {
            trackEvent({category: "Pago", action: 'Adquiere Entradas', name: `EventoId ${eventId}`});
        }
        let countdownEnabled = !!localStorage.getItem('countDownTarget');
        if (countdownEnabled) localStorage.removeItem('countDownTarget');
        setCurrentStep(1);
    }, [openDialog])

    const GoogleMarkerComponent = () => (
        <div
            style={{backgroundColor: 'transparent', cursor: 'pointer'}}
            onClick={event => {
                event.preventDefault();
                window.open(ubicationUrl, "_blank");
            }}
        >
            <img src={GoogleMarker}
                 alt="Guía De Hoy"
                 style={{height: '40px', width: '40px', backgroundColor: 'transparent', objectFit: 'inherit'}}/>
        </div>
    );

    return (
        <>
            <MainContainer>

                <SEO description='Guía De Hoy description' title='Guía De Hoy title' siteTitle="Guía De Hoy" twitter="guiadehoy"/>
                <Container>
                    <StickyContainer>
                        <EventDetail url={`${process.env.REACT_APP_PUBLIC_URL}detail/${get(match, 'params.id', '')}`}
                                     data={jsonResponse}
                                     onClickButton={() => toggleDialog(get(match, 'params.id', ''))}
                                     openModalOnLoad={openModalOnLoad}/>
                        <Title className="title-event">
                            {get(event, 'subtitle', '')}
                        </Title>
                        <Description readMore={descriptionLength > 400 ? readMore : true}>
                            <div id='description'/>
                        </Description>
                        {descriptionLength > 400 && (
                            <ButtonRead
                                onClick={() => setReadMore(!readMore)}>{readMore ? 'Leer menos' : 'Leer más'}
                            </ButtonRead>
                        )}
                        {embedUrl &&
                            <div style={{marginTop: 64}}>
                                <iframe
                                    className="iframe-video"
                                    src={embedUrl.replace('&t=96s', '')}
                                >
                                </iframe>
                            </div>

                        }
                        {get(event, 'event_type', '') !== 'VIRTUAL' ?
                            <div>
                                <Title>¿Cómo llegar?</Title>
                                <div className="map-container">
                                    {event ? <GoogleMapReact
                                        bootstrapURLKeys={{key: process.env.REACT_APP_GOOGLE_API_KEY}}
                                        defaultCenter={{lat: 24.8090649, lng: -107.3940117}}
                                        center={{
                                            lat: Number(get(event, 'location.latitude', 24.8090649)),
                                            lng: Number(get(event, 'location.longitude', -107.3940117))
                                        }}
                                        defaultZoom={15}
                                    >
                                        <GoogleMarkerComponent
                                            lat={Number(get(event, 'location.latitude', 0))}
                                            lng={Number(get(event, 'location.longitude', 0))}
                                            latitude={Number(get(event, 'location.latitude', 0))}
                                            longitude={Number(get(event, 'location.longitude', 0))}
                                        />
                                    </GoogleMapReact> : React.null}
                                </div>
                            </div> : React.null}
                        <LocalizationContainer>
                            {get(event, 'event_type', '') !== 'VIRTUAL' ? <DirectionContainer>
                                <Subtitle>Dirección:</Subtitle>
                                <LabelRedirect href={ubicationUrl}
                                               target="_blank"> {get(event, 'location.address', '')}</LabelRedirect>
                            </DirectionContainer> : React.null}
                            {get(event, 'phone', '') !== '' ?
                                <TelephoneContainer>
                                    <Subtitle>Teléfono:</Subtitle>
                                    <LabelRedirect
                                        href={"tel:" + get(event, "phone", "")}>{get(event, 'phone', '')}</LabelRedirect>
                                </TelephoneContainer> : React.null}
                        </LocalizationContainer>
                    </StickyContainer>
                    <div style={{width: 1280}}/>
                </Container>
            </MainContainer>
            {cardInfo.length !== 0 ? (
                <CarrouselContainer>
                    <Carrousel
                        data={cardInfo}
                        title="También podría gustarte"
                        path="/eventos-destacados"
                    />
                </CarrouselContainer>
            ) : null}
            <PaymentConfirmModal toggleDialog={() => toggleDialog()} openDialog={openDialog} step={currentStep}
                                 id={get(match, 'params.id', '')} detailImage={jsonResponse}/>
        </>
    );
}

EventDetailContainer.propTypes = {};

EventDetailContainer.defaultProps = {};

export default memo(EventDetailContainer);
