import styled from 'styled-components';

export const ResponsiveMainContainer = styled.div`
  width: 100%;
  min-width: 100%;
  border: 1px solid #E5E5E5;
  padding-top: 56px;
  position:fixed;
  height: 203px;
  background: #fff;
  z-index: 1;
`;


export const ResponsiveOptionsContainer = styled.div`
  display: flex;
  height: 25px;
`;

export const ResponsiveMenuOption = styled.div`
  display: flex;
  width: fit-content;
  color: ${props => props.isActive ? '#B361CF' : '#666666'};
  font-size: 16px;
  font-weight: ${props => props.isActive ? '600' : '400'};
  letter-spacing: 0.15px;
  line-height: 24px;
  border-bottom: ${props => props.isActive ? '3px solid #B361CF' : 'none'};
  border-radius: 2px;
  margin-bottom: -1px;
  cursor: ${props => props.isActive ? 'inherit' : 'pointer'};
  margin-left: 16px;
`;

export const ResponsiveTitle = styled.div`
  padding-top: ${props => props.pwa ? 0 : '56px'};
  color: rgba(0,0,0,0.87);
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.18px;
  line-height: 32px;
  margin-bottom: 32px;
  margin-left: 16px;
`;
