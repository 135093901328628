import React, {memo} from 'react';
import PropTypes from 'prop-types';

import {
    Container,
    IconContainer,
} from './styles';

function InputText(props) {
    const {
        placeholder,
        icon,
        onChange,
        value,
        style,
        inputStyles,
        width,
        id,
        stylesIcon,
        className,
        placeholderSize,
        parentClass,
        fullWidthOnResponsive,
        maxlength
    } = props;

    return (
        <Container style={style} icon={icon} width={width} id={!!id ? id : ''} placeholderSize={placeholderSize} className={parentClass} fullWidthOnResponsive={fullWidthOnResponsive}>
            {icon && (
                <IconContainer style={stylesIcon}>
                    <img
                        src={icon}
                        alt=""
                        width={24}
                        height={24}
                    />
                </IconContainer>
            )}
            <input
                type="text"
                placeholder={placeholder}
                onChange={onChange}
                value={value}
                style={inputStyles}
                className={className}
                maxLength={maxlength}
            />
        </Container>
    );
}

InputText.propTypes = {
    placeholder: PropTypes.string,
    icon: PropTypes.any,
    onChange: PropTypes.func,
    value: PropTypes.string,
    style: PropTypes.object,
    width: PropTypes.string,
    inputStyles: PropTypes.object,
    id: PropTypes.string,
    stylesIcon: PropTypes.object,
    className: PropTypes.string,
    placeholderSize: PropTypes.string,
    parentClass: PropTypes.string,
    maxlength: PropTypes.number,
    fullWidthOnResponsive: PropTypes.bool
};

InputText.defaultProps = {
    placeholder: 'label',
    value: '',
    onChange: () => {
    },
    style: {},
    width: 'fit-content',
    inputStyles: {},
    stylesIcon: {},
    className: '',
    placeholderSize: '16px',
    parentClass:'',
    maxlength: 75,
    fullWidthOnResponsive: false
};

export default memo(InputText);
