import styled from 'styled-components';
import { mediaQuery } from '../../utils';

export const Image = styled.img`
  width: 60%;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  z-index: -1;

  ${mediaQuery} {
    display: none;
  }
`;

export const LoginForm = styled.div`
  width: 40%;
  padding: 56px 24px 16px;

  & .content {
    max-width: 392px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .login-label {
    color: #636363;
    font-size: 18px;
    margin-bottom: 24px;
    text-align: center;
  }

  .social-options {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
  }

  .social-options > *:not(:last-child) {
    margin-right: 16px;
  }

  & .social-button {
    width: 56px;
    height: 56px;
  }

  & .divider {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 40px;
  }

  & .divider span {
    padding: 0 24px;
    color: #999999;
    font-size: 18px;
    flex-shrink: 0;
  }

  & .divider .line {
    height: 2px;
    background-color: #F2F2F2;
    width: 100%;
  }

  ${mediaQuery} {
    width: 100%;
  }
`;

export const Form = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > *:not(:last-child) {
    width: 100%;
  }

  .input-correo {
    height: 52px;
    width: 100%;
    border: 1px solid #ededf3;
    border-radius: 8px;

    ${mediaQuery} {
      max-width: 100%;
    }
  }

  .divider {
    margin-bottom: 16px;
  }

  .input-password {
    height: 52px;
    width: 100%;
    border: 1px solid #ededf3;
    border-radius: 8px;

    ${mediaQuery} {
      max-width: 100%;
    }
  }
`;

export const LoginContainer = styled.div`
  padding-top: ${props => props.preventRedirect ? "0": "80px"};
  display: flex;
`;

export const Title = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 32px;
  text-align: center;

  ${mediaQuery} {
    font-size: 28px;
    letter-spacing: 0;
  }
`;

export const TextInput = styled.input`
  height: 52px;
  line-height: 52px;
  margin: 12px 0;
  border-radius: 5px;
  border: 1.3px solid #dfdfea;
  padding: 0 10px;
  width: ${(props) => (props.width ? props.width : '200px')};
  max-width: 392px;
  position: relative;

  ::placeholder {
    color: #84839c;
    line-height: 52px;
    font-size: 14px;
  }

  :focus {
    border: 1.3px solid #dfdfea;
    outline: none !important;
  }

  & img {
    position: absolute;
    top: 14px;
    right: 12px;
  }

  ${mediaQuery} {
    width: 100%;
    max-width: 100%;
  }
`;

export const PasswordInput = styled.div`
  position: relative;
  max-width: 392px;

  & img {
    position: absolute;
    top: 26px;
    right: 12px;
    width: 24px;
    height: 24px;
  }

  ${mediaQuery} {
    max-width: 100%;
  }
`;

export const ForgotPassword = styled.div`
  margin-top: 12px;
  height: 20px;
  width: 200px;
  color: #7B00B2;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.2px;
  line-height: 20px;
  cursor: pointer;
`;

export const ButtonContainer = styled.div`
  margin: 40px 0;
  width: 100%;

  ${mediaQuery} {
    width: 100%;
    margin: 44px 0 36px 0;
    padding: 0 16px;
  }

  .button-login {
    background-color: #7B00B2;
    border: none;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.8px;
    line-height: 22px;
    text-align: center;
    width: 100%;
    height: 48px;
    border-radius: 8px;

    ${mediaQuery} {
      max-width: 100%;
    }
  }

  .button-login:hover {
    opacity: 0.9;
  }

  .button-create {
    width: 100%;
    max-width: 392px;

    ${mediaQuery} {
      max-width: 100%;
    }
  }
`;

export const NewAccountText = styled.div`
  height: 20px;
  color: #636363;
  font-size: 14px;
  letter-spacing: -0.2px;
  line-height: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 32px;
  max-width: 392px;

  ${mediaQuery} {
    max-width: 100%;
  }
`;

export const CreateAccount = styled.div`
  height: 20px;
  color: #7B00B2;
  font-size: 14px;
  letter-spacing: -0.2px;
  line-height: 20px;
  font-weight: 600;
  cursor: pointer;
  margin-left: 5px;
`;
