import React, { memo } from 'react';
import PropTypes from 'prop-types';

import {
  MainContainer,
  LeftContainer,
  RightContainer,
  TitlesContainer,
  ButtonsContainer,
  Title,
  Subtitle,
  Button
} from './styles';

import { AppleStoreIcon, GooglePlayIcon, AppleStoreLabel, GooglePlayLabel } from '../../Assets';

function BannerApp(props) {
  const { subtitle, title, image, imageResponsive, urlAppStore, urlGooglePlay } = props;
  
  return (
    <MainContainer>
      <LeftContainer>
        <TitlesContainer>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>

        </TitlesContainer>
        <ButtonsContainer>
          <Button href={urlAppStore} >
            <img src={AppleStoreIcon} alt="" />
            <img src={AppleStoreLabel} alt="App Store" className="lastImage" />
          </Button>
          <Button href={urlGooglePlay}>
            <img src={GooglePlayIcon} alt="Ir a GooglePlay" />
            <img src={GooglePlayLabel} alt="Google Play" className="lastImage" />
          </Button>
        </ButtonsContainer>

      </LeftContainer>
      <RightContainer>
        <img src={image} alt="" className="hide-on-phone" />
        <img src={imageResponsive} alt="" className="hide-on-desktop" />

      </RightContainer>

    </MainContainer>
  );
}

BannerApp.propTypes = {
  subtitle: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.string,
  imageResponsive: PropTypes.string,
  urlAppStore: PropTypes.string,
  urlGooglePlay: PropTypes.string
};

BannerApp.defaultProps = {
  subtitle: '',
  title: '',
  image: '',
  imageResponsive: '',
  urlAppStore: "",
  urlGooglePlay: ""
};

export default memo(BannerApp);