import React from "react";

export const demo = {

  "actionType": {
    "delivery": true,
    "togo": true,
    "table": true,
  },
  "payMethod": {
    "cash": true,
    "terminal": true,
    "online": true,
  },
  "restaurant": {
    "id": 2,
    "name": "Demo",
    "status": 1,
    "restaurantTypeId": 1,
    "created_at": "2020-08-16T15:06:35.000+0000",
    "updated_at": "2020-08-16T15:06:35.000+0000"
  },
  "branchs": [{
    "id": 3,
    "state": "Sinaloa",
    "addressName": "Casa",
    "delegation": "Culiacan",
    "suburb": "Zona Dorada",
    "street": "Lago Mayor",
    "numOutside": "4321",
    "numInside": null,
    "full_name": "Uno",
    "notNumber": null,
    "status": 1,
    "created_at": "2020-08-16T15:06:35.000+0000",
    "updated_at": "2020-08-16T15:06:35.000+0000"
  },
    {
      "id": 4,
      "state": "Sinaloa",
      "addressName": "Trabajo",
      "delegation": "Culiacan",
      "suburb": "Zona Dorada",
      "street": "Lago Mayor",
      "numOutside": "4321",
      "full_name": "Dos",
      "numInside": null,
      "notNumber": null,
      "status": 1,
      "created_at": "2020-08-16T15:06:35.000+0000",
      "updated_at": "2020-08-16T15:06:35.000+0000"
    }
  ],
  "locations": [{
    "id": 3,
    "state": "Sinaloa",
    "addressName": "Casa",
    "delegation": "Culiacan",
    "suburb": "Zona Dorada",
    "street": "Lago Mayor",
    "numOutside": "4321",
    "numInside": null,
    "full_name": "Uno",
    "notNumber": null,
    "status": 1,
    "created_at": "2020-08-16T15:06:35.000+0000",
    "updated_at": "2020-08-16T15:06:35.000+0000"
  },
    {
      "id": 4,
      "state": "Sinaloa",
      "addressName": "Trabajo",
      "delegation": "Culiacan",
      "suburb": "Zona Dorada",
      "street": "Lago Mayor",
      "numOutside": "4321",
      "full_name": "Dos",
      "numInside": null,
      "notNumber": null,
      "status": 1,
      "created_at": "2020-08-16T15:06:35.000+0000",
      "updated_at": "2020-08-16T15:06:35.000+0000"
    }
  ],
  "products": [{
    "categoryName": "Carnes blancas",
    "items": [{
      "id": 1,
      "description": "La tradicional ala de pollo elaborada se compone de dos ingredientes: una salsa picante (a base de vinagre y cayena) y margarina o mantequilla.",
      "name": "Alitas",
      "image": "https://live.mrf.io/statics/i/ps/www.cocinacaserayfacil.net/wp-content/uploads/2016/02/Alitas-de-pollo-buffalo-wings.jpg?width=1200&enable=upscale",
      "categoryId": 1,
      "price": 95.0,
      "status": 1,
      "created_at": "2020-08-16T13:53:24.000+0000",
      "updated_at": "2020-08-16T13:53:24.000+0000"
    },
      {
        "id": 2,
        "description": "La definición de deshuesada en el diccionario es que se han extraído los huesos o que no contienen huesos.",
        "name": "Boneless",
        "image": "https://tvpacifico.mx/recetas/intranet/images/recipes/508-546.jpg",
        "categoryId": 1,
        "price": 90.0,
        "offer": 40.0,
        "status": 1,
        "created_at": "2020-08-16T13:53:24.000+0000",
        "updated_at": "2020-08-16T13:53:24.000+0000"
      },
      {
        "id": 3,
        "description": "La tradicional ala de pollo elaborada se compone de dos ingredientes: una salsa picante (a base de vinagre y cayena) y margarina o mantequilla.",
        "name": "Alitas",
        "image": "https://live.mrf.io/statics/i/ps/www.cocinacaserayfacil.net/wp-content/uploads/2016/02/Alitas-de-pollo-buffalo-wings.jpg?width=1200&enable=upscale",
        "categoryId": 1,
        "price": 95.0,
        "status": 1,
        "created_at": "2020-08-16T13:53:24.000+0000",
        "updated_at": "2020-08-16T13:53:24.000+0000"
      },
      {
        "id": 4,
        "description": "La definición de deshuesada en el diccionario es que se han extraído los huesos o que no contienen huesos.",
        "name": "Boneless",
        "image": "https://tvpacifico.mx/recetas/intranet/images/recipes/508-546.jpg",
        "categoryId": 1,
        "price": 90.0,
        "status": 1,
        "created_at": "2020-08-16T13:53:24.000+0000",
        "updated_at": "2020-08-16T13:53:24.000+0000"
      },
    ],
  },
    {
      "categoryName": "Bebidas",
      "items": [{
        "id": 5,
        "description": "La carne asada (como se la llama en este país) es una comida habitual.",
        "name": "Carne",
        "image": "https://i2.wp.com/www.downshiftology.com/wp-content/uploads/2018/04/Carne-Asada-8.jpg",
        "categoryId": 1,
        "price": 120.0,
        "status": 1,
        "created_at": "2020-08-16T13:53:24.000+0000",
        "updated_at": "2020-08-16T13:53:24.000+0000"
      },
        {
          "id": 6,
          "description": "Las costillas de cerdo (también en singular costilla de cerdo) son un corte especial del cerdo, diferente de la chuleta.",
          "name": "Costillas",
          "image": "https://assets.kraftfoods.com/recipe_images/opendeploy/65059_640x428.jpg",
          "categoryId": 1,
          "price": 120.0,
          "status": 1,
          "created_at": "2020-08-16T13:53:24.000+0000",
          "updated_at": "2020-08-16T13:53:24.000+0000"
        },
        {
          "id": 7,
          "description": "La carne asada (como se la llama en este país) es una comida habitual.",
          "name": "Carne",
          "image": "https://i2.wp.com/www.downshiftology.com/wp-content/uploads/2018/04/Carne-Asada-8.jpg",
          "categoryId": 1,
          "price": 120.0,
          "status": 1,
          "created_at": "2020-08-16T13:53:24.000+0000",
          "updated_at": "2020-08-16T13:53:24.000+0000"
        },
        {
          "id": 8,
          "description": "La carne asada (como se la llama en este país) es una comida habitual.",
          "name": "Carne",
          "image": "https://i2.wp.com/www.downshiftology.com/wp-content/uploads/2018/04/Carne-Asada-8.jpg",
          "categoryId": 1,
          "price": 120.0,
          "status": 1,
          "created_at": "2020-08-16T13:53:24.000+0000",
          "updated_at": "2020-08-16T13:53:24.000+0000"
        },
        {
          "id": 9,
          "description": "La carne asada (como se la llama en este país) es una comida habitual.",
          "name": "Carne",
          "image": "https://i2.wp.com/www.downshiftology.com/wp-content/uploads/2018/04/Carne-Asada-8.jpg",
          "categoryId": 1,
          "price": 120.0,
          "status": 1,
          "created_at": "2020-08-16T13:53:24.000+0000",
          "updated_at": "2020-08-16T13:53:24.000+0000"
        },
      ],
    },
  ],
  "promotions": [{
    "id": 3,
    "description": "description 1",
    "name": "name 1",
    "url": "image 1",
    "status": 1,
    "initDate": "2020-08-16T15:06:48.000+0000",
    "endDate": "2020-08-16T15:06:48.000+0000",
    "created_at": "2020-08-16T15:06:48.000+0000",
    "updated_at": "2020-08-16T15:06:48.000+0000"
  },
    {
      "id": 4,
      "description": "description 2",
      "name": "name 2",
      "url": "image 2",
      "status": 1,
      "initDate": "2020-08-16T15:06:48.000+0000",
      "endDate": "2020-08-16T15:06:48.000+0000",
      "created_at": "2020-08-16T15:06:48.000+0000",
      "updated_at": "2020-08-16T15:06:48.000+0000"
    }
  ]
};
