import styled from 'styled-components';
import {mediaQuery} from '../../utils';

export const MainContainer = styled.div`
  width: fit-content;
  color: rgba(0, 0, 0, 0.87);
  padding: 50px 0 17px 0;
  text-align: center;
  position: relative;
  z-index: 99999999;
  & img {
    margin-bottom: 54px;

    ${mediaQuery} {
      margin-bottom: 40px;
      width: 126px;
    }
  }
  
  .close-icon {
    width: 20px;
    height: 20px;
    position: absolute;
    z-index: 999999;
    top: 0;
    right: 0;
    cursor: pointer;
  }
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 40px;
  text-align: center;
  margin-bottom: 24px;

  ${mediaQuery} {
    font-size: 24px;
    letter-spacing: 0.18px;
    line-height: 32px;
  }
`;

export const Text = styled.div`
  padding: 0 8px;
  font-size: 18px;
  letter-spacing: 0.14px;
  line-height: 26px;
  color: #666666
  ${mediaQuery} {
    font-size: 15px;
    letter-spacing: 0.10px;
    line-height: 24px;
    padding: 0;
  }
`;
export const ButtonContainer = styled.div`
  margin: 40px 0 0px 0;
  width: 100%;

  ${mediaQuery} {
    width: 100%;
    margin: 25px 0 0px 0;
    padding: 0 0px;
  }

  .button-continue {
    background-color: #7B00B2;
    border: none;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 17px;
    text-align: center;
    width: 100%;
    max-width: 133px;
    height: 48px;
    border-radius: 8px;

  }
`;

