import React, {memo, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import EventCard from '../../components/EventCard';
import {get} from 'lodash';
import {
    MainContainer,
    DateContainer,
    BoldText,
    ElementsFound,
    Container,
    Text,
    BoldTextItem,
} from './styles';
import moment from 'moment';
import {useHistory} from "react-router-dom";

function SearchText(props) {
    const {item, firstDate, secondDate, results} = props;

    const [formattedSearch, setFormattedSearch] = useState('');
    const format = 'DD MMMM YYYY';

    const history = useHistory();

    useEffect(() => {
        const replaceAllString = item.replaceAll('%20', ' ');
        setFormattedSearch(replaceAllString);
    })

    const changeRoute = (item) => {
        const id = get(item, 'id', '1')
        history.push(`/detail/${id}`);
        window.location.reload();
    }

    return (
        <>
            <MainContainer>
                <Text>Resultados para</Text>
                <BoldTextItem>{formattedSearch}</BoldTextItem>
                {firstDate && secondDate ? (
                        <DateContainer>
                            <p style={{ marginRight: 8 }}>de</p><BoldText>"{moment(firstDate).format(format)}" - "{moment(secondDate).format(format)}"</BoldText>
                        </DateContainer>
                    )
                 : firstDate ? (
                        <DateContainer>el
                            <BoldText>"{moment(firstDate).format(format)}"</BoldText>
                        </DateContainer>
                    ) : ''
                }
            </MainContainer>
            <ElementsFound>{results.length} elementos encontrados</ElementsFound>
            <Container id={"2"}>
                {results.map((item, index) => (
                    <div key={index} style={{cursor: 'pointer'}} onClick={() => changeRoute(item)}>
                        <EventCard {...item} viewAll={true} isSearchResponse={true}/>
                    </div>
                ))}
            </Container>
        </>
    );
}

SearchText.propTypes = {
    item: PropTypes.string,
    firstDate: PropTypes.string,
    secondDate: PropTypes.string,
    results: PropTypes.array,
    onlyText: PropTypes.bool,
};

SearchText.defaultProps = {
    item: '',
    firstDate: '',
    secondDate: '',
    results: [],
    onlyText: false
};

export default memo(SearchText);
