import { createGlobalStyle } from 'styled-components';
import {mediaQuery, mediaQuery1150, mediaQuery930} from '../../utils';

const StylesTabs = createGlobalStyle`


[data-theme='compact'] .site-collapse-custom-doubtscontainer .site-collapse-custom-panel,
.site-collapse-custom-doubtscontainer .site-collapse-custom-panel {
  width: 60rem;
  overflow: hidden;
  background-color: #F9F9F9!important;
  border-bottom: 1px solid #E5E5E5;
  border-radius: 2px;
  color: #191919;
  font-family: Rubik;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.19px;
  line-height: 28px;
 
  ${mediaQuery1150}{
    width: 100%;
  }


  ${mediaQuery}{
     width: 100%;
     background-color: #FFFFFF!important;
  }

  .ant-collapse-arrow:first-child{
  padding-top:  9px!important;
  }
}
  

.collapse-custom-title-responsive:first-child{
  padding-top: 10px!important;
}


.radioGroup {
  display: flex;
  flex-direction:column;
}

.ant-radio-button-wrapper{
  background-color: transparent;
  display: flex ;
  align-items: center;
  color: #1263B8;
  font-family: Rubik;
  font-size: 20px;
  font-weight: 800;
  letter-spacing: 0.19px;
  line-height: 28px;
  border: none!important;
  border-left: none!important;
  height: 52px;
  width: 338px;
  border-radius: 8px!important;
  
}

.ant-radio-button-wrapper:not(:first-child)::before {
  display: none ;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
  color: #1263B8;
}
.ant-radio-button-checked{
  background-color: #E9E9E9;
  border-radius: 8px;
}
.ant-radio-checked .ant-radio-inner:focus{
  border-color: red!important;
}

.ant-collapse-borderless {
  background-color: #F9F9F9!important;
  border: 0;

  ${mediaQuery930}{
    background-color: #FFFFFF!important;
  }
}

.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  /* margin-top: 10px!important; */
  padding-bottom: 0px!important;
  padding-right: 40px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  top: 12px!important;
}

.spaceLines{
  display: block;
  margin-bottom: 5px;
}

`;

export default StylesTabs;