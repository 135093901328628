import React, { useState } from 'react';
import MaskedInput from 'react-maskedinput'
import { Input } from 'antd';
import {
  CardElement,
  Elements,
  useElements,
  useStripe
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import {
  Container,
  MethodItem,
  Check,
  CardItem,
  ErrorMessage,
  NewPaymentContainer,
} from './styles';

const methods = [
  {
    key: 'card',
    icon: 'card.svg',
    text: 'Tarjeta de débito o crédito',
  },
  {
    key: 'card2',
    icon: 'card.svg',
    text: 'Tarjeta de débito o crédito',
  },
];

const CARD_OPTIONS = {
  // iconStyle: "solid",
  // style: {
  //   base: {
  //     iconColor: "#a2a2a2",
  //     color: "#000",
  //     fontWeight: 500,
  //     fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
  //     fontSize: "16px",
  //     fontSmoothing: "antialiased",
  //     ":-webkit-autofill": {
  //       color: "#000"
  //     },
  //     "::placeholder": {
  //       color: "#a2a2a2",
  //       fontSize: "15px"
  //     }
  //   },
  //   invalid: {
  //     iconColor: "red",
  //     color: "red"
  //   }
  // }
};

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: "https://fonts.googleapis.com/css?family=Roboto"
    }
  ]
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const FormNewCard = ({ showTitle, elements }) => {
  return (
    <NewPaymentContainer>
      {showTitle && (
        <div className="payment-title">Ingresa los datos de pago</div>
      )}
      <div className="instructions">
        Te recomendamos utilizar tarjetas digitales. Todas las compras son seguras y están encriptadas.
      </div>
      <Input
        className="input-name"
        maxLength={255}
        placeholder="Nombre completo"
        size="large"
      />
      <CardElement
        options={CARD_OPTIONS}
        onChange={(e) => {
          console.log('EVENT: ', e);
          const card = elements.getElement(CardElement);
          if (!elements) {
            return;
          }
          console.log('CARD: ', card);
          // setError(e.error);
          // setCardComplete(e.complete);
        }}
      />
    </NewPaymentContainer>
  );
};

const PaymentMethods = () => {
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const elements = useElements();

  const cards = [
    {
      type: 'visa',
      name: 'Laura Flores',
      card: '···· ···· ···· 4034',
      month: '05',
      year: '23',
      icon: 'visa.svg',
      id: 1,
    },
    {
      type: 'mastercard',
      name: 'Aslly Melisse Vega Corvera',
      card: '···· ···· ···· 1234',
      month: '05',
      year: '23',
      icon: 'mastercard.svg',
      id: 2,
    },
    {
      type: 'mastercard',
      name: 'Aslly Melisse Vega Corvera',
      card: '···· ···· ···· 1234',
      month: '05',
      year: '23',
      icon: 'mastercard.svg',
      id: 3,
    },
  ];

  const handleSetSelectedCard = (cardId) => {
    if (cardId === selectedCard) {
      setSelectedCard(null);
      return;
    }

    setSelectedCard(cardId);
  };

  const cardsEmpty = !cards.length;
  const cardsFull = cards.length === 3;

  return (
    <Container>
      {/* <h2>Forma de pago</h2> */}
      {methods.map((method, index) => (
        <MethodItem
          key={method.key}
          onClick={() => setSelectedMethod(method.key)}
          lastItem={index === methods.length - 1}
        >
          <img src={`/icons/${method.icon}`} alt="Método de pago" />
          <div className="method-name">{method.text}</div>
          <Check active={method.key === selectedMethod}>
            <div />
          </Check>
        </MethodItem>
      ))}
      {!cardsEmpty && (
        <>
          <div className="select-card-label">
            Selecciona una de tus tarjetas guardadas
          </div>
          {cards.map((card) => (
            <CardItem open={card.id === selectedCard} key={card.id}>
              <div className="header" onClick={() => handleSetSelectedCard(card.id)}>
                <img src={`/icons/${card.icon}`} className="card-image" />
                <div className="card-details">
                  {`${card.name}       ${card.card}    ${card.month}/${card.year}`}
                </div>
                <img src="/icons/arrow-down.svg" className="arrow-icon" />
              </div>
              {card.id === selectedCard && (
                <div className="content">
                  <MaskedInput
                    mask="111"
                    size={52}
                    maxLength={3}
                    placeholder="CVV"
                    className="ant-input ant-input-lg input"
                  />
                  <span>Código de seguridad</span>
                </div>
              )}
            </CardItem>
          ))}
          <CardItem open={selectedCard === 'new-card'} key="new-card">
            <div className="header" onClick={() => handleSetSelectedCard('new-card')}>
              <div className="card-details medium" style={{ color: '#7B00B2' }}>
                Pagar con otra tarjeta
              </div>
              <img src="/icons/arrow-down.svg" className="arrow-icon" />
            </div>
            {selectedCard === 'new-card' && (
              <div className="content new-card">
                <FormNewCard showTitle={false} elements={elements} />
              </div>
            )}
          </CardItem>
        </>
      )}
      {cardsFull && (
        <ErrorMessage>
          <img src="./icons/checkmark-red.svg" alt="Aviso" />
          <div>
          Solo podemos almacenar 3 tarjetas, si deseas agregar una nueva, ve a Tu perfil / Mis tarjetas.
          </div>
        </ErrorMessage>
      )}
    </Container>
  );
};

const Wrapper = () => {
  return (
    <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
      <PaymentMethods />
    </Elements>
  );
}

export default Wrapper;
