import { createGlobalStyle } from 'styled-components';

const StylesPanel = createGlobalStyle`


[data-theme='compact'] .site-collapse-custom-collapse .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
  width: 514.5px;
  overflow: hidden!important;
  border-bottom: 1px solid #E5E5E5;
  border-radius: 2px;
  color: #191919;
  font-family: Rubik;
  background-color: #F4F4F4!important;
  font-size: 20px;
  font-weight: 600!important;
  letter-spacing: 0.19px;
  line-height: 28px;
}

[data-theme='compact'] .site-collapse-custom-collapseWhiteVersion .site-collapse-custom-panel,
.site-collapse-custom-collapseWhiteVersion .site-collapse-custom-panel {
  width: 514.5px;
  overflow: hidden!important;
  border-bottom: 1px solid #E5E5E5;
  border-radius: 2px;
  color: #191919;
  font-family: Rubik;
  background-color: #FFFFFF!important;
  font-size: 20px;
  font-weight: 600!important;
  letter-spacing: 0.19px;
  line-height: 28px;
}

.ant-collapse-borderless {
  background-color: #F4F4F4!important;
  border: 0;
}

.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  /* margin-top: 10px!important; */
  padding-bottom: 0px!Important;
  padding-right: 40px;

}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  top: 30.0005px!important; // estaba en 22
}


.site-custom-tab-bar {
  z-index: 1;
  background: #fff;
}


`;

export default StylesPanel;