import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SearchIcon, SittingWoman } from '../../Assets';
import { useHistory } from "react-router-dom";

import {
    MainContainer,
    SearchTextContainer,
    InputButtonContainer,
    Search,
} from './styles';
import InputText from "../Input";
import Button from "../Button";

function EmptySearch(props) {
    const { search, initialDate, finalDate } = props;

    const [searchEvent, setSearchEvent] = useState('');
    const [formattedSearch, setFormattedSearch] = useState('');
    const history = useHistory();

    useEffect(() => {
        const replaceAllString = search.replaceAll('%20', ' ');
        setFormattedSearch(replaceAllString);
    })

    const searchScreen = () => {
        if (initialDate && finalDate && searchEvent) {
            history.push({
                pathname: '/search',
                search: `?initialDate=${initialDate}&finalDate=${finalDate}&eventName=${searchEvent}`
            });
            window.location.reload();
        }

        if (searchEvent) {
            history.push({
                pathname: '/search',
                search: `?eventName=${searchEvent}`
            });
            window.location.reload();
        }


    }

    return (
        <MainContainer>
            <img src={SittingWoman} alt="" />
            <SearchTextContainer>
                No hay resultados para<Search>"{formattedSearch}"</Search>
            </SearchTextContainer>
            intenta con otra palabra
            <InputButtonContainer>
                <InputText
                    placeholder="¿Qué quieres hacer?"
                    onChange={event => setSearchEvent(event.target.value)}
                    inputStyles={{ backgroundColor: '#F4F4F4' }}
                    value={searchEvent}
                    icon={SearchIcon}
                    width="300px"
                    stylesIcon={{ top: '10px' }}
                    className="search-input"
                />
                <Button
                    text="Buscar plan"
                    onClick={searchScreen}
                    className="search-button"
                />
            </InputButtonContainer>
        </MainContainer>
    );
}

EmptySearch.propTypes = {
    search: PropTypes.string,
    initialDate: PropTypes.string,
    finalDate: PropTypes.string
};

EmptySearch.defaultProps = {
    search: '',
    initialDate: '',
    finalDate: ''
};

export default memo(EmptySearch);
