import React, {memo} from 'react';
import PropTypes from 'prop-types';

import {
    MainContainer,
    TitleContainer,
} from './styles';

function RecommendationCard(props) {
    const {title, image, bigCard} = props;

    return (
        <MainContainer bigCard={bigCard}>
            <img src={image} alt=""/>
            <TitleContainer>
                {title}
            </TitleContainer>
        </MainContainer>
    );
}

RecommendationCard.propTypes = {
    title: PropTypes.string,
    image: PropTypes.any,
    bigCard: PropTypes.bool,
};

RecommendationCard.defaultProps = {
    title: '',
    bigCard: false,
};

export default memo(RecommendationCard);
