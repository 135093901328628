import styled from 'styled-components';
import {mediaQuery} from '../../utils';

export const MainContainer = styled.div`
  margin-bottom: 80px;
  
  ${mediaQuery} {
    margin: 0;
  }
`;

export const Container = styled.div`
  display: flex;
  width: calc(100% - 80px);
  flex-wrap: wrap; 
  justify-content: center;
  margin: 0 40px;
  
  .card {
    margin: 24px 16px 0;
    cursor: pointer;
    
    ${mediaQuery} {
      margin: 0 20px 0 0;
    }
  }
  
  ${mediaQuery} {
    width: 100%;
    margin: 0;
    padding: 0 4%;
    justify-content:  center;
  }
`;

export const LoadMoreContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 181px;
`;