import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PublicRoute from '../routers/types/PublicRoute';
import PublicRouteNotHeader from '../routers/types/PublicRouteNotHeader';
import PrivateRoute from '../routers/types/PrivateRoute';
import EmptyLayout from '../layouts/EmptyLayout';
import Dashboard from '../views/Dashboard/Dashboard';
import Events from '../views/Events';
import Food from '../views/Food/Food';
import Search from '../views/Search/Search';
import EventDetailContainer from '../views/EventDetailContainer/EventDetailContainer';
import Login from '../views/Login';
import Register from '../views/Register';
import RecoveryPassword from '../views/RecoveryPassword';
import ResetPassword from '../views/ResetPassword';
import withTracker from '../withTracker';
import RemainderActiveAccount from '../views/RemainderActiveAccount';
import ConfirmAccount from '../views/ConfirmAccount';
import RequiredLogin from '../views/RequiredLogin';
import NotFoundEvent from '../views/NotFoundEvent';
import TicketView from '../views/TicketsView';
import { NotFound } from '../pages/NotFound';
import ConfirmRegister from '../views/ConfirmRegister';
import UpcomingEvents from '../views/UpcomingEvents';
import PastEvents from '../views/PastEvents';
import PerfilMVP from '../views/PerfilMVP';
import ScrollToTop from '../Hooks/scrollToTop';
import AboutUs from '../views/AboutUs';
import AnnounceWithUs from '../views/AnnounceWithUs';
import EventsSection from '../views/EventsSection';

function Routes() {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <Switch>
                <PublicRoute
                    exact
                    path="/"
                    component={withTracker(Dashboard)}
                    layout={EmptyLayout}
                />
                <Route
                    exact
                    path="/remainder-active-account"
                    component={withTracker(RemainderActiveAccount)}
                />
                <Route
                    exact
                    path="/verify-account"
                    component={withTracker(ConfirmAccount)}
                />
                <PublicRoute
                    exact
                    path="/food"
                    component={withTracker(Food)}
                    layout={EmptyLayout}
                />
                <PublicRoute
                    exact
                    path="/about"
                    component={withTracker(AboutUs)}
                    layout={EmptyLayout}
                />
                <PublicRoute
                    exact
                    path="/eventos"
                    component={withTracker(EventsSection)}
                    layout={EmptyLayout}
                />
                <PublicRoute
                    exact
                    path="/anunciate"
                    component={withTracker(AnnounceWithUs)}
                    layout={EmptyLayout}
                />
                <PublicRoute
                    exact
                    path="/eventos-destacados"
                    component={withTracker(Events)}
                    title={'Eventos destacados'}
                    layout={EmptyLayout}
                />
                <PublicRoute
                    exact
                    path="/search"
                    component={withTracker(Search)}
                    layout={EmptyLayout}
                />
                <PublicRoute
                    exact
                    path="/recomendaciones"
                    component={withTracker(Events)}
                    title={'Nuestras recomendaciones'}
                    layout={EmptyLayout}
                />
                <PublicRoute
                    exact
                    path="/esta-semana"
                    component={withTracker(Events)}
                    title={'Esta semana'}
                    layout={EmptyLayout}
                />
                <PublicRoute
                    exact
                    path="/en-linea"
                    component={withTracker(Events)}
                    title={'En linea'}
                    layout={EmptyLayout}
                />
                <PublicRoute
                    exact
                    path="/entrada-libre"
                    component={withTracker(Events)}
                    title={'Entrada libre'}
                    layout={EmptyLayout}
                />
                <PublicRoute
                    exact
                    path="/ofertas"
                    component={withTracker(Events)}
                    title={'Ofertas'}
                    layout={EmptyLayout}
                />
                <PrivateRoute
                    fixedHeader='true'
                    exact
                    path="/login"
                    component={withTracker(Login)}
                    layout={EmptyLayout}
                />
                <PrivateRoute
                    fixedHeader='true'
                    exact
                    path="/register"
                    component={withTracker(Register)}
                    layout={EmptyLayout}
                />
                <PrivateRoute
                    fixedHeader='true'
                    exact
                    path="/recovery-password"
                    component={withTracker(RecoveryPassword)}
                    layout={EmptyLayout}
                />
                <PrivateRoute
                    exact
                    path="/reset-password"
                    component={withTracker(ResetPassword)}
                    layout={EmptyLayout}
                />
                <PublicRoute
                    exact
                    path="/categoria"
                    component={withTracker(Events)}
                    layout={EmptyLayout}
                />

                <PublicRoute
                    exact
                    path="/no-login/:event"
                    component={RequiredLogin}
                    layout={EmptyLayout}
                />
                <PublicRoute
                    exact
                    path="/detail/:id"
                    component={withTracker(EventDetailContainer)}
                    layout={EmptyLayout} />
                <PublicRoute
                    exact
                    path="/not-found-event"
                    component={withTracker(NotFoundEvent)}
                    layout={EmptyLayout}
                />
                <PublicRoute
                    exact
                    path="/message-register"
                    component={withTracker(ConfirmRegister)}
                    layout={EmptyLayout}
                />
                <PublicRouteNotHeader
                    exact
                    path="/ticket/:basket"
                    component={withTracker(TicketView)}
                    layout={EmptyLayout}
                />
                <PrivateRoute
                    exact
                    path="/siguientes-eventos"
                    component={withTracker(UpcomingEvents)}
                    layout={EmptyLayout}
                    headerBorder
                />
                <PrivateRoute
                    exact
                    path="/mi-perfil"
                    component={withTracker(PerfilMVP)}
                    layout={EmptyLayout}
                    headerBorder
                />
                <PrivateRoute
                    exact
                    path="/eventos-pasados"
                    component={withTracker(PastEvents)}
                    layout={EmptyLayout}
                    headerBorder
                />
                <Route exact path="*" component={withTracker(NotFound)} />
            </Switch>
        </BrowserRouter>
    );
}

Routes.propTypes = {};

export default Routes;