import styled from 'styled-components';
import {mediaQuery, mediaQuery1320} from '../../utils';

export const MainContainer = styled.div`

  width: 100%;
  height: fit-content;
  background-color: ${props => props.color == "secondary" ? "white" : "#F4F4F4"};
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 112px  5.63%;
  margin-bottom: ${props => props.color == "secondary" ? "" : "120px"};
  ${mediaQuery}{
    min-height: fit-content;
    padding: 40px 0px 0px 0px;
    margin-bottom: 0px;
  }
  
`;

export const DesktopContainer = styled.div`
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-top:74px;

    ${mediaQuery}{
      display: none;
    }
`;

export const MobileContainer = styled.div`
display: none;
${mediaQuery}{
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: ${props => props.type == "secondary" ? "80px" : "0px"};
}

`;

export const TopContainer = styled.div`
    margin: 32px 0px 0px 0px;
    width: 100%;
    border-radius: 8px;
    display: flex;
    height: 240px;
    background-color: #F4F4F4;
    ${mediaQuery}{
      margin-bottom: 10px;
    }
    
`
export const BottomContainer = styled.div`
height: fit-content;
width: 100%;
.ant-tabs-ink-bar {
  background-color: #B361CF;
  height: 2px;
}
.ant-tabs-nav::before {
  border-bottom: 1px solid #CCCCCC!important;
}
.ant-tabs-tab{
  margin: 0px 13px 0px 13px!important;
}

.ant-tabs-nav {
width: 100% !important;
}

.ant-tabs-tab {
display: block; // centers text inside tabs
flex: 1;
text-align: center;
}

.ant-tabs-nav > div:nth-of-type(1) {
display: unset !important;
width: 100% !important;
}

`

export const LeftContainer = styled.div`
    display: flex;
    height: fit-content;
    width: 2/5;
    background-color: inherit;
    padding-right: 32px;

    .ant-collapse-arrow{
      margin-top: 14px!important;
    }

    .arrow{
      margin-top: 22px!important;
    }
`;

export const RightContainer = styled.div`
     width: 100%;
     border-radius: 8px;
    display: flex;
    height: 100%;
    width: 3/5;
    background-color: #F4F4F4;
`;

export const Instruction = styled.div`
  padding: 15px 0px 15px 0px;
  display: flex;
  flex-direction: row;
  justify-content: stretch;

`;

export const TabContent = styled.div`
padding: 16px 24px 0px 24px;
`;

export const TabNumber = styled.div`
  color: #B361CF;
  font-family: Rubik;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.17px;
  line-height: 28px;
  text-align: center;

`;

export const Title = styled.div`
  color: #40006E;
  font-family: Gotham;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 0.3px;
  line-height: 48px;

  ${mediaQuery}{
    padding-right:20px ;
    padding-left: 24px;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 0px;
    line-height: 36px;
  }
`;

export const Subtitle = styled.div`
  color: #191919;
  font-family: Rubik;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.19px;
  line-height: 28px;

  ${mediaQuery}{
    color: #191919;
    font-family: Rubik;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.19px;
    line-height: 28px;
    margin-bottom: 16px;
  }
`

export const Description = styled.p`
 margin-left: 110px;
 color: #7E7E7E;
  font-family: Rubik;
  font-size: 18px;
  letter-spacing: 0.17px;
  line-height: 26px;
  font-weight: 300!important ;
  ${mediaQuery}{
    font-family: Rubik;
    font-size: 18px;
    letter-spacing: 0.17px;
    line-height: 26px;
    margin-left: 0;

  }
`

export const CircleNumber = styled.div`
  display: flex;
  margin-right: 61px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 48px;
  min-width: 48px;
  background: ${props => props.colors == "secondary" ? "#F7EFFA" : "white"};
  border-radius: 50%;
  color: #B361CF;
  
  & .p {
    width: 48px;
    height: 48px;
    object-fit: contain;
  }

  
`;

export const ImageExample = styled.div`
  height: fit-content;
  width: 100%;

  ${mediaQuery}{
    height: 240px;
  }
`

export const InstructionTitle = styled.p`
margin-top: 7px;
`