import React, { useState } from "react";
import PropTypes from 'prop-types';
import "./index.css";
import DateInput from "../DateInput";

const FloatLabelDate = props => {
  const [focus, setFocus] = useState(false);
  const { children, label, value, onChange } = props;

  const labelClass =
    focus || (value && value.length !== 0) ? "label label-float" : "label";
  return (
    <>
        <div
        className="float-label"
        onBlur={() => setFocus(false)}
        onFocus={() => setFocus(true)}
        >
        <DateInput value={value} onChange={onChange} className="input paddingLeft" autoComplete="new-password"/>
        <label className={labelClass}>{label}</label>
        </div>
    </>
  );
};

FloatLabelDate.propTypes = {
    children: PropTypes.node,
    label: PropTypes.string,
    value: PropTypes.string
};
  
FloatLabelDate.defaultProps = {
    children: '',
    label: '',
    value: '',
};

export default FloatLabelDate;