import React, {memo, useState, useEffect} from 'react';
import Menu from '../../components/Menu';
import {
    MainContainer,
    PrimeContainer,
    TopContainer,
    UserItemAvatar,
    UserName,
    TabsContainer,
    HeaderText
} from './styles';
import {Tabs} from 'antd';
import {
    ProfileDefaultGray,
  } from '../../Assets';
import {Helmet} from "react-helmet";
import PersonalInformation from '../../components/PersonalInformation';
import MyCards from '../../components/MyCards';
import CompleteProfile from '../../components/CompleteProfile';
import { useHistory } from 'react-router-dom';
import { userStoraged } from '../../utils';


const { TabPane } = Tabs;
const PerfilMVP = () => {
    const history = useHistory()
    const user = JSON.parse(localStorage.getItem('d'));
    const [showOnboarding, setShowOnboarding] = useState(false);
    const [openProfileModal, setOpenProfileModal] = useState(false);
    const handleCloseProfileModal = () => {
        setOpenProfileModal(false);
    }
    useEffect(() => {
        if(!user){
            history.push("/login")
        }
        setShowOnboarding(user && user.showOnboarding)
        if (showOnboarding) {
            setOpenProfileModal(true);
        }
    }, []);

    return (
        <MainContainer>
            <Helmet>
                <html lang="en" />
                <title>Mi Perfil</title>
                <meta name="theme-color" content="#E6E6FA" />
            </Helmet>
            <CompleteProfile open={openProfileModal} onClose={handleCloseProfileModal} />
            <Menu className="menu" title='Mi perfil' showResponsive={false}/>
            <PrimeContainer>
                <HeaderText>Mi perfil</HeaderText>
                <TopContainer>
                    <UserItemAvatar>
                        <img src={user && user.profile ? user.profile :ProfileDefaultGray} alt="" />
                    </UserItemAvatar>
                    <UserName>{user && user.first_name} {user && user.last_name}</UserName>
                </TopContainer>
                <TabsContainer> 
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="Datos personales" key="1">
                            <PersonalInformation/>
                        </TabPane>
                        {/* <TabPane tab="Mis tarjetas" key="2">
                            <MyCards />
                        </TabPane> */}
                    </Tabs>
                </TabsContainer>
            </PrimeContainer>
        </MainContainer>
    )
};


PerfilMVP.propTypes = {};

PerfilMVP.defaultProps = {};

export default memo(PerfilMVP);