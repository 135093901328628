import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ExploreGrouper from '../ExploreGrouper';
import CardExplore from '../CardExplore';

import {
    ExploreGrouperContainer
} from './styles';

function ExploreEventsCarrousel(props) {
    const { data } = props;
    return (
        <ExploreGrouperContainer>
            <ExploreGrouper
                events={
                    <>
                        {data.map((item, index, array) => {
                            return index < 10 ?
                                <div key={index}>
                                    <div className="hide-on-phone"
                                        style={{
                                            margin: '20px 0 20px 48px',
                                            marginRight: array.length - 1 === index ? '48px' : 'inherit'
                                        }}>
                                        <CardExplore {...item} />
                                    </div>
                                    <div className="hide-on-desktop"
                                        style={{
                                            margin: '20px 4px 20px 12px',
                                            marginRight: array.length - 1 === index ? '12px' : 'inherit'
                                        }}>
                                        <CardExplore {...item} />
                                    </div>
                                </div> : null
                        })}
                    </>
                }>
            </ExploreGrouper>
        </ExploreGrouperContainer>
    );
}

ExploreEventsCarrousel.propTypes = {
    data: PropTypes.array,
};

ExploreEventsCarrousel.defaultProps = {
    data: [],
};

export default memo(ExploreEventsCarrousel);
