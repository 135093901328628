import React, {memo} from 'react';
import {MainContainer, Title, Description} from './styles';
import { HappyPhone} from '../../Assets';
import Button from "../../components/Button";
import ModalFullscreen from "../../components/ModalFullscreen";
import {useHistory} from "react-router-dom";
import { Helmet } from "react-helmet";
const ConfirmRegister = () => {

    const history = useHistory();
    const closeAndRedirectIcon = () => {
        history.push("/login")
    }

    return (
        <ModalFullscreen openModal onCloseModal={closeAndRedirectIcon}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Confirmar Registrar</title>
            </Helmet>
            <MainContainer>
                <img src={HappyPhone} alt="" className="phone-icon"/>
                <Title>Activa tu cuenta</Title>
                <Description>Revisa tu bandeja de entrada, hemos enviado un correo de activación al correo electrónico que nos proporcionaste </Description>
                <Button
                    text="Aceptar"
                    className="button-back"
                    onClick={closeAndRedirectIcon}
                />
            </MainContainer>
        </ModalFullscreen>
    )
};


export default memo(ConfirmRegister);