import React, {memo, useEffect, useState} from 'react';
import Linkify from 'react-linkify';
import PropTypes from 'prop-types';
import { formatHourText } from '../../utils'; 
import {
    MainContainer,
    Container,
    TopContainer,
    EventInfoContainer,
    EventDetailsContainer,
    EventDetail,
    PurchaseSummaryContainer,
    TicketsPriceInfo,
    TotalInfo,
    AdditionalInfo,
    Termstext,
    ButtonContainer,
} from './styles';
import {
    UbicationIcon,
    ClockIcon,
    CalendarIcon,
    TicketIcon
} from '../../Assets';
import Button from '../Button';
import SuccessIcon from "../Lottie/SuccessIcon";
import { get } from 'lodash';
import PriceWithDiscount from "../PriceWithDiscount";
import { Drawer } from 'antd';
import RegisterSocialMediaModal from '../RegisterSocialMediaModal';

const PaymentModalStep3 = (props) => {
    const {onClickButton} = props;
    const [orderDetail, setOrderDetail] = useState({});
    const [showRegisterModal, setShowRegisterModal] = useState(false);

    const token = localStorage.getItem("auth_token");

    const closeLoginModal = () => {
        setShowRegisterModal(false);
    };

    useEffect(() => {
        let order = sessionStorage.getItem('paymentDetails');
        let orderObject = JSON.parse(order)
        orderObject.date = orderObject.date.charAt(0).toUpperCase() + orderObject.date.substr(1).toLowerCase();
        setOrderDetail(orderObject);
        document.getElementsByClassName('ant-drawer-body')[0].scrollTop = 0;
        setShowRegisterModal(!!!token);
    },[])
    
    return (
        <MainContainer>
            <Container>
                <TopContainer>
                    <div className="success-icon">
                        <SuccessIcon className="success-icon"/>
                    </div>
                    <div>
                        <div className="title">¡Gracias por tu pedido!</div>
                        <div className="subtitle">#{orderDetail.order_number}  -  No. de orden</div>
                    </div>
                </TopContainer>
                <EventInfoContainer>
                    <img src={orderDetail.cover_image} alt=""/>
                    <div className="name">{orderDetail.title}</div>
                    <div className="confirm-text">
                        Hemos enviado la confirmación de compra a tu correo electrónico:
                        <a>{orderDetail.email}</a>
                    </div>
                </EventInfoContainer>
                <EventDetailsContainer>
                    <EventDetail width={"70%"}>
                        <img src={UbicationIcon} alt=""/>
                        {orderDetail.location}
                    </EventDetail>
                    <EventDetail width={"30%"}>
                        <img src={ClockIcon} alt=""/>
                        {formatHourText(get(orderDetail,'time',''))}
                    </EventDetail>
                    <EventDetail width={"70%"}>
                        <img src={CalendarIcon} alt=""/>
                        {orderDetail.date}
                    </EventDetail>
                    <EventDetail width={"30%"}>
                        <img src={TicketIcon} alt=""/>
                        {orderDetail.ticket_amount} {orderDetail.ticket_amount === 1 ? 'ticket' : 'tickets'}
                    </EventDetail>
                </EventDetailsContainer>
                <PurchaseSummaryContainer>
                    <div className="text">Resumen de compra</div>
                    {orderDetail.ticket_info && orderDetail.ticket_info.map(ticketInfo => (
                        <TicketsPriceInfo key={`${ticketInfo.name}${ticketInfo.amount}`}>
                            <div>{ticketInfo.amount} x {ticketInfo.name}</div>
                            <div className="price">
                                {ticketInfo.price_without_discount !== '' ?
                                    <PriceWithDiscount discountPrice={ticketInfo.paid_amount} normalPrice={ticketInfo.price_without_discount} smallText showPriceSign={false}/> :
                                    ticketInfo.paid_amount
                                }
                            </div>
                        </TicketsPriceInfo>
                    ))}
                    <TotalInfo>
                        <div>
                            Total
                        </div>
                        <div className="price">
                        {orderDetail.paid_amount}
                        </div>
                    </TotalInfo>
                    {orderDetail.additional_information &&  (
                    <AdditionalInfo>
                        <div>Información adicional</div>
                        <div className="information">
                            <Linkify>{orderDetail.additional_information}</Linkify>
                        </div>
                    </AdditionalInfo>
                    )}
                </PurchaseSummaryContainer>
                <Termstext>
                Compra sujeta a <a href={process.env.REACT_APP_PRIVACY_POLICY} target="_blank"> Términos y Condiciones</a> de Guía de Hoy
                </Termstext>
                <ButtonContainer>
                    <Button
                        text="Ver entradas"
                        className="button-show-tickets"
                        onClick={() => onClickButton(orderDetail.basket)}
                    />
                </ButtonContainer>
            </Container>

        </MainContainer>
    )
};


PaymentModalStep3.propTypes = {
    onClickButton: PropTypes.func,
};

PaymentModalStep3.defaultProps = {
    onClickButton: () => {},
};

export default memo(PaymentModalStep3);