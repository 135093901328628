import React, {memo, useState} from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import {
    MainContainer,
    LeftContainer,
    RightContainer,
    Place,
    Title,
} from './styles';

function CardExplore(props) {
    const {title, description, image_url, button_text, redirect_url, color_background, color_button, color_text } = props;

    const [showExploreButton] = useState(true);

    const handleRedirect = () => {
        window.open(redirect_url, '_blank');
    }

    return (
        <MainContainer onClick={handleRedirect}>
        <LeftContainer color={color_background} textColor={color_text}>
            <Place>{title}</Place>
            <Title>{description}</Title>
            {showExploreButton && (
                <Button
                    text={button_text}
                    style={{padding: '15px 40px 19px 40px'}}
                    color={color_button}
                    textColor={color_button === '#7B00B2' ? '#EDE7F1' : '#7B00B2'}
                />
            )}
        </LeftContainer>
        <RightContainer>
            <img src={image_url} alt=""/>
        </RightContainer>
        </MainContainer>
    );
}

CardExplore.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    image_url: PropTypes.string,
    button_text: PropTypes.string,
    redirect_url: PropTypes.string,
    color_background: PropTypes.string, 
    color_button: PropTypes.string, 
    color_text: PropTypes.string
};

CardExplore.defaultProps = {
    title: '',
    description: '',
    image_url: '',
    button_text: 'Explorar',
    redirect_url: 'https://www.google.com.mx',
    color_background: '#7B00B2', 
    color_button: '#EDE7F1', 
    color_text: '#FFFFFF'
};

export default memo(CardExplore);
