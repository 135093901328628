import styled from 'styled-components';
import {mediaQuery, mediaQuery1320, mediaQuery1150} from '../../utils';

export const MainContainer = styled.div`
  width: 100%;
  height: 680px;
  background-color: #FFFFFF;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 120px 80px;
  ${mediaQuery1320}{
    padding-left: 30px;
    padding-right: 30px;
  }
  ${mediaQuery}{
    height: 420px;
    justify-content: end;
    flex-direction: column;
    padding: 0px;
    margin-bottom: 64px;
    
  }
`;



export const CardContainer = styled.a`
    position: relative;
    display: inline-block;
    text-align: center;
    height: 440px;
    width: 100%;
    ${mediaQuery}{
      margin-bottom: 4px;
      width: 100%;
      height: 420px;
      flex: 1;
    }
    :hover {
    opacity: 0.9;
  }
    `;

export const Title = styled.div`
  width: ${props => props.long == "short" ? "400px" : "500px"};
  text-transform: uppercase;
  position: absolute;
  bottom: 8%;
  left: 48px;
  transform: translate(0%, 0%);
  color: #FFFFFF;
  font-family: Gotham;
  font-weight: ultra!important;
  font-size: 48px;
  letter-spacing: 3px;
  line-height: 56px;
  text-align: left;
  ${mediaQuery}{
    transform: translate(0%, 0%);
    width: 313px;
    bottom: 5%;
    left: 24px;
    font-size: 32px;
    letter-spacing: 2px;
    line-height: 40px;
  }
`;

export const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
    ${mediaQuery}{
      border-radius: 0px;
    }
`
