import styled from 'styled-components';
import {mediaQuery} from '../../utils';

export const MainContainer = styled.div`
  width: 100%;
  height: 128px;
  position: relative;
  line-height: 100%;
  text-align: center;
  
  ${mediaQuery} {
    height: 128px;
  }

  & img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
`;

export const Title = styled.div`
  color: ${props => props.titleColor ? props.titleColor : '#fff'};
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 100px;
  text-align: center;

  ${mediaQuery} {
    font-size: 28px;
    letter-spacing: 0;
    line-height: 60px;
    text-align: center;
    margin-bottom: 40px;
  }
`;



