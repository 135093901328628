import React, {memo} from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import {
    Container,
    Title,
    BackgroundColorContainer,
} from './styles';

function CategoryCard(props) {
    const {image, title, color, id} = props;
    const history = useHistory();
    const { trackEvent } = useMatomo();

    const goToEventByCategory = () => {
        trackEvent({category: "Categoria", action: 'click', name: `Id ${id}`});
       
        history.push({
            pathname: '/categoria',
            search: `?id=${id}`
          });
    }

    return (
        <Container>
            <BackgroundColorContainer color={color} onClick={goToEventByCategory}>
                <img src={image} alt="" className="icon"/>
            </BackgroundColorContainer>
            <Title>{title}</Title>
        </Container>
    );
}

CategoryCard.propTypes = {
    image: PropTypes.string,
    title: PropTypes.string,
    color: PropTypes.string,
    id: PropTypes.number
};

CategoryCard.defaultProps = {
    image: '',
    title: '',
    color: '',
    id: 1
};

export default memo(CategoryCard);
