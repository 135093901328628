import React, { memo} from 'react';
import 'moment/locale/es-mx';
import {
    MainContainer,
    Title,
} from './styles';
import PropTypes from 'prop-types';

function PhraseContainer(props) {
    const {phrase} = props;
    return (
        <MainContainer >
            <Title>{phrase}</Title>     
        </MainContainer>
    );
}
PhraseContainer.propTypes = {
    phrase: PropTypes.string,
    
  };
  
  PhraseContainer.defaultProps = {
    phrase: '',
    
  };
export default memo(PhraseContainer);
