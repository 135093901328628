import styled from 'styled-components';
import { mediaQuery } from '../../utils';

export const MainContainer = styled.div`
  width: calc(100% - 96px);
  margin-left: 48px;
  height: 224px;
  position: relative;
  padding: 80px 0 32px 32px;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 8px;
  margin-bottom: 64px;

  & img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* z-index: -1; */
    border-radius: 8px;
  }

  ${mediaQuery} {
    padding: 16px;
    font-weight: 500;
    width: calc(100% + -11px);
    height: 192px;
    margin-left: 11px;
    margin-bottom: 20px;
    
    img {
      border-radius: 8px;
    }
  }
`;

export const Title = styled.div`
  font-family: Gotham;
  position: relative;
  COLOR: ${props => props.color ? props.color : '#191919'};
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 40px;
  margin-bottom: 32px;
  
  ${mediaQuery} {
    font-size:18px;
    line-height: 26px;
    margin-bottom: 24px;
  }
`;

export const ButtonContactUS = styled.a`
  position: relative;
  text-decoration: none;
  padding: 10px 32px;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.8px;
  line-height: 22px;
  text-align: center;
  border-radius: 8px;
  background-color: #7B00B2;
  
  :hover {
    opacity: 0.9;
    color: white !important;
  }
`;


export const UnderlineWord = styled.span`
  border-bottom: 4px solid #B361CF;
  z-index: 999;
  width: 100%;
`;



