import styled from 'styled-components';
import {mediaQuery, mediaQuery1320} from '../../utils';


export const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px 96px 0 96px;

  & .container {
    display: flex;
    padding-bottom: 64px;

    ${mediaQuery1320} {
      display: inherit;
    }
  }

  ${mediaQuery1320} {
    padding: 0;
  }
`;

export const LeftContainer = styled.div`
  width: 60%;
  margin-right: 50px;
  padding-right: 10px;
  padding-bottom: 64px;
  overflow-y: auto;

  ${mediaQuery1320} {
    width: 100%;
    padding-bottom: 32px;
  }
`;

export const RightContainer = styled.div`
  width: 40%;
  ${mediaQuery1320} {
    display: none;
  }
`;

export const ControlsContainer = styled.div`
  position: relative;
  ${mediaQuery1320} {
    display: none;
  }
`;

export const LeftControl = styled.div`
  position: absolute;
`;

export const RightControl = styled.div`
  position: absolute;
  right: 0;
`;

export const Title = styled.div`
  color: #191919;
  font-size: 32px;
  letter-spacing: 0;
  line-height: 40px;
  font-weight: 600;
  margin-bottom: 64px;

  ${mediaQuery1320} {
    font-size: 20px;
    letter-spacing: 0.15px;
    line-height: 28px;
    position: fixed;
    top: 0;
    left: 0;
    padding: 18px 16px;
    width: 100%;
    height: 64px;
    border-bottom: 1px solid #D9D9D9;
    background-color: white;
  }
`;

export const TitleResponsive = styled.div`
  display: none;

  ${mediaQuery1320} {
    display: inherit;
    color: rgba(0, 0, 0, 0.87);
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.18px;
    line-height: 32px;
    margin-top: 88px;
    margin-bottom: 24px;
    padding: 0 16px;
  }
`;

export const PlaceResponsive = styled.div`
  display: none;

  ${mediaQuery1320} {
    display: flex;
    color: rgba(0, 0, 0, 0.87);
    font-size: 16px;
    letter-spacing: 0.13px;
    line-height: 22px;
    padding: 0 16px;

    & .place-icon {
      width: 16px;
      height: 16px;
      margin-top: 2px;
      margin-right: 8px;
    }
  }
`;

export const Text = styled.div`
  color: #666666;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 24px;

  ${mediaQuery1320} {
    margin-top: 10px;
    padding: 0 16px;
  }
`;

export const TextCustom = styled.div`
  color: #666666;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 24px;

  ${mediaQuery1320} {
    margin-top: 8px;
    padding: 0 16px;
  }
`;

export const DateText = styled.div`
  color: #424159;
  font-family: Rubik;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.17px;
  line-height: 26px;
  margin-bottom: 0px;
  padding: 0px;
  ${mediaQuery1320} {
    margin-top: 10px;
    padding: 0 16px;
  }
`;

export const CheckItemsContainer = styled.div`
  width: 93%;
  display: flex;
  margin-bottom: 10px;
  padding-bottom: 16px;
  overflow-x: hidden;
  ${mediaQuery1320} {
    width: 100%;
    margin-left: 0;
    overflow-x: auto;
    margin-bottom:  5px;
    padding-bottom: 8px;
  }

`;

export const AdmissionItem = styled.div`
  padding: 24px 0;
  border-bottom: 1px solid #E5E5E5;
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 79px;

  ${mediaQuery1320} {
    display: inherit;
    padding: 4px 0;
    height: inherit;
  }

  & .admission-text {
    display: flex;

    ${mediaQuery1320} {
      display: inline;
      padding: 0 16px;
    }
  }
`;

export const CheckItem = styled.div`
  padding: 9px 12px;
  color: ${props => props.selected ? '#7B00B2' : '#333333'};
  font-weight: ${props => props.selected ? '600' : '400'};
  font-size: 14px;
  letter-spacing: 0.7px;
  line-height: 22px;
  min-width: fit-content;
  border: 1.4px solid ${props => props.selected ? '#D3C3DD' : '#E5E5E5'};
  border-radius: 8px;
  margin-right: 16px;
  cursor: pointer;
  user-select: none;
`;

export const AdmissionText = styled.div`
  color: #424159;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.17px;
  line-height: 32px;
  margin-right: 16px;

  ${mediaQuery1320} {
    font-size: 16px;
    letter-spacing: 0.15px;
    line-height: 24px;
    padding: 0 16px;
  }
`;

export const AdmissionFewPlaces = styled.div`
  color: #CC1417;
  font-size: 14px;
  letter-spacing: 0.13px;
  line-height: 32px;
`;

export const AdmissionPrice = styled.div`
  color: #999999;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 32px;
  margin-right: 32px;
  
  & .fix-discount {
    width: 100%;

    ${mediaQuery1320} {
      display: flex;
    }
  }

  ${mediaQuery1320} {
    width: 100%;
  }
`;

export const PriceQuantityContainer = styled.div`
  display: flex;
  margin-bottom: 8px;

  ${mediaQuery1320} {
    width: 100%;
    margin-bottom: 16px;
    padding: 0 16px;
  }

  & .icon-less {
    margin-right: 16px;
    cursor: pointer;
    height: 32px;
  }

  & .icon-more {
    cursor: pointer;
    height: 32px;
  }
`;

export const Quantity = styled.div`
  color: #333333;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.15px;
  line-height: 32px;
  margin-right: 16px;
`;

export const RightContainerCard = styled.div`
  width: 416px !important;
  border-radius: 8px;
  background-color: #F4F4F4;
  color: rgba(0, 0, 0, 0.87);
  padding: 32px;

  & .card-image {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    height: 208px;
    width: 416px;
    margin: -32px -32px 24px -32px;
  }

  & .card-icon {
    width: 24px;
    height: 24px;
    margin-right: 16px;
  }
`;

export const EventName = styled.div`
  color: #191919;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.15px;
  line-height: 28px;
  margin-bottom: 16px;
`;

export const EventInfoContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 24px;
`;

export const EventInfoText = styled.div`
  color: #666666;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 24px;
`;

export const ResumeBuy = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: #191919;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.12px;
  line-height: 24px;
  padding-top: 16px;
  margin-bottom: 16px;
`;

export const CardAdmissionItem = styled.div`
  color: #666666;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 24px;
  padding: 16px 0;
  border-bottom: 1px solid #E5E5E5;
  display: flex;
  justify-content: space-between;
`;

export const CardAdmissionTotal = styled.div`
  color: #666666;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 24px;
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
`;

export const CardAdmissionItemPrice = styled.div`
  color: #333333;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.15px;
  line-height: 24px;
`;

export const BottomContainer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  border-top: 1px solid #E2E2E2;
  background-color: #FFFFFF;
  padding: 24px 120px;
  display: flex;

  ${mediaQuery1320} {
    padding: 12px 16px 24px 16px;
  }
`;

export const TotalBottom = styled.div`
  color: #333333;
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 56px;

  ${mediaQuery1320} {
    font-size: 20px;
    letter-spacing: 0.15px;
    line-height: 36px;
  }
`;

export const TotalBottomText = styled.div`
  color: #333333;
  font-size: 18px;
  letter-spacing: 0.17px;
  line-height: 56px;
  margin-left: 12px;

  ${mediaQuery1320} {
    font-size: 14px;
    letter-spacing: 0.13px;
    line-height: 40px;
  }
`;

export const BottomLeftContainer = styled.div`
  width: 60%;
  display: flex;

  ${mediaQuery1320} {
    width: 50%;
  }
`;

export const BottomRightContainer = styled.div`
  width: 40%;
  max-width: 416px;
  display: flex;
  justify-content: flex-end;
  white-space: nowrap;

  ${mediaQuery1320} {
    width: 55%;
  }

  & .button-entradas {
    height: 56px;
    width: 219px;

    ${mediaQuery1320} {
      max-width: 164px;
      padding: 9px 14px;
      height: 40px;
      font-size: 14px;
      letter-spacing: 0.8px;
      line-height: 22px;
    }
  }
`;

export const ChangePage = styled.div`
  box-sizing: border-box;
  height: 40px;
  width: 40px;
  border: 1px solid #E9E1EE;
  border-radius: 50%;
  overflow: hidden;
  margin: 0;
  background: #fff;
  box-shadow: 0px 0px 17px 23px #ffffff;
  
  img {
    width: 100%;
    cursor: pointer;
  }
  
  ${mediaQuery} {
    overflow-x: scroll;
  }
`;
