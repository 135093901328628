import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {MainContainer, Title, DescriptionDesktop, DescriptionResponsive} from './styles';
import ModalFullscreen from '../ModalFullscreen';
import {SadPhone} from '../../Assets';
import Button from '../Button';

const  ExpiredTimeModal= (props) => {
    const {openModal, onCloseModal, onClickButton} = props;

    return (
        <ModalFullscreen openModal={openModal} onCloseModal={onCloseModal}>
            <MainContainer>
                <img src={SadPhone} alt="" className="phone-icon"/>
                <Title>Se ha vencido el tiempo</Title>
                <DescriptionDesktop>Tu reservación se ha liberado, vuelve a  <br/> confirmar tus entradas para continuar</DescriptionDesktop>
                <DescriptionResponsive>Tu reservación se ha liberado, vuelve a  <br/> confirmar tus entradas para continuar</DescriptionResponsive>
                <Button
                    text="Volver a entradas"
                    className="button-back"
                    onClick={onClickButton}
                />
            </MainContainer>
        </ModalFullscreen>
    )
};


ExpiredTimeModal.propTypes = {
    openModal: PropTypes.bool,
    onCloseModal: PropTypes.func,
    onClickButton: PropTypes.func,
};

ExpiredTimeModal.defaultProps = {
    openModal: false,
    onCloseModal: () => {
    },
    onClickButton: () => {
    },
};

export default memo(ExpiredTimeModal);