import React, {memo} from 'react';
import PropTypes from 'prop-types';

import {
    Title,
} from './styles';

function CategoryTitle(props) {
    const {title, titleColor} = props;
    return (
        <div>
            <Title titleColor={titleColor}>{title}</Title>
        </div>
    );
}

CategoryTitle.propTypes = {
    title: PropTypes.string,
    titleColor: PropTypes.string,
};

CategoryTitle.defaultProps = {
    title: '',
    titleColor: '#191919',
};

export default memo(CategoryTitle);
