import styled from 'styled-components';
import {mediaQuery} from "../../utils";

export const MainContainer = styled.div`
  .empty-desktop{
    ${mediaQuery} {
      display: none;
    }
  }
  
  .empty-responsive{
    display: none;
    ${mediaQuery} {
      display: inherit;
    }
  }
`;