import styled from 'styled-components';
import {mediaQuery, mediaQuery1320} from '../../utils';

export const Container = styled.div`
  position: relative;
  
  ${mediaQuery1320} {
    ${props => props.fullWidthOnResponsive ? `width: 100% !important;`: ``}
  }

  & input {
    width: ${props => props.width};
    border: none;
    color: rgba(0, 0, 0, 0.87);
    font-size: 16px;
    letter-spacing: 0.15px;
    line-height: 24px;
    padding: 16px 20px 16px ${props => props.icon !== undefined ? '60px' : '20px'};
    border-radius: 8px;

    ::placeholder {
      color: #000000;
      opacity: 1;
      font-size: ${props => props.placeholderSize};
      letter-spacing: 0.15px;
      line-height: 24px;
    }

    &:focus {
      outline: none;

      ::placeholder {
        opacity: 0;
      }
    }
    
    ${mediaQuery} {
      padding: 16px 20px 16px ${props => props.icon !== undefined ? '52px' : '12px'};
    }
  }
`;

export const IconContainer = styled.div`
  position: absolute;
  top: 16px;
  left: 20px;
  z-index: 10;
  width: 24px;
  height: 24px;

  & img {
    max-width: 24px;
    max-height: 24px;
  }
  
  ${mediaQuery} {
    left: 12px;
  }
`;




