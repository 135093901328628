import {
    ADD_TO_CART,
    REMOVE_ITEM_CART,
    ADD_QUANTITY,
    SUB_QUANTITY,
    REMOVE_ALL_ITEMS,
    HANDLE_TOGO_FORM, HANDLE_HOW_TO_ORDER, HANDLE_OPEN_CART_ORDER,
    HANDLE_ALL_CART_ASIDE
} from './actionTypes';

import {demo} from './data'
import {data} from './business'

const initialState = {
    items: [],
    data: demo,
    bussines : data,
    total: 0,
    openToGo: false,
    openHowToOrder: false,
    openCart: false,
    form: 'delivery'
};

export default function productReducer(state = initialState, action) {
    if (action.type === ADD_TO_CART) {
        let addedItem = state.items.find(item => item.id === action.product.id);
        let existed_item = state.items.find(item => action.product.id === item.id);
        if (existed_item) {
            addedItem.quantity = action.product.quantity;
            addedItem.note = action.product.note;
            return {
                ...state,
                total: state.total + (action.product.quantity * action.product.price)
            }
        } else {
            let newTotal = state.total + (action.product.quantity * action.product.price);
            return {
                ...state,
                items: [...state.items, action.product],
                total: newTotal
            }

        }
    }
    if (action.type === REMOVE_ITEM_CART) {
        let itemToRemove = state.items.find(item => action.id === item.id);
        let new_items = state.items.filter(item => action.id !== item.id);

        let newTotal = state.total - (itemToRemove.price * itemToRemove.quantity);
        return {
            ...state,
            items: new_items,
            total: newTotal
        }
    }

    if (action.type === ADD_QUANTITY) {
        let addedItem = state.items.find(item => item.id === action.id);
        addedItem.quantity += 1;
        let newTotal = state.total + addedItem.price;
        return {
            ...state,
            total: newTotal
        }
    }

    if (action.type === SUB_QUANTITY) {
        let addedItem = state.items.find(item => item.id === action.id);
        if (addedItem.quantity === 1) return state;
        addedItem.quantity -= 1;
        let newTotal = state.total - addedItem.price;
        return {
            ...state,
            total: newTotal
        }

    }

    if (action.type === 'delete') {
        let addedItem = state.items.find(item => item.id === action.id);
        if (addedItem.quantity === 1) {
            let new_items = state.addedItems.filter(item => item.id !== action.id);
            let newTotal = state.total - addedItem.price;
            return {
                ...state,
                items: new_items,
                total: newTotal
            }
        } else {
            addedItem.quantity -= 1;
            let newTotal = state.total - addedItem.price;
            return {
                ...state,
                total: newTotal
            }
        }
    }

    if (action.type === REMOVE_ALL_ITEMS) {
        return {
            ...state,
            items: [],
            total: 0,
        }
    }

    if (action.type === HANDLE_TOGO_FORM) {
      console.log(action.form)
        return {
            ...state,
            openToGo: !state.openToGo,
            form: action.form,
        }
    }

    if (action.type === HANDLE_ALL_CART_ASIDE) {
        return {
            ...state,
            openToGo: false,
            openHowToOrder: false,
            openCart: false
        }
    }

    if (action.type === HANDLE_OPEN_CART_ORDER) {
        return {
            ...state,
            openCart: !state.openCart,
        }
    }

    if (action.type === HANDLE_HOW_TO_ORDER) {
        return {
            ...state,
            openHowToOrder: !state.openHowToOrder,
        }
    } else {
        return state;
    }
}
