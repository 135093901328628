import styled from 'styled-components';
import {mediaQuery} from '../../utils';

export const MainContainer = styled.div`
  width: 100%;
  height: 320px;
  position: relative;
  padding: 80px;

  .background-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 320px;
    z-index: -1;
  }

  ${mediaQuery} {
    padding: 0;
  }
`;

export const Title = styled.div`
  font-family: Gotham;
  color: rgba(255,255,255,0.87);
  font-size: 48px;
  letter-spacing: 0;
  line-height: 56px;
  margin-bottom: 58px;
  font-weight: 900;

  ${mediaQuery} {
    font-size: 28px;
    line-height: 36px;
    padding: 40px 115px 0 16px;
    min-width: 360px;
    margin-bottom: 30px;
    font-weight: 900;
    letter-spacing: -1px; 
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  position: relative;
  padding: 0;
  width: 100%;

  ${mediaQuery} {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    padding: 0 12px;

    div div {
      min-width: 48px;
    }

    #search-event {
      width: 100%;
      margin-bottom: 10px;
    }

    #search-event input {
      width: 100%;
    }

  }
`;

export const BottomContainer = styled.div`
  display: flex;
  height: 56px;

  ${mediaQuery} {
    .ant-picker-panels {
      width: 50%;
    }
    
    .ant-space {
      margin-right: 12px; 
    }
  }

  .ant-picker-input > input {
    line-height: 24px;
  }

  .ant-picker {
    border-radius: 8px;
    border-color: white;
    padding-left: 58px;
    
    ${mediaQuery} {
      padding-left: 50px;
    }
  }

  .ant-picker-range-separator {
    display: none;
  }

  .ant-picker-input > input::placeholder {
    color: rgba(0, 0, 0, 0.87);
    font-size: 16px;
    letter-spacing: 0.15px;
    line-height: 24px;
  }

  .ant-picker-range.ant-picker-focused .ant-picker-active-bar {
    opacity: 0;
  }

  .ant-picker-panel-container {
    border-radius: 8px;
  }

  .ant-picker:hover {
    border-color: white;
  }

  .icon-rangepicker {
    width: 24px;
    height: 24px;
    top: 14px;
    left: 18px;
    position: absolute;
    z-index: 10;
    
    ${mediaQuery} {
      left: 12px;
    }
  }
`;