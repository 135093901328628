import React, { memo,  } from 'react';

import 'moment/locale/es-mx';
import {
    MainContainer,
    LeftContainer,
    RightContainer,
    Title,
    Subtitle,
    DescriptionContainer,
    ButtonsContainer,
    SocialMediaContainer,
    SocialMediaItem,
    Button,
} from './styles';
import PropTypes from 'prop-types';
import { ContactUsImage } from '../../Assets';


function ContactUs(props) {
    const {title, subtitle, description, data} = props;
    return (
        <>
        <MainContainer>
            <LeftContainer>
                <Title>{title}</Title>
                <DescriptionContainer>{description}</DescriptionContainer>
                <ButtonsContainer>
                    <Button primary href="https://wa.me/5216674718532">Enviar WhatsApp</Button>
                    <Button secondary href="mailto:ayuda@guiadehoy.com">Enviar correo</Button>
                </ButtonsContainer>
                <Subtitle>
                    {subtitle}
                </Subtitle>
                <SocialMediaContainer>
                {
                    data.map((media =>
                            <SocialMediaItem href={media.url_direction} key={media.number} target="_blank" rel="noopener noreferrer">
                                <img src={media.icon} className="icon-social"/>
                            </SocialMediaItem>
                    ))
                }
                </SocialMediaContainer>
            </LeftContainer>
            <RightContainer>
                <img src={ContactUsImage}/>
            </RightContainer>
        </MainContainer>
        
        </>
    );
}
ContactUs.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    description: PropTypes.string,
    data: PropTypes.array
  };
  
  ContactUs.defaultProps = {
    title: '',
    subtitle: '',
    description: '',
    data: {}
  };
export default memo(ContactUs);
