import styled from 'styled-components';
import {mediaQuery1320} from '../../utils';

export const EventContainer = styled.div`
  display: flex;
  margin-bottom: 30px;
  padding-bottom: 24px;
  border-bottom: 1px solid #E5E5E5;
  cursor: ${props => props.isResponsive ? 'pointer' : 'inherit'};

  & .button-show-tickets {
    color: #7B00B2;
    background-color: #E9E1EE;
    margin-top: 36px;
  }

  ${mediaQuery1320} {
    & .hide-on-phone1320 {
      display: none;
    }
  }
`;

export const EventImage = styled.img`
  width: 128px;
  height: 128px;
  border-radius: 5px;

  ${mediaQuery1320} {
    width: 72px;
    height: 72px;
  }
`;

export const DetailsContainer = styled.div`
  margin-left: 40px;
  width: 100%;

  ${mediaQuery1320} {
    margin-left: 16px;
  }
`;

export const InnerDetailsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 14px;

  & .hide-on-desktop-1320 {
    display: none;
  }

  ${mediaQuery1320} {
    & .hide-on-desktop-1320 {
      display: inherit;
      flex: 50%;
    }
  }

`;

export const Title = styled.div`
  color: #191919;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.14px;
  line-height: 26px;
  margin-bottom: 24px;

  ${mediaQuery1320} {
    margin-bottom: 8px;
  }
`;

export const Detail = styled.div`
  flex: 50%;
  min-width: 50%;
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  ${mediaQuery1320} {
    flex: ${props => props.flex ? props.flex : '50%'};
  }
`;

export const Icon = styled.img`
  margin-right: 16px;
  width: 24px;
  height: 24px;

  ${mediaQuery1320} {
    margin-right: 4px;
    width: 16px;
    height: 16px;
  }
`;

export const DetailText = styled.div`
  color: #666666;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 24px;

  ${mediaQuery1320} {
    font-size: 12px;
  }

  ${mediaQuery1320} {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
`;