import styled from 'styled-components';
import { mediaQuery1320 } from '../../utils';

export const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px 96px 0 96px;

  & .container {
    display: flex;
    padding-bottom: 90px;

    ${mediaQuery1320} {
      display: inherit;
      padding: 0 16px;
    }
  }

  ${mediaQuery1320} {
    padding: 0;
  }
`;

export const LeftContainer = styled.div`
  width: 60%;
  margin-right: 50px;
  overflow-y: auto;

  ${mediaQuery1320} {
    width: 100%;
  }
`;

export const RightContainer = styled.div`
  width: 40%;

  ${mediaQuery1320} {
    display: none;
  }
`;

export const TitleTop = styled.div`
  display: flex;
  align-items: center;
  color: #191919;
  font-size: 32px;
  letter-spacing: 0;
  line-height: 40px;
  font-weight: 600;
  margin-bottom: 56px;

  ${mediaQuery1320} {
    font-size: 20px;
    letter-spacing: 0.15px;
    line-height: 28px;
    position: fixed;
    top: 0;
    left: 0;
    padding: 18px 16px;
    width: 100%;
    height: 64px;
    border-bottom: 1px solid #d9d9d9;
    background-color: white;
    z-index: 999995;
  }
`;

export const Title = styled.div`
  color: #191919;
  font-size: 32px;
  letter-spacing: 0;
  line-height: 40px;
  font-weight: 600;
  margin-bottom: 28px;
  margin-top: 24px;

  ${mediaQuery1320} {
    font-size: 24px;
    letter-spacing: 0.18px;
    line-height: 32px;
    margin-bottom: 32px;
  }
`;

export const Text = styled.div`
  color: #666666;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 24px;
`;

export const AdmissionFewPlaces = styled.div`
  color: #cc1417;
  font-size: 14px;
  letter-spacing: 0.13px;
  line-height: 32px;
`;

export const Quantity = styled.div`
  color: #333333;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.15px;
  line-height: 32px;
  margin-right: 16px;
`;

export const RightContainerCard = styled.div`
  width: 416px !important;
  border-radius: 8px;
  background-color: #f4f4f4;
  color: rgba(0, 0, 0, 0.87);
  padding: 32px;

  & .card-image {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    height: 208px;
    width: 416px;
    margin: -32px -32px 24px -32px;
  }

  & .card-icon {
    width: 24px;
    height: 24px;
    margin-right: 16px;
  }

  ${mediaQuery1320} {
    display: none;
  }
`;

export const ResumenContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 16px;
  margin-bottom: 16px;
`;

export const EventName = styled.div`
  color: #191919;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.15px;
  line-height: 28px;
  margin-bottom: 16px;
`;

export const EventInfoContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 24px;
`;

export const EventInfoText = styled.div`
  color: #666666;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 24px;
  text-transform: ${(props) => props.isDate && 'capitalize'};
`;

export const ResumeEdit = styled.div`
  color: #7b00b2;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.7px;
  line-height: 22px;
  cursor: pointer;
`;

export const ResumeBuy = styled.div`
  color: #191919;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.12px;
  line-height: 24px;
`;

export const CardAdmissionItem = styled.div`
  color: #666666;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 32px;
  padding: 16px 0;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  justify-content: space-between;
`;

export const CardAdmissionTotal = styled.div`
  color: #666666;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 24px;
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
`;

export const CardAdmissionItemPrice = styled.div`
  color: #333333;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.15px;
  line-height: 24px;
`;

export const BottomContainer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  border-top: 1px solid #e2e2e2;
  background-color: #ffffff;
  padding: 24px 120px;
  display: flex;

  ${mediaQuery1320} {
    padding: 12px 16px 24px 16px;
  }
`;

export const TotalBottom = styled.div`
  color: #333333;
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 56px;

  ${mediaQuery1320} {
    font-size: 20px;
    letter-spacing: 0.15px;
    line-height: 36px;
  }
`;

export const TotalBottomText = styled.div`
  color: #333333;
  font-size: 18px;
  letter-spacing: 0.17px;
  line-height: 56px;
  margin-left: 12px;

  ${mediaQuery1320} {
    font-size: 14px;
    letter-spacing: 0.13px;
    line-height: 40px;
    margin-left: 8px;
  }
`;

export const BottomLeftContainer = styled.div`
  width: 60%;
  display: flex;

  ${mediaQuery1320} {
    width: 50%;
  }
`;

export const BottomRightContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;

  ${mediaQuery1320} {
    width: 55%;
  }

  & .button-confirm {
    height: 56px;
    width: 219px;
    min-width: 219px;
    max-width: 219px;

    ${mediaQuery1320} {
      max-width: 164px;
      padding: 9px 12px;
      height: 40px;
      font-size: 14px;
      letter-spacing: 0.8px;
      line-height: 22px;
      min-width: fit-content;
    }
  }
`;

export const TimerItem = styled.div`
  height: 56px;
  display: flex;
  border: 1px solid rgba(248, 210, 53, 0.4);
  border-radius: 8px;
  background-color: #fefaea;
  color: #737373;
  font-size: 14px;
  letter-spacing: -0.2px;
  line-height: 24px;
  padding: 16px;
  margin-right: 24px;
  width: 354px;
  max-width: 354px;
  min-width: 354px;

  & .text-time {
    display: flex;
  }

  & img {
    height: 24px;
    width: 24px;
    margin-right: 8px;
  }

  & p {
    font-weight: 600;
    margin-left: 4px;
  }

  ${mediaQuery1320} {
    display: none;
  }
`;

export const TimerItemResponsive = styled.div`
  display: none;

  ${mediaQuery1320} {
    margin-top: 75px;
    height: fit-content;
    width: 100%;
    max-width: 810px;
    display: flex;
    justify-content: center;
    border: 1px solid rgba(248, 210, 53, 0.4);
    border-radius: 8px;
    background-color: #fefaea;
    color: #737373;
    font-size: 14px;
    letter-spacing: -0.2px;
    line-height: 24px;
    padding: 16px;
    margin-bottom: 24px;

    & .text-time {
      display: flex;
    }

    & img {
      height: 24px;
      width: 24px;
      margin-right: 8px;
    }

    & p {
      font-weight: 600;
      margin-left: 4px;
    }
  }
`;

export const TicketInfo = styled.div`
  width: 100%;
  margin-bottom: 40px;
  margin-top: 24px;

  & .free-divider {
    ${mediaQuery1320} {
      padding-bottom: 60px;
    }
  }

  & .AppWrapper {
    height: fit-content;

    ${mediaQuery1320} {
      height: fit-content;
      width: 100%;
    }
  }
`;

export const TicketName = styled.div`
  color: #666666;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.15px;
  line-height: 32px;
  margin-bottom: 32px;
`;

export const TicketInputsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 704px;

  & .input {
    padding: 15px 30px 15px 16px;
    width: 100%;
    height: 52px;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    margin-bottom: 24px;

    ${mediaQuery1320} {
      min-width: 100%;
      max-width: 100%;
    }
  }

  & .input::placeholder {
    color: #999999;
  }

  & .input-styles {
    color: green;
  }

  ${mediaQuery1320} {
    width: 100%;
    max-width: 100%;
  }
`;

export const EmailErrorContainer = styled.div`
  width: 49%;

  ${mediaQuery1320} {
    width: 100%;
  }
`;

export const ErrorText = styled.div`
  color: #ff4d4f;
  margin-top: -22px;
  user-select: none;
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  color: #666666;
  font-size: 14px;
  letter-spacing: -0.2px;
  min-width: 341px;
  line-height: ${(props) => props.lineHeight || '22px'};
  margin-bottom: ${(props) => props.marginBottom};

  ${mediaQuery1320} {
    margin-bottom: 100px;
  }

  & .check-box {
    color: #666666;
    font-size: 14px;
    letter-spacing: -0.2px;
    margin-right: 8px;
  }

  & .marginRight {
    margin-right: 16px;
  }

  .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border: 2px solid #c4c4c4;
    width: 18px;
    height: 18px;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #7b00b2 !important;
    border-color: #7b00b2 !important;
  }

  & a {
    color: #7b00b2;
    font-weight: 600;
  }
`;

export const EventInfoContainerResponsive = styled.div`
  display: none;

  ${mediaQuery1320} {
    display: inherit;
    margin-bottom: 32px;
  }
`;

export const TitleResponsive = styled.div`
  display: none;

  ${mediaQuery1320} {
    display: inherit;
    color: rgba(0, 0, 0, 0.87);
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.18px;
    line-height: 32px;
    margin-bottom: 24px;
  }
`;

export const EventInfoResponsive = styled.div`
  display: none;
  text-transform: ${(props) => props.isDate && 'capitalize'};

  ${mediaQuery1320} {
    display: flex;
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    letter-spacing: 0.13px;
    line-height: 22px;
    margin-bottom: 16px;

    & .info-icon {
      width: 16px;
      height: 16px;
      margin-top: 2px;
      margin-right: 8px;
    }
  }
`;

export const ResumeContainerResponsive = styled.div`
  display: none;

  ${mediaQuery1320} {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 32px;
  }
`;

export const ResumeBuyResponsive = styled.div`
  display: none;

  ${mediaQuery1320} {
    display: inherit;
    color: #191919;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.12px;
    line-height: 24px;
  }
`;

export const ResumeEditResponsive = styled.div`
  display: none;
  text-decoration: underline;
  ${mediaQuery1320} {
    display: inherit;
    color: #7b00b2;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.7px;
    line-height: 22px;
    cursor: pointer;
  }
`;

export const CardAdmissionItemResponsive = styled.div`
  display: none;

  ${mediaQuery1320} {
    color: #666666;
    font-size: 16px;
    letter-spacing: 0.15px;
    line-height: 24px;
    padding: 16px 0;
    border-bottom: 1px solid #e5e5e5;
    display: flex;
    justify-content: space-between;
  }
`;

export const CardAdmissionItemPriceResponsive = styled.div`
  display: none;

  ${mediaQuery1320} {
    display: inherit;
    color: #333333;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.15px;
    line-height: 24px;
  }
`;

export const BackImage = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 24px;
  cursor: pointer;

  ${mediaQuery1320} {
    display: none;
  }
`;
