import styled from 'styled-components';
import {mediaQuery} from '../../utils';

export const MainContainer = styled.div``;

export const ChildrenContainer = styled.div`
  position: relative;
  height: 100%;
  
  & .close-icon {
    position: absolute;
    top: 40px;
    right: 40px;
    cursor: pointer;
    z-index: 999999;
    
    ${mediaQuery} {
      top: 16px;
      right: 16px;
    }
  }
`;