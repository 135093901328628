import React, { memo,  } from 'react';
import PropTypes from 'prop-types';
import 'moment/locale/es-mx';
import {
    MainContainer,
    TopContainer,
    BottomContainer,
    Title,
    AdvantageContainer,
    Subtitle,
    Icon,
    Description
} from './styles';

function AdvantagesGroup(props) {

    const {titleMobile, titleDesktop, data, style} = props; 
    
    return (
        <MainContainer color={style}>
            <TopContainer>
                <Title className="hide-on-phone">{titleMobile}</Title>
                <Title className="hide-on-desktop">{titleDesktop}</Title>
            </TopContainer>
            <BottomContainer>
                {
                    data.map((advantage =>
                        <>
                            <AdvantageContainer key={advantage.id}>
                                <Icon src={advantage.icon}/>
                                <Subtitle>{advantage.title}</Subtitle>
                                <Description>{advantage.description}</Description>
                            </AdvantageContainer>
                        </>
                    ))
                }   
            </BottomContainer>
        </MainContainer>
    );
}

AdvantagesGroup.propTypes = {
    titleMobile: PropTypes.string,
    titleDesktop: PropTypes.string,
    data: PropTypes.array,
    style: PropTypes.string,
  };
  
  AdvantagesGroup.defaultProps = {
    titleMobile: '',
    titleDesktop: '',
    data: {},
    style: ""
  };

export default memo(AdvantagesGroup);
