import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'antd';
import {SwingWoman, CloseIconGray} from '../../Assets';
import {
    MainContainer,
    Title,
    Text,
} from './styles';

function DialogFirstLogin(props) {
    const {open, onClose} = props;

    return (
        <Modal visible={open} onCancel={onClose} footer={null} width={417}>
            <MainContainer>
                <img src={CloseIconGray} alt="" className="close-icon" onClick={onClose}/>
                <img src={SwingWoman} alt=""/>
                <Title>¡Bienvenido a bordo!</Title>
                <Text>Ya eres parte de Guía De Hoy, pronto podrás guardar lo que te gusta y ver tu historial de compra en tu perfil.</Text>
            </MainContainer>
        </Modal>
    );
}

DialogFirstLogin.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
};

DialogFirstLogin.defaultProps = {
    open: false,
    onClose: () => {},
};

export default memo(DialogFirstLogin);
