import styled from 'styled-components';
import {mediaQuery} from '../../utils';

export const MainContainer = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-size: 36px;
  letter-spacing: 0;
  line-height: 56px;
  padding-top: 56px;
  display: flex;
  flex-wrap: wrap;

  ${mediaQuery} {
    width: 75%;
    font-size: 32px;
    letter-spacing: 0.15px;
    line-height: 28px;
  }
`;

export const Text = styled.div`
  margin-right: 8px
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding-bottom: 80px;
`;

export const DateContainer = styled.div`
  display: flex;
`;

export const BoldText = styled.p`
  font-weight: 600;
  margin-left: 8px;
`;

export const BoldTextItem = styled.div`
  font-weight: 600;
  width: auto;
  padding-right: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const ElementsFound = styled.div`
  margin: 32px 0 32px 0;
  width: 100%;
  color: rgba(0, 0, 0, 0.87);
  font-size: 24px;
  letter-spacing: 0.18px;
  line-height: 32px;

  ${mediaQuery} {
    font-size: 20px;
    letter-spacing: 0.15px;
    line-height: 28px;
  }
`;



