import React, { memo } from 'react';
import PropTypes from 'prop-types';

import {
    MainContainer,
    Title,
    ButtonContactUS,
} from './styles';

function CardOrganizeEvent(props) {
    const { lastWord, title, image, imageResponsive, url, color } = props;

    return (
        <MainContainer>
            <img src={image} alt="" className="hide-on-phone" />
            <img src={imageResponsive} alt="" className="hide-on-desktop" />
            <Title color={color}>{title}{lastWord}</Title>
            <ButtonContactUS href={url} target="_blank">
                Contáctanos
            </ButtonContactUS>
        </MainContainer>
    );
}

CardOrganizeEvent.propTypes = {
    lastWord: PropTypes.string,
    title: PropTypes.string,
    image: PropTypes.string,
    imageResponsive: PropTypes.string,
    url: PropTypes.string,
    color: PropTypes.string,
};

CardOrganizeEvent.defaultProps = {
    lastWord: '',
    title: '',
    image: '',
    imageResponsive: '',
    url: '',
    color: '#191919'
};

export default memo(CardOrganizeEvent);
