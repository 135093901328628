import styled from 'styled-components';
import { mediaQuery1320 } from '../../utils';

export const MainContainer = styled.div`
  display: flex;
  
  ${mediaQuery1320} {
    flex-direction: column;
  }
`;

export const RightContainer = styled.div`
  width: 100%;
  margin-left: 288px;
  height: calc(100vh - 80px);
  background-color: #FAFAFA;
  margin-top: 80px;
  padding: 56px 80px;
  
  ${mediaQuery1320} {
    margin-left: auto;
    margin-top: 203px;
    height: calc(100vh - 203px);
    padding: 22px 16px;
  }
`;

export const EmptyMainContainer = styled.div`
  width: 100%;
  text-align: center;

  & .phone-icon {
    margin-top: 150px;
    margin-bottom: 40px;
    height: 120px;
  }

  & .button-back {
    margin-top: 48px;
    font-weight: 600;
  }
`;

export const EmptyTitle = styled.div`
  color: #333333;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.18px;
  line-height: 32px;
  margin-bottom: 32px;
`;

export const EmptyDescription = styled.div`
  color: #666666;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 26px;
  text-align: center;
`;

export const ViewMore = styled.a`
  float: right;
  height: 24px;
  width: 63px;
  color: #7B00B2;
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.15px;
  line-height: 24px;
`;