import styled from 'styled-components';
import {mediaQuery} from '../../utils';

export const Container = styled.div`
  display: flex;
  justify-content: space-around;
  width: calc(100% - 96px);
  margin-left: 48px;
  flex-wrap: nowrap;
  overflow:hidden;
  margin-bottom: 64px;

  ${mediaQuery} {
    overflow-x: scroll;
    justify-content: flex-start;
    width: 100%;
    margin-left: inherit;
    margin-bottom: 15px;
  }
`;

export const CardContainer = styled.div`
  margin-right: 12px;
  margin-bottom: 8px;

  ${mediaQuery} {
    margin-right: 0;
    margin-left: 0;
  }
`;
