import React, {memo, useState, useEffect, useRef} from 'react';
import {
    MainContainer,
    TicketsContainer,
    ButtonsContainer,
    Title,
    TicketBody,
    PDFTicketsContainer,
    PDFTicketBody,
    Container,
} from './styles';
import Ticket from '../../components/Ticket';
import {GoogleCalendarIcon, CloseIconGray} from '../../Assets';
import {Button, message} from 'antd';
import {get} from 'lodash';
import https from '../../config/https';
import {useHistory} from 'react-router-dom';
import {Helmet} from "react-helmet";
import Axios from 'axios';

const TicketsView = (props) => {
    const {match} = props;
    const [showButtons] = useState(false);
    const [tickets, setTickets] = useState([]);
    const [disableDownload, setDisableDownload] = useState(false);

    const pdfTickets = useRef();
    const history = useHistory();

    useEffect(() => {
        fetchTickets();
    }, []);

    const fetchTickets = async () => {
        https.get(`/api/v1/sale/${get(match, 'params.basket', '')}`)
            .then(res => {
                let tempTickets = [];
                get(res, 'data', []).map(ticket => {
                    tempTickets.push({
                        img: get(ticket, 'cover_image', ''),
                        name: `${get(ticket, 'first_name', '')} ${get(ticket, 'last_name', '')}`,
                        event_name: get(ticket, 'title', ''),
                        date: get(ticket, 'date', ''),
                        hour: get(ticket, 'time', '0:00'),
                        place: get(ticket, 'location', ''),
                        type: get(ticket, 'type_ticket', ''),
                        qr: get(ticket, 'qr', ''),
                        cover_image_base64: get(ticket, 'cover_image_base64', ''),
                    })
                })
                setTickets(tempTickets);
            })
            .catch(
                //ERR
            )
            .finally(() => {
                sessionStorage.removeItem('paymentDetails');
            })
    }

    /* const printPDF = () => {
        setDisableDownload(true);
        const domElement = pdfTickets.current;
        html2canvas(domElement, {
            scrollY: 0,
            scrollX: 0,
            margin: 0,
            allowTaint: true,
            removeContainer: true,
            backgroundColor: null,
            scale: 2,
            useCORS: true,
        }).then(canvas => {
            window.scrollTo(0, 0);
            const contentDataURL = canvas.toDataURL("image/png");

            let position = 5;
            let imgWidth = 585;
            let pageHeight = 800;
            const doc = new jsPDF('p', 'mm', [imgWidth, pageHeight]);
            let imgHeight = (canvas.height * imgWidth) / canvas.width;
            const imgProps = doc.getImageProperties(contentDataURL);
            const pdfWidth = doc.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            let heightLeft = imgHeight;

            doc.addImage(contentDataURL, "PNG", 0, position, pdfWidth, pdfHeight, 'page', 'SLOW');
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                doc.addPage();
                doc.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight, 'page', 'SLOW');
                heightLeft -= pageHeight;
            }

            const pageCount = doc.internal.getNumberOfPages();
            doc.deletePage(pageCount);

            // window.open(doc.output("bloburl"), "_blank");
            doc.save(`${get(tickets[0], 'event_name', '')} tickets.pdf`);
        })
            .finally(() => {
                setDisableDownload(false);
                }
            );
    }; */

    const getTicketsPdf = async () => {
        Axios.get(`${process.env.REACT_APP_API_ENDPOINT_URL}/api/v1/sale/${get(match, 'params.basket', '')}/pdf`)
            .then(res => {
                if (res) {
                    window.open(`${process.env.REACT_APP_API_ENDPOINT_URL}/api/v1/sale/${get(match, 'params.basket', '')}/pdf`);
                } else {
                    throw Error();
                }
            })
            .catch( () => {
                    message.error({
                        content: 'La sesión caduco entre de nuevo a la plataforma para seguir con la compra',
                        duration: 6
                    });
                }
            )
    }

    const onClick = () => {
        if (history.length > 1) {
            history.goBack()
        } else {
            history.push('/');
        }
    };

    return (
        <MainContainer>
            <Helmet>
                <html lang="en"/>
                <title>Tickets View</title>
                <meta name="theme-color" content="#E6E6FA"/>
            </Helmet>
            <img src={CloseIconGray} alt="" className="close-icon" onClick={onClick}/>
            <PDFTicketsContainer ref={pdfTickets} id="tickets">
                {tickets.map((ticket, index) => (
                    <PDFTicketBody isLast={index === tickets.length}>
                        <Ticket data={ticket} textInsideImage={`Entrada ${index + 1} de ${tickets.length}`} insidePdf/>
                    </PDFTicketBody>
                ))}
            </PDFTicketsContainer>
            <Title>Mis entradas</Title>
            <Container>
                <TicketsContainer>
                    {tickets.map((ticket, index) => (
                        <TicketBody>
                            <Ticket data={ticket} textInsideImage={`Entrada ${index + 1} de ${tickets.length}`}/>
                        </TicketBody>
                    ))}
                </TicketsContainer>
                {tickets.length ? <ButtonsContainer>
                    <Button className="button-download" onClick={() => getTicketsPdf()} disabled={disableDownload}>
                        Descargar entradas
                    </Button>
                    {showButtons && (
                        <Button className="button-calendar">
                            <img src={GoogleCalendarIcon} alt=""/>
                            Añadir a Calendario
                        </Button>
                    )}
                </ButtonsContainer> : React.null}
            </Container>
        </MainContainer>
    )
};


TicketsView.propTypes = {};

TicketsView.defaultProps = {};

export default memo(TicketsView);