import React, { memo } from 'react';
import { MainContainer, Title, Description } from './styles';
import { HappyPhone } from '../../Assets';
import Button from "../../components/Button";
import ModalFullscreen from "../../components/ModalFullscreen";
import { useHistory } from "react-router-dom";
import { get } from 'lodash';
import { Helmet } from "react-helmet";

const RequiredLogin = (props) => {
    const { match } = props;
    const id = get(match, 'params.event', '');
    const history = useHistory();

    const redirectToLogin = () => {
        history.push(`/login?event=${id}`)
    }

    const redirectToEvent = () => {
        history.goBack();
    }

    return (
        <ModalFullscreen openModal onCloseModal={redirectToEvent}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Por Favor Inicia Sesion</title>
            </Helmet>
            <MainContainer>
                <img src={HappyPhone} alt="" className="phone-icon" />
                <Title>Por favor inicia sesión</Title>
                <Description>Para continuar con tu pedido inicia sesión</Description>
                <Description>o crea tu cuenta</Description>
                <Button
                    text="Continuar"
                    className="button-back"
                    onClick={redirectToLogin}
                />
            </MainContainer>
        </ModalFullscreen>
    )
};

export default memo(RequiredLogin);