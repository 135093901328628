import React, { memo, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  MainContainer,
  LeftContainer,
  RightContainer,
  RightContainerCard,
  EventInfoContainer,
  EventName,
  EventInfoText,
  ResumenContainer,
  ResumeBuy,
  ResumeEdit,
  CardAdmissionItem,
  CardAdmissionTotal,
  CardAdmissionItemPrice,
  BottomContainer,
  BottomLeftContainer,
  BottomRightContainer,
  TotalBottom,
  TotalBottomText,
  Title,
  TitleTop,
  TicketInfo,
  TicketName,
  TicketInputsContainer,
  CheckBoxContainer,
  TimerItem,
  TimerItemResponsive,
  EventInfoContainerResponsive,
  EventInfoResponsive,
  TitleResponsive,
  ResumeBuyResponsive,
  ResumeEditResponsive,
  ResumeContainerResponsive,
  CardAdmissionItemResponsive,
  CardAdmissionItemPriceResponsive,
  BackImage,
  EmailErrorContainer,
  ErrorText,
} from './styles';
import {
  UbicationIcon,
  ClockIcon,
  CalendarIcon,
  YellowTimer,
} from '../../Assets';
import Button from '../Button';
import { get } from 'lodash';
import InputText from '../Input';
import { Checkbox } from 'antd';
import App from './../FormCheckout/index';
import AppPayPal from './../AppPayPal';
import PaymentMethods from './../PaymentMethods';
import { ArrowBack } from '../../Assets';
import { formatHourText, getUserStoraged } from '../../utils';
import PriceWithDiscount from '../PriceWithDiscount';

const PaymentModalStep2 = (props) => {
  const user = getUserStoraged();
  const {
    onClickButton,
    onClickEdit,
    buyDetails,
    detailImage,
    nextStep,
    openNoTimeModal,
  } = props;
  const currentDate = new Date().getTime() + 10 * 60 * 1000;
  const [tickets, setTickets] = useState([]);
  const [isFilled, setFilled] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedParentIndex, setSelectedParentIndex] = useState(null);
  const [countDownTarget, setCountDownTarget] = useState(currentDate);
  const [allFieldsValidated, setAllFieldsValidated] = useState(false);
  const [tosChecked, setTosChecked] = useState(true);
  const [buyDetailsStep2, setBuyDetailsStep2] = useState(buyDetails);
  const [isPreventPaymentEnabled, setPreventPaymentEnabled] = useState(false);
  const [formattedDate, setFormattedDate] = useState(null);
  const [emailError, setEmailError] = useState([]);
  const [guestEmail, setGuestEmail] = useState('');

  const name = user && user.first_name;
  const lastName = user && user.last_name;
  const email = user && user.email;

  let counter = 0;

  const minRef = useRef(null);
  const minRefResponsive = useRef(null);
  const secRef = useRef(null);
  const secRefResponsive = useRef(null);

  useEffect(() => {
    if (!name) setTosChecked(false);
    const formatDate = () => {
      let tempDate = moment(get(buyDetails, 'originalDate', '')).format(
        'dddd, DD [de] MMMM [de] YYYY',
      );
      tempDate = tempDate.charAt(0).toUpperCase() + tempDate.substr(1);
      setFormattedDate(tempDate);
    };

    let countDownTarget = localStorage.getItem('countDownTarget');
    if (!countDownTarget) {
      localStorage.setItem('countDownTarget', currentDate);
    } else {
      setCountDownTarget(countDownTarget);
    }
    setTickets(buyDetails.admissionCount);
    let ticketsHelper = [...buyDetails.admissionCount];
    let allTicketsSet = true;
    ticketsHelper.forEach((tempTicket) => {
      let tempTicketHelper = get(tempTicket, 'clients', []);
      if (tempTicketHelper.length > 0) {
        tempTicketHelper.forEach((ticket) => {
          if (!ticket.name) allTicketsSet = false;
        });
      }
    });
    setAllFieldsValidated(allTicketsSet);

    formatDate();
    document.getElementsByClassName('ant-drawer-body')[0].scrollTop = 0;
  }, []);

  const setSelectedFilled = (parentIndex, childIndex, ev) => {
    setFilled(!isFilled);
    let ticketsHelper = [...tickets];
    if (!!ev.target.checked) {
      ticketsHelper[parentIndex].selected = true;
      ticketsHelper[parentIndex].clients[childIndex].name = name;
      ticketsHelper[parentIndex].clients[childIndex].lastName = lastName;
      ticketsHelper[parentIndex].clients[childIndex].email = email;
      setTickets(ticketsHelper);
      setBuyDetailsStep2({ ...buyDetails, admissionCount: ticketsHelper });
      setSelectedIndex(childIndex);
      setSelectedParentIndex(parentIndex);
    } else {
      ticketsHelper[parentIndex].selected = false;
      ticketsHelper[parentIndex].clients[childIndex].name = '';
      ticketsHelper[parentIndex].clients[childIndex].lastName = '';
      ticketsHelper[parentIndex].clients[childIndex].email = '';
      setTickets(ticketsHelper);
      setBuyDetailsStep2({ ...buyDetails, admissionCount: ticketsHelper });
      setSelectedIndex(null);
      setSelectedParentIndex(null);
    }
    let allTicketsSet = true;
    ticketsHelper.forEach((tempTicket) => {
      let tempTicketHelper = get(tempTicket, 'clients', []);
      if (tempTicketHelper.length > 0) {
        tempTicketHelper.forEach((ticket) => {
          if (!ticket.name) allTicketsSet = false;
        });
      }
    });
    setAllFieldsValidated(allTicketsSet);
  };

  const showClock = (target) => {
    const distance = target - new Date().getTime();
    const mins =
      distance < 0
        ? 0
        : Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const secs = distance < 0 ? 0 : Math.floor((distance % (1000 * 60)) / 1000);
    if (mins === 0 && secs === 0 && !sessionStorage.getItem('preventTimeout')) {
      openNoTimeModal();
    }

    // Output the results

    let minutesContainer = document.getElementById('minutes');
    let minutesText = (minutesContainer && minutesContainer.innerText) || '00';

    let secondsContainer = document.getElementById('seconds');
    let secondsText = (secondsContainer && secondsContainer.innerText) || '00';

    let tempMins = !isNaN(mins) ? '0' + mins : '0' + minutesText;
    let tempSecs = !isNaN(secs) ? '0' + secs : '0' + secondsText;

    tempMins =
      tempMins.length === 3 ? tempMins.substr(1, 3) : tempMins.substr(0, 2);
    tempSecs =
      tempSecs.length === 3 ? tempSecs.substr(1, 2) : tempSecs.substr(0, 2);

    if (minRefResponsive.current && minRef.current) {
      minRefResponsive.current.innerHTML = tempMins;
      minRef.current.innerHTML = tempMins;
    }
    if (secRef.current && secRefResponsive.current) {
      secRefResponsive.current.innerHTML = tempSecs;
      secRef.current.innerHTML = tempSecs;
    }
  };

  const confirmPayment = () => {
    localStorage.removeItem('countDownTarget');
    sessionStorage.setItem('preventTimeout', true);

    const isFree = get(detailImage, 'isFree', false);
    onClickButton(isFree, guestEmail.trim());
  };

  let timeInterval = setInterval(function () {
    showClock(countDownTarget);
    let isValidPayment = sessionStorage.getItem('validPayment') || false;
    if (isValidPayment) {
      clearInterval(timeInterval);
      nextStep();
    }
    if (countDownTarget - new Date().getTime() < 0) {
      localStorage.removeItem('countDownTarget');
      clearInterval(timeInterval);
    }
  }, 1000);

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email.trim()).toLowerCase());
  }

  const setValue = (parentIndex, childIndex, fieldName, event) => {
    if (
      !/^[a-zA-Z0-9 @~`áéíóúüñÑ!@#$%^&*()_=+\\\\';:\"\\/?>.<,-]*$/i.test(
        event.target.value,
      ) &&
      fieldName === 'email'
    ) {
      return;
    }

    if (
      !/^[a-zA-Z\sáéíóúÁÉÍÓÚ]*$/.test(event.target.value) &&
      (fieldName === 'name' || fieldName === 'lastName')
    ) {
      return;
    }

    if (
      fieldName === 'email' &&
      !validateEmail(event.target.value) &&
      !JSON.stringify(emailError).includes(
        JSON.stringify([parentIndex, childIndex]),
      )
    ) {
      let errors = emailError;
      errors.push([parentIndex, childIndex]);
      setEmailError(errors);
    }

    if (fieldName === 'email' && validateEmail(event.target.value)) {
      const newErrors = emailError.filter(
        (item) =>
          JSON.stringify(item) !== JSON.stringify([parentIndex, childIndex]),
      );
      setEmailError(newErrors);
    }

    if (fieldName === 'email' && event.target.value === '') {
      let errors = emailError;
      errors.splice(childIndex, 1);
      setEmailError(errors);
    }

    let tempTickets = [...tickets];
    let allTicketsSet = true;
    tempTickets[parentIndex].clients[childIndex][fieldName] =
      event.target.value;
    tempTickets.forEach((tempTicket) => {
      let tempTicketHelper = get(tempTicket, 'clients', []);
      if (tempTicketHelper.length > 0) {
        tempTicketHelper.forEach((ticket) => {
          if (!ticket.name) allTicketsSet = false;
        });
      }
    });
    setAllFieldsValidated(allTicketsSet);
    setTickets(tempTickets);
    setBuyDetailsStep2({ ...buyDetails, admissionCount: tempTickets });
  };

  const preventMultiplePayment = (value) => {
    setPreventPaymentEnabled(value);
  };

  const handleGuestEmailChange = (ev) => {
    setGuestEmail(ev.target.value);
    setValue(0, 0, 'email', ev);
  }
  return (
    <MainContainer>
      <div className="container">
        <LeftContainer>
          <TitleTop>
            <BackImage src={ArrowBack} onClick={onClickEdit} /> Información de
            las entradas
          </TitleTop>
          <TimerItemResponsive>
            <img src={YellowTimer} alt="" />
            <div className="text-time">
              Tiempo restante para finalizar compra:{' '}
              <p>
                <span ref={minRefResponsive}>10</span>:
                <span ref={secRefResponsive}>00</span>
              </p>
            </div>
          </TimerItemResponsive>
          <TitleResponsive>{get(detailImage, 'name', '')}</TitleResponsive>
          <EventInfoContainerResponsive>
            <EventInfoResponsive>
              <img src={UbicationIcon} alt="" className="info-icon" />
              {get(detailImage, 'place', '')}
            </EventInfoResponsive>
            <EventInfoResponsive>
              <img src={CalendarIcon} alt="" className="info-icon" />
              {formattedDate}
            </EventInfoResponsive>
            <EventInfoResponsive>
              <img src={ClockIcon} alt="" className="info-icon" />
              {get(buyDetails, 'hourName', '')}
            </EventInfoResponsive>
          </EventInfoContainerResponsive>
          <ResumeContainerResponsive>
            <ResumeBuyResponsive>Resumen de compra</ResumeBuyResponsive>
            <ResumeEditResponsive onClick={() => onClickEdit()}>
              Editar
            </ResumeEditResponsive>
          </ResumeContainerResponsive>
          {tickets.map((item) => {
            let count = get(item, 'count', 0);
            if (count === 0) {
              return;
            } else {
              return (
                <CardAdmissionItemResponsive
                  key={`${get(item, 'type', '')}${get(item, 'id', '')}`}
                >
                  {count} x {get(item, 'text', '')}
                  <CardAdmissionItemPriceResponsive>
                    {get(item, 'discounted_price', '') !== '' ? (
                      <PriceWithDiscount
                        discountPrice={count * item.discounted_price}
                        normalPrice={count * item.price}
                        smallText
                      />
                    ) : (
                      get(item, 'total', '0.00').toFixed(2)
                    )}
                  </CardAdmissionItemPriceResponsive>
                </CardAdmissionItemResponsive>
              );
            }
          })}
          <TicketInfo>
            {tickets.map((ticket, index) => {
              let ticketArray = [];
              for (let i = 0; i < ticket.count; i++) {
                counter++;
                ticketArray.push(
                  <div key={`${index}-${i}`}>
                    <TicketName>{`Ticket ${counter}: ${
                      ticket.text + ' ' + (i + 1)
                    }`}</TicketName>
                    <TicketInputsContainer>
                      <InputText
                        placeholder="Nombre"
                        className="input"
                        parentClass="w-49"
                        placeholderSize="14px"
                        value={ticket.clients[i].name}
                        onChange={(ev) => setValue(index, i, 'name', ev)}
                      />
                      <InputText
                        placeholder="Apellido"
                        className="input"
                        parentClass="w-49"
                        placeholderSize="14px"
                        value={ticket.clients[i].lastName}
                        onChange={(ev) => setValue(index, i, 'lastName', ev)}
                      />
                      {name && (
                        <EmailErrorContainer>
                          <InputText
                            placeholder="Correo electrónico (opcional)"
                            className="input"
                            parentClass="w-100"
                            placeholderSize="14px"
                            value={ticket.clients[i].email}
                            fullWidthOnResponsive={true}
                            onChange={(ev) => setValue(index, i, 'email', ev)}
                          />
                          {JSON.stringify(emailError).includes(
                            JSON.stringify([index, i]),
                          ) && <ErrorText>Inserte un correo válido</ErrorText>}
                        </EmailErrorContainer>
                      )}
                      {name &&
                      ((i === selectedIndex && index === selectedParentIndex) ||
                        selectedIndex == null) ? (
                        <CheckBoxContainer lineHeight="56px">
                          <Checkbox
                            className="check-box"
                            onClick={(ev) => setSelectedFilled(index, i, ev)}
                          >
                            Rellenar con mi información
                          </Checkbox>
                        </CheckBoxContainer>
                      ) : (
                        React.null
                      )}
                    </TicketInputsContainer>
                  </div>,
                );
              }
              return ticketArray;
            })}
            {!name && (
              <>
                <Title>¿A dónde enviamos tus entradas?</Title>
                <TicketInputsContainer>
                  <EmailErrorContainer>
                    <InputText
                      placeholder="Correo electrónico"
                      className="input"
                      parentClass="w-100"
                      placeholderSize="14px"
                      value={guestEmail}
                      fullWidthOnResponsive={true}
                      onChange={(ev) => handleGuestEmailChange(ev)}
                    />
                    {emailError.length ? <ErrorText>Inserte un correo válido</ErrorText> : ''}
                  </EmailErrorContainer>
                </TicketInputsContainer>
              </>
            )}
            {!get(detailImage, 'isFree', false) ? (
              <>
                <Title>Forma de pago</Title>
                {/*  <AppPayPal type='paypal' currency='MXN' amount={12} />
                                    <AppPayPal type='mercadopago' currency='MXN' amount={12} />*/}

                <App tickets={buyDetailsStep2} preventPayment={preventMultiplePayment} />
                {/*<PaymentMethods />*/}
              </>
            ) : (
              <div className="free-divider" />
            )}
            {!name && (
              <CheckBoxContainer marginBottom="48px">
                <Checkbox
                  className="check-box marginRight"
                  onClick={(ev) => setTosChecked(ev.target.checked)}
                />
                <div>
                  Acepto la{' '}
                  <a
                    href={process.env.REACT_APP_PRIVACY_POLICY}
                    target="_blank"
                  >
                    Política de Privacidad
                  </a>{' '}
                  de Guía De Hoy.
                </div>
              </CheckBoxContainer>
            )}
          </TicketInfo>
          {/*<CheckBoxContainer marginBottom="16px">*/}
        </LeftContainer>
        <RightContainer>
          <RightContainerCard>
            <img
              src={get(detailImage, 'images[0]', '')}
              alt=""
              className="card-image"
            />
            <EventName>{get(detailImage, 'name', '')}</EventName>
            <EventInfoContainer>
              <img src={UbicationIcon} alt="" className="card-icon" />
              <EventInfoText>{get(detailImage, 'place', '')}</EventInfoText>
            </EventInfoContainer>
            <EventInfoContainer>
              <img src={CalendarIcon} alt="" className="card-icon" />
              <EventInfoText>{formattedDate}</EventInfoText>
            </EventInfoContainer>
            <EventInfoContainer>
              <img src={ClockIcon} alt="" className="card-icon" />
              <EventInfoText>
                {formatHourText(get(buyDetails, 'hourName', ''))}
              </EventInfoText>
            </EventInfoContainer>
            <ResumenContainer>
              <ResumeBuy>Resumen de compra</ResumeBuy>
              <ResumeEdit onClick={() => onClickEdit()}>Editar</ResumeEdit>
            </ResumenContainer>
            {tickets.map((item) => {
              let count = get(item, 'count', 0);
              if (count === 0) {
                return;
              } else {
                return (
                  <CardAdmissionItem key={get(item, 'text', '')}>
                    {count} x {get(item, 'text', '')}
                    <CardAdmissionItemPrice>
                      {get(item, 'discounted_price', '') !== '' ? (
                        <PriceWithDiscount
                          discountPrice={count * item.discounted_price}
                          normalPrice={count * item.price}
                          smallText
                        />
                      ) : (
                        get(item, 'total', '0.00').toFixed(2)
                      )}
                    </CardAdmissionItemPrice>
                  </CardAdmissionItem>
                );
              }
            })}
            <CardAdmissionTotal>
              Total
              <CardAdmissionItemPrice>
                {get(buyDetails, 'total', '0.00')}
              </CardAdmissionItemPrice>
            </CardAdmissionTotal>
          </RightContainerCard>
        </RightContainer>
      </div>
      <BottomContainer>
        <BottomLeftContainer>
          {get(detailImage, 'isFree', false) ? (
            <TotalBottom>Gratis</TotalBottom>
          ) : (
            <>
              <TotalBottom>{get(buyDetails, 'total', '0.00')}</TotalBottom>
              <TotalBottomText>Total</TotalBottomText>
            </>
          )}
        </BottomLeftContainer>
        <BottomRightContainer>
          <TimerItem>
            <img src={YellowTimer} alt="" />
            <div className="text-time">
              Tiempo restante para finalizar compra:{' '}
              <p>
                <span ref={minRef}>10</span>:<span ref={secRef}>00</span>
              </p>
            </div>
          </TimerItem>
          <Button
            text="Confirmar compra"
            className="button-confirm"
            onClick={confirmPayment}
            disabled={
              !allFieldsValidated ||
              !tosChecked ||
              isPreventPaymentEnabled ||
              (!name && !guestEmail) ||
              (!name && emailError.length)
            }
          />
        </BottomRightContainer>
      </BottomContainer>
    </MainContainer>
  );
};
PaymentModalStep2.propTypes = {
  onClickButton: PropTypes.func,
  onClickEdit: PropTypes.func,
  buyDetails: PropTypes.object,
  openNoTimeModal: PropTypes.func,
};

PaymentModalStep2.defaultProps = {
  onClickButton: () => {},
  onClickEdit: () => {},
  buyDetails: {},
  openNoTimeModal: () => {},
};

export default memo(PaymentModalStep2);
