import styled from 'styled-components';
import {mediaQuery1320} from '../../utils';

export const HeaderContainer = styled.div`
  position: ${props => props.fixedHeader ? 'fixed' : 'inherit'};
  width: ${props => props.fixedHeader ? '100%' : 'inherit'};
  z-index: ${props => props.fixedHeader ? '99' : 'inherit'};
  align-items: center;
  background: #fff;
  display: flex;
  height: 80px;
  margin: auto;
  // padding: 0 4%;
  border-bottom: ${props => props.headerBorder ? '1px solid #C5C3C6' : 'none'};

`;

export const LogoItem = styled.div`
  & .image {
    width: 140px;
    margin: 0 32px 0 80px;
    cursor: pointer;
  }

  ${mediaQuery1320} {
    .image {
      width: 140px;
      margin: 0 32px 0 24px;
    }
  }
`;

export const MenuItemGroup = styled.div`
  display: flex;
  flex-direction: row;
`;

export const MenuItem = styled.a`
  cursor: pointer;
  color: rgba(0,0,0,0.87);
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 24px;
  margin: 0 32px;
  margin-right: auto;
  text-decoration: none;

  a {
    color: #404040;
  }

  ${mediaQuery1320} {
    display: none;
    cursor: inherit;
  }


`;

export const DropMenuItem = styled.div`
  cursor: pointer;
  display: flex;
  color: white;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 24px;
  height: 35px!important;
  margin-top: 10px;
  
  a {
    color: #404040;
  }

  ${mediaQuery1320} {
    display: none;
    cursor: inherit;
  }

`;

export const DropdownText = styled.a`
    cursor: pointer  !important;
    color: rgba(0,0,0,0.87)!important;
    font-size: 16px!important;
    letter-spacing: 0.15px!important;
    line-height: 24px!important;
    text-decoration: none!important;
`

export const UserItemGroup = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 104px;

  & .dropdown {
    margin-left: 8px;
  }

  ${mediaQuery1320} {
    cursor: inherit;
  }

  .hide-icon-on-desktop {
    position: absolute;
    right: -88px !important;
  }

`;

export const UserInfoCard = styled.span`
  display: flex;
  align-items: center;
`;

export const UserItemName = styled.div`
  color: #404040;
  font-size: 16px;
  font-weight: bold;
  margin: 0 24px;

  ${mediaQuery1320} {
    display: none;
  }
`;

export const UserItemAvatar = styled.div`
  border-radius: 50%;
  height: 40px;
  overflow: hidden;
  width: 40px;

  & img {
    height: 40px;
    width: 40px;
  }

  ${mediaQuery1320} {
    display: none;
  }
`;

export const DropdownMenu = styled.div`
  position: absolute;
  top: 34px;
  right: -60px;
  width: fit-content;
  background: #fff;
  text-align: center;
  border: 1px solid #c5c3c6;
  border-radius: 5px;
  padding: 10px;
  z-index: 1000000;

  ${mediaQuery1320} {
    height: 100vh;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    border: 0;
    border-radius: 0;
    color: black;
    display: flex;
    flex-direction: column;
    padding: 0px;
    justify-content: flex-start;
    align-items: center;
  }

  ul > li {
    list-style: none;
  }
`;

export const TopContainer = styled.div`
  width: 100%;
  background: #40006E;
  height: 120px;
  min-height: fit-content;
  flex-direction: row;
  display: flex;
  padding: 40px 85px 16px 24px;
  justify-content: flex-start;
  color: white;
`
export const BottomContainer = styled.div`
  padding-top: 16px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: white;
`

export const DropdownOption = styled.div`
  border-bottom: ${props => props.lastItem ? 'none' : '1px solid #e8e8e8'};
  padding: 4px 0;
  line-height: 24px;
  display: flex;
  justify-content: center;

  :hover {
    color: #B361CF;
    
    & .icon-no-hover {
      display: none;
    }
    
    & .icon-hover {
      display: inherit;
    }
  }

  & .icon-no-hover {
    display: inherit;
  }

  & .icon-hover {
    display: none;
  }
  
  & .icon {
    margin-right: 8px;
  }
  
  & .text {
    margin-top: 2px;
  }
`;

export const CloseMenu = styled.span`
  position: absolute;
  right: 16px;
  top: 20px;
  font-size: 24px;
  cursor: pointer;

  & img {
    width: 24px;
    height: 24px;
  }
`;

export const MenuItemResponsive = styled.div`
  padding-left: 24px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.15px;
  line-height: 24px;
  display: flex;
  cursor: pointer;
  margin-top: 24px;
  & img {
    width: 24px;
    height: 24px;
    margin-right: 16px;
    object-fit: fill;
  }
`;

export const TitleMenuResponsive = styled.div`
  position: relative;
  color: #FFFFFF;
  font-family: Rubik;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.19px;
  line-height: 28px;
  display: flex;
  padding-left: 12px;
  align-items: center;
  & div {
    margin-left: 4px;
    border-bottom: 1px solid #ffffff;
    cursor: pointer;
  }
`;

export const UserItemAvatarResponsive = styled.div`
  display: none;
  & img {
    ${mediaQuery1320} {
      background-color: #ffffff;
      height: 64px;
      width: 64px;
      border-radius: 50%;
    }
  }

  ${mediaQuery1320} {
    display: inherit;
    position: relative;
    height: 64px;
    width: 64px;
  }
`;

export const EndLine = styled.div`
  box-sizing: border-box;
  height: 1px;
  margin-top: 23.5px;
  background-color: #E5E5E5;
`;