import styled from 'styled-components';
import {mediaQuery} from '../../utils';

export const MainContainer = styled.div`
  display: flex;
  padding-top: 80px;
`;

export const Image = styled.img`
  width: 60%;

  ${mediaQuery} {
    display: none;
  }
`;

export const LeftContainer = styled.div`
  width: 40%;

  ${mediaQuery} {
    width: 100%;
  }
`;

export const Title = styled.div`
  height: 40px;
  color: rgba(0,0,0,0.87);
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 40px;
  margin: 100px 10% 58px 10%;

  ${mediaQuery} {
    font-size: 28px;
    letter-spacing: 0;
    line-height: 36px;
    margin: 32px 16px 60px 16px;
  }
`;

export const ButtonContainer = styled.div`
  margin-bottom: 0;
  width: 100%;
  padding: 0 10%;
  margin-top: 40px;

  ${mediaQuery} {
    width: 100%;
    margin-top: 40px;
    padding: 0 16px;
  }

  .button-create {
    width: 100%;
    max-width: 392px;
    height: 48px;
    padding: 0;

    ${mediaQuery} {
      max-width: 100%;
    }
  }
`;

export const InputContainer = styled.div`
  margin: 40px 0;
  width: 100%;
  padding: 0 10%;

  ${mediaQuery} {
    width: 100%;
    padding: 0 16px;
  }
`;

export const Desing = styled.div`
  margin: 0 10%;
  height: max-content;

  ${mediaQuery} {
    margin: 0 16px;
  }

  .input {
    height: 52px;
    width: 100%;
    max-width: 392px;
    border: 1px solid #EDEDF3;
    border-radius: 8px;

    ${mediaQuery} {
      max-width: 100%;
    }
  }

  & .ant-form-item-explain.ant-form-item-explain-error {
    max-width: 392px;

    ${mediaQuery} {
      max-width: 100%;
    }
  }
`;


export const TextInput = styled.input`
  height: 52px;
  line-height:52px;
  border-radius: 5px;
  border: 1.3px solid #dfdfea;
  padding: 0 10px;
  width: 100%;
  max-width: 392px;

  ::placeholder {
    color: #84839c;
    line-height: 52px;
    font-size: 14px;
  }

  :focus {
    border: 1.3px solid #dfdfea;
    outline: none !important;
  }
  
  ${mediaQuery} {
    width: 100%;
    max-width: 100%;
  }
`;