import React, {memo} from 'react';
import {
    MainContainer,
    MenuOption,
    BottomContainer,
    MenuOptionMarker,
} from './styles';
import PropTypes from 'prop-types';
import {
    ResponsiveMainContainer,
    ResponsiveMenuOption,
    ResponsiveTitle,
    ResponsiveOptionsContainer
} from './responsiveStyles';
import {TicketEnabled, TicketDisabled, HistoryEnabled, HistoryDisabled, LogoutGray, MyProfile, MyProfileActive} from '../../Assets';
import {useHistory} from 'react-router-dom';

function Menu(props) {
    const history = useHistory();
    const route = window.location.href.replace(`${process.env.REACT_APP_PUBLIC_URL}`, '');

    const signOut = () => {
        localStorage.clear();
        history.push('/login');
        window.location.reload();
    }
    const {title} = props;
    const {showResponsive} = props;
    return (
        <>
            <MainContainer className="hide-on-phone1320">
            <MenuOption isActive={route.includes('mi-perfil')}
                            onClick={() => history.push('mi-perfil')}>
                    <MenuOptionMarker isActive={route.includes('mi-perfil')}/>
                    <img src={route.includes('mi-perfil') ? MyProfileActive : MyProfile} alt=""
                         className="icon"/>
                    Mi perfil
                </MenuOption>
                <MenuOption isActive={route.includes('siguientes-eventos')}
                            onClick={() => history.push('siguientes-eventos')}>
                    <MenuOptionMarker isActive={route.includes('siguientes-eventos')}/>
                    <img src={route.includes('siguientes-eventos') ? TicketEnabled : TicketDisabled} alt=""
                         className="icon"/>
                    Próximos eventos
                </MenuOption>
                <MenuOption isActive={route.includes('eventos-pasados')}
                            onClick={() => history.push('eventos-pasados')}>
                    <MenuOptionMarker isActive={route.includes('eventos-pasados')}/>
                    <img src={route.includes('eventos-pasados') ? HistoryEnabled : HistoryDisabled} alt=""
                         className="icon"/>
                    Historial de compras
                </MenuOption>
                <BottomContainer>
                    <MenuOption onClick={() => signOut()}>
                        <img src={LogoutGray} alt="" className="icon"/>
                        Cerrar sesión<br/>
                    </MenuOption>
                </BottomContainer>
            </MainContainer>
            { showResponsive &&
                <ResponsiveMainContainer className="hide-on-desktop1320MW">
                    <ResponsiveTitle pwa={localStorage.getItem('pwa')}>{title}</ResponsiveTitle>
                </ResponsiveMainContainer>
            }
        </>
    );
}
Menu.propTypes = {
    title: PropTypes.string,
    showResponsive: PropTypes.bool
  };
  
Menu.defaultProps = {
    title: '',
    showResponsive: true
};
export default memo(Menu);
