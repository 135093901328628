import React, {memo, useEffect} from 'react';
import GlobalStyles from '../../GlobalStyles';
import { MainContainer } from './styles';
import EmptyFood from '../../components/EmptyFood';
import { EmptyFoodImage, EmptyFoodImageResponsive } from '../../Assets';
import { Helmet } from "react-helmet";
function Food() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <MainContainer>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Comida</title>
            </Helmet>
            <GlobalStyles/>
            <div className="empty-desktop">
                <EmptyFood
                    title="Pronto encontrarás
                               lo mejor de"
                    lastWord="comida"
                    image={EmptyFoodImage}
                />
            </div>
            <div className="empty-responsive">
                <EmptyFood
                    title="Pronto encontrarás
                               lo mejor de"
                    lastWord="comida"
                    image={EmptyFoodImageResponsive}
                />
            </div>
        </MainContainer>
    );
}

export default memo(Food);
