import React, {memo} from 'react';
import GlobalStyles from '../../GlobalStyles';
import {
    BodyAboutResponsive,
} from './styles';
import BannerApp from '../../components/BannerApp';
import {
    VasAOrganizar,
    VasAOrganizarResponsive,
    OrganizaUnEvento,
    AnunciateConNosotros,
    BuscasAnunciarte,
    BuscasAnunciarteResponsive,
    BannerAppImage,
    BannerAppImageResponsive,
    AboutusBanner,
    AboutusMobileBanner,
    AboutUsVideo,
    AboutUsVideoResponsive,
    HowToBuyVideo
    
} from '../../Assets';
// import DialogFirstLogin from '../../components/DialogFirstLogin';
import DiscoverPlan from '../../components/DiscoverPlan';
import PhraseContainer from '../../components/PhraseContainer';
import AdvantagesGroup from '../../components/AdvantagesGroup';
import DoubtsContainer from '../../components/DoubtsContainer';
import {advantages_data, 
    instructions_data, doubts_data, social_media_data, doubts_topics_data, doubts_questions_data
       }from './data';
import ContactUs from '../../components/ContactUs';
import UsBanner from '../../components/UsBanner';
import HowToBuyContainer from '../../components/HowToBuyContainer';

function AboutUs() {
    return (
        <div style={{overflowX: 'hidden'}}>
            
            <GlobalStyles backgroundWhite={true}/>
            <>
            <DiscoverPlan
                title="Tu mejor plan comienza aquí."
                image = {AboutUsVideo}
                buttonText = "Descubre un Plan"
                imageResponsive = {AboutUsVideoResponsive}
            />
            <PhraseContainer phrase="Somos la plataforma ideal para disfrutar cada parte de tu ciudad."/>
            <AdvantagesGroup 
                titleMobile="Usar Guía de Hoy es"
                titleDesktop="Usar Guía de Hoy es"
                data={advantages_data}/>
            <HowToBuyContainer
                title="¿Cómo comprar?"
                data={instructions_data}
                // video="//www.youtube.com/embed/jFGKJBPFdUA?autoplay=1"
                video="//www.youtube.com/embed/cN9B4QOn63U?autoplay=1"
                />
            <DoubtsContainer 
                title="¿Tienes dudas?"
                data={doubts_data}
                topics={doubts_topics_data}
                questions={doubts_questions_data}
                />
            <UsBanner
                 titleOrganize="¿Vas a organizar un evento?"
                 imageOrganize={OrganizaUnEvento}
                 urlOrganize="/eventos"
                 titleSearch="¿Buscas anunciarte con nosotros?"
                 imageSearch={AnunciateConNosotros}
                 urlSearch="/anunciate"
            />
            <ContactUs
                title="Escríbenos o llámanos, estamos para ayudarte"
                description="Nuestro equipo esta listo para ayudarte, comunícate con nosotros."
                subtitle="Síguenos en nuestras redes sociales"
                data={social_media_data}
            />
            {/* <BodyAboutResponsive >
                <CardOrganizeEvent
                image={VasAOrganizar}
                imageResponsive={VasAOrganizarResponsive}
                title="¿Vas a organizar un "
                lastWord="evento?"
                color="#FFFFFF"
                url="https://form.typeform.com/to/g5BzNZtl"/>
                <br />
                <br />
                <CardOrganizeEvent
                image={BuscasAnunciarte}
                imageResponsive={BuscasAnunciarteResponsive}
                title="¿Buscas "
                lastWord="anunciarte?"
                url="https://form.typeform.com/to/jDHUs9Vo"/>
            </BodyAboutResponsive> */}
           
                {!localStorage.getItem('pwa') &&
                <BannerApp
                    image={BannerAppImage}
                    imageResponsive={BannerAppImageResponsive}
                    title="¡Llévanos contigo! "
                    subtitle="Descarga la app"
                    urlAppStore=""
                    urlGooglePlay=""
                />} 

            </>
        </div>
    );
}

export default memo(AboutUs);
