import React, {memo, useState} from 'react';
import PropTypes from 'prop-types';
import {get} from 'lodash';
import {
    TicketContainer,
    ImageTextContainer,
    TextInsideImage,
    EventInfoContainer,
    TicketOwner,
    EventName,
    EventDataContainer,
    EventData,
    TicketQRContainer,
    LeftCircle,
    RightCircle,
} from './styles';
import Share from '../Share';
import { formatHourText } from '../../utils';


const Ticket = (props) => {
    const {data, textInsideImage, insidePdf} = props;
    const [openSharePopup, setOpenSharePopup] = useState(false);

    
    return (
        <TicketContainer onClick={() => setOpenSharePopup(false)}>
            <ImageTextContainer>
                <img src={`data:image/png;base64,${get(data, 'cover_image_base64', '')}`} alt="" className="event-img"/>
                <TextInsideImage>{textInsideImage}</TextInsideImage>
            </ImageTextContainer>
            <EventInfoContainer>
                <TicketOwner>{get(data, 'name', '')}</TicketOwner>
                <EventName>{get(data, 'event_name', '')}</EventName>
                <EventDataContainer>
                    <EventData>
                        <div className="title">Fecha</div>
                        <div className="subtitle">{get(data, 'date', '')}</div>
                    </EventData>
                    <EventData>
                        <div className="title">Hora</div>
                        <div className="subtitle">{get(data, 'hour', '')}</div>
                    </EventData>
                    <EventData>
                        <div className="title">Lugar</div>
                        <div className="subtitle">{get(data, 'place', '')}</div>
                    </EventData>
                    <EventData>
                        <div className="title">Tipo</div>
                        <div className="subtitle">{get(data, 'type', '')}</div>
                    </EventData>
                </EventDataContainer>
            </EventInfoContainer>
            <TicketQRContainer>
                <img src={get(data, 'qr', '')} alt="" className="qr"/>
                {!insidePdf && (
                    <div className="share-icon">
                        <Share
                            urlText={`Aquí está tu entrada para *${get(data, 'event_name', '')}* el ${get(data, 'date', '')} a las ${formatHourText(get(data, 'hour', ''))} `}
                            url={`${get(data, 'qr', '')}`}
                            onlyIcon
                            onlyWhatsapp
                            onClickButton={() => setOpenSharePopup(!openSharePopup)}
                            isOpen={openSharePopup}
                        />
                    </div>
                )}
            </TicketQRContainer>
            <LeftCircle/>
            <RightCircle/>
        </TicketContainer>
    )
};


Ticket.propTypes = {
    data: PropTypes.object,
    textInsideImage: PropTypes.string,
    insidePdf: PropTypes.bool,
};

Ticket.defaultProps = {
    data: {},
    TextInsideImage: '',
    insidePdf: false,
};

export default memo(Ticket);