import styled from 'styled-components';
import {mediaQuery} from '../../utils';

export const MainContainer = styled.div`
  display: flex;
  padding-top: 80px;
`;

export const Image = styled.img`
  width: 60%;

  ${mediaQuery} {
    display: none;
  }
`;

export const LeftContainer = styled.div`
  width: 40%;
  
  ${mediaQuery} {
    width: 100%;
  }
`;

export const Title = styled.div`
  height: 40px;
  color: rgba(0,0,0,0.87);
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 40px;
  margin: 100px 10% 58px 10%;

  ${mediaQuery} {
    font-size: 28px;
    letter-spacing: 0;
    line-height: 36px;
    margin: 32px 16px 60px 16px;
  }
`;

export const Description = styled.div`
  width: 100%;
  margin: 0px 10% 40px 10%;
  color: #636363;
  font-size: 18px;
  letter-spacing: 0.14px;
  line-height: 26px;
  max-width: 392px;
  
  ${mediaQuery} {
    color: #636363;
    font-size: 16px;
    letter-spacing: 0.12px;
    line-height: 24px;
    margin: 0 16px 24px 16px;
  }
`;

export const ButtonContainer = styled.div`
  margin-bottom: 0;
  width: 100%;
  padding: 0 10%;
  margin-top: 40px;

  ${mediaQuery} {
    width: 100%;
    margin-top: 40px;
    padding: 0 16px;
  }
  
  .button-create {
    width: 100%;
    max-width: 392px;
    height: 48px;
    padding: 0;
    
    ${mediaQuery} {
      max-width: 100%;
    }
  }
`;

export const Desing = styled.div`
  margin: 0 10%;
  height: max-content;

  ${mediaQuery} {
    margin: 0 16px;
  }
  
  .input {
    height: 52px;
    width: 100%;
    max-width: 392px;
    border: 1px solid #EDEDF3;
    border-radius: 8px;

    ${mediaQuery} {
      max-width: 100%;
    }
  }
`;
