import React, {memo} from 'react';
import PropTypes from 'prop-types';
import EventGrouper from '../EventGrouper';
import RecommendationCard from '../RecommendationCard';
import {get} from 'lodash';
import { useHistory } from "react-router-dom";
import {ItemContainer, Items} from './styles';
import { useMatomo } from '@datapunt/matomo-tracker-react';

function RecommendationsCarrousel(props) {
    const {recommendationsGroup} = props;
    const history = useHistory();
    const { trackEvent } = useMatomo();

    function getParameterByName(url, name) {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(url);
        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    }

    const goToRecomendation = (item) => {
        var idRecomendation = getParameterByName(item.url, 'recommendationId'); 
        trackEvent({category: "Recomendaciones", action: 'click', name: `Id ${idRecomendation}`})
        history.push(get(item, 'url', '/'));
    }
    return (
        <EventGrouper
            title={recommendationsGroup.title}
            path='/recomendaciones'
            isRecommendations
            events={
                <ItemContainer id={recommendationsGroup.title}>
                    {recommendationsGroup.cardsInfo.length !== 0 && recommendationsGroup.cardsInfo.map((item, index) => {
                        return index < 7 ?
                            <Items
                                key={index}
                                onClick={() =>  goToRecomendation(item)}
                                style={{ cursor: 'pointer'}}
                            >
                                <RecommendationCard {...item} />
                            </Items> : null
                    })}
                </ItemContainer>
            }>
        </EventGrouper>
    );
}

RecommendationsCarrousel.propTypes = {
    recommendationsGroup: PropTypes.object,
};

RecommendationsCarrousel.defaultProps = {
    recommendationsGroup: {},
};

export default memo(RecommendationsCarrousel);
