import React, {memo, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Drawer} from 'antd';
import { ChildrenContainer} from './styles';
import {CloseIconGray} from "../../Assets";

const ModalFullscreen = (props) => {
    const {openModal, onCloseModal, children} = props;

    const [isOpen, setIsOpen] = useState(openModal);

    useEffect(() => {
        setIsOpen(openModal);
    }, [openModal])

    return (
        <Drawer
            placement={"bottom"}
            closable={false}
            visible={isOpen}
            onClose={onCloseModal}
            key={"bottom"}
            width={"100%"}
            height={"100%"}
            style={{zIndex: 999998}}
        >
            <ChildrenContainer>
                <img src={CloseIconGray} alt="" className="close-icon" onClick={onCloseModal}/>
                {children}
            </ChildrenContainer>
        </Drawer>
    )
};


ModalFullscreen.propTypes = {
    openModal: PropTypes.bool,
    onCloseModal: PropTypes.func,
    children: PropTypes.any,
};

ModalFullscreen.defaultProps = {
    openModal: false,
    onCloseModal: () => {},
    children: <div/>,
};

export default memo(ModalFullscreen);