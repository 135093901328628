import {
    IconAnnounceAlcance,
    IconAnnouncePublicidad,
    IconAnnounceContent,
    IconAnnounceMercados,
    IconAnnounceAsesoria,
    IconAnnouncePaquetes,
    Facebook,
    Instagram,
    LinkedIn,
    Spotify,
    Tiktok,
    Twitter,
    Youtube,
    OurClientsFirst,
    OurClientsFirstResponsive,

} from '../../Assets';

export const advantages_data = [
    {'id':'1', 'icon': IconAnnounceAlcance, 'title':'Alcance','description':  'Contamos con más de 40k seguidores en redes sociales.'},
    {'id':'2', 'icon': IconAnnouncePublicidad, 'title':'Posicionamiento','description':  'Gran impacto a través de todos nuestros canales.'},
    {'id':'3', 'icon': IconAnnounceContent, 'title':'Contenido de calidad','description':  'Nuestro equipo captará la esencia de tu marca.'},
    {'id':'4', 'icon': IconAnnounceMercados, 'title':'Mercado local','description':  'Llegamos e impactamos a tu público ideal.'},
    {'id':'5', 'icon': IconAnnounceAsesoria, 'title':'Atención personalizada','description':  'Recibirás el seguimiento de tu campaña.'},
    {'id':'6', 'icon': IconAnnouncePaquetes,'title':'Paquetes a tu medida','description': 'Tenemos servicios que se adaptan a tus necesidades. '},
]

export const instructions_data = [
    {'number': '1', 'title': 'Contacta a alguno de nuestros asesores', 'description':
      `Puedes comunicarte con nosotros a través de nuestras redes sociales o da click <a href="https://wa.me/526674718532">aquí</a> para ir a Whatsapp.`
    },
    {'number': '2', 'title': 'Elige el mejor paquete para tí', 'description':
      `Tenemos distintos servicios que se adaptan a tu negocio. Si necesitas algo especial, estaremos encantados de ayudarte.`
    },
    {'number': '3', 'title': 'Efectúa tu pago', 'description':
      `Al acordar tu paquete te daremos nuestra información bancaria y realizaremos tu factura si la necesitas.`
    },
    {'number': '4', 'title': 'Recibe al equipo Guía de Hoy', 'description':
      `Te asignaremos una fecha para hacer la visita de levantamiento de contenido de tu marca, después empezaremos con la difusión.`
    },
    {'number': '5', 'title': 'Evalúa tu desempeño', 'description':
      `Al final recibirás un informe con las métricas y resultados de tu campaña con nosotros.`
    },
]

export const doubts_topics_data = [
    {'id' : '1', 'title' : 'Pagos y costos'},
    {'id' : '2', 'title' : 'Servicio'},
    {'id' : '3', 'title' : 'Paquetes'},
    {'id' : '4', 'title' : 'Resultados'},
]

export const doubts_questions_data = [
    //Pagos y costos
    {'id' :  '1', 'foreignId' : '1', 'title': '¿Cómo puedo pagar mi paquete?', 'text': `
    Mediante una transferencia bancaria, un depósito o en efectivo el día de tu sesión.
    `},
    {'id' :  '2', 'foreignId' : '1', 'title': '¿Recibiré una factura?', 'text': `
    Claro, facturamos todos nuestros servicios.
    `},
    // Servicio
    {'id' :  '3', 'foreignId' : '2', 'title': '¿Puedo usar el contenido en mis redes sociales?', 'text': `
    Si tu paquete lo incluye recibirás el contenido con la mejor calidad en tu correo electrónico listo para subirse.
    `},
    {'id' :  '4', 'foreignId' : '2', 'title': '¿Cuánto dura mi sesión de fotos? ', 'text': `
    Depende de la cantidad y del tipo de contenido que se va a levantar. En promedio trabajamos con tu marca 1 hora.
    `},
    {'id' :  '5', 'foreignId' : '2', 'title': '¿Con qué canales cuentan para dar difusión? ', 'text': `
    Estamos en redes sociales como <a href="https://www.instagram.com/guiadehoycln/" target="_blank" >instagram</a>, <a href="https://www.facebook.com/guiadehoymx/" target="_blank" >facebook</a>, <a href="https://www.tiktok.com/@guiadehoy" target="_blank" >tiktok</a>,  <a href="https://www.youtube.com/channel/UCVDtf1lfz6Qnc6THN_DLaaQ" target="_blank" >youtube</a> y <a href="https://twitter.com/guiadehoycln" target="_blank" >twitter</a>. También contamos con newsletter y nuestra plataforma web  
    `},
    // Paquetes
    {'id' :  '6', 'foreignId' : '3', 'title': '¿Qué tipos de paquetes manejan?', 'text': `
    Tenemos paquetes ya establecidos y dependiendo de tu objetivo que van desde un giveaway hasta una campaña de lanzamiento o posicionamiento de marca.
    `},
    {'id' :  '7', 'foreignId' : '3', 'title': '¿Me pueden hacer un paquete personalizado?', 'text': `
    Claro, siempre podemos armar un paquete especial de acuerdo a lo que tu necesites.
    `},
    // Resultados
    {'id' :  '8', 'foreignId' : '4', 'title': '¿Cuándo recibiré las métricas sobre mi paquete?', 'text': `
    Al concluir con tu campaña.
    `},
    {'id' :  '9', 'foreignId' : '4', 'title': '¿Generaré nuevos seguidores?', 'text': `
    Es posible que obtengas seguidores gracias al alcance que tenemos.
    `},
    {'id' :  '10', 'foreignId' : '4', 'title': '¿Qué métricas recibiré?', 'text': `
    Tu reporte incluye las métricas del alcance, impresiones, crecimiento e interacciones.
    `},

]

export const our_clients_data = [
    {"id": 1, "image": OurClientsFirst, "imageResponsive": OurClientsFirstResponsive, "title": "PizzaHut lanzamiento para apertura de sucursal ", "description": "El trabajo en general fue muy bueno, las fotografías, la atención con la marca, lo publicado en redes sociales, cada uno de esos puntos cumplimento al 100 % su función, lo que me hace volver a querer trabajar con guía de hoy, así como los resultados obtenidos.",
     "author": "Martha" }

]

export const social_media_data = [
    {'number': '1', 'icon': Facebook, 'url_direction':'https://www.facebook.com/guiadehoymx/'},
    {'number': '2', 'icon': Twitter, 'url_direction':'https://twitter.com/guiadehoymx'},
    {'number': '3', 'icon': Instagram, 'url_direction':'https://www.instagram.com/guiadehoycln/'},
    {'number': '4', 'icon': Spotify, 'url_direction':'https://open.spotify.com/user/gotgwg9ue4zwdlecv15ivl3bv?si=yKDItPaGSVWVp-uRHp8KxQ'},
    {'number': '4', 'icon': Youtube, 'url_direction':'https://www.youtube.com/channel/UCVDtf1lfz6Qnc6THN_DLaaQ"'},
    {'number': '4', 'icon': LinkedIn, 'url_direction':'https://www.linkedin.com/company/guia-de-hoy/'},

]
