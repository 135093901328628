import styled from 'styled-components';
import {mediaQuery} from '../../utils';

export const ItemContainer = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;
  margin-bottom: 64px;

  ${mediaQuery} {
    width: 103%;
    overflow-x: scroll;
    margin-bottom: 15px;
  }
`;

