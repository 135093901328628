import React, { memo,  } from 'react';
import { Collapse, Tabs } from 'antd';
import 'moment/locale/es-mx';
import PropTypes from 'prop-types';
import {
    MainContainer,
    DesktopContainer,
    MobileContainer,
    TopContainer,
    BottomContainer,
    LeftContainer,
    RightContainer,
    Title,
    Description,
    Instruction,
    CircleNumber,
    TabContent,
    TabNumber,
    Subtitle,
    ImageExample,
    InstructionTitle
} from './styles';
import StylesPanel from './stylesPanel'; 
import {ArrowDownIcon } from '../../Assets';
const { Panel } = Collapse;
const { TabPane } = Tabs;


function HowToBuyContainer(props) {
    const {title, data, style, video} = props;
    return (
      <>
      <StylesPanel/>
        <MainContainer color={style}>
            <Title>{title}</Title>
            <DesktopContainer>
            <LeftContainer>
            <Collapse
                bordered={false}
                defaultActiveKey={['1']}
                expandIcon={({ isActive }) => isActive ? <img src={ArrowDownIcon} style={{transform : "rotate(180deg)"}} className="arrow"/> : <img src={ArrowDownIcon}/>}
                className={style == "secondary" ? "site-collapse-custom-collapseWhiteVersion" : "site-collapse-custom-collapse"}
                expandIconPosition="right"
                accordion
                >
                {data.map((instruction => 
                    
                        <Panel header={<Instruction> <CircleNumber colors={style}>{instruction.number}</CircleNumber> <InstructionTitle>{instruction.title}</InstructionTitle> </Instruction>} key={instruction.number} className="site-collapse-custom-panel">
                        <Description><div dangerouslySetInnerHTML={{__html: instruction.description}} /></Description>
                        </Panel>
                  
                ))}
            </Collapse>
            </LeftContainer>
            <RightContainer>
            <ImageExample>
                <iframe width="100%" height="400rem" src={video}></iframe>
            </ImageExample>
            </RightContainer>
            </DesktopContainer>
            <MobileContainer type={style}>
                    <TopContainer>
                    <ImageExample>
                        <iframe width="100%" height="250rem" src={video}></iframe>
                    </ImageExample>
                    </TopContainer>
                    <BottomContainer>
                    <Tabs defaultActiveKey="1">
                       {data.map((instruction =>
                         <TabPane tab={<TabNumber>{instruction.number}</TabNumber>} key={instruction.number}>
                             <TabContent>
                                <Subtitle>{instruction.title}</Subtitle>
                                <Description><div dangerouslySetInnerHTML={{__html: instruction.description}} /></Description>
                             </TabContent>
                         </TabPane>
                        ))}
                    </Tabs>
                    </BottomContainer>
            </MobileContainer>
        </MainContainer>
        
        </>
    );
}
 HowToBuyContainer.propTypes = {
    title: PropTypes.string,
    data: PropTypes.array,
    style: PropTypes.string,
    video: PropTypes.string
  };
  
   HowToBuyContainer.defaultProps = {
    title: '',
    data: {},
    style: "",
    video: "",
  };
export default memo(HowToBuyContainer);
