import React, {memo, useState} from 'react';
import {LoginImage} from '../../Assets';
import Button from '../../components/Button/index';
import {Form, Input, message} from 'antd';
import {
    passwordRecoveryRequest,
} from '../../services/AccountServices';

import {
    MainContainer,
    ButtonContainer,
    LeftContainer,
    Image,
    Title,
    Description, Desing,
} from './styles';
import {useHistory} from "react-router-dom";
import { Helmet } from "react-helmet";

function RecoveryPassword() {
    const history = useHistory();
    const [form] = Form.useForm();
    const [buttonEnabled, setButtonEnabled] = useState(false);

    const sendMail = () => {
        form.validateFields().then(values => {
            let payload = {email: values.email};
            passwordRecoveryRequest(payload)
                .then(res => {
                    if (res.data) {
                        message.success({content: 'Correo de recuperación enviado.', duration: 5});
                    }
                    history.push('/login');
                })
                .catch(err => {
                    message.error({
                        content: 'Ha ocurrido un error al solicitar la contraseña, favor de comunicar al administrador.',
                        duration: 5
                    });
                    history.push('/login');
                })
        }).catch(() => {
            // TODO sin mensaje
        });
    }

    const handleEmailChange = ev => {
        setButtonEnabled(!!ev.target.value)
    }

    return (
        <MainContainer id="login-container">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Recuperar Contraseña</title>
            </Helmet>
            <LeftContainer>
                <Title>¿Olvidaste tu contraseña?</Title>
                <Description>Ingresa el correo electrónico que utilizaste para crear tu cuenta.</Description>
                <Desing>
                    <Form
                        layout="vertical"
                        form={form}
                        initialValues={{
                            email: "",
                        }}
                        onFinish={() => sendMail()}
                        scrollToFirstError
                        name="advanced_search"
                        className="ant-advanced-search-form"
                    >
                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor ingresa tu correo',
                                },
                                {
                                    type: 'email',
                                    message: 'Por favor ingresa un correo válido'
                                }
                            ]}
                        >
                            <Input
                                placeholder="Correo electrónico"
                                className="input"
                                autoComplete="new-password"
                                onChange={ev => handleEmailChange(ev)}
                                maxLength={50}
                            />
                        </Form.Item>
                    </Form>
                </Desing>
                <ButtonContainer>
                    <Button text="Enviar correo" className="button-create" onClick={() => sendMail()} disabled={!buttonEnabled}/>
                </ButtonContainer>
            </LeftContainer>
            <Image src={LoginImage} alt=""/>
        </ MainContainer>
    );
}

export default memo(RecoveryPassword);
