import React, {memo, useState, useEffect} from 'react';
import {MainContainer, RightContainer, EmptyMainContainer, EmptyDescription, ViewMore} from './styles';
import CrudService from "../../services/CrudService";
import PastEventsTable from '../../components/PastEventsTable';
import Menu from '../../components/Menu';
import {CheckingPhone} from '../../Assets';
import Button from '../../components/Button';
import https from '../../config/https';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import moment from 'moment';
import { formatHourText } from '../../utils';
import { Helmet } from "react-helmet";

const PastEvents = () => {
    const user = JSON.parse(localStorage.getItem('d'));
    const token = user && user.access_token;
    const [tableData, setTableData] = useState([]);
    const [isResponsiveMode, setResponsiveMode] = useState(false);
    const [page, setPage] = useState(1);
    const [remaining, setRemaining] = useState(0);
    const history = useHistory();
    const [showInfo, setShowInfo] = useState(false);

    window.onresize = reportWindowSize;

    useEffect(() => {
        fetchTableitems(page);
        setResponsiveMode(window.screen.width <= 810);
    }, []);

    const fetchTableitems = async (page) => {
        const params = {
            page,
            per_page:10
        };
            https.get('/api/v1/sale/past-events', params, {headers: {Authorization: `Bearer ${user.access_token}`}})
            .then(res => {
                const elementsHelper = [...tableData];
                const response = get(res, 'cardInfo', []);
                setPage(res.next_page);
                setRemaining(get(res, 'remaining', 0));
                response.forEach((element, index) => {
                    elementsHelper.push({
                        key: index,
                        image: element.cover_image,
                        event: element.event,
                        place: element.location,
                        date: `${moment(element.event_date).format('DD/MMM/YYYY')} ${formatHourText(element.time)}`,
                        purchaseDate: moment(element.purchase_date).format('DD/MMM/YYYY'),
                        total: element.sale_amount
                    })
                })
                setTableData(elementsHelper);
            })
            .finally(() => {
                setShowInfo(true);
            })
    };

    function reportWindowSize() {
        setResponsiveMode(window.window.innerWidth <= 1320);
      }

    const onClickButton = () => {
        history.push('/');
    }
    
    return (
        <MainContainer>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Tus Compras</title>
            </Helmet>
            <Menu title="Historial de compra"/>
            <RightContainer isEmpty={!tableData.length}>
                {tableData.length ? <PastEventsTable data={tableData} isResponsive={isResponsiveMode}/> :
                    <EmptyMainContainer>
                        {showInfo && <div>
                            <img src={CheckingPhone} alt="" className="phone-icon"/>
                            <EmptyDescription>Aquí podrás ver el historial de tus</EmptyDescription>
                            <EmptyDescription>compras, ¡crea tu plan ahora!</EmptyDescription>
                            <Button
                                text="Explorar"
                                className="button-back"
                                onClick={onClickButton}
                            />
                        </div>}
                    </EmptyMainContainer>
                }
            { remaining > 0 ? <ViewMore onClick={()=> fetchTableitems(page)}>Ver más</ViewMore> : React.null }
            </RightContainer>
        </MainContainer>
    )
};


PastEvents.propTypes = {};

PastEvents.defaultProps = {};

export default memo(PastEvents);