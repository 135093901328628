import styled from 'styled-components';
import { mediaQuery } from '../../utils';

export const MainContainer = styled.div`
  width: 100%;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  letter-spacing: 0.15px;
  line-height: 24px;


`;

export const BottomContainer = styled.div`
  padding: 32px 80px;
  background-color: #E9E1EE;
  display: flex;
  justify-content: space-between;

  ${mediaQuery} {
    padding: 32px 25px;
    display:flex;
    flex-direction: column-reverse;
  }
`;

export const TopContainer = styled.div`
  height: auto;
  background-color: #F5F1F7;
  padding: 80px;
  position: relative;
  display: flex;
  justify-content: space-between;


  & img {
    margin-bottom: 40px;
  }

  ${mediaQuery} {
    flex-direction: column;
    padding: 40px 25px;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  ${mediaQuery} {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    p {
      margin-bottom: 0;
    }
    .separator{
      display: none;
    }
  }

  
`;

export const RightContainer = styled.div`
  display: flex;

  & a {
    text-decoration: none;
    margin-right: 32px;
  }

  .icon-social {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }

  ${mediaQuery} {
    a {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-right: 0;
    }
  }
`;

export const LineSeparator = styled.div`
  margin: 0px 10px;
`;

export const LinkConditions = styled.a`
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
`;

export const MiddleContainer = styled.div`
  width: 100%;

  .first-link {
    margin-top: 80px;
    margin-bottom: 16px;

    ${mediaQuery} {
      margin-top: 40px;
    }
  }

  .link-container {
    margin-bottom: 16px;
  }

  .contact-us {
    display: flex;
    height: 40px;
  }

  & img {
    width: 24px;
    height: 24px;
    margin-right: 16px;
  }
`;

export const Title = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.18px;
  line-height: 38px;
`;

export const LabelLink = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 24px;
  cursor: pointer;

  &:hover {
    color: #B361CF;
  }
`;

export const LabelRedirect = styled.a`
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 24px;
`;

export const ContactUs = styled.div`
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.15px;
  line-height: 24px;
  margin-bottom: 24px;
  margin-top: 80px;

  ${mediaQuery} {
    margin-top: 40px;
  }
`;











