import styled from 'styled-components';
import { mediaQuery, mediaQuery1150, mediaQuery1320 } from '../../utils';

export const MainContainer = styled.div`
  display: flex;
  ${mediaQuery} {
    flex-direction: column;
  }
`;

export const Title = styled.div`
  color: #40006e;
  font-family: Gotham;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
  height: auto;
`;

export const SubTitle = styled.div`
  color: #333333;
  width: 290px;
  font-family: Rubik;
  font-size: 16px;
  letter-spacing: 0.12px;
  line-height: 26px;
  margin: 35px 0;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 350px;
  position: relative;
  margin-bottom: 40px;
  ${mediaQuery} {
    margin-top: 0;
    margin: 0, auto;
    height: 100%;
    border-right: none;
  }

  & .content {
    max-width: 392px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .login-label {
    color: rgba(0, 0, 0, 0.87);
    font-family: Rubik;
    font-size: 32px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 40px;
    margin-bottom: 40px;
    width: 395px;
  }

  .social-options {
    width: 395px;

    ${mediaQuery} {
      width: 100%;
    }
  }

  .social-options > a {
    width: 100%;
    justify-content: center;
  }

  .social-options > a > img {
    margin-bottom: 0;
    max-width: fit-content;
  }

  .social-options > a > span {
    color: #666666;
    font-family: Rubik;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.8px;
    line-height: 24px;
  }

  .social-options > a > img {
    margin-right: 16px;
  }

  & .social-button {
    width: 56px;
    height: 56px;
  }

  & .divider {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 40px;
  }

  & .divider span {
    padding: 0 24px;
    color: #999999;
    font-size: 18px;
    flex-shrink: 0;
  }

  & .divider .line {
    height: 2px;
    background-color: #f2f2f2;
    width: 100%;
  }

  ${mediaQuery} {
    width: 100%;
  }
`;

export const Button = styled.a`
  display: flex;
  align-items: center;
  margin-right: 2rem;
  padding: 12px 20px;

  box-sizing: border-box;
  height: 49.4px;
  border: 1.4px solid #e9e1ee;
  border-radius: 8px;
  background-color: #ffffff;
  margin-bottom: 16px;

  :hover {
    opacity: 0.9;
  }

  .lastImage {
    margin-left: 0.3rem;
    margin-top: 6px;
  }
`;
