const dev = {
  apiKey: 'AIzaSyAMY63IP-W6TTFIvzPjyHhetPNmhHgL4DI',
  authDomain: 'guia-de-hoy-dev.firebaseapp.com',
  projectId: 'guia-de-hoy-dev',
  storageBucket: 'guia-de-hoy-dev.appspot.com',
  messagingSenderId: '674279453000',
  appId: '1:674279453000:web:93d57ca3424f5fb3f7c37d',
  measurementId: 'G-60J4WGTDZ0',
};

const prod = {
  apiKey: 'AIzaSyC9YHiP92xC3O-VvrGr2fNBj-0mXEuY0Ek',
  authDomain: 'guiadehoy-cms.firebaseapp.com',
  projectId: 'guiadehoy-cms',
  storageBucket: 'guiadehoy-cms.appspot.com',
  messagingSenderId: '400933220385',
  appId: '1:400933220385:web:01f2f19a108b99b3a9b360',
  measurementId: 'G-C6FPP2TBKZ',
};

const getEnv = () => {
  switch (process.env.REACT_APP_NODE_ENV) {
    case 'development':
      return prod;
    case 'test':
      return prod;
    case 'beta':
      return prod;
    case 'main':
      return prod;
    default:
      return prod;
  }
};

const env = prod;
// const env = getEnv();

export default env;
