import styled from 'styled-components';
import {mediaQuery} from '../../utils';

export const ItemContainer = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;
  margin-bottom: 64px;
  
  ${mediaQuery} {
    overflow-x: scroll;
    margin-bottom: 15px;
  }
`;

export const Items = styled.div`
  margin: 24px 0 0 48px;
  
  ${mediaQuery} {
    margin: 24px 6px;
  }
`;

