import styled from 'styled-components';
import { mediaQuery, mediaQuery1150, mediaQuery1320 } from '../../utils';

export const MainContainer = styled.div`
  display: flex;
  ${mediaQuery} {
    padding: 10px;
    flex-direction: column;
  }
`;

export const LeftContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 578px;
  margin-top: 50px;
  position: relative;
  margin-bottom: 32px;
  border-right: 1px solid #ccc;
  ${mediaQuery} {
    align-items: flex-start;
      width: 100%;
     flex-direction: column;
      margin-top: 0;
      margin: 0, auto;
      height: 100%;
      border-right: none;
  }
  
  & .content {
    max-width: 392px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .login-label {
    color: rgba(0,0,0,0.87);
    font-family: Rubik;
    font-size: 32px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 40px;
    margin-bottom: 40px;
    text-align: center;
    ${mediaQuery} {
      text-align: left;
    }
  }

  .social-options {
    width: 395px;

    ${mediaQuery} {
      width: 100%;
    }
  }

  .social-options > a {
    width: 100%;
    justify-content: center;
  }

  .social-options > a > span {
    color: #666666;
    font-family: Rubik;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.8px;
    line-height: 24px;
  }

  .social-options > a > img {
    margin-right: 16px;
  }

  & .social-button {
    width: 56px;
    height: 56px;
  }

  & .divider {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 40px;
  }

  & .divider span {
    padding: 0 24px;
    color: #999999;
    font-size: 18px;
    flex-shrink: 0;
  }

  & .divider .line {
    height: 2px;
    background-color: #F2F2F2;
    width: 100%;
  }

  ${mediaQuery} {
    width: 100%;
  }
`;

export const RightContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 50%;
  margin-top: 50px;
  margin-bottom: 40px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  ${mediaQuery} {
    align-items: flex-start;
  }

  & .title {
    width: 392px;
    height: auto;
    color: rgba(0,0,0,0.87);
    font-family: Rubik;
    font-size: 32px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 40px;
    margin-bottom: 32px;
    ${mediaQuery} {
      margin-bottom: 16px;
      width: 100%;
    }
  }

  .subtitle {
    color: #666;
    width: 392px;
    font-family: Rubik;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;

    ${mediaQuery} {
      width: 100%;
    }
  }

  .icons-container {    
    width: 392px;
    display: flex;
    justify-content: space-between;
    text-align: center;
    ${mediaQuery} {
      width: 100%;
    }
  }

  .icons-container > div >  img {
    margin: 24px 0 16px 0;
  }

  .icons-title {
    width: 100px;
    color: #666666;
    font-family: Rubik;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
  }

  & .button-full-width {
    margin: 32px 0 48px;
    width: 392px;

    ${mediaQuery} {
      width: 100%;
    }
  }

  & .button-full-width-inverted {
    margin-top: 32px;
    width: 392px;
    background: #E9E1EE;
    color: #7B00B2;

    ${mediaQuery} {
      width: 100%;
    }
  }

  ${mediaQuery} {
    width: 100%;
  }
`

export const Button = styled.a`
  display: flex;
  align-items: center;
  margin-right: 2rem;
  padding: 12px 20px;
  
  box-sizing: border-box;
  height: 49.4px;
  border: 1.4px solid #E9E1EE;
  border-radius: 8px;
  background-color: #FFFFFF;
  margin-bottom: 16px;

  :hover{
    opacity: 0.9;
  }

  .lastImage {
    margin-left: .3rem;
    margin-top: 6px;
  }
  `
