import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";

export function EmptyLayout({children}) {
  return <div>
    {children}
  </div>;
}

EmptyLayout.propTypes = {
  children: PropTypes.any,
};


export default connect(null, null)(EmptyLayout);
