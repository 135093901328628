import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`

  @import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;800;900&family=Rubik:wght@800&display=swap');
  
  
  * {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
  }

  html {
    box-sizing: border-box;
    font-size: 62.5%;
  }

  body {
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
    line-height: 1.6;
    font-size: 1.6rem;
    background: ${props => props.backgroundWhite ? "#FFFFFF" : "F9F9F9"}!important;
    color: #333;

  }

  .body-dashboard {
    padding: 64px 0 32px;
  }

  .containerSearch {
    background-color: white;
  }

  .searchText {
    margin: 0 80px;
    @media only screen and (max-device-width: 810px) {
      margin: 0 16px;
    }
  }

  .gothamText {
    font-family: Gotham !important;
}

  @media only screen and (max-device-width: 810px) {
    .body-dashboard {
      max-width: 100%;
      padding: 32px 11px 32px 0;
    }
  }

`;

export default GlobalStyles;
