import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useElements,
  useStripe
} from "@stripe/react-stripe-js";
import "./style.css";
import { get } from 'lodash';
import https, { getToken } from '../../config/https';
import { message } from "antd";
import axios from 'axios';

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#a2a2a2",
      color: "#000",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#000"
      },
      "::placeholder": {
        color: "#a2a2a2",
        fontSize: "15px"
      }
    },
    invalid: {
      iconColor: "red",
      color: "red"
    }
  }
};

const CardField = ({ onChange }) => (
  <div className="FormRow">
    <CardElement options={CARD_OPTIONS} onChange={onChange} />
  </div>
);

const Field = ({
  label,
  id,
  type,
  placeholder,
  required,
  autoComplete,
  value,
  onChange,
  maxLength
}) => (
  <div className="FormRow">
    <input
      className="FormRowInput"
      id={id}
      aria-label={label}
      type={type}
      maxLength={maxLength}
      placeholder={placeholder}
      required={required}
      autoComplete={autoComplete}
      value={value}
      onChange={onChange}
    />
  </div>
);

const SubmitButton = ({ processing, error, children, disabled, tickets }) => (<button
  className={`SubmitButton ${error ? "SubmitButton--error" : ""}`}
  type="submit"
  disabled={processing || disabled}
  id='stripe-payment'
  style={{ display: 'none' }}
>
  {processing ? "Processing..." : children}
</button>
);

const ErrorMessage = ({ children }) => (
  <div className="ErrorMessage" role="alert" style={{ color: 'red' }}>
    <svg width="16" height="16" viewBox="0 0 17 17">
      <path
        fill="#FFF"
        d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
      />
      <path
        fill="#6772e5"
        d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
      />
    </svg>
    {children}
  </div>
);

const ResetButton = ({ onClick }) => (
  <button type="button" className="ResetButton" onClick={onClick}>
    <svg width="32px" height="32px" viewBox="0 0 32 32">
      <path
        fill="#FFF"
        d="M15,7.05492878 C10.5000495,7.55237307 7,11.3674463 7,16 C7,20.9705627 11.0294373,25 16,25 C20.9705627,25 25,20.9705627 25,16 C25,15.3627484 24.4834055,14.8461538 23.8461538,14.8461538 C23.2089022,14.8461538 22.6923077,15.3627484 22.6923077,16 C22.6923077,19.6960595 19.6960595,22.6923077 16,22.6923077 C12.3039405,22.6923077 9.30769231,19.6960595 9.30769231,16 C9.30769231,12.3039405 12.3039405,9.30769231 16,9.30769231 L16,12.0841673 C16,12.1800431 16.0275652,12.2738974 16.0794108,12.354546 C16.2287368,12.5868311 16.5380938,12.6540826 16.7703788,12.5047565 L22.3457501,8.92058924 L22.3457501,8.92058924 C22.4060014,8.88185624 22.4572275,8.83063012 22.4959605,8.7703788 C22.6452866,8.53809377 22.5780351,8.22873685 22.3457501,8.07941076 L22.3457501,8.07941076 L16.7703788,4.49524351 C16.6897301,4.44339794 16.5958758,4.41583275 16.5,4.41583275 C16.2238576,4.41583275 16,4.63969037 16,4.91583275 L16,7 L15,7 L15,7.05492878 Z M16,32 C7.163444,32 0,24.836556 0,16 C0,7.163444 7.163444,0 16,0 C24.836556,0 32,7.163444 32,16 C32,24.836556 24.836556,32 16,32 Z"
      />
    </svg>
  </button>
);

const CheckoutForm = ({ tickets, preventPayment }) => {
  const user = JSON.parse(localStorage.getItem('d'));
  const email = user && user.email
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [billingDetails, setBillingDetails] = useState({
    email,
    phone: "",
    name: ""
  });

  const handleSubmitOxxo = async (event) => {
    event.preventDefault();
    const key = 'updatable';
    message.loading({ content: 'Procesando pago por oxxo...', key });

    let postPayload = {
      tickets: []
    };

    tickets.admissionCount.forEach(ticket => {
      let tempClients = get(ticket, 'clients', []);
      tempClients.forEach(client => {
        postPayload.tickets.push({
          id_hour: tickets.hour,
          id_day: tickets.day,
          id_ticket: ticket.id,
          email: client.email,
          first_name: client.name,
          last_name: client.lastName
        })
      })
    });

    preventPayment(true);

    const payTicketsOxxo = async () => {
      let res;
      try {
        // Create intent Pay
        res = await axios.post(process.env.REACT_APP_API_ENDPOINT_URL + '/api/v1/sale/pay', { basket: tickets.basket, payment_method: 'oxxo' }, {
          headers: {
            'Authorization': `Bearer ${getToken()}`,
          }
        })
      } catch (err) {

        console.log(err.response.data.errorCode);
        message.error({
          content: err.response.data.message,
          key,
          duration: 6
        })
        return;
      }

      // Get client secret for intent
      message.success({
        content: 'Pago realizado con éxito, dame unos segundos estoy preparando todo una experiencia para ti',
        key,
        duration: 4
      });
      const clientSecret = get(res.data, 'client_secret', '');

      // Pass intent pay to checkout form
      const payload = await stripe
        .confirmOxxoPayment(
          clientSecret,
          {
            payment_method: {
              billing_details: {
                name: "Carlos Alexis Galaviz Rosas",
                email: "carlosalexis.galavizrosas@gmail.com"
              }
            }
          }
        )

      if (payload.error) {
        sessionStorage.removeItem('preventTimeout');
        setError(payload.error);
        preventPayment(false);
        message.error({ content: 'Hubo un error con la información de pago, por favor confirma todos los datos' });
      } else {
        const reservedTickeds = await https.post('/api/v1/sale/paid-reserved-tickets', {
          ...postPayload,
          basket: tickets.basket
        });
        preventPayment(false);
        sessionStorage.removeItem('paymentDetails');
        sessionStorage.setItem('paymentDetails', JSON.stringify({
          ...reservedTickeds,
          email: email || tickets.admissionCount[0].clients[0].email,
          basket: tickets.basket
        }));
        sessionStorage.setItem('validPayment', true);
        setPaymentMethod(payload.paymentMethod);
      }
    }
    payTicketsOxxo();
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    const key = 'updatable';
    message.loading({ content: 'Procesando pago...', key });
    let validClients = true;
    tickets.admissionCount.forEach(ticket => {
      let tempClients = get(ticket, 'clients', []);
      tempClients.forEach(client => {
        if (!client.name || !client.lastName) validClients = false;
      })

    });

    if (!validClients) {
      setError({ message: "Nombre y/o apellidos faltantes." });
      return;
    } else {
      setError(null);
    }
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (error) {
      elements.getElement("card").focus();
      return;
    }

    if (cardComplete) {
      setProcessing(true);
    }

    let postPayload = {
      tickets: []
    };

    tickets.admissionCount.forEach(ticket => {
      let tempClients = get(ticket, 'clients', []);
      tempClients.forEach(client => {
        postPayload.tickets.push({
          id_hour: tickets.hour,
          id_day: tickets.day,
          id_ticket: ticket.id,
          email: client.email,
          first_name: client.name,
          last_name: client.lastName
        })
      })
    });
    preventPayment(true);

    const payTickets = async () => {
      let res;
      try {

        let payload = { basket: tickets.basket }

        let headers = {
          'Authorization': `Bearer ${getToken()}`,
        }
        if (!user) {
          payload.send_sale_info_to_email = tickets.admissionCount[0].clients[0].email;
          headers = undefined;
        }

        // Create intent Pay
        res = await axios.post(process.env.REACT_APP_API_ENDPOINT_URL + '/api/v1/sale/pay', payload, {
          headers: headers
        })
      } catch (err) {

        console.log(err.response.data.errorCode);
        message.error({
          content: err.response.data.message,
          key,
          duration: 6
        })
        return;
      }

      // Get client secret for intent
      message.success({
        content: 'Pago realizado con éxito, dame unos segundos estoy preparando todo una experiencia para ti',
        key,
        duration: 4
      });
      const clientSecret = get(res.data, 'client_secret', '');

      // Pass intent pay to checkout form
      const payload = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      });

      setProcessing(false);

      if (payload.error) {
        sessionStorage.removeItem('preventTimeout');
        setError(payload.error);
        preventPayment(false);
        message.error({ content: 'Hubo un error con la información de pago, por favor confirma todos los datos' });
      } else {
        const reservedTickeds = await https.post('/api/v1/sale/paid-reserved-tickets', {
          ...postPayload,
          basket: tickets.basket
        });

        preventPayment(false);
        sessionStorage.removeItem('paymentDetails');
        sessionStorage.setItem('paymentDetails', JSON.stringify({
          ...reservedTickeds,
          email: email || tickets.admissionCount[0].clients[0].email,
          basket: tickets.basket
        }));
        sessionStorage.setItem('validPayment', true);
        setPaymentMethod(payload.paymentMethod);
      }
    }
    payTickets();
  };

  const reset = () => {
    setError(null);
    setProcessing(false);
    setPaymentMethod(null);
    setBillingDetails({
      email: "",
      phone: "",
      name: ""
    });
  };

  return paymentMethod ? (
    <div className="Result">
      <div className="ResultTitle" role="alert">
        Payment successful
      </div>
      <div className="ResultMessage">
        Thanks for trying Stripe Elements. No money was charged, but we
        generated a PaymentMethod: {paymentMethod.id}
      </div>
      <ResetButton onClick={reset} />
    </div>
  ) : (
    <form className="Form" onSubmit={handleSubmit}>
      <div className="buy-text">Te recomendamos utilizar tarjetas digitales. Todas las compras son seguras
        y están encriptadas.
      </div>
      <fieldset className="FormGroup">
        <Field
          id="name"
          maxLength={60}
          type="text"
          placeholder="Nombre completo"
          label="Nombre completo"
          required
          autoComplete="name"
          value={billingDetails.name}
          onChange={(e) => {
            setBillingDetails({ ...billingDetails, name: e.target.value });
          }}
        />
      </fieldset>
      <fieldset className="FormGroup">
        <CardField
          onChange={(e) => {
            setError(e.error);
            setCardComplete(e.complete);
          }}
        />
      </fieldset>
      {error && <ErrorMessage>{error.message}</ErrorMessage>}
      <div className="problem-text">¿Tienes problemas con tu compra? Llámanos estamos para ayudarte</div>
      <a href="tel:667 471 8532" className="problem-text-phone">667 471 8532</a>
      <SubmitButton processing={processing} error={error} disabled={!stripe} tickets={tickets} />
    </form>
  );
};

const ELEMENTS_OPTIONS = { 
  fonts: [
    {
      cssSrc: "https://fonts.googleapis.com/css?family=Roboto"
    }
  ]
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const App = (props) => {
  const { tickets, preventPayment } = props;
  return (
    <div className="AppWrapper">
      <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
        <CheckoutForm tickets={tickets} preventPayment={preventPayment} />
      </Elements>
    </div>
  );
};

export default App;