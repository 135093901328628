import styled from 'styled-components';
import {mediaQuery, mediaQuery1150} from '../../utils';

export const MainContainer = styled.div`
  width: 100%;
  height: fit-content;
  ${mediaQuery}{
    display: flex;
    height: fit-content;
    padding: 0px 0px 64px 0px;
    background-color: #FFFFFF;
  }
`

export const DesktopContainer = styled.div`
  width: 100%;
  height: fit-content;
  background-color: #FFFFFF;
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 0px  0px 0px 0px;
  ${mediaQuery}{
    display: none;
  }
`;

export const MobileContainer = styled.div`
  display: none;
  ${mediaQuery}{
    width: 100%;
    margin-top: 64px;
    padding: 0px 24px;
    display: block;
    
    .ant-collapse-header{
      padding: 0px!important;
    }
    
    .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px;
    }


  }

`
export const LeftContainer = styled.div`
    min-height: 100% ;
    display: flex;
    flex-direction: column;
    flex: 0 1 calc(36%);
    padding: 5% 5% 7.2% 5.63%;
    width: 100%;
    background-color: #F4F4F4;
    ${mediaQuery}{
      background-color: #FFFFFF;
        padding: 8% 6% 0% 9% ;
        min-height: 176px;
    }
`;

export const RightContainer = styled.div`
    min-height: 100%;
    display: flex;
    flex: 0 1 calc(64%);
    background-color: #F9F9F9!important;
    align-items: top;
    padding: 5% 13% 4% 7%;
    width: 100%;
    
    ${mediaQuery}{
      background-color: #FFFFFF;
        display: inline-block;
        padding: 10px 24px 30px 24px;
        width: 100%;
    }
`;

export const Instruction = styled.div`
  padding: 1px 0px 5px 0px;
  display: flex;
`;


export const Title = styled.div`
  color: #40006E;
  font-family:  Gotham;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 0.3px;
  line-height: 48px;
  padding-bottom:5% ;
  text-align: left;
  ${mediaQuery1150}{
    font-size: 3rem;
  }
  ${mediaQuery}{
    color: #40006E;
    font-family:  Gotham;
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 0.21px;
    line-height: 36px;
    padding-bottom: 27px;
  }
`;

export const TitleTopic = styled.div`
  color: #1263B8;
  font-family: Rubik;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0.21px;
  line-height: 28px;
  padding: 19px 0px 19px 0px;
`

export const TitleCollapse = styled.div`
      color: #191919;
      font-family: Rubik;
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 0.17px;
      line-height: 28px;
      padding: 5px 0px 8px 0px;
      ${mediaQuery}{
          font-family: Rubik;
          font-size: 16px;
          letter-spacing: 0.15px;
          line-height: 24px;
          padding: 19px 50px 16px 0px;;
        }
`;


export const Description = styled.p`
 color: #7E7E7E;
  font-family: Rubik;
  font-size: 18px;
  letter-spacing: 0.17px;
  line-height: 26px;
  text-align: left;
  ${mediaQuery}{
    display: ${props => props.responsive ? 'none' : '' };
    font-family: Rubik;
    font-size: 14px;
    letter-spacing: 0.17px;
    line-height: 26px;
  }
`



export const SectionsContainer = styled.div`
  height: 100%;
  width: 100%;
  margin-top: 47px;
`

export const TopicsContainer = styled.div`
.ant-collapse-header{
      padding: 10px 0px 10px 0px!important;
    }
    
    .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px;
    }
`