import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {Table} from 'antd';
import {MainContainer} from './styles';

function PastEventsTable(props) {
    const {data, isResponsive = false} = props;

    const cellStyle = {
        color: '#666',
        fontFamily: 'Rubik',
        fontSize: '14px',
        letterSpacing: 0.13,
        lineHeight: '22px',
    }

    const cellStyleResponsive = {
        color: '#666',
        fontFamily: 'Rubik',
        fontSize: '12px',
        letterSpacing: 0.13,
        lineHeight: '20px',
    }


    const PriceStyle = {
        color: '#000',
        fontFamily: 'Rubik',
        fontSize: '14px',
        fontWeight: 600,
        letterSpacing: 0.13,
        lineHeight: '22px',
    }

    const PriceStyleResponsive = {
        color: '#000',
        fontFamily: 'Rubik',
        fontSize: '12px',
        fontWeight: 600,
        letterSpacing: 0.13,
        lineHeight: '20px',
    }

    const innerCellStyle = {
        minWidth: '90px',
    }

    const columns = [{
        title: '',
        dataIndex: 'image',
        render: (image) => <img src={image} className="event-image" alt="Guía De Hoy"/>
    }, {
        title: 'Evento',
        dataIndex: 'event',
        render: (text) => {
            return {
                props: {
                    style: cellStyle
                },
                children: <div>{text}</div>
            }
        }
    }, {
        title: 'Lugar',
        dataIndex: 'place',
        render: (text) => {
            return {
                props: {
                    style: cellStyle
                },
                children: <div>{text}</div>
            }
        }
    }, {
        title: 'Fecha evento',
        dataIndex: 'date',
        render: (text) => {
            return {
                props: {
                    style: cellStyle
                },
                children: <div>{text}</div>
            }
        }
    }, {
        title: 'Fecha compra',
        dataIndex: 'purchaseDate',
        render: (text) => {
            return {
                props: {
                    style: cellStyle
                },
                children: <div>{text}</div>
            }
        }
    }, {
        title: 'Total',
        dataIndex: 'total',
        render: (text) => {
            return {
                props: {
                    style: PriceStyle
                },
                children: <div>{text}</div>
            }
        }
    }];

    const responsiveColumns = [{
        title: 'Evento',
        dataIndex: 'event',
        render: (text) => {
            return {
                props: {
                    style: cellStyleResponsive
                },
                children: <div style={innerCellStyle}>{text}</div>
            }
        }
    }, {
        title: 'Fecha compra',
        dataIndex: 'purchaseDate',
        render: (text) => {
            return {
                props: {
                    style: cellStyleResponsive
                },
                children: <div style={innerCellStyle}>{text}</div>
            }
        }
    }, {
        title: 'Total',
        dataIndex: 'total',
        render: (text) => {
            return {
                props: {
                    style: PriceStyleResponsive
                },
                children: <div style={innerCellStyle}>{text}</div>
            }
        }
    }, {
        title: 'Lugar',
        dataIndex: 'place',
        render: (text) => {
            return {
                props: {
                    style: cellStyle
                },
                children: <div style={{minWidth: 150}}>{text}</div>
            }
        }
    }, {
        title: 'Fecha evento',
        dataIndex: 'date',
        render: (text) => {
            return {
                props: {
                    style: cellStyle
                },
                children: <div style={innerCellStyle}>{text}</div>
            }
        }
    }];

    return (
        <MainContainer>
            <Table dataSource={data} columns={isResponsive ? responsiveColumns : columns} pagination={false}/>
        </MainContainer>
    )
}


PastEventsTable.propTypes = {
    data: PropTypes.array
};

PastEventsTable.defaultProps = {
    data: [],
};

export default memo(PastEventsTable);