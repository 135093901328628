import React, { memo, useEffect, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { get } from 'lodash';
import {
  GoogleAuthProvider,
  signInWithPopup,
  FacebookAuthProvider,
  OAuthProvider,
} from 'firebase/auth';

import { Form, message, Input, Button } from 'antd';

import { LoginImage } from '../../Assets';
import { version } from '../../../package.json';

import FirebaseAuthContext from '../../contexts/firebase-auth-context';
import {
  LoginContainer,
  Image,
  Form as Desing,
  LoginForm,
  Title,
  ButtonContainer,
  ForgotPassword,
  NewAccountText,
  CreateAccount,
} from './styles';
import { HOME_REDIRECT, LOGIN_REDIRECT } from '../../constants';
import { userStoraged } from '../../utils';

const FACEBOOK = 'FACEBOOK';
const GOOGLE = 'GOOGLE';
const APPLE = 'APPLE';

function Login({ preventRedirect, onSuccess }) {
  const [emailValidateType, setEmailValidateType] = useState('onBlur');

  const firebaseAuth = useContext(FirebaseAuthContext);
  console.log('Current versión-' + version);
  const [form] = Form.useForm();

  const history = useHistory();
  const user = userStoraged;

  useEffect(() => {
    window.scrollTo(0, 0);
    if (user && user.access_token) {
      history.push(HOME_REDIRECT);
    }
  }, [history]);

  const goToRegister = () => {
    let path = `/register`;
    history.push(path);
  };

  const goToRecoveryPassword = () => {
    const path = '/recovery-password';
    history.push(path);
  };

  const handleLogin = async (loginType) => {
    if (loginType === FACEBOOK) {
      handleLoginFacebook();
    } else if (loginType === GOOGLE) {
      handleLoginGoogle();
    } else if (loginType === APPLE) {
      handleLoginApple();
    }
  };

  const handleLoginFacebook = async () => {
    try {
      const provider = new FacebookAuthProvider();
      const response = await signInWithPopup(firebaseAuth, provider);

      handleSuccessSocialLogin(response);
    } catch (e) {
      handleErrorHandler(e, FACEBOOK);
    }
  };

  const handleLoginGoogle = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const response = await signInWithPopup(firebaseAuth, provider);

      handleSuccessSocialLogin(response);
    } catch (e) {
      handleErrorHandler(e, GOOGLE);
    }
  };

  const handleLoginApple = async () => {
    try {
      const provider = new OAuthProvider('apple.com');
      provider.addScope('email');
      provider.addScope('name');
      const response = await signInWithPopup(firebaseAuth, provider);

      handleSuccessSocialLogin(response);
    } catch (e) {
      handleErrorHandler(e, APPLE);
    }
  };

  const handleSuccessSocialLogin = async (firebasePayload) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_ENDPOINT_URL + '/api/v1/login/hook ',
        firebasePayload,
      );

      localStorage.setItem('d', JSON.stringify(response.data));
      if (!response.data.completed_profile) {
        history.push('/mi-perfil');
      } else if (window.location.search) {
        history.push(`/detail/${window.location.search.split('=')[1]}?compra`);
      } else {
        history.push(LOGIN_REDIRECT);
      }
    } catch (e) {
      message.error('Intente de nuevo por favor');
    }
  };

  const handleErrorHandler = (e, loginType) => {
    try {
      const errorCode = e.code;
      console.log(e)
      console.log(errorCode)
      console.log(JSON.parse(e))
      if (loginType === FACEBOOK) {
        FacebookAuthProvider.credentialFromError(e);
      } else if (loginType === GOOGLE) {
        GoogleAuthProvider.credentialFromError(e);
      } else if (loginType === APPLE) {
        OAuthProvider.credentialFromError(e);
      }

      if (
        errorCode === 'auth/popup-closed-by-user' || // Usuario cierra el popup
        errorCode === 'auth/cancelled-popup-request' || // Usuario abre más de un popup
        errorCode === 'auth/user-cancelled' // Usuario cancela desde el login social correspondiente
      ) {
        return;
      } else if (errorCode === 'auth/popup-blocked') {
        message.error(
          'Revisa que la configuración de tu explorador te permita abrir ventanas emergentes',
        );
      } else if (
        errorCode === 'auth/account-exists-with-different-credential' // Mismo correo en distintos proveedores
      ) {
        message.error('Intenta de nuevo utilizando otro proveedor');
      } else {
        message.error('Intente de nuevo por favor');
      }
    } catch (e) {
      message.error('Intente de nuevo por favor');
    }
  };

  const login = () => {
    form
      .validateFields()
      .then((values) => {
        axios
          .post(
            process.env.REACT_APP_API_ENDPOINT_URL + '/api/v1/auth/login',
            values,
          )
          .then((res) => {
            localStorage.setItem('d', JSON.stringify(res.data));
            if (preventRedirect) {
              onSuccess(false);
            } else if (window.location.search) {
              history.push(
                `/detail/${window.location.search.split('=')[1]}?compra`,
              );
            } else {
              history.push(LOGIN_REDIRECT);
            }
          })
          .catch((error) => {
            const statusCode = get(error, 'response.status', 401);
            if (statusCode === 418) {
              history.push('/remainder-active-account?email=' + values.email);
            } else {
              message.error('Credenciales incorrectas.');
            }
          });
      })
      .catch(() => {
        message.error('Por favor ingresa todos los campos requeridos ');
      });
  };

  const onChangeEmail = () => {
    const values = form.getFieldsValue();
    form.setFieldsValue({
      email: values.email.trim(),
    });
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Inicia Sesion</title>
      </Helmet>
      <Form
        layout="vertical"
        form={form}
        initialValues={{
          email: '',
          password: '',
        }}
        onFinish={login}
        scrollToFirstError
        name="advanced_search"
        className="ant-advanced-search-form"
      >
        <LoginContainer id="login-container" preventRedirect={preventRedirect}>
          <LoginForm>
            <div className="content">
              <Title>Bienvenido de vuelta</Title>
              <div className="login-label">Iniciar sesión con</div>
              <div className="social-options">
                <Button
                  shape="circle"
                  className="social-button"
                  onClick={() => handleLogin(FACEBOOK)}
                >
                  <img src="/images/social/facebook.svg" alt="facebook" />
                </Button>
                <Button
                  shape="circle"
                  className="social-button"
                  onClick={() => handleLogin(GOOGLE)}
                >
                  <img src="/images/social/google.svg" alt="google" />
                </Button>
                <Button
                  shape="circle"
                  className="social-button"
                  onClick={() => handleLogin(APPLE)}
                >
                  <img src="/images/social/apple.svg" alt="apple" />
                </Button>
              </div>
              <div className="divider">
                <div className="line" />
                <span>o</span>
                <div className="line" />
              </div>
              <div className="login-label" style={{ marginBottom: 32 }}>
                Utiliza tu correo electrónico para iniciar sesión
              </div>
              <Desing>
                <Form.Item
                  name="email"
                  validateTrigger={emailValidateType}
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingresa tu correo',
                    },
                    {
                      type: 'email',
                      message: 'Por favor ingresa un correo válido',
                    },
                  ]}
                >
                  <Input
                    onBlur={() => {
                      onChangeEmail();
                      setEmailValidateType('onChange');
                    }}
                    onChange={onChangeEmail}
                    placeholder="Correo electrónico"
                    className="input-correo"
                  />
                </Form.Item>
                <div className="divider" />
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingresa tu contraseña',
                    },
                  ]}
                >
                  <Input.Password
                    placeholder="Contraseña"
                    className="input-password"
                  />
                </Form.Item>

                <ForgotPassword onClick={goToRecoveryPassword}>
                  ¿Olvidaste tu contraseña?
                </ForgotPassword>
              </Desing>
              <ButtonContainer>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  className="button-login"
                >
                  Iniciar sesión
                </Button>
                <NewAccountText>
                  ¿No tienes cuenta?
                  <CreateAccount onClick={goToRegister}>
                    Crear cuenta
                  </CreateAccount>
                </NewAccountText>
              </ButtonContainer>
            </div>
          </LoginForm>

          <Image src={LoginImage} alt="" />
        </LoginContainer>
      </Form>
    </>
  );
}

export default memo(Login);
