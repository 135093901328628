import styled from 'styled-components';
import { mediaQuery, mediaQuery1150, mediaQuery1320 } from '../../utils';

export const MainContainer = styled.div`
  
  width: 100%;
  height: 334px;
  position: relative;
  display: flex;
  align-items: stretch;
  flex-direction: row;
  ${mediaQuery}{
     flex-direction: column;
      margin-top: 0px;
      margin: 0, auto;
      height: 100%;
      min-width: 360px;
  }
  

`;

export const LeftContainer = styled.div`
display: flex;
position: relative;
width: 33.334%;
min-width: 350px;
height: 100%;
flex-direction: column;
background-color: #500080;
${mediaQuery} {
  display: flex;
  flex-direction: column;

    width: 100%;
    height: 100%;
  }
`
export const RightContainer = styled.div`
position: relative;
display: flex;
width: 66.666%;
height: 100%;
& img {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  ${mediaQuery} {
    width: 100%;
    
    &img{
      height: 205px;
    }
  }
`
export const TitlesContainer = styled.div`
display: flex;
flex-direction: column;
width: 100%;
height: 50%;
margin: 64px 80px 32px 80px; 
  ${mediaQuery1320}{
   margin: 64px 0% 32px 3rem;
  }

  ${mediaQuery} {
    margin: 24px 24px 24px 24px;
  }
`

export const Title = styled.h1`

  white-space: nowrap;
  width: 100%;
  font-size: 40px;
  font-weight: bold;
  color: #FFF9CC;
  letter-spacing: 0;
  line-height: 48px;
  margin-bottom: 32px;
  
  ${mediaQuery1320}{
    font-size: 3rem;
  }

  ${mediaQuery1150}{

    white-space: normal;
  }
  
  ${mediaQuery} {
    white-space: nowrap;
    font-size:32px;
    line-height: 40px;
    margin-bottom: 24px;
  }
`;

export const Subtitle = styled.p`
  font-size: 20px;
  color: #FFFFFF;
  letter-spacing: 0;
  line-height: 28px;
  margin-bottom: 32px;
  ${mediaQuery1320}{
    font-size: 1.8rem;
  }
  ${mediaQuery} {
    font-size:18px;
    line-height: 26px;
    margin-bottom: 24px;
  }
`;


export const ButtonsContainer = styled.div`
display: flex;
position: relative;
margin-bottom: 74px;
width: 100%;
height: 100%;
flex-direction: row;
margin-left: 7rem;
${mediaQuery1320}{
  margin-left: 6rem;
  justify-content: space-evenly;
  width: 60%;
  height: 60%;
}
${mediaQuery1150}{
  justify-content: initial;
  margin-left: 3.5rem ;
  width: 80%;
height: 800%;
}
${mediaQuery} {
  margin-left: 2.2rem;
  margin-bottom: 32px;
  }
`


export const Button = styled.a`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 48px;
  width: 150px;
  margin-right: 2rem;
  padding: 12px 20px;
  
  border-radius: 30px;
  background-color: #FFFFFF;
  box-shadow: 23px 23px 60px 0 rgba(0,0,0,0.4);

  :hover{
    opacity: 0.9;
  }

  .lastImage {
    margin-left: .3rem;
    margin-top: 6px;
  }
  
 
  `
