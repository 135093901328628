import styled from 'styled-components';

import { mediaQuery } from '../../utils';

export const Container = styled.div`
  padding-top: 24px;
  padding-bottom: 24px;
  
  .cards-container {
    display: flex;
    flex-wrap: nowrap;
  }

  .input {
    height: 52px;
    border-radius: 8px;
  }

  ${mediaQuery} {
    .cards-container {
      flex-direction: column;
    }
  }
`;

export const CardItem = styled.div`
  min-width: 280px;
  padding: 16px;
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  margin: 0 16px 16px 0;

  .owner-name {
    margin-bottom: 16px;
    font-size: 16px;
  }

  .details-section {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  .type-image {
    width: 38px;
    object-fit: contain;
    margin-right: 16px;
  }

  .card-detail {
    font-size: 16px;
    color: #666666;
  }

  .card-detail:not(:last-child) {
    margin-right: 16px;
  }

  .delete-button {
    color: #7B00B2;
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 14px;
    cursor: pointer;
    transition: opacity 0.25s;
    font-weight: 600;
  }

  .delete-button:hover {
    opacity: 0.6;
  }

  ${mediaQuery} {
    width: 100%;
  }
`;

export const AddCardItem = styled.div`
  min-width: 280px;
  height: 138px;
  padding: 16px;
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: opacity 0.25s;
  font-weight: 600;

  img {
    margin-bottom: 16px;
  }

  div {
    color: #7B00B2;
    font-size: 14px;
  }

  &:hover {
    opacity: 0.6;
  }

  ${mediaQuery} {
    width: 100%;
  }
`;

export const ErrorMessage = styled.div`
  display: flex;
  align-items: baseline;
  margin-top: 16px;

  img {
    margin-right: 16px;
  }

  div {
    font-size: 16px;
    color: #666666;
    max-width: 546px;
  }
`;

export const EmptyStateContainer = styled.div`
  padding: 70px 44px 80px;
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  img {
    margin-bottom: 40px;
  }

  div {
    font-size: 16px;
    color: #666666;
    margin-bottom: 32px;
  }

  button {
    color: #7B00B2;
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 14px;
    cursor: pointer;
    text-decoration: underline;
    transition: opacity 0.25s;
    font-weight: 600;
  }

  button:hover {
    opacity: 0.6;
  }
`;

export const AddCardContainer = styled.div`
  max-width: 368px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px 16px;

  img {
    margin-bottom: 32px;
    width: 106px;
    object-fit: contain;
  }

  .title {
    color: #000000;
    font-size: 24px;
    margin-bottom: 24px;
  }

  .instructions {
    color: #666666;
    font-size: 16px;
    margin-bottom: 24px;
  }

  .input {
    height: 52px;
    border-radius: 8px;
    margin-bottom: 16px;
  }

  .inputs-pair {
    display: flex;
    margin-bottom: 16px;
  }

  .inputs-pair input:first-child {
    margin-right: 16px;
  }

  .save-button {
    height: 48px;
    border-radius: 8px;
    background-color: #7B00B2;
    color: #ffffff;
    border: none;
    font-weight: 600;
  }

  .save-button:disabled {
    background-color: #f5f5f5;
    color: rgba(0, 0, 0, 0.25);
  }
`;

export const DeleteCardContainer = styled.div`
  max-width: 400px;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  height: 100%;
  font-weight: 600;

  img {
    margin-bottom: 40px;
    width: 166px;
    object-fit: contain;
  }

  .title {
    text-align: center;
    margin-bottom: 32px;
    font-size: 24px;
  }

  .delete-button {
    height: 48px;
    border-radius: 8px;
    background-color: #7B00B2;
    color: #ffffff;
    border: none;
    width: 152px;
    margin-bottom: 24px;
    font-weight: 600;
  }

  .cancel-button {
    color: #8645AD;
    font-size: 16px;
    font-weight: 600;
  }
`;
