import {
    IconAboutUsEasy,
    IconAboutUsFast,
    IconAboutUsConctact,
    IconAboutUsSave,
    IconAboutUsPrivate,
    IconAboutUsQRTickets,
    Facebook,
    Instagram,
    LinkedIn,
    Spotify,
    Tiktok,
    Twitter,
    Youtube
} from '../../Assets';

export const advantages_data = [
    {'id':'1', 'icon': IconAboutUsEasy,     'title':'Fácil','description':  'Nosotros sacamos el plan ¡Tú eliges!'},
    {'id':'2', 'icon': IconAboutUsFast,     'title':'Rápido','description':  'Ahorra tiempo en filas con unos cuantos clics.'},
    {'id':'3', 'icon': IconAboutUsConctact, 'title':'Sin contacto','description':  '¡Haz todo desde tu celular! '},
    {'id':'4', 'icon': IconAboutUsSave,     'title':'Seguro','description':  'Estamos certificados con cifrado web (SSL).'},
    {'id':'5', 'icon': IconAboutUsPrivate,  'title':'Privado','description':  'No nos quedamos con tu información bancaria.'},
    {'id':'6', 'icon': IconAboutUsQRTickets,'title':'Boletos QR','description': 'Siempre disponibles en línea para descargar.'},
]

export const instructions_data = [
    {'number': '1', 'title': 'Explora nuestra plataforma', 'description':  `Navega en Guía de Hoy y checa todas las categorías, eventos destacados, nuestras recomendaciones, entre otras cosas que tenemos para tí.`},
    {'number': '2', 'title': 'Elige el evento que más te guste', 'description':  `Una vez que encuentres tu evento ideal, dale clic para conocer el lugar, la hora, el precio y la modalidad.`},
    {'number': '3', 'title': 'Inicia sesión o crea una cuenta', 'description':  `Ingresa tu correo y tu contraseña. Si no tienes cuenta, dale clic al botón “Crear cuenta”, llena tus datos y bienvenido a bordo.`},
    {'number': '4', 'title': 'Personaliza tus entradas', 'description':  `Elige el día y la hora que más te guste, el número y el tipo de boletos que quieres adquirir. Para continuar da clic en el botón “Comprar”.`},
    {'number': '5', 'title': 'Finaliza tu compra', 'description':  `Tendrás 10 minutos para ingresar tus datos: Nombre, Apellido, Correo electrónico e información bancaria. Da clic en el botón “Finalizar compra” para recibir y descargar tus entradas.`},
    {'number': '6', 'title': 'Comparte y disfruta tu plan', 'description':  `!Listo, ya tienes algo que hacer! Si quieres invitar a tus amigos, recuerda que tienes la opción de enviar el link del evento vía WhatsApp.`},

]

export const doubts_data = [
    {'number': '1', 'title': '¿Cómo son los eventos gratuitos?', 'description':  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.', 'extra_description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.'},
    {'number': '2', 'title': '¿Se hacen reembolsos en Guía de Hoy?', 'description':  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.', 'extra_description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.'},
    {'number': '3', 'title': 'Ingresa la información de los tickets y de pago', 'description':  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.', 'extra_description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.'},
    {'number': '4', 'title': 'Comparte y disfruta de tu plan', 'description':  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.', 'extra_description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.'},
]

export const doubts_topics_data = [
    {'id' : '1', 'title' : 'Crear cuenta'},
    {'id' : '2', 'title' : 'Eventos'},
    {'id' : '3', 'title' : 'Entradas'},
    {'id' : '4', 'title' : 'Organizador'},
]

export const doubts_questions_data = [
    //Crear Cuenta
    {'id' :  '1', 'foreignId' : '1', 'title': '¿Cómo puedo crear una cuenta?', 'text': `
    1. En la página de inicio de Guía de Hoy en la esquina superior derecha está el botón de “Iniciar Sesión”. <span class="spaceLines"></span>
    2. En la sección para iniciar sesión está el botón para crear cuenta. (describir donde está el botón) <span class="spaceLines"> </span>
    3. Llena el formulario de registro con tu nombre, correo electrónico, contraseña y fecha de nacimiento.<span class="spaceLines"> </span>
    4. Lee y acepta la política de privacidad. <span class="spaceLines"> </span>
    5. Recibirás un correo de confirmación para autentificar tu email.<span class="spaceLines"> </span>
    6 .Da clic en el botón de verificación del correo que te enviamos. <span class="spaceLines"> </span>
    7. Listo, tu cuenta ya está creada.  <span class="spaceLines"> </span>
    `},
    {'id' :  '2', 'foreignId' : '1', 'title': '¿Qué hago si no me llegó el correo de confirmación?', 'text': `
    En caso de que tengas problemas con tu correo de confirmación, contáctanos a <a href="mailto:ayuda@guiadehoy.com">ayuda@guiadehoy.com</a> o llámanos al <a href="tel:6674718532">667 471 8532</a> para asistirte con el problema. Te recomendamos crear tu cuenta con un correo electrónico Gmail. 
    `},
    {'id' :  '3', 'foreignId' : '1', 'title': '¿Cómo puedo recuperar mi contraseña?', 'text': `
    Si has olvidado tu contraseña, deberás cambiarla a una nueva. <span class="spaceLines"></span>
        1. En la sección de iniciar sesión está el botón de “¿Olvidaste tu contraseña?”. <span class="spaceLines"></span>
        2. Introduce el correo electrónico que usaste para crear tu cuenta. <span class="spaceLines"></span>
        3. Te enviaremos un correo con el enlace para crear tu nueva contraseña. <span class="spaceLines"></span>
        4. Ingresa tu nueva contraseña.
    `},
    //Eventos
    {'id' :  '4', 'foreignId' : '2', 'title': '¿Cómo encuentro un evento?', 'text': `
    Lo puedes encontrar navegando en las diferentes categorías o bien, si ya tienes en mente un evento en específico, lo puedes encontrar en el buscador ubicado en la parte superior de la pantalla.
    `},
    {'id' :  '5', 'foreignId' : '2', 'title': '¿Cuáles son las diferentes categorías de eventos?', 'text': `
    <strong>Comida: </strong>Puedes encontrar festivales y eventos en distintos restaurantes.<span class="spaceLines"></span> 
    <strong>Vida Social: </strong>Fiestas, bazares y eventos sociales.<span class="spaceLines"></span>
    <strong>Música: </strong>Conciertos, festivales de música y tributos.<span class="spaceLines"></span>
    <strong>Cine: </strong>La cartelera de estrenos, proyección de películas y recomendaciones en las plataformas de streaming.<span class="spaceLines"></span>
    <strong>Cultura: </strong>Obras de teatro, exposiciones de arte, museos y galerías.<span class="spaceLines"></span>
    <strong>Formación: </strong>Cursos, talleres y conferencias.<span class="spaceLines"></span>
    <strong>Aire Libre: </strong>Carreras, maratones y actividades con la naturaleza.<span class="spaceLines"></span>
    <strong>Deportes: </strong>Partidos, torneos y diferentes competencias deportivas.<span class="spaceLines"></span>
    `},
    {'id' :  '6', 'foreignId' : '2', 'title': '¿Qué tipo de eventos hay en Guía de hoy?', 'text': `
    <strong>Informativos: </strong>Son recomendaciones de eventos que hay en Culiacán y algunos de ellos los puedes adquirir con el organizador.<span class="spaceLines"></span>
    <strong>Gratuitos: </strong>Son eventos sin costo, pero en algunos tienes que registrarte para adquirir un boleto.<span class="spaceLines"></span>
    <strong>Venta: </strong>Son los eventos que puedes comprar en la plataforma.<span class="spaceLines"></span>
    `},
    {'id' :  '7', 'foreignId' : '2', 'title': '¿Cómo puedo compartir el evento con mis amigos?', 'text': `
    Dentro del evento de tu preferencia vas a encontrar debajo del banner el botón “Compartir”, lo puedes hacer por WhatsApp o copiar el enlace y enviarlo por la plataforma de tu preferencia.
    `},
    {'id' :  '8', 'foreignId' : '2', 'title': '¿Por qué no aparece el botón “adquirir entradas” en ciertos eventos?', 'text': `
    Algunos eventos son sólo informativos por lo cual no se requieren entradas o el organizador tiene su propia taquilla.
    `},
    {'id' :  '9', 'foreignId' : '2', 'title': '¿Se hacen reembolsos o cambios en Guía de Hoy? ', 'text': `
    Puedes encontrar su contacto en los detalles del evento en la parte inferior de la pantalla.
    `},
    {'id' :  '10', 'foreignId' : '2', 'title': '¿Cómo me puedo contactar con el organizador del evento?', 'text': `
    Mandanos un correo electrónico a <a href="mailto:ayuda@guiadehoy.com">ayuda@guiadehoy.com</a> para poder proporcionarte los datos del organizador.
    `},
    {'id' :  '11', 'foreignId' : '2', 'title': '¿Cómo saber si el evento se vende en Guía de Hoy?', 'text': `
    Los eventos con una cinta morada, son eventos que se venden a través de nosotros.
    `},
    {'id' :  '12', 'foreignId' : '2', 'title': '¿Dónde veo más información sobre el evento?', 'text': `
    Dale click al banner del evento y podrás ver la descripción, el lugar, la hora, las modalidades y un mapa con la ubicación.
    `},
    //Entradas
    {'id' :  '13', 'foreignId' : '3', 'title': '¿Cómo recibo mis entradas? ', 'text': `
    Al finalizar la compra:<span class="spaceLines"></span>
    Cuando terminas tu compra se genera un código QR dentro de la aplicación que te servirá como boleto.<span class="spaceLines"></span> 
    En tu perfil:<span class="spaceLines"></span>
    Dale clic a la pestaña con tu nombre y en la sección de “Próximos Eventos” podrás encontrar tus entradas.<span class="spaceLines"></span>
    En tu correo electrónico:<span class="spaceLines"></span>
    Cuando hagas una compra en Guía de Hoy automáticamente se te envía un correo con tus entradas.<span class="spaceLines"></span>
    `},
    {'id' :  '14', 'foreignId' : '3', 'title': '¿Qué información aparece en mis entradas?', 'text': `
    Nombre, evento, tipo de entrada, fecha, hora y lugar. Además viene un link para descargar tus entradas.
    `},
    {'id' :  '15', 'foreignId' : '3', 'title': '¿Qué pasa si no pude descargar mis entradas o no me llegó el correo?', 'text': `
    Ingresa a la plataforma y ve a tu perfil al apartado “Próximos eventos” ahí los podrás ver y descargar
    `},
    {'id' :  '16', 'foreignId' : '3', 'title': '¿Qué métodos de pago puedo usar?', 'text': `
    Aceptamos tarjetas de débito y crédito (Mastercard, Visa y American Express)
    `},
    {'id' :  '17', 'foreignId' : '3', 'title': '¿Cómo comprar boletos?', 'text': `
    Checa el siguiente <a href="https://www.youtube.com/watch?v=cN9B4QOn63U" target="_blank" >video</a> y compra tus boletos. 
    `},
    {'id' :  '18', 'foreignId' : '3', 'title': '¿Cómo recibiré el acceso al evento en línea?', 'text': `
    Un día antes del evento se envía un correo con los accesos, en caso de que no te llegue el correo contáctanos a <a href="mailto:ayuda@guiadehoy.com">ayuda@guiadehoy.com</a> o llámanos al <a href="tel:6674718532">667 471 8532</a>
    `},
    {'id' :  '19', 'foreignId' : '3', 'title': 'Ocurrió un error al momento de mi compra en internet ¿Cómo puedo asegurarme de que se realizó la compra y el cargo a mi tarjeta?', 'text': `
    Puedes revisar tu perfil, si la compra fue exitosa tus entradas aparecerán en el apartado “Próximos eventos”.<span class="spaceLines"></span> 
    Si estás en tu celular selecciona en la parte superior derecha el menú y ve a “Mis entradas”.<span class="spaceLines"></span>
    Si estás en computadora da clic en tu perfil en la parte superior derecha y ve a “Próximos eventos”.<span class="spaceLines"></span>
    Revisa tu correo electrónico, busca en promociones y en spam. En caso de no encontrar tu correo de confirmación, comunícate a <a href="mailto:ayuda@guiadehoy.com">ayuda@guiadehoy.com</a> o llámanos al <a href="tel:6674718532">667 471 8532</a>
    `},
    {'id' :  '20', 'foreignId' : '3', 'title': '¿Cómo sé que mi información está segura? ', 'text': `
    Puedes confiar en nosotros, usamos cifrado web (SSL) para proteger tu información
    `},
    //Organizador
    {'id' :  '21', 'foreignId' : '4', 'title': '¿Cómo puedo subir mi evento?', 'text': `
    Entra a esta <a href="https://form.typeform.com/to/aFOy7WHS?typeform-source=guiadehoy.com">liga</a> y llena los datos de tu evento.
    `},
    {'id' :  '22', 'foreignId' : '4', 'title': '¿Qué estadísticas de mi evento me proporciona Guía de Hoy como boletera?', 'text': `
    Reporte de ventas diarias, visitas del evento y monto de ventas total.
    `},
    {'id' :  '23', 'foreignId' : '4', 'title': '¿Qué beneficios recibo al vender mi evento con ustedes?', 'text': `
    Atención y asesoría personalizada, difusión en nuestras redes sociales y cobertura del evento.
    `},
    {'id' :  '24', 'foreignId' : '4', 'title': '¿En cuánto tiempo recibo el dinero del evento?', 'text': `
    El pago se verá reflejado 7 días después de la venta de cada día. 
    `},
    {'id' :  '25', 'foreignId' : '4', 'title': '¿Qué tipos de eventos puedo subir a la plataforma?', 'text': `
    Tenemos distintas categorías por eventos, aquí te mostramos algunos eventos.<span class="spaceLines"></span>
    <strong>Comida: </strong>Puedes encontrar festivales y eventos en distintos restaurantes.<span class="spaceLines"></span> 
    <strong>Vida Social: </strong>Fiestas, bazares y eventos sociales.<span class="spaceLines"></span>
    <strong>Música: </strong>Conciertos, festivales de música y tributos.<span class="spaceLines"></span>
    <strong>Cine: </strong>La cartelera de estrenos, proyección de películas y recomendaciones en las plataformas de streaming.<span class="spaceLines"></span>
    <strong>Cultura: </strong>Obras de teatro, exposiciones de arte, museos y galerías.<span class="spaceLines"></span>
    <strong>Formación: </strong>Cursos, talleres y conferencias.<span class="spaceLines"></span>
    <strong>Aire Libre: </strong>Carreras, maratones y actividades con la naturaleza.<span class="spaceLines"></span>
    <strong>Deportes: </strong>Partidos, torneos y diferentes competencias deportivas.<span class="spaceLines"></span>
    Nuestra institución no promueve temas de violencia, política ni religiosos.<span class="spaceLines"></span>
    `},

]

export const social_media_data = [
    {'number': '1', 'icon': Facebook, 'url_direction':'https://www.facebook.com/guiadehoymx/'},
    {'number': '2', 'icon': Twitter, 'url_direction':'https://twitter.com/guiadehoymx'},
    {'number': '3', 'icon': Instagram, 'url_direction':'https://www.instagram.com/guiadehoycln/'},
    {'number': '4', 'icon': Spotify, 'url_direction':'https://open.spotify.com/user/gotgwg9ue4zwdlecv15ivl3bv?si=yKDItPaGSVWVp-uRHp8KxQ'},
    {'number': '4', 'icon': Youtube, 'url_direction':'https://www.youtube.com/channel/UCVDtf1lfz6Qnc6THN_DLaaQ"'},
    {'number': '4', 'icon': LinkedIn, 'url_direction':'https://www.linkedin.com/company/guia-de-hoy/'},

]