import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {MainContainer, Title, Description} from './styles';
import ModalFullscreen from '../ModalFullscreen';
import {ImpressedPhone} from '../../Assets';
import Button from '../Button';

const NoTimeModal = (props) => {
    const {openModal, onCloseModal, onAccept, onCancel} = props;

    return (
        <ModalFullscreen openModal={openModal} onCloseModal={onCloseModal}>
            <MainContainer>
                <img src={ImpressedPhone} alt="" className="phone-icon"/>
                <Title>¿Estás seguro que deseas</Title>
                <Title>abandonar la compra de entradas?</Title>
                <Description> Al salir tus entradas se liberarán.</Description>
                <Button
                    text="Continuar comprando"
                    className="button-back"
                    onClick={onAccept}
                />
                <div>
                    <Button
                        text="Salir"
                        className='button-exit'
                        onClick={onCancel}
                    />
                </div>
            </MainContainer>
        </ModalFullscreen>
    )
};


NoTimeModal.propTypes = {
    openModal: PropTypes.bool,
    onCloseModal: PropTypes.func,
    onAccept: PropTypes.func,
    onCancel: PropTypes.func,
};

NoTimeModal.defaultProps = {
    openModal: false,
    onCloseModal: () => {
    },
    onAccept: () => {
    },
    onCancel: () => {
    },
};

export default memo(NoTimeModal);