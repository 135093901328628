import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  LogoHeader,
  BurguerIcon,
  FoodIconActive,
  HomeIconActive,
  UsActive,
  ProfileDefault,
  LogoutIconActive,
  CloseIcon,
  TicketEnabled,
  HistoryEnabled,
  MyProfileActive,
  EventsIcon,
  AnunciateIcon
} from '../../Assets';
import { Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';
import {ArrowDownIcon} from '../../Assets';
import { get } from 'lodash';
import {
  HeaderContainer,
  LogoItem,
  MenuItem,
  DropMenuItem,
  MenuItemGroup,
  UserItemName,
  UserItemAvatar,
  UserItemGroup,
  DropdownMenu,
  CloseMenu,
  TitleMenuResponsive,
  MenuItemResponsive,
  UserItemAvatarResponsive,
  UserInfoCard,
  TopContainer,
  BottomContainer,
  DropdownText,
  EndLine
} from './styles';
import { HOME_REDIRECT } from '../../constants';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import packageJson from '../../../package.json';
// TODO Modificaciones Sushi fest
function Header(props) {
  const { fixedHeader } = props;
  const location = useLocation();
  const [showDropdown, setShowDropdown] = useState(false);
  const { trackEvent } = useMatomo();
  
  const user = JSON.parse(localStorage.getItem('d'));
  const userFirstName = user ? user.first_name : null
  const userLastName = user ? user.last_name : null
  const token = user? user.access_token : null

  const userFullName = userFirstName
    ? `${userFirstName} ${userLastName}`
    : location.pathname === '/login'
    ? ''
    : 'Inicia sesión';
  let history = useHistory();

  useEffect(() => {
    const hideDropdownIfVisible = (ev) => {
      if (get(ev, 'path', []).length !== 0) {
        let eventPath = ev.path[0];
        if (eventPath.id === 'cerrar-sesion') {
          signOut();
        }
        if (showDropdown) {
          setShowDropdown(false);
        }
      }
    };

    document
      .getElementById('root')
      .addEventListener('click', hideDropdownIfVisible);

    return () => {
      document
        .getElementById('root')
        .removeEventListener('click', hideDropdownIfVisible);
    };
    
  }, []);

  const goToLogin = () => {
    history.push('/login');
    setShowDropdown(false);
  };

  const signOut = () => {
    localStorage.clear();
    setShowDropdown(false);
    history.push('/login');
    window.location.reload();
  };

  const goToProfileIfLogged = () => {
    if (token) {
      if (window.location.pathname !== '/mi-perfil') {
        trackEvent({
          category: 'Mi perfil',
          action: 'click',
          name: 'Mi perfil',
        });
      }

      history.push('/mi-perfil');
    } else {
      history.push('/login');
    }
  };

  const onClickStart = () => {
    history.push('/');
    setShowDropdown(false);
  };

  const onClickFood = () => {
    history.push('/food');
    setShowDropdown(false);
  };

  const onClickAbout = () => {
    history.push('/about');
    setShowDropdown(false);
  };

  const onClickEvents = () => {
    history.push('/eventos');
    setShowDropdown(false);
  };

  const onClickAnnounce = () => {
    history.push('/anunciate');
    setShowDropdown(false);
  };

  const onClickProfile = () => {
    history.push('/mi-perfil');
    setShowDropdown(false);
  };

  const onClickUpcomingEvents = () => {
    history.push('/siguientes-eventos');
    setShowDropdown(false);
  };

  const onClickPastEvents = () => {
    history.push('/eventos-pasados');
    setShowDropdown(false);
  };

  const onGoToLoginAndRefresh = () => {
    history.push(HOME_REDIRECT);
    setShowDropdown(false);
    window.location.reload(true);
  };

  const formaParteMenu = (
    <Menu>
        <Menu.Item key="0">
          <DropMenuItem onClick={() => history.push('/anunciate')}>
            <a>Anúnciate</a>
          </DropMenuItem>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="1">
          <DropMenuItem onClick={() => history.push('/eventos')}>
            <a>Organiza un evento</a>
          </DropMenuItem>
        </Menu.Item>
      </Menu>
  );

  return (
    <HeaderContainer fixedHeader={fixedHeader}>
      <LogoItem>
        <img
          src={LogoHeader}
          className="image"
          alt=""
          onClick={() => onGoToLoginAndRefresh()}
        />
      </LogoItem>
      <MenuItemGroup>
        <MenuItem className="justify-start" onClick={() => history.push('/')}>
          Inicio
        </MenuItem>
        <MenuItem
          className="justify-start"
          onClick={() => history.push('/food')}
        >
          Comida
        </MenuItem>
        <MenuItem
          className="justify-start"
          onClick={() => history.push('/about')}
        >
          Nosotros
        </MenuItem>
        <MenuItem className="justify-start">
          <Dropdown overlay={formaParteMenu} trigger={['click']}>
            <DropdownText className="justify-start ant-dropdown-link" onClick={e => e.preventDefault()}>
              Forma Parte <img src={ArrowDownIcon} style={{"height": "15px"}}/>
            </DropdownText>
          </Dropdown>
        </MenuItem>
      </MenuItemGroup>
      <UserItemGroup>
        <UserInfoCard onClick={goToProfileIfLogged}>
          <UserItemName>{userFullName}</UserItemName>
          {token && (
            <UserItemAvatar>
              <img src={user && user.profile ? user.profile :ProfileDefault} alt="" />
            </UserItemAvatar>
          )}
        </UserInfoCard>
        <img
          src={BurguerIcon}
          className="dropdown hide-on-desktop1320MW hide-icon-on-desktop"
          alt=""
          onClick={(_) => setShowDropdown(!showDropdown)}
        />
        {showDropdown ? (
          <DropdownMenu className="hide-on-desktop1320MW">
            <TopContainer>
              <CloseMenu onClick={(_) => setShowDropdown(false)}>
                <img src={CloseIcon} alt="" />
              </CloseMenu>
              <UserItemAvatarResponsive>
                <img src={user && user.profile ? user.profile :ProfileDefault} alt="" />
              </UserItemAvatarResponsive>
              {token ? (
                <TitleMenuResponsive>¡Hola! {userFullName}</TitleMenuResponsive>
                ) : (
                  <TitleMenuResponsive>
                  ¡Hola!
                  <div onClick={goToLogin}>Inicia sesión</div>
                </TitleMenuResponsive>
              )}
            </TopContainer>
            <BottomContainer>
              <MenuItemResponsive onClick={() => onClickStart()}>
                <img src={HomeIconActive} alt="" />
                Inicio
              </MenuItemResponsive>
              {token && (
                <div>
                  <MenuItemResponsive
                    onClick={() => onClickUpcomingEvents()}>
                    <img src={TicketEnabled} alt="" />
                    Mis entradas
                  </MenuItemResponsive>
                  <MenuItemResponsive
                    onClick={() => onClickPastEvents()}>
                    <img src={HistoryEnabled} alt="" />
                    Historial de compras
                  </MenuItemResponsive>
                </div>
              )}
              <MenuItemResponsive
                onClick={() => onClickFood()} >
                <img src={FoodIconActive} alt="" />
                Comida
              </MenuItemResponsive>
              {token && (
                <MenuItemResponsive
                onClick={() =>  onClickProfile()}>
                <img src={MyProfileActive} alt="" />
                Mi perfil
              </MenuItemResponsive>
              )}
              <MenuItemResponsive
                onClick={() => onClickAbout()}>
                <img src={UsActive} alt="" />
                Nosotros
              </MenuItemResponsive>
              <MenuItemResponsive
                onClick={() => onClickEvents()}>
                <img src={EventsIcon} alt="" />
                Eventos
              </MenuItemResponsive>
              <MenuItemResponsive
                onClick={() => onClickAnnounce()}>
                <img src={AnunciateIcon} alt="" />
                Anúnciate
              </MenuItemResponsive>
              <EndLine/>
              {token && (
                <MenuItemResponsive
                onClick={() => signOut()}>
                  <img src={LogoutIconActive} alt={packageJson.version} />
                  Cerrar sesión -{packageJson.version}
                </MenuItemResponsive>
              )}
            </BottomContainer>
          </DropdownMenu>
        ) : (
          React.null
        )}
      </UserItemGroup>
    </HeaderContainer>
    );
  }
  
  Header.propTypes = {
    fixedHeader: PropTypes.string,
};

Header.defaultProps = {
  fixedHeader: '',
};

export default memo(Header);
