import React, { memo,  } from 'react';
import 'moment/locale/es-mx';
import PropTypes from 'prop-types';
import { Carousel } from 'antd';

import {
    MainContainer,
    DesktopContainer,
    MobileContainer,
    Title,
    CarouselCard,
    ImageCircle,
    InfoCard,
    InfoCardText,
    PlaceText,
    DescriptionText,
    Comillas,
    ImageResponsive
} from './styles';

function OurClients(props) {
    const {title, data } = props;
    
    return (
      <>
        <MainContainer>
            <DesktopContainer>
            <Title>{title}</Title>
              <Carousel dots>
                {data.map((testimony =>
                  <CarouselCard key={testimony.id}>
                    <ImageCircle src={testimony.image}/>
                    <InfoCard>
                      <Comillas>“</Comillas>
                      <InfoCardText>
                          <PlaceText>{testimony.title}</PlaceText>
                          <DescriptionText>{testimony.description}</DescriptionText>
                          <br />
                          <DescriptionText>-{testimony.author}</DescriptionText>
                      </InfoCardText>
                    </InfoCard>
                </CarouselCard>
                  ))}
              </Carousel>
            </DesktopContainer>
            <MobileContainer>
              <Title>{title}</Title>
              <Carousel dots="true">
                {data.map((testimony =>
                  <CarouselCard key={testimony.id}>
                    <ImageResponsive src={testimony.imageResponsive}/>
                    <InfoCard>
                      <InfoCardText>
                          <PlaceText>{testimony.title}</PlaceText>
                          <DescriptionText>{testimony.description}</DescriptionText>
                          <br />
                          <DescriptionText>-{testimony.author}</DescriptionText>
                      </InfoCardText>
                    </InfoCard>
                </CarouselCard>
                  ))}
              </Carousel>
            </MobileContainer>
        </MainContainer>
        </>
    );
}
 OurClients.propTypes = {
    title: PropTypes.string,
    data: PropTypes.array,
  };
  
   OurClients.defaultProps = {
    title: '',
    data: {},
  };
export default memo(OurClients);
