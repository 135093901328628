import React from 'react';
import PropTypes from 'prop-types';
import {compose} from 'redux';
import {Route, withRouter} from 'react-router-dom';

const Index = ({component: Component, layout: Layout, history, title, ...rest}) => (
    <Route
        {...rest}
        render={props => (
            <Layout pathname={props.location.pathname} history={history}>
                <Component {...props} title={title}/>
            </Layout>
        )}
    />
);

Index.propTypes = {
    component: PropTypes.any,
    location: PropTypes.any,
    layout: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    title: PropTypes.string,
};

export default compose(withRouter)(Index);
