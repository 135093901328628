import React, {memo} from 'react';
import PropTypes from 'prop-types';

import {
    MainContainer,
    ImageContainer,
    DateText,
    EventName,
    EventPlace,
    EventPrice,
    EventPriceDiscounted,
    OldPrice,
    NewPrice,
    Discount,
    TitleContainer
} from './styles';

function EventCard(props) {
    const {
        image,
        date,
        name,
        place,
        price,
        discount,
        customStyle,
        customCardStyle,
        recommendationTitle,
        eventType,
        viewAll,
        isSearchResponse,
        price_range_discounted
    } = props;
    return (
        <MainContainer style={customStyle} viewAll={viewAll} isSearchResponse={isSearchResponse} isRecomendation={!!recommendationTitle}>
            <ImageContainer isRecomendation={!!recommendationTitle}>
                <img
                    src={image}
                    className={`image ${recommendationTitle ? 'recommendation-size' : ''}`}
                    alt=""
                    style={customCardStyle}
                />
                {discount && (
                    <Discount>{discount}</Discount>
                )}
                {recommendationTitle && (
                    <TitleContainer>{recommendationTitle}</TitleContainer>
                )}
            </ImageContainer>
            <DateText>
                {date}
            </DateText>
            <EventName>
                {name}
            </EventName>
            <EventPlace>
                {place}
            </EventPlace>
            {discount 
            ?  <>
                    <EventPriceDiscounted>
                        <OldPrice>{price}</OldPrice>
                        <NewPrice>{price_range_discounted}</NewPrice>
                    </EventPriceDiscounted> 
                </>
            : 
                <EventPrice>
                    {price}
                </EventPrice> 
            }
            
        </MainContainer>
    );
}

EventCard.propTypes = {
    image: PropTypes.string,
    date: PropTypes.string,
    name: PropTypes.string,
    place: PropTypes.string,
    price: PropTypes.string,
    discount: PropTypes.string,
    customStyle: PropTypes.object,
    customCardStyle: PropTypes.object,
    recommendationTitle: PropTypes.string,
    eventType: PropTypes.string,
    viewAll: PropTypes.bool,
    isSearchResponse: PropTypes.bool
};

EventCard.defaultProps = {
    image: '',
    date: '',
    name: '',
    place: '',
    price: '',
    discount: '',
    customStyle: {},
    customCardStyle: {},
    recommendationTitle: '',
    eventType: '',
    viewAll: false,
    isSearchResponse: false
};

export default memo(EventCard);
