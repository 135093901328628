import { Carousel } from 'antd';
import styled from 'styled-components';
import {mediaQuery, mediaQuery1050, mediaQuery1150, mediaQuery1350, mediaQuery930} from '../../utils';

export const MainContainer = styled.div`
  width: 100%;
  height: fit-content;
  background-color: white;
`;

export const DesktopContainer = styled.div`
    width: 100%;
    padding: 120px 80px;
    height: 100%;
    .ant-carousel .slick-slide {
      margin-top: 99px;
    }
    ${mediaQuery930}{
      display: none;
    }
    
    .ant-carousel .slick-dots li button{
      background: #D8D8D8!important;
      height: 16px;
      width: 16px;
      border-radius: 100px;
      opacity: 1!important;
      transition: none;
    }

    .ant-carousel .slick-dots li {
      margin: 5px;
    }

    .ant-carousel .slick-dots li.slick-active {
      width: auto!important;
    }

    .ant-carousel .slick-dots li.slick-active button{
      background: #666666!important;
      transition: none;
    }
    
    ${mediaQuery1050}{
      padding: 60px 80px;
      
      .ant-carousel .slick-slide {
      margin-top: 50px;
    }
    }
    
`;

export const MobileContainer = styled.div`
  display: none;
  ${mediaQuery930}{
    display: block;
    height: fit-content;
    width: 100%;
    padding: 80px 0px 16px 0px;
  }
  .ant-carousel .slick-dots li button{
      background: #D8D8D8!important;
      height: 12px;
      width: 12px;
      border-radius: 100px;
      opacity: 1!important;
      transition: none;
    }

    .ant-carousel .slick-dots li {
      margin: 5px;
    }

    .ant-carousel .slick-dots li.slick-active {
      width: auto!important;
    }

    .ant-carousel .slick-dots li.slick-active button{
      background: #666666!important;
      transition: none;
    }

    .ant-carousel .slick-dots {
      margin-top: 20px;
      position: static;
    }
    
`;

export const CarouselCard = styled.div`
  height: 569px;
  width: 100%;
  ${mediaQuery1350}{
    height: 500px;
  }

  ${mediaQuery930}{
    height: fit-content;
  }
`;

export const InfoCard = styled.div`
  width: fit-content;
  height: 385px!important;
  background-color: #F4F4F4;
  margin-top: 50px;
  margin-left: 400px;
  padding-left: 83px;

  ${mediaQuery1350}{
    height: 320px!important;
    background-color: #F4F4F4;
    margin-top: 50px;
    margin-left: 300px;
    padding-left: 83px;
  }

  ${mediaQuery1050}{
    height: 300px!important;
    background-color: #F4F4F4;
    margin-top: 50px;
    margin-left: 250px;
    padding-left: 83px;
  }

  ${mediaQuery930}{
    height: fit-content!important;
    background-color: #F4F4F4;
    margin-top: -60px;
    margin-left: 16px;
    margin-right: 16px;
    border-radius: 8px;
    padding-left: 0px;
  }
`;

export const InfoCardText = styled.div`
  width: 100%;
  text-align: justify;
  padding: 88px 109px 61px 140px;

  ${mediaQuery1350}{
    padding: 88px 40px 61px 80px;
  }

  ${mediaQuery930}{
    padding: 32px 40px;
  }

`
export const PlaceText = styled.div`
  color: #000000;
  font-family: Rubik;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
  margin-bottom: 25px;

  ${mediaQuery1350}{
    margin-bottom: 15px;
  }

  ${mediaQuery1050}{
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 10px;
  }

  ${mediaQuery930}{
    font-size: 20px;
    letter-spacing: 0.19px;
    line-height: 24px;
  }

  
`

export const DescriptionText = styled.div`
  color: #666666;
  font-family: Rubik;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 28px;

  ${mediaQuery1350}{
    font-size: 16px;
    line-height: 24px;
  }

  ${mediaQuery1050}{
    font-size: 15px;
    line-height: 22px;
  }

  ${mediaQuery930}{
    font-size: 16px;
    letter-spacing: 0.15px;
    line-height: 24px;
  }
`


export const ImageCircle = styled.img`
  width: 513px;
  height: 513px;
  border-radius: 1000;
  position: absolute;
  z-index: 2;

  ${mediaQuery1350}{
    width: 420px;
    height: 420px;
  }

  ${mediaQuery1050}{
    width: 370px;
    height: 370px;
  }

`;

export const Title = styled.div`
  color: #40006E;
  font-family: Gotham;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 0.3px;
  line-height: 48px;

  ${mediaQuery930}{
    font-size: 28px;
    letter-spacing: 0;
    line-height: 36px;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 32px;
  }
`;


export const Comillas = styled.div`
  position: absolute;
  margin-top: 10px;
  margin-left: 50px;
  height: 67px;
  width: 67px;
  color: #B361CF;
  font-family: Gotham;
  font-size: 120px;
  font-weight: ultra;
  letter-spacing: 0;
  line-height: 144px;

  ${mediaQuery1350}{
    margin-top: 5px;
    margin-left: 30px;
    height: 67px;
    width: 67px;
    font-size: 100px;
  }

  ${mediaQuery1050}{
    margin-top: 0px;
    margin-left: 35px;
    font-size: 90px;
  }

`;


export const ImageResponsive = styled.img`
  height: 360px;
  position: relative;
  z-index: -1;
  width: 100%;

  
`
