import { getToken, postGeneric } from '../config/https';

export async function passwordRecoveryRequest(data) {
  const token = getToken();
  return await postGeneric({
    url: `${process.env.REACT_APP_API_ENDPOINT_URL}/api/v1/auth/initiate-reset-password`,
    headers: { Authorization: `Bearer ${token}` },
    data: data,
  })
}

