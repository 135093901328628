import React, {memo} from 'react';
import GlobalStyles from '../../GlobalStyles';
import BannerApp from '../../components/BannerApp';
import {
    OrganizaUnEvento,
    OrganizeEventResponsive,
    AnunciateConNosotros,
    BannerAppImage,
    BannerAppImageResponsive,
    AnnounceWithUsImage,
    AnnounceWithUsImageResponsive,
} from '../../Assets';
import DiscoverPlan from '../../components/DiscoverPlan';
import AdvantagesGroup from '../../components/AdvantagesGroup';
import HowtoBuyList from '../../components/HowToBuyContainer';
import DoubtsContainer from '../../components/DoubtsContainer';
import {advantages_data, 
    instructions_data, social_media_data, doubts_topics_data, doubts_questions_data, our_clients_data
       }from './data';
import ContactUs from '../../components/ContactUs';
import OurClients from '../../components/OurClients';
import UsBannerSeparate from '../../components/UsBannerSeparate';

function AnnounceWithUs() {
    return (
        <div style={{overflowX: 'hidden'}}>
            
            <GlobalStyles/>
            <>
            <DiscoverPlan
                title="Conecta con tu audiencia."
                buttonText = "Empieza aquí"
                image = {AnnounceWithUsImage}
                imageResponsive = {AnnounceWithUsImageResponsive}
                type= "image"
                urlToGo= "https://form.typeform.com/to/jDHUs9Vo"
            />
            <HowtoBuyList 
                title="¿Cómo anunciarme en la Guía de hoy? "
                data={instructions_data}
                style="secondary"
                video="//www.youtube.com/embed/fz6rTtcjJyo"
                />
            <AdvantagesGroup 
                titleMobile="Beneficios de anunciarte"
                titleDesktop="Beneficios de anunciarte"
                data={advantages_data}
                style="secondary"
                />
            <OurClients
                title="Nuestros clientes"
                data={our_clients_data}
            />
            <DoubtsContainer 
                title="¿Tienes dudas?"
                topics={doubts_topics_data}
                questions={doubts_questions_data}
                style="secondary"
                />
            <UsBannerSeparate
                 title="¿Vas a organizar un evento?"
                 image={OrganizaUnEvento}
                 imageResponsive={OrganizeEventResponsive}
                 url="/eventos"
                 titleWidth="short"
            />
            <ContactUs
                title="Escríbenos o llámanos, estamos para ayudarte"
                description="Nuestro equipo esta listo para ayudarte, comunícate con nosotros."
                subtitle="Síguenos en nuestras redes sociales"
                data={social_media_data}
            />
                {/* {!localStorage.getItem('pwa') &&
                <BannerApp
                    image={BannerAppImage}
                    imageResponsive={BannerAppImageResponsive}
                    title="¡Llévanos contigo! "
                    subtitle="Descarga la app"
                    urlAppStore=""
                    urlGooglePlay=""
                />}  */}

            </>
        </div>
    );
}

export default memo(AnnounceWithUs);
