import styled from 'styled-components';
import {mediaQuery, mediaQuery1150, mediaQuery930, mediaQuery290} from '../../utils';

export const MainContainer = styled.div`

  width: 100%;
  height: 720px;
  background-color: #F4F4F4;
  position: relative;
  display: flex;
  flex-direction: row;
  margin-bottom: 120px;
  ${mediaQuery930}{
    display: flex;
    height: fit-content;
    padding: 0px 0px 64px 0px;
    flex-direction: column;
    margin-bottom: 0;
  }
  
`;



export const LeftContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 0 1 calc(51.4%);
    padding: 5% 3.9% 1.96% 5.63%;
    width: 100%;
    background-color: #F4F4F4;
    ${mediaQuery930}{
        order: 2;
        padding: 48px 6% 0% 6% ;
        flex: 1;
    }
`;

export const RightContainer = styled.div`
    flex: 0 1 calc(48.6%);
    width: 100%;
    background-color: #E5E5E5;
    & img {
      height: 100%;
      width: 100%;
 
    }
    ${mediaQuery930}{
        height: fit-content;
        width: 100%;
        flex: 1;
        & img {
          height: 240px;
          width: 100%;
    
        }
    }
`;

export const Title = styled.div`
  color: #191919;
  font-family: Gotham;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0.24px;
  line-height: 40px;
  padding-bottom:5% ;
  padding-right: 20%;
  text-align: left;
  ${mediaQuery}{
    font-size: 28px;
    letter-spacing: 0px;
    line-height: 36px;
    padding-bottom: 40px;
  }
`;

export const Subtitle = styled.div`
  color: #191919;
  font-family: Gotham;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0.24px;
  line-height: 40px;
  padding-bottom: 48px;
  padding-right: 18%;
  display: flex;
  ${mediaQuery}{
    padding-right: 0px;
    padding-bottom: 8%;
    font-family: Rubik;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 0px;
    line-height: 36px;
  }
`

export const DescriptionContainer= styled.p`
  color: #333333;
  font-family: Rubik;
  font-size: 20px;
  letter-spacing: 0.19px;
  line-height: 28px;
  text-align: left;
  padding-right: 15%;
${mediaQuery}{  
  padding-right: 5%;
  font-size: 18px;
  letter-spacing: -0.1px;
  line-height: 26px;
}
`

export const ButtonsContainer = styled.div`
    display: flex;
    width: fit-content;
    direction: row;
    padding: 3% 0% 10% 0%;
    justify-content: space-between;

    ${mediaQuery930}{
      padding-bottom: 48px;
      flex-direction: column;
      width: 100%;
    }
`;


export const SocialMediaContainer = styled.div`
display: flex;
width: 100%;
& a {
  text-decoration: none;
  margin-right: 32px;
}

.icon-social {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

${mediaQuery} {
    justify-content: space-between;
}

`
export const SocialMediaItem = styled.a`
${mediaQuery} {
    display: flex;
    margin-right: 0px!important;
}
`;

export const CircleExample = styled.div`
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 80px;
  width: 80px;
  background: #D8D8D8;
  border-radius: 50%;
  
  /* & .icon {
    width: 48px;
    height: 48px;
    object-fit: contain;
  } */

  ${mediaQuery1150}{
    height: 80px;
    width: 80px;
  }

  ${mediaQuery} {
    width: 64px;
    height: 64px;
  
    & .icon {
      width: 24px;
      height: 24px;
    }
  }
  ${mediaQuery290}{
      width: 60px;
      height: 60px;
  }
`;

export const Button = styled.a`
  min-width: fit-content;
  height: 48px;
  width: ${props => props.primary ? '188px' : '112px'};
  padding: 14px 25px;
  margin-right: 5% ;
  border-radius: 8px;
  font-family: Rubik;
  background-color: ${props => props.primary ? '#7B00B2' : '#EDE7F1'};
  color: ${props => props.primary ? '#FFFFFF' : '#7B00B2'};
  font-size: 14px;
  font-weight: 800;
  letter-spacing: 0px;
  line-height: 19px;
  text-align: center;
  cursor: pointer;
  user-select: none;
  border: 0;
  
  :hover {
    opacity: .9;
    text-decoration: none!important;
    color: ${props => props.primary ? '#FFFFFF' : '#7B00B2'}!important;
    
  }

  
  & img {
    width: 24px;
    height: 24px;
  }

  ${mediaQuery930} {
    margin: 8px 0px 8px 0px;
    height: 48px;
    width: 100%;
    background-color: ${props => props.primary ? '#7B00B2' : '#EDE7F1'};
    color: ${props => props.primary ? '#FFFFFF' : '#7B00B2'};
    font-size: 16px;
    padding: 12px 16px;
    font-weight: 800;
    letter-spacing: 0.8px;
    line-height: 22px;
    text-align: center;
  }
  ${mediaQuery290}{
      font-size: 14px;
  }
`;