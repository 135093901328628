import styled from 'styled-components';
import { mediaQuery1320, mediaQuery, mediaQuery290 } from '../../utils';

export const MainContainer = styled.div`
  display: flex;
  
  ${mediaQuery} {
    flex-direction: column;
  }
  
`;

export const PrimeContainer = styled.div`
  width: 100%;
  margin-left: 288px;
  height: calc(100vh - 80px);
  background-color: #FAFAFA;
  margin-top: 80px;
  padding: 32px 73px;
  display: flex;
  flex-direction: column;
  
  ${mediaQuery1320}{
    margin-left: 20%;
    margin-right: 10%;
  }
  .ant-tabs-ink-bar {
    background: #B361CF;

  } 
  
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #B361CF !important; 
    font-family: Rubik;
    font-size: 16px;
    letter-spacing: 0.15px;
    line-height: 24px;
    font-weight: 600;
}
  
  ${mediaQuery} {
    margin-left: auto;
    margin-top: 83.5px;
    height: calc(100vh - 203px);
    padding: 22px 16px;
  }
`;

export const TopContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 32px;
`;

export const UserItemAvatar = styled.div`
  border-radius: 50%;
  height: 88px;
  width: 88px;
  overflow: hidden;

  & img {
    height: 88px;
    width: 88px;
  }

  ${mediaQuery} {
    height: 56px;
    width: 56px;
    & img {
      height: 56px;
      width: 56px;
    }

    ${mediaQuery290}{
      height: 48px;
      width: 48px;
      & img {
        height: 48px;
        width: 48px;
      }
    }

  }
`;

export const UserName = styled.div`
  color: rgba(0,0,0,0.87);
  font-family: Rubik;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 40px;
  padding-left: 32px;
  ${mediaQuery} {
    padding-left: 16px;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 28px;
  }
`;

export const EndLine = styled.div`
  box-sizing: border-box;
  height: 1px;
  margin-top: 23.5px;
  background-color: #E5E5E5;
`;

export const FormContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;

  width: 100%;
`

export const Form = styled.div`
  margin: 0 10% 0 0;
  height: max-content;

  ${mediaQuery} {
    margin: 0 0px;
  }
  
  & #advanced_search_birthday {
    margin: 12px 0 4px 0;
  }
  
  & .ant-form-item-explain, .ant-form-item-extra {
    max-width: 392px;
    margin-bottom: 10px;
    ${mediaQuery} {
      max-width: 100%;
    }
  }
  
  .input {
    height: 52px;
    width: 100%;
    max-width: 432px;
    border: 1px solid #EDEDF3;
    border-radius: 8px;
    ${mediaQuery} {
      max-width: 100%;
    }
  }
  
`;

export const ButtonContainer = styled.div`
  margin: 9px 0 32px 0;
  width: 100%;
  padding: 0 10% 0 0;

  ${mediaQuery} {
    width: 100%;
    margin: 8px 0 36px 0;
    padding: 0 0px;
  }

  .button-register {
    background-color: #7B00B2;
    border: none;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.8px;
    line-height: 22px;
    text-align: center;
    width: 100%;
    max-width: 432px;
    height: 48px;
    border-radius: 8px;

    ${mediaQuery} {
      max-width: 100%;
    }
  }
`;

export const HeaderText = styled.div`
  height: 32px;
  width: 328px;
  color: rgba(0,0,0,0.87);
  font-family: Rubik;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.18px;
  line-height: 32px;
  display: none;
  ${mediaQuery}{
    display: block;
    margin-bottom: 24px;
  }


`;

export const DateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  
  & .ant-row {
    max-width: 100%;
    min-width: 100%;
  }
  
  ${mediaQuery} {
    max-width: 100%;
  }
`;

export const TabsContainer = styled.div`
 
`