import React, {memo, useState} from 'react';
import {LoginImage} from '../../Assets';
import Button from '../../components/Button/index';
import {Form, Input, message} from 'antd';
import {
    passwordRecoveryRequest,
} from '../../services/AccountServices';

import {
    MainContainer,
    ButtonContainer,
    LeftContainer,
    Image,
    Title, Desing,
} from './styles';
import {useHistory, useLocation} from "react-router-dom";
import https from "../../config/https";
import { Helmet } from "react-helmet";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function ResetPassword() {
    let query = useQuery();
    const history = useHistory();

    const [form] = Form.useForm();

    const confirmPasswordReset = () => {
        form.validateFields().then(values => {
            const token = query.get("token");
            const payload = {
                new_password: values.password,
                repeat_new_password: values.confirm
            }
            https.post(`/api/v1/auth/reset-password?token=${token}`, payload)
                .then(res => {
                    message.success({content: 'Contraseña reestablecida correctamente.', duration: 3});
                    history.push('/login');
                })
        }).catch(() => {
            // TODO sin mensaje
        });
    }


    return (
        <MainContainer id="login-container">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Recuperar Contraseña</title>
            </Helmet>
            <LeftContainer>
                <Title>Recuperar tu contraseña</Title>
                <Desing>
                    <Form
                        layout="vertical"
                        form={form}
                        initialValues={{
                            email: "",
                        }}
                        onFinish={() => confirmPasswordReset()}
                        scrollToFirstError
                        name="advanced_search"
                        className="ant-advanced-search-form"
                    >
                        <Form.Item
                            name="password"
                            label="Nueva contraseña"
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor introduce tu contraseña',
                                },
                                {
                                    pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
                                    message: "Tu contraseña debe contener al menos 1 número, 1 mayúscula, 1 minúscula, 8 caracteres como mínimo"
                                }
                            ]}
                        >
                            <Input.Password placeholder="Nueva contraseña" className="input"/>
                        </Form.Item>
                        <Form.Item
                            name="confirm"
                            label="Confirma la contraseña"
                            dependencies={['password']}
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor ingresa tu contraseña',
                                },
                                ({getFieldValue}) => ({
                                    validator(rule, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject('Las contraseñas ingresadas no coinciden');
                                    },
                                }),
                            ]}
                        >
                            <Input.Password placeholder="Confirma la contraseña" className="input"/>
                        </Form.Item>
                    </Form>
                </Desing>
                <ButtonContainer>
                    <Button
                        text="Restablecer contraseña"
                        className="button-create"
                        onClick={confirmPasswordReset}
                    />
                </ButtonContainer>
            </LeftContainer>
            <Image src={LoginImage} alt=""/>
        </ MainContainer>
    );
}

export default memo(ResetPassword);
