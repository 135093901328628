import {
    IconEventCustom,
    IconCheapComision,
    IconEventFast,
    IconEventAsesoria,
    IconEventOnlinePublicity,
    IconEventLocalMer,
    Facebook,
    Instagram,
    LinkedIn,
    Spotify,
    Tiktok,
    Twitter,
    Youtube,
    NuestrosClientesTestimonio1,
    NuestrosClientesTestimonio1Responsive,

} from '../../Assets';

export const advantages_data = [
    {'id':'1', 'icon': IconEventCustom, 'title':'Personalizado','description':  'Trabajamos de acuerdo a tus necesidades.'},
    {'id':'2', 'icon': IconCheapComision, 'title':'Comisión baja','description':  'Contamos con una de las comisiones más bajas.'},
    {'id':'3', 'icon': IconEventFast, 'title':'Rapidez','description':  'Tenemos el pago más rápido del mercado.'},
    {'id':'4', 'icon': IconEventAsesoria, 'title':'Asesoría','description':  'Atención rápida, reportes diarios y métricas.'},
    {'id':'5', 'icon': IconEventOnlinePublicity, 'title':'Publicidad digital','description':  'Alto alcance con difusión en todos nuestros canales.'},
    {'id':'6', 'icon': IconEventLocalMer,'title':'Mercado local','description': 'Llegamos e impactamos a tu público ideal.'},
]

export const instructions_data = [
    {'number': '1', 'title': 'Platícanos de tu proyecto', 'description':  `Accede al <a href="https://form.typeform.com/to/aFOy7WHS">formulario</a> y complétalo con la información.`},
    {'number': '2', 'title': 'Reúnete con nosotros', 'description':  `Concretamos todos los detalles de tu evento y resolvemos tus dudas.`},
    {'number': '3', 'title': 'Tu evento ya está con nosotros', 'description':  `Nosotros nos encargamos de darlo de alta en la plataforma.`},
    {'number': '4', 'title': 'Recibe seguimiento personalizado', 'description':  `Nosotros nos encargamos de darlo de alta en la plataforma.`},

]

export const doubts_topics_data = [
    {'id' : '1', 'title' : 'Costos y pagos'},
    {'id' : '2', 'title' : 'Logística '},
    {'id' : '3', 'title' : 'Plataforma'},
    {'id' : '4', 'title' : 'Publicidad'},
]

export const doubts_questions_data = [
    //Costos y pagos
    {'id' :  '1', 'foreignId' : '1', 'title': '¿Cuál es el costo de usar Guía de Hoy como boletera?', 'text': `
    Dependiendo de los detalles de tu evento realizamos un plan personalizado.
    `},
    {'id' :  '2', 'foreignId' : '1', 'title': '¿Qué tan rápido puedo recibir mi pago después de que se vendieron mis boletos?', 'text': `
    Tendrás disponibilidad a tu pago a 7 días mediante la app de Stripe connect.
    `},
    {'id' :  '3', 'foreignId' : '1', 'title': '¿Recibiré una factura por sus servicios?', 'text': `
    Sí, todos nuestros paquetes son facturables.
    `},
    //Logística
    {'id' :  '4', 'foreignId' : '2', 'title': '¿Mi evento puede ser de cupo limitado?', 'text': `
    Sí, trabajamos con cualquier tipo y tamaño de evento.
    `},
    {'id' :  '5', 'foreignId' : '2', 'title': '¿Qué pasa si mi evento se cancela?', 'text': `
    Tu evento se da de baja de la plataforma y en caso de que hayas vendido algún boleto solo se te cobrará la comisión de la boletera. La ganancia de cada ticket se verá reflejada y serás responsable de gestionar el reembolso.
    `},
    {'id' :  '6', 'foreignId' : '2', 'title': '¿Cómo funciona el escaneo el día del evento?', 'text': `
    Contamos con una aplicación y te mandaremos un tutorial para que tu equipo la pueda usar el día del evento.
    `},
    {'id' :  '7', 'foreignId' : '2', 'title': '¿Cómo los puedo contactar por cualquier duda?', 'text': `
    Escríbenos o llámanos al <a href="tel:6674718532">667 471 8532</a> con un horario de atención de 9:00am a 6:00pm.
    `},
    {'id' :  '8', 'foreignId' : '2', 'title': '¿Es posible dar cortesías a ciertos clientes?', 'text': `
    Sí, estos beneficios los reciben patrocinadores y algunos asistentes que necesites.
    `},
    {'id' :  '9', 'foreignId' : '2', 'title': '¿Cómo reciben los tickets mis asistentes?', 'text': `
    Las entradas se obtienen al finalizar la compra, se pueden descargar y compartir. Además se envían por correo electrónico con formato pdf.
    `},
    //Plataforma
    {'id' :  '10', 'foreignId' : '3', 'title': '¿Dónde puedo encontrar el formulario para publicar mi evento?', 'text': `
    Da click <a href="https://form.typeform.com/to/aFOy7WHS?typeform-source=localhost" target="_blank" >aquí</a> para completar tu información y te contactaremos lo más pronto posible.
    `},
    {'id' :  '11', 'foreignId' : '3', 'title': '¿Qué tipo de eventos se pueden dar de alta en la página? ', 'text': `
    Eventos con costo y eventos solo informativos (sin costo), de temas culturales, sociales, deportivos, recreativos, de formación y entretenimiento.
    `},
    {'id' :  '12', 'foreignId' : '3', 'title': 'Si tengo un evento sin costo, ¿puede estar en la plataforma?', 'text': `
    También difundimos eventos solamente informativos, estos espacios dentro de la página varían en precio a diferencia de usar la boletera.
    `},

    {'id' :  '13', 'foreignId' : '3', 'title': '¿Cómo saber si mi evento es apto para subirlo a la plataforma?', 'text': `
    Nos gusta tener una gran variedad de eventos, sin embargo no apoyamos ningún tipo de violencia o partido político.
    `},
    //Publicidad
    {'id' :  '14', 'foreignId' : '4', 'title': '¿Qué incluye la difusión del evento?', 'text': `
    Contamos con un menú de servicios que se adaptan a tu evento, los cuales pueden incluir difusión en nuestras redes sociales; banner principal y evento destacado en nuestra plataforma web; grabaciones de cápsulas de tu evento e integración a nuestro newsletter semanal.
    `},

]

export const our_clients_data = [
    {"id": 1, "image": NuestrosClientesTestimonio1, "imageResponsive": NuestrosClientesTestimonio1Responsive, "title": "Cervecería Matachín - Beer Fest", "description": "Colaborar con Guía de Hoy en nuestro festival fue una buena experiencia. Nos ayudaron en lo que requerimos en su momento. Nos agradó que sí sentimos mucho menos peso en nuestros hombros el día del evento, y eso fue muy importante.",
     "author": "Harry Gallegos" }

]

export const social_media_data = [
    {'number': '1', 'icon': Facebook, 'url_direction':'https://www.facebook.com/guiadehoymx/'},
    {'number': '2', 'icon': Twitter, 'url_direction':'https://twitter.com/guiadehoycln'},
    {'number': '3', 'icon': Instagram, 'url_direction':'https://www.instagram.com/guiadehoycln/'},
    {'number': '4', 'icon': Spotify, 'url_direction':'https://open.spotify.com/user/gotgwg9ue4zwdlecv15ivl3bv?si=yKDItPaGSVWVp-uRHp8KxQ'},
    {'number': '4', 'icon': Youtube, 'url_direction':'https://www.youtube.com/channel/UCVDtf1lfz6Qnc6THN_DLaaQ'},
    {'number': '4', 'icon': LinkedIn, 'url_direction':' https://www.linkedin.com/company/guia-de-hoy/'},

]
