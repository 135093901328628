import React, { memo,  } from 'react';

import 'moment/locale/es-mx';
import {
    MainContainer,
    CardContainer,
    Title,
    Image,
} from './styles';
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';

function UsBannerSeparate(props) {
    const {title, image, url, imageResponsive, titleWidth} = props;
    let history = useHistory();
    const goToPage = (url) => {
        history.push(url)
      };
    return (
        <>
        <MainContainer>
            <CardContainer onClick={() => goToPage(url)}>
                <Image src={image}/>
                <Title long={titleWidth}>{title}</Title>
            </CardContainer>
        </MainContainer>
        </>
    );
}
UsBannerSeparate.propTypes = {
    title: PropTypes.string,
    image: PropTypes.string,
    imageResponsive: PropTypes.string,
    url: PropTypes.string,
    titleWidth: PropTypes.string
};

UsBannerSeparate.defaultProps = {
    title: '',
    image: '',
    imageResponsive: '',
    url: '',
    titleWidth: ""
};
export default memo(UsBannerSeparate);
